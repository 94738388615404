import { Button, FormHelperText, IconButton, TextField, Typography, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { isCoordinatesValid } from '@terragotech/gen5-shared-utilities';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ActionIcon from '../../ActionIcon';
import PinCircleSidebar from './PinCircleSidebar';

const useInputStyles = makeStyles(theme => ({
  input: {
    '& > *': {
      minWidth: '275px',
      height: '35px',
      marginLeft: '10px',
    },
    width: '100%',
  },
  adornedStart: {
    paddingLeft: '4px',
  },
  changePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.palette.primary.main || '#56CCF2'}`,
  },
}));

const TGCoordinateTextField = (props: any) => {
  const classes = useInputStyles();
  const theme = useTheme();
  const {
    index,
    specificLocation,
    capturedLocation,
    setCapturedLocation,
    setBlankField,
    blankField,
    selectedPoint,
    setSelectedPoint,
    selectedPointRef,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  // Local Coords is the string manipulated while focused
  const [localCoords, setLocalCoords] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);

  const getCoordinateFromLocalCoord = (coordinates: string | null | undefined) => {
    if (!coordinates) {
      return undefined;
    }
    let _s = coordinates.split(',').map(x => Number(x));
    if (!_.isEmpty(_s) && Array.isArray(_s)) {
      return _.reverse(_s);
    }
    return undefined;
  };

  useEffect(() => {
    if (_.isEmpty(localCoords)) {
      setHasError(false);
    } else {
      const values = getCoordinateFromLocalCoord(localCoords);
      setHasError(
        !values ||
          !Array.isArray(values) ||
          !_.isNumber(values[1]) ||
          !_.isNumber(values[0]) ||
          !isCoordinatesValid(values[1], values[0])
      );
    }
  }, [localCoords]);

  const isSelectedPoint: boolean = selectedPoint?.indexOfCoords === index;
  return (
    <div
      key={index}
      style={{ width: '100%', paddingTop: 14, display: 'flex', alignItems: 'center', justifyContent: 'start' }}
    >
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
      <ActionIcon name="fa-bars" size='lg' color='gray'/>
      </div>
      <div style={{ flex: 9 }}>
        <TextField
          className={classes.input}
          // If localCoords is not null, display localCoords
          // If localCoords is null, check if blankField is true which means specificLocation is undefined,
          // if blank field display empty string, if not display specificLocation normally
          value={
            !localCoords && localCoords !== ''
              ? blankField
                ? ``
                : `${specificLocation?.coordinates[1]}, ${specificLocation?.coordinates[0]}`
              : localCoords
          }
          id="outlined-basic"
          style={{ border: '1px sold blue' }}
          onBlur={() => {
            //If no error displayed with localCoords, then set the state with the new coords in object
            if (localCoords && !hasError) {
              setCapturedLocation((prevState: any) => {
                if (blankField) {
                  if (prevState && Array.isArray(prevState) && localCoords) {
                    const temp = [...prevState];
                    // const parsedCoords = localCoords.split(',').map(x => Number(x));
                    return [...temp, { type: 'Point', coordinates: getCoordinateFromLocalCoord(localCoords) }];
                  } else {
                    return [
                      {
                        type: 'Point',
                        coordinates: getCoordinateFromLocalCoord(localCoords),
                      },
                    ];
                  }
                } else if (prevState && Array.isArray(prevState)) {
                  const temp = [...prevState];
                  if (temp[index] && temp[index]?.coordinates && localCoords) {
                    temp[index].coordinates = getCoordinateFromLocalCoord(localCoords);
                    return [...temp];
                  }
                } else {
                  return [
                    {
                      type: 'Point',
                      coordinates: getCoordinateFromLocalCoord(localCoords),
                    },
                  ];
                }
              });
            }
            setLocalCoords(null);
            setBlankField(index === 0);
          }}
          error={hasError}
          onChange={e => setLocalCoords(e.target.value)}
          placeholder={'Location Coordinates'}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ paddingLeft: -9 }}>
                <div style={{ width: 20, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ position: 'absolute', zIndex: 1, top: 6, left: 4 }}>
                    <PinCircleSidebar fill={theme.palette.primary.main || '#56CCF2'} />
                  </div>
                  <Typography style={{ color: 'white', zIndex: 100, position: 'absolute', marginTop: 1 }}>
                    {index + 1}
                  </Typography>
                </div>
              </InputAdornment>
            ),
            classes: {
              adornedStart: classes.adornedStart,
              input: classes.changePadding,
              notchedOutline: isSelectedPoint ? classes.notchedOutline : undefined,
            },
          }}
        />
        <FormHelperText style={{ color: 'red', position: 'absolute', paddingLeft: 15, fontStyle: 'italic' }}>
          {hasError && 'Coordinates are invalid'}
        </FormHelperText>
      </div>
        <IconButton
          color="default"
          aria-label="Delete Location"
          disabled={hasError}
          onClick={() => {
            if(hasError){
                return
            }
            if (blankField) {
              setBlankField(index === 0);
              setSelectedPoint(undefined);
              selectedPointRef.current = undefined;
              return;
            }
            setShowDialog(true);
          }}
        >
          <ActionIcon name="fa-xmark" size='sm'/>
        </IconButton>

      {showDialog && (
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`The coordinates and the location pin on the map will be deleted. Are you sure you want to delete the selected coordinates of location ${
                index + 1
              }?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                const temp = [...capturedLocation.slice(0, index), ...capturedLocation.slice(index + 1)];
                setCapturedLocation(temp);
                if (!blankField && temp.length === 0) {
                  setBlankField(true);
                }
                setSelectedPoint(undefined);
                selectedPointRef.current = undefined;
                setShowDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default TGCoordinateTextField;
