import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../utils/layers';
import ImportDoneCancelTitleWithToggle from '../components/Import/ImportDoneCancelTitleWithToggle';
import { CommandAction, AuthConnector } from '@terragotech/gen5-shared-components';
import useSubmitImport from '../hooks/useSubmitImport';
import { v4 } from 'uuid';
import { DOC_API_URL } from '../utils/docApiURL';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { usePusher } from '../contexts/pusherContext';
import _ from 'lodash';
import { UseConditionalImports, UseConditionalImport } from '../hooks/useConditionalImports';
import { getFileExtensionFromBlob } from '../utils/fileHelper';
import ImportFileScreen from '../components/Import/ImportFileScreen';
import ImportHistory from '../components/Import/ImportHistory';
import { FileWithPath } from 'react-dropzone';
import { useInfoContext } from '../contexts/InfoContext/infoContext';
import { useDataMapping } from '../hooks/useDataMapping';

export const QUERY = gql`
  {
    imports {
      id
      errorsWarnings {
        message
        is_error
        is_form_level
        row
        column
        sheet
        column_name
      }
      aggregateType
      commandName
      committed
      success
      isintegration
      filename
      metadata
      target
      timestamp
      type
      version
      commandError
      commandErrorMessage
    }
  }
`;

export interface QueryResult {
  imports: readonly {
    id: string;
    errorsWarnings: readonly {
      message: string;
      is_error: boolean;
      is_form_level: boolean;
      row: number;
      column: number;
      sheet: string;
      column_name: string;
    }[];
    aggregateType: string;
    commandName: string;
    committed?: boolean;
    success?: boolean;
    isintegration?: boolean;
    filename: string;
    metadata?: object;
    target: string;
    timestamp: Date;
    type: string;
    version: string;
    commandError?: number;
    commandErrorMessage?: string;
  }[];
}

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
  },
  displayRoot: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    width: '100vw',
    minHeight: 'calc(100vh - 46px)',
    backgroundColor: '#fff',
    overflowX: 'hidden',
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },
}));

interface Props {
  onBack: () => void;
}

const Import = (props: Props) => {
  const classes = useStyles();
  const pusher = usePusher();
  const impSubmit = useSubmitImport();
  const { openSnackbar } = useInfoContext();

  const [displayHistory, setDisplayHistory] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileWithPath | undefined>();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [index, setIndex] = useState('');
  const [imports, setImports] = useState<QueryResult>();
  const { buttons } = UseConditionalImports();

  const { data, refetch } = useQuery<QueryResult>(QUERY, { fetchPolicy: 'no-cache' });
  if (!_.isEqual(data, imports)) {
    setImports(data);
  }
  const [picked, setPicked] = useState<UseConditionalImport | undefined>();

  useEffect(() => {
    if (buttons && buttons[0]) {
      setPicked(buttons[0]);
    }
  }, [buttons]);

  const changeHandler = (acceptedFiles: FileWithPath[]) => {
    setSelectedFile(acceptedFiles[0]);
    setIsSelected(true);
  };

  const clearSelection = () => {
    setSelectedFile(undefined);
    setIsSelected(false);
  };

  const handleBackClick = () => {
    props.onBack();
  };

  useEffect(() => {
    const debounceRefetch = _.debounce(refetch, 100);
    pusher.subscribe('import', 'update', (m: any) => {
      console.log('Import update pusher message', m);
      openSnackbar({
        title: m.message,
        type: m.level || 'SUCCESS',
        autoHideDuration: m.level === 'ERROR' ? 30000 : undefined,
      });
      debounceRefetch();
    });
    return () => {
      pusher.unSubscribe('import', 'update');
    };
  });

  const downloadFile = async (fileUrl: string, fileName: string) => {
    const token = await AuthConnector.getToken();
    const response = await fetch(fileUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    });
    const blob = await response.blob();
    const fileExtension = (await getFileExtensionFromBlob(blob)) || 'csv';
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const apolloClient = useApolloClient();
  const contextGetter = useDataMapping();
  const downloadTemplate = async (aggregateType: string, commandName: string, version: number, fileName: string) => {
    const context = contextGetter();
    const metadata = context.accessors.METADATA ? context.accessors.METADATA() : {};
    const response = await apolloClient.query({
      query: gql`
        query importTemplate($METADATA: JSON) { importTemplate(aggregateType: "${aggregateType}", command: "${commandName}", version: ${version}, metadata: $METADATA){
          file
        } }
        `,
      fetchPolicy: 'no-cache',
      variables: {
        METADATA: metadata,
      },
    });
    const blob = new Blob([new Uint8Array(response.data.importTemplate.file.data).buffer]);
    const fileExtension = (await getFileExtensionFromBlob(blob)) || 'csv';
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const uploadFile = async (fileName: string) => {
    const id = v4();
    if (!picked) {
      return;
    }
    const fileUrl = DOC_API_URL + id;
    const token = await AuthConnector.getToken();
    const formData = new FormData();

    if (!selectedFile) {
      return;
    }

    formData.append('file', selectedFile);

    impSubmit
      .stageImport({
        target: id,
        version: (picked.action as CommandAction).commandVersion,
        aggregateType: picked.aggregate.name,
        commandName: (picked.action as CommandAction).commandName,
        fileName: fileName,
        isSynchronous: false, // Don't need high polling rate for this
        hasNoAggregateIdCommand: false,
      })
      .then(() => {
        refetch();
      });
    pusher.subscribe('import', id, (m: any) => {
      console.log('Pusher message from specific import', m);
      openSnackbar({
        title: m.message,
        type: m.level || 'SUCCESS',
        autoHideDuration: m.level === 'ERROR' ? 30000 : undefined,
      });
      pusher.unSubscribe('import', id);
    });

    fetch(fileUrl, {
      method: 'POST',
      body: formData,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          impSubmit.submitImport({
            target: id,
            version: (picked.action as CommandAction).commandVersion,
            aggregateType: picked.aggregate.name,
            commandName: (picked.action as CommandAction).commandName,
            fileName: id,
            isSynchronous: false, // Don't need high polling rate for this
            hasNoAggregateIdCommand: false,
          });
        }
      })
      .then(result => {
        console.log('Success:', result);
        clearSelection();
        setDisplayHistory(prev => !prev);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const commit = async (id: string) => {
    console.log('Committing import with id ', id);
    impSubmit.commitImport(id);
  };

  const [fileIdToView, setFileIdToView] = useState<string | undefined>();

  return (
    <div style={{ overflowX: 'hidden' }}>
      {!displayHistory && (
        <div className={classes.root}>
          <ImportDoneCancelTitleWithToggle
            onToggle={() => setDisplayHistory(prev => !prev)}
            title={'Import'}
            onDone={handleBackClick}
          />

          {!displayHistory && (
            <ImportFileScreen
              downloadFile={downloadFile}
              picked={picked}
              changeHandler={changeHandler}
              buttons={buttons}
              setPicked={setPicked}
              uploadFile={uploadFile}
              clearSelection={clearSelection}
              downloadTemplate={downloadTemplate}
              index={index}
              setIndex={setIndex}
              selectedFile={selectedFile}
              isSelected={isSelected}
            />
          )}
        </div>
      )}
      {displayHistory && (
        <div>
          <ImportDoneCancelTitleWithToggle
            onToggle={() => setDisplayHistory(prev => !prev)}
            title={fileIdToView ? 'Import Details' : 'Import History'}
            fileIdToView={Boolean(fileIdToView)}
            setFileIdToView={setFileIdToView}
            onDone={handleBackClick}
          />
          <div>
            {displayHistory && (
              <div className={classes.displayRoot}>
                <ImportHistory
                  data={data}
                  onToggle={() => setDisplayHistory(prev => !prev)}
                  commit={commit}
                  fileIdToView={fileIdToView}
                  setFileIdToView={setFileIdToView}
                  buttons={buttons}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Import;
