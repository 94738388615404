import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuClassKey,
  MenuItem,
  StyledComponentProps,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import Info from '@material-ui/icons/Info';
import Input from '@material-ui/icons/Input';
import magicText from 'i18next';
import React, { FunctionComponent, useContext } from 'react';
import AboutMenuItemModalContainer from '../containers/AboutMenuItemModalContainer';
import { useAccountModal } from '../contexts/accountModalContext';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { useUserInfo, colors } from '@terragotech/gen5-shared-components';
import settingsGear from '../images/settingsGear.svg';

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      marginTop: 62,
    },
    topMenuItem: {
      fill: '#8c8c8c',
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    menuItem: {
      fill: '#8c8c8c',
    },
    changePasswordButton: {
      padding: 0,
      '&:hover': {
        background: 'unset',
      },
      textTransform: 'none',
    },
    primary: {
      color: colors.black,
    },
    secondary: {
      color: colors.black,
    },
    icon: {
      margin: 0,
      width: 35,
      minWidth: 35,
    },
    accountIcon: {
      padding: 0,
    },
    divider: {
      width: 200,
      alignSelf: 'center',
      marginLeft: 8,
    },
    smallSettingsLabel: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 6,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
    },
    smallSettingsWrapper: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  });

interface AccountModalProps extends WithStyles<typeof styles> {
  menu: any;
  onClose: () => void;
  onClick: (event: any) => void;
  onLogoutClick: () => void;
  user: string;
  username: string;
  permissionStatus: string;
  openChangePasswordModal: boolean;
  openPasswordModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closePasswordModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openAboutModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closeAboutModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openAbout: boolean;
  menuClasses?: StyledComponentProps<MenuClassKey>['classes'];
}

const AccountModal: FunctionComponent<AccountModalProps> = (props) => {
  const { openUserAndRoles } = useAccountModal();
  const { fullscreen } = useContext(AssetsDashboardContext);
  const { isAdmin } = useUserInfo();

  const handleAboutOpen = (event: any) => {
    if (props.openAbout === false) props.openAboutModal(event);
  };

  const handleUsersAndRolesOpen = (_: any) => {
    openUserAndRoles();
    props.onClose();
  };

  return (
    <div>
      {!fullscreen ? (
        <IconButton
          className={props.classes.accountIcon}
          aria-owns={props.menu ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={props.onClick}
        >
          <img src={settingsGear} alt="accountIcon" />
        </IconButton>
      ) : (
        <div className={props.classes.smallSettingsWrapper} onClick={props.onClick}>
          <img src={settingsGear} alt="accountIcon" style={{ width: 20 }} />
          <Typography variant="h2" className={props.classes.smallSettingsLabel}>
            <> {magicText.t('Settings.AccountModalLabel')}</>
          </Typography>
        </div>
      )}
      <Menu
        disableAutoFocus
        className={props.classes.layout}
        id="simple-menu"
        anchorEl={props.menu}
        open={Boolean(props.menu)}
        onClose={props.onClose}
        classes={props.menuClasses}
      >
        <MenuItem className={props.classes.topMenuItem}>
          <ListItemText
            classes={{ primary: props.classes.primary, secondary: props.classes.secondary }}
            primary={`${props.user} | ${props.username}`}
            secondary={`${magicText.t('Settings.Permissions')}: ${props.permissionStatus}`}
          />
        </MenuItem>
        <Divider className={props.classes.divider} />
        {/*         <MenuItem className={props.classes.menuItem} onClick={props.onClick}>
          <Button onClick={props.openPasswordModal} className={props.classes.changePasswordButton}>
            <ListItemIcon className={props.classes.icon}>
              <Lock />
            </ListItemIcon>
            <ListItemText classes={{ primary: props.classes.primary }} primary="Change Password" />
          </Button>
          <PasswordModalContainer
            onClick={props.onClick}
            username={props.username}
            openChangePasswordModal={props.openChangePasswordModal}
            closePasswordModal={props.closePasswordModal}
          />
        </MenuItem> */}
        {isAdmin && (
          <MenuItem className={props.classes.menuItem} onClick={handleUsersAndRolesOpen}>
            <ListItemIcon className={props.classes.icon}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: props.classes.primary }} primary="Users &amp; Roles" />
          </MenuItem>
        )}
        <MenuItem className={props.classes.menuItem} onClick={handleAboutOpen}>
          <ListItemIcon className={props.classes.icon}>
            <Info />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: props.classes.primary }}
            primary={<>{magicText.t('Settings.aboutText')}</>}
          />

          <AboutMenuItemModalContainer openAbout={props.openAbout} closeAboutModal={props.closeAboutModal} />
        </MenuItem>
        <MenuItem className={props.classes.menuItem} onClick={props.onLogoutClick}>
          <ListItemIcon className={props.classes.icon}>
            <Input style={{ transform: `rotate(90deg)` }} />
          </ListItemIcon>
          <ListItemText classes={{ primary: props.classes.primary }} primary={<>{magicText.t('Common.logOut')}</>} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(AccountModal);
