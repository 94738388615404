import React, { useRef, useEffect, useCallback, useMemo, useContext, useState } from 'react';
import RDG, { CheckCellIsEditableEvent, DataGridHandle, SortDirection } from '@terragotech/react-data-grid';
import '@terragotech/react-data-grid/dist/react-data-grid.css';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import StyledTableEditor from './Editors/StyledTableEditor';
import { useTable, TableData } from '../../hooks/useTable';
import StyledTableEmpty from './StyledTableEmpty';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Column, FilterRendererProps, ID_COLUMN_KEY } from '../../hooks/tableHooks/useColumns';
import { DIALOG_Z_INDEX } from '../../utils/layers';
import { EditModeContext } from '../../contexts/editModeContext';
import _, { isArray } from "lodash";
import { getLighterColorCode } from "../../utils/utilityHelper";

export const rowHeight = 24;
const headerRowHeight = 32;
const filterRowHeight = 29;

export interface StyledTableProps<Data extends TableData> {
  columns: ReadonlyArray<Column<Data>>;
  data: ReadonlyArray<Data>;
  width: number;
  height: number;
  onCellBlur?: (id: string, field: string, value: unknown) => void;
  onChange?: (id: string, field: string, value: unknown) => void;
  onLoad?: (firstRowIndex: number) => void | Promise<void>;
  onEditModeOn?: (row: Data) => void;
  backdrop?: boolean;
  selectedRowComparator?: (row: Data) => boolean;
  filterRenderer: (props: FilterRendererProps<Data>) => JSX.Element;
  onColumnsMove: (fromIndex: number, toIndex: number) => void;
  onBlur?: () => void;
  onInput?: () => void;
  emptyView?: () => JSX.Element;
  setSortColumn?: (column: string) => void;
  setSortDirection?: (direction: SortDirection) => void;
  editRowComparator?: (row: Data) => boolean;
  onCheckCellIsEditable?: (event: CheckCellIsEditableEvent<Data, unknown>) => boolean;
}

const StyledTable = <Data extends TableData>(props: StyledTableProps<Data>) => {
  const {
    onCellBlur,
    onChange,
    onLoad,
    selectedRowComparator,
    columns,
    data,
    onBlur,
    height,
    setSortColumn,
    setSortDirection,
    editRowComparator,
    onCheckCellIsEditable,
  } = props;
  const classes = useStyles();
  const handle = useRef<DataGridHandle>(null);

  /*
    To prevent filters losing focus and unnecessary rerenders all arguments passed to useTable need to be declared using either useMemo, useCallback or useState.
    Otherwise changing references would cause columns to recompute and the table would reload.
  */
  const [dataToRender, setDataToRender] = useState<{data:readonly Data[],columns:any}>({data:[],columns:[]});
  const { sortColumn, sortDirection, columnsToRender: columnsIntermediate, dataToRender: tableRows, setSort } = useTable<Data>({
    columns,
    data,
    editable: !!props.onChange,
    filterRenderer: props.filterRenderer,
    headerRenderer: DraggableHeaderRenderer,
    editor: StyledTableEditor,
    handleColumnsMove: props.onColumnsMove,
    setEditModeOn: props.onEditModeOn,
  });
  useEffect(() => {
    setDataToRender({
      data: tableRows.map((tableRow, idx) => {
        return {...tableRow, [ID_COLUMN_KEY]: idx}
      }),
      columns: columnsIntermediate
    });
  }, [tableRows, columnsIntermediate]);
  const [editedData, setEditedData] = useState<readonly Data[]>([]);
  const { editModeActive, editModeData } = useContext(EditModeContext);
  useEffect(() => {
    if (editModeActive && editedData.length === 0) {
      setEditedData(dataToRender.data);
    } else if (!editModeActive && editedData.length !== 0) {
      setEditedData([]);
    }
  }, [editModeActive, editedData, dataToRender]);

  useEffect(() => {
    setSortColumn && setSortColumn(sortColumn);
    setSortDirection && setSortDirection(sortDirection);
  }, [sortColumn, sortDirection, setSortColumn, setSortDirection]);

  const handleSetSort = (columnKey: string, direction: SortDirection) => {
    setSort(columnKey, direction);
  };

  const getNewRow = useCallback(
    (rows: any): (Data & { __changes: { [index: string]: string } }) | null =>
      rows.find((row: Data & { __changes: { [index: string]: string } }) => row.__changes),
    []
  );
  const getColumn = useCallback((newRow: any): string | null => newRow && Object.keys(newRow.__changes)[0], []);

  const handleOnCellBlur = useCallback(
    (rows: any): void => {
      const newRow = rows.find((row: Data & { __changes: { [index: string]: string } }) => {
        return row.__changes;
      });
      //we will assume only one row has changed. This will only work if the data model is rebuilt when changes are pushed.
      if (newRow) {
        let col = Object.keys(newRow.__changes)[0];

        onCellBlur && onCellBlur(newRow.id as string, col, newRow.__changes[col]);
        delete newRow['__changes'];
        setEditedData(editedData.map((cell) => (cell.id === newRow.id ? newRow : cell)));
        onBlur && onBlur();
      }
    },
    [onBlur, onCellBlur, editedData]
  );

  const handleOnChange = useCallback(
    (rows: any): void => {
      const newRow = getNewRow(rows);
      if (newRow) {
        let col = getColumn(newRow);
        col && onChange && onChange(newRow.id as string, col, newRow.__changes[col]);
      }
    },
    [getColumn, getNewRow, onChange]
  );

  const selectedRowIndex = useMemo(
    () => (selectedRowComparator ? dataToRender.data.findIndex((row) => selectedRowComparator(row)) : undefined),
    [selectedRowComparator, dataToRender]
  );

  const editRowIndices = useMemo(
      () => (editRowComparator ? (editedData.length === 0 ? dataToRender.data : editedData).reduce((acc, curr, i) =>
        editRowComparator(curr) && [...acc, i] || acc
      , [] as number[]) : undefined),
      [editRowComparator, dataToRender, editedData]
  );

  useEffect(() => {
    if (handle.current && selectedRowComparator) {
      handle.current.scrollToRow(dataToRender.data.findIndex((row) => selectedRowComparator(row)));
    }
  }, [selectedRowComparator, dataToRender]);

  const handleScroll = useMemo(
    () => async (event: React.UIEvent<HTMLDivElement>) => {
      if (onLoad) {
        await onLoad(Math.floor(event.currentTarget.scrollTop / rowHeight));
      }
    },
    [onLoad]
  );

  const handleCheckCellIsEditable = useCallback((event: CheckCellIsEditableEvent<Data, unknown>) =>
    onCheckCellIsEditable ? onCheckCellIsEditable(event) : (editModeActive && (isArray(editModeData) ? editModeData.some(x => x.id === event?.row?.id) : event?.row?.id === editModeData?.id))
  , [onCheckCellIsEditable, editModeActive, editModeData]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={!editModeActive ? classes.root : `${classes.root} ${classes.editModeRoot}`}>
        <RDG
          enableFilters
          columns={dataToRender.columns}
          rows={editedData.length === 0 ? dataToRender.data : editedData}
          onCellBlur={handleOnCellBlur}
          onChange={handleOnChange}
          rowHeight={rowHeight}
          headerFiltersHeight={filterRowHeight}
          headerRowHeight={headerRowHeight}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={handleSetSort}
          emptyRowsRenderer={props.emptyView ? props.emptyView : StyledTableEmpty}
          onCheckCellIsEditable={handleCheckCellIsEditable}
          ref={handle}
          onScroll={handleScroll}
          rowKey={ID_COLUMN_KEY}
          selectedRows={editModeActive ? new Set((editRowIndices ?? []) as Data[typeof ID_COLUMN_KEY][]) : new Set((!_.isNil(selectedRowIndex) ? [selectedRowIndex] : []) as Data[typeof ID_COLUMN_KEY][])}
          style={{ height: height - 3 }}
        />
      </div>
      {!!props.backdrop && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    zIndex: DIALOG_Z_INDEX,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: '#000000',
  },
  root: {
    '& .rdg': {
      overflowY: 'auto',
    },
    display: 'block',
    width: '100%',
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    '& .rdg-editor-container': {
      marginTop: '-8px',
    },
    '& .rdg-cell-mask': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rdg-cell': {
      fontWeight: '500',
      '&.rdg-cell-frozen-last': {
        borderRightWidth: '2px',
      },
    },
    '& .rdg-row': {
      height: `${rowHeight}px`,
      fontSize: '13px',
    },
    '& .rdg-row:nth-of-type(2n)': {
      backgroundColor: '#F8F8F8',
      '&.rdg-row-selected': {
        backgroundColor: '#E0E0E0',
      },
    },
    '& .rdg-row:nth-of-type(2n+1)': {
      backgroundColor: '#FFF',
      '&.rdg-row-selected': {
        backgroundColor: '#E0E0E0',
      },
    },
    '& .rdg-row-selected': {
      backgroundColor: '#E0E0E0',
    },
    '& .rdg-filter-row': {
      height: `${filterRowHeight}px`,
      fontSize: '13px',
      top: `${headerRowHeight}px`,
    },
    '& .rdg-header-row': {
      fontSize: '13px',
      height: `${headerRowHeight}px`,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '& .rdg-cell': {
        fontWeight: 500,
        height: '100%',
        padding: '0',
        borderBottom: 'none',
        '& .rdg-header-sort-cell': {
          cursor: 'pointer',
          height: '100%',
          '& span:nth-of-type(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  editModeRoot: {
    '& .rdg-row:nth-of-type(2n)': {
      backgroundColor: '#F8F8F8',
      '&.rdg-row-selected': {
        marginLeft: -1,
        backgroundColor: `${getLighterColorCode(`${theme?.palette?.primary?.main}`, 70)}`,
        border: `1px solid ${theme?.palette?.primary?.main}`
      },
    },
    '& .rdg-row:nth-of-type(2n+1)': {
      backgroundColor: '#FFF',
      '&.rdg-row-selected': {
        marginLeft: -1,
        backgroundColor: `${getLighterColorCode(`${theme?.palette?.primary?.main}`, 70)}`,
        border: `1px solid ${theme?.palette?.primary?.main}`
      },
    },
    '& .rdg-row-selected': {
      marginLeft: -1,
      backgroundColor: `${getLighterColorCode(`${theme?.palette?.primary?.main}`, 70)}`,
      border: `1px solid ${theme?.palette?.primary?.main}`
    },
    '& .rdg-cell-selected': {
      boxShadow: 'none',
    }
  }
}));

export default StyledTable;
