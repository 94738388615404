import React, { useCallback, useState } from 'react';
import {
  Dialog,
  makeStyles,
  createStyles,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import PasswordChangeView from '../PasswordChangeView';
import magicText from 'i18next';
import { AuthConnector } from '@terragotech/gen5-shared-components';

export interface ForgotPasswordFlowProps {
  title: string;
  okText?: string;
  onClose: () => void;
}

const ForgotPasswordFlow: React.FC<ForgotPasswordFlowProps> = props => {
  const classes = useStyles();
  const { okText, title, onClose } = props;
  const [password, setPassword] = useState<string | undefined>();
  const [email, setEmail] = useState<string>();
  const [emailCaptured, setEmailCaptured] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState();

  const handlePassword = useCallback((newPassword?: string) => {
    setPassword(newPassword);
  }, []);
  const handleForgotPasswordSubmit = useCallback(() => {
    if (email && code && password) {
      setError(undefined);
      AuthConnector.forgotPasswordSubmit(email, code, password)
        .then(data => {
          onClose();
        })
        .catch(err => {
          setError(err.message);
        });
    }
  }, [code, email, onClose, password]);

  const handleEmailSubmitted = useCallback(() => {
    if (email) {
      AuthConnector.forgotPassword(email).then(() => {
        setEmailCaptured(true);
      });
    }
  }, [email]);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      className={classes.dialogBox}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!emailCaptured && (
          <>
            <DialogContentText>
              <>{magicText.t('PasswordReset.EnterEmailText')}</>
            </DialogContentText>
            <TextField
              label={<>{magicText.t('PasswordReset.EnterEmailLabel')}</>}
              required
              className={classes.input}
              value={email}
              onChange={ev => setEmail(ev.target.value)}
            />
          </>
        )}
        {emailCaptured && (
          <>
            <DialogContentText>
              <>{magicText.t('PasswordReset.EnterCodeText')}</>
            </DialogContentText>
            <TextField
              label={<>{magicText.t('PasswordReset.VerificationCode')}</>}
              required
              className={classes.input}
              value={code}
              onChange={ev => setCode(ev.target.value)}
            />
            <PasswordChangeView {...props} onPasswordChange={handlePassword} />
          </>
        )}
        {error && <DialogContentText color="error">{error}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {!emailCaptured && (
          <Button
            className={classes.btn}
            disabled={!email}
            onClick={handleEmailSubmitted}
            color="primary"
            variant="contained"
          >
            <> {okText || magicText.t('Submit')}</>
          </Button>
        )}
        {password && code && (
          <Button className={classes.btn} onClick={handleForgotPasswordSubmit} color="primary">
            <> {okText || magicText.t('Change Password')}</>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    dialogBox: {
      '& .MuiPaper-root.MuiDialog-paper': {
        minWidth: 500,
      },
    },
    btn: {
      letterSpacing: '1.25px',
      fontWeight: 500,
      fontSize: 14,
    },
    input: {
      display: 'flex',
      width: '100%',
      marginBottom: 20,
    },
  })
);

export default ForgotPasswordFlow;
