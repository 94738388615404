import React from 'react';
import { Select, MenuItem, List } from '@material-ui/core';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import MapIcon from '@material-ui/icons/Map';
import magicText from 'i18next';

export interface MapTypeSectionProps {
  allowedTypes: string[];
  currentType: string;
  onChange: (value: string) => void;
}

export const mapTypeSection = (props: MapTypeSectionProps): DrawerItem => ({
  icon: <MapIcon />,
  iconMenu: (
    <List>
      {props.allowedTypes.map((type) => (
        <MenuItem key={type} onClick={() => props.onChange(type)} selected={type === props.currentType}>
          {type}
        </MenuItem>
      ))}
    </List>
  ),
  title: magicText.t('map.mapType.header'),
  content: (
    <Select value={props.currentType} onChange={(e) => props.onChange(String(e.target.value))}>
      {props.allowedTypes.map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </Select>
  ),
});
