import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Theme, Button, Typography, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import dialogWarningIcon from '../images/importPage/dialogWarningIcon.svg';
import DialogQuestionMark from './DialogQuestionMark';

interface AlertDialogProps {
  open: boolean;
  title: string;
  bodyText?: string;
  warningIcon?: boolean;
  bodySubtext?: string;
  children?: React.ReactNode;
  confirmButtonTitle: string;
  onConfirm: () => void;
  onCancel?: () => void;
  theme: Theme;
}

export default function TGAlertDialogWithIcon({
  open,
  title,
  children,
  confirmButtonTitle,
  onConfirm,
  onCancel,
  bodyText,
  bodySubtext,
  warningIcon,
  theme,
}: AlertDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={false}
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          display: 'flex',
          height: 66,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #D2D2D2',
        }}
      >
        <Typography
          style={{
            marginLeft: 30,
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: 30,
            lineHeight: '35px',
            color: `${theme?.palette?.primary?.main || "#56CCF2"}`,
          }}
        >
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div style={{ display: 'flex', width: 750, marginTop: 15 }}>
          <div style={{ marginRight: 50, width: 80 }}>
            {warningIcon ? (
              <img src={dialogWarningIcon} alt="Warning Icon" width={80} />
            ) : (
              <DialogQuestionMark color={`${theme?.palette?.primary?.main || "#56CCF2"}`} size={80} />
            )}
          </div>
          <div
            style={{
              width: 570,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{ fontFamily: 'Roboto', fontWeight: 300, fontSize: 22, lineHeight: '25px', color: '#000000' }}
            >
              {bodyText}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 200,
                fontSize: 18,
                lineHeight: '20px',
                color: '#656565',
                marginTop: 3,
              }}
            >
              {bodySubtext}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginTop: '20px', marginBottom: '16px' }}>
          <Button
            onClick={onCancel}
            style={{ marginLeft: '10px', color: `${theme?.palette?.primary?.main || "#56CCF2"}`, borderRadius: 7, width: 142, height: 40, marginRight: 10 }}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            style={{ marginLeft: '10px', color: '#FFFFFF', borderRadius: 7, width: 142, height: 40, marginRight: 10 }}
            color="primary"
            variant="contained"
          >
            {confirmButtonTitle}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
