import React from 'react';
import {
  FormRendererComponentMap,
  FieldProps,
  FormControlProps,
  FileFieldProps,
  FileFieldPropsWithName,
} from '@terragotech/form-renderer';
import TGLocationField from '../FormFields/TGLocationField/TGLocationField';
import TGPolylineField from '../FormFields/TGLocationField/TGPolylineField';
import {
  withTextFieldProps,
  withTextHeaderProps,
  withCheckboxProps,
  withTimeFieldProps,
  withRadioFieldProps,
  withNumberFieldProps,
  withCurrencyFieldProps,
  withTextareaFieldProps,
  withSelectFieldProps,
  withDateFieldProps,
  withLocationFieldProps,
  withMediaFieldProps,
  withBarcodeFieldProps,
  withAggregateSelectorFieldProps,
  withPolylineFieldProps,
  withTextFieldPropsComputed,
  withFileFieldProps,
} from './FormHelpers';
import { V2FormComponentDef, V2TextHeaderComponent } from '@terragotech/form-renderer';
import TGMapAggregateSelectorField from '../FormFields/TGMapAggregateSelectorField';
import {
  TGBarCodeField,
  TGDateField,
  TGFileUploadField,
  TGImageUploadField,
  TGRadioField,
  TGSelectField,
  TGTextField,
  TGTextHeader,
  TGTimeField,
  GroupWrapper,
  RepeatWrapper,
  RepeatFieldWrapper,
  RootWrapper,
  WorkflowFieldWrapper,
} from '@terragotech/gen5-shared-components';

const withNoRepeatableWorkflowFieldWrapper = <T extends FormControlProps<V2TextHeaderComponent>>(
  Component: React.FC<T>
) => {
  return (props: T) => {
    const { controlDefinition } = props;

    return (
      <WorkflowFieldWrapper controlDefinition={controlDefinition} repeatable={false}>
        <Component {...props} />
      </WorkflowFieldWrapper>
    );
  };
};

/**
 * This is a higher order component that wraps a repeatable component instance and determines its visibility based on the current context and props
 * Its main responsibility is to hide repeated elements if the repeat is collapsed
 * @param Component The UI component to wrap
 */
const withRepeatable = (Component: React.ElementType) => {
  return (
    props:
      | FieldProps<V2FormComponentDef>
      | FileFieldProps<V2FormComponentDef>
      | FileFieldPropsWithName<V2FormComponentDef>
  ) => {
    const {
      // multiline, we will reuse it maybe later
      repeatable,
      index,
      repeatCount,
      controlDefinition,
      onAddClick,
      onRemoveClick,
      moveItems,
      isOriginal,
    } = props;
    return repeatable ? (
      <RepeatFieldWrapper
        onAddClick={onAddClick}
        onRemoveClick={onRemoveClick}
        controlDefinition={controlDefinition}
        repeatable={repeatable}
        index={index}
        repeatCount={repeatCount}
        moveItems={moveItems}
        isOriginal={isOriginal}
      >
        <Component {...props} />
      </RepeatFieldWrapper>
    ) : (
      <div style={{ marginBottom: 10 }}>
        <Component {...props} />
      </div>
    );
  };
};

/**
 * This is a higher order component that wraps a repeatable component instance and determines its visibility based on the current context and props
 * Its main responsibility is to hide repeated elements if the repeat is collapsed
 * @param Component The UI component to wrap
 */
// const withFileRepeatable = (Component: React.ElementType) => {
//   return (props: FieldProps<V2FormComponentDef> | FileFieldProps<V2FormComponentDef>) => {
//     const {
//       // multiline, we will reuse it maybe later
//       repeatable,
//       index,
//       repeatCount,
//       controlDefinition,
//       onAddClick,
//       onRemoveClick,
//       moveItems,
//       isOriginal,
//     } = props;
//     return repeatable ? (
//       <RepeatFieldWrapper
//         onAddClick={onAddClick}
//         onRemoveClick={onRemoveClick}
//         controlDefinition={controlDefinition}
//         repeatable={repeatable}
//         index={index}
//         repeatCount={repeatCount}
//         moveItems={moveItems}
//         isOriginal={isOriginal}
//       >
//         <Component {...props} />
//       </RepeatFieldWrapper>
//     ) : (
//       <div style={{ marginBottom: 10 }}>
//         <Component {...props} />
//       </div>
//     );
//   };
// };

const ControlProvider: FormRendererComponentMap = {
  //Can't use filterFieldProps on the textheader, because it isn't a field.
  RootWrapper: RootWrapper,
  GroupWrapper: GroupWrapper,
  RepeatWrapper: RepeatWrapper,
  textheader: withNoRepeatableWorkflowFieldWrapper(withTextHeaderProps(TGTextHeader)),
  computed: withRepeatable(withTextFieldPropsComputed(TGTextField)),
  textInput: withRepeatable(withTextFieldProps(TGTextField)),
  radio: withRepeatable(withRadioFieldProps(TGRadioField)),
  numberInput: withRepeatable(withNumberFieldProps(TGTextField)),
  select: withRepeatable(withSelectFieldProps(TGSelectField)),
  checkbox: withRepeatable(withCheckboxProps(TGSelectField)),
  time: withRepeatable(withTimeFieldProps(TGTimeField)),
  date: withRepeatable(withDateFieldProps(TGDateField)),
  textArea: withRepeatable(withTextareaFieldProps(TGTextField)),
  currency: withRepeatable(withCurrencyFieldProps(TGTextField)),
  imageupload: withRepeatable(withMediaFieldProps(TGImageUploadField)),
  fileupload: withRepeatable(withFileFieldProps(TGFileUploadField)),
  barcode: withRepeatable(withBarcodeFieldProps(TGBarCodeField)),
  location: withRepeatable(withLocationFieldProps(TGLocationField)),
  polyline: withRepeatable(withPolylineFieldProps(TGPolylineField)),
  mapAggregateSelector: withRepeatable(withAggregateSelectorFieldProps(TGMapAggregateSelectorField)),
};

export default ControlProvider;
