import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useTableColumns } from '../../contexts/TableColumnContext';
import BottomActionBar from './BottomActionBar';
import FluidStyledTable from '../StyledTable/FluidStyledTable';
import { Column, FilterRendererProps } from '../../hooks/tableHooks/useColumns';
import SlideDialog from '../SlideDialog';
import ColumnFilter from '../Filters/ColumnFilter/ColumnFilter';
import ColumnSearch from './ColumnSearch';
import { moveColumns } from '../StyledTable/utils';
import { EditModeContext } from '../../contexts/editModeContext';
import { useSelectedProject } from '../../contexts/selectedProjectContext';
import magicText from 'i18next';
import { useFilter } from '../../contexts/FilterContext/filterContext';
import { AssetType } from '../../contexts/AggregatesContext/types';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';
import { isArray } from 'lodash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
});

export type RecordsChanged = {
  [recordId: string]: {
    [field: string]: unknown;
  };
};
export interface AssetTableProps {
  height: number;
  rowData: any[];
  id?: string;
  columnFilterOpen: boolean;
  setColumnFilterOpen: (value: boolean) => void;
  changedRecords?: Array<string>;
  onEditSaved: () => void;
  onEditCanceled: () => void;
  onCellBlur?: (recordId: string, field: string, value: unknown) => void;
  onChange?: (recordId: string, field: string, value: unknown) => void;
}

const getNumberOfStickyColumns = (columns: ReadonlyArray<Column<AssetType>>) => {
  return columns.reduce<number>((r, x) => r + (x.sticky ? 1 : 0), 0);
};

const EmptyView = (props: { filterCount: number; selectedProjects: any[]; height: number }) => {
  const HEADER_HEIGHT = 64;
  const BOTTOM_GAP = 64;
  return (
    <div
      style={{
        width: '100%',
        height: props.height - HEADER_HEIGHT - BOTTOM_GAP,
        position: 'fixed',
        left: 0,
        top: window.innerHeight - props.height + HEADER_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <>
        {!props.filterCount && props.selectedProjects.length
          ? magicText.t('AssetTable.noRowsNotFiltered')
          : magicText.t('AssetTable.noRowsFiltered')}
      </>
    </div>
  );
};

/**
 *
 * AssetTable - Displays list of filtered assets on bottom half of the screen.
 *
 * Data for the table is passed down from the container as "rowData"
 *
 * There are many props passed to assetTable from its container
 *
 * @param height
 * @param columnDefs
 * @param rowData
 * @param assetId
 * @param columnFilterOpen
 * @param setColumnFilterOpen
 * @param setColumnDefs
 * @param allAvailableColumns
 * @param setAllCols
 * @param shouldSelectAllNodes
 * @param setShouldSelectAllNodes
 * @param setSelected
 * @param selected
 * @param setValue
 * @param changedAssets
 * @param setChangedAssets
 */
const AssetTable: FunctionComponent<AssetTableProps> = (props) => {
  const {
    height,
    rowData,
    changedRecords,
    onEditCanceled,
    onEditSaved,
    id,
    columnFilterOpen,
    setColumnFilterOpen,
    onChange,
  } = props;
  const classes = useStyles();

  const { setEditModeActive, setSaveActive, editModeActive, editModeData } = useContext(EditModeContext);
  const { columns, setColumnOrder } = useTableColumns();
  const { filterCount } = useFilter();
  const { selectedProjects } = useSelectedProject();
  const { setSortColumn, setSortDirection } = useAssetsTableState();
  const handleColumnMove = useCallback(
    (fromIndex: number, toIndex: number) => {
      const numberOfPinned = getNumberOfStickyColumns(columns);
      const newColumns = moveColumns(columns, fromIndex, toIndex);
      const numberOfPinnedDelta = (() => {
        if (fromIndex >= numberOfPinned && toIndex < numberOfPinned) return 1;
        if (fromIndex < numberOfPinned && toIndex >= numberOfPinned) return -1;
        return 0;
      })();
      setColumnOrder(
        newColumns.map((column, index) => ({
          ...column,
          key: column.key,
          sticky: index < numberOfPinned + numberOfPinnedDelta,
        }))
      );
    },
    [setColumnOrder, columns]
  );

  const handleCellEditStart = useCallback(() => {
    setSaveActive(false);
  }, [setSaveActive]);

  const handleCellEditStop = useCallback(() => {
    setSaveActive(true);
  }, [setSaveActive]);

  const stopCellEditing = useCallback(() => {
    setEditModeActive(false);
  }, [setEditModeActive]);

  const onDiscardClick = useCallback(() => {
    stopCellEditing();
    onEditCanceled();
  }, [stopCellEditing, onEditCanceled]);

  const onSaveClick = useCallback(() => {
    stopCellEditing();
    onEditSaved();
  }, [stopCellEditing, onEditSaved]);

  const bottomRecordTypeBarHeight = 48;

  const selectedRowComparator = useCallback((asset: AssetType) => asset.id === id, [id]);
  const editRowComparator = useCallback((asset: AssetType) => isArray(editModeData) ? editModeData.some(x => x.id === asset.id) : asset.id === editModeData?.id, [editModeData]);

  const filterRenderer = useCallback(
    ({ column }: FilterRendererProps<AssetType>) => <ColumnSearch displayName={column.name} field={column.key} />,
    []
  );

  return (
    <div className={classes.root}>
      <SlideDialog open={columnFilterOpen} onClose={() => setColumnFilterOpen(false)}>
        <ColumnFilter open={columnFilterOpen} setColumnFilterOpen={setColumnFilterOpen} />
      </SlideDialog>
      <FluidStyledTable<AssetType>
        data={rowData}
        columns={columns}
        height={height - bottomRecordTypeBarHeight}
        onChange={onChange}
        selectedRowComparator={!editModeActive ? selectedRowComparator : undefined}
        filterRenderer={filterRenderer}
        onColumnsMove={handleColumnMove}
        onInput={handleCellEditStart}
        onBlur={handleCellEditStop}
        emptyView={() => <EmptyView selectedProjects={selectedProjects} filterCount={filterCount} height={height} />}
        setSortColumn={setSortColumn}
        setSortDirection={setSortDirection}
        editRowComparator={editModeActive ? editRowComparator : undefined}
      />
      <BottomActionBar
        onDiscardClick={onDiscardClick}
        onSaveClick={onSaveClick}
        label={isArray(editModeData) ? (editModeData.length === 1 ? editModeData[0].label : `${editModeData.length} Assets`) : editModeData?.label}
        changedRecords={changedRecords}
      />
    </div>
  );
};
export default React.memo(AssetTable);
