import React from 'react';
import { FormControlLabel, createStyles, Switch, WithStyles, withStyles, Theme } from '@material-ui/core';
import magicText from 'i18next';

interface SearchBarSelectProps extends WithStyles<typeof styles> {
  isLoading: boolean;
  checked: boolean;
  setChecked: (value: boolean) => void;
}

const SearchBarSelect = (props: SearchBarSelectProps) => {
  const { classes, isLoading, checked, setChecked } = props;

  return (
    <FormControlLabel
      label={<>{magicText.t('SearchBar.SELECT')}</>}
      className={classes.selectSwitch}
      classes={{ label: classes.label }}
      control={
        <Switch
          disabled={isLoading}
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          value="checked"
          color="primary"
        />
      }
    />
  );
};

const styles = (theme: Theme) =>
  createStyles({
    selectSwitch: {
      marginLeft: 5,
      marginRight: 10,
      color: theme.palette.primary.main,
      width: 120,
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 1,
    },
  });

export default withStyles(styles)(SearchBarSelect);
