import React, { CSSProperties } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { List, makeStyles } from '@material-ui/core';
import TGDrawerItem from './TGDrawerItem';
import TGDrawerBurgerButton from './TGDrawerBurgerButton';
import clsx from 'clsx';

export interface DrawerItem {
  icon?: React.ReactNode;
  title?: string;
  tooltip?: string;
  content?: React.ReactNode;
  iconMenu?: React.ReactNode;
  closeMenuOnClick?: boolean;
  iconClickHandler?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void ;
}

export interface TGDrawerProps {
  items: DrawerItem[];
  width?: CSSProperties['width'];
  open: boolean;
  setOpen: (value: boolean) => void;
}

const TGDrawer = (props: TGDrawerProps) => {
  const { items, open, setOpen } = props;
  const classes = useStyles(props);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      classes={{
        paper: clsx(classes.drawer, {
          [classes.openedDrawer]: open,
          [classes.closedDrawer]: !open,
        }),
      }}
    >
      <List className={classes.list}>
        <TGDrawerBurgerButton onClick={toggleOpen} open={open} />
        {items.map((item, index) => (
          <TGDrawerItem key={index} {...item} open={open} children={item.content} onIconClick={item.iconClickHandler as any} />
        ))}
      </List>
    </Drawer>
  );
};

export const CLOSED_DRAWER_WIDTH = 48;
export const DEFAULT_OPEN_DRAWER_WIDTH = 150;
export const DRAWER_TRANSITION_TIME = '200ms';

const useStyles = makeStyles((theme) => ({
  drawer: {
    alignItems: 'flex-start',
    transition: `width ${DRAWER_TRANSITION_TIME}`,
    position: 'absolute',
    cursor: 'default',
  },
  closedDrawer: {
    width: CLOSED_DRAWER_WIDTH,
  },
  openedDrawer: (props: TGDrawerProps) => ({
    width: props.width ?? DEFAULT_OPEN_DRAWER_WIDTH,
  }),
  list: {
    width: '100%',
  },
}));

export default TGDrawer;
