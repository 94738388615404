import React from 'react';
import { ActionButton } from './useActionsExecutor';
import { AssetType } from '../contexts/AggregatesContext/types';
import ActionIcon from '../components/ActionIcon';
import { colors } from '../styles/theme';
import { Action } from '../components/Map/AssetCard/AssetCardAction';
import { ActionButton as ConfigActionButton, AggregateDefinition, CardActionButton, ColorDef } from '@terragotech/gen5-shared-components';
import { ActionButtonState } from './useConditionalCardActionButtons';

export type DefinedButtonConditionalMap<TButton extends ConfigActionButton> = Exclude<
  TButton['conditionalMap'],
  undefined
>;

export type DefinedButtonConditionalMapOutput<
  TButton extends ConfigActionButton
> = DefinedButtonConditionalMap<TButton>['outputDefinition'];

export type DefinedCardDefinition = Exclude<AggregateDefinition['cardDefinition'], undefined>;

export const getStringAttributeValue = (attribute: unknown, placeholder = '') => {
  if (typeof attribute === 'string') return attribute;
  else if (typeof attribute === 'object') {
    if (attribute && 'label' in attribute) {
      return (attribute as { label: string }).label;
    } else if (Array.isArray(attribute)) {
      // Most likely a one to many relationship attribute
      return attribute.map((attr) => (attr as { label: string }).label).join(', ');
    }
    return '';
  }
  return attribute ? String(attribute) : placeholder;
};

export const getColorString = (item: AssetType, colorDef?: ColorDef): string => {
  if (colorDef) {
    if (colorDef.__typename === 'HexColor') {
      return colorDef.value;
    }
    if (colorDef.__typename === 'ColorObject') {
      return String(item[colorDef.hexColorKey]);
    }
    const value = item[colorDef.colorKey];
    // find any mapping that applies
    const mappedColor = colorDef.mapping.find((curMapping) => curMapping.value === value);
    //return the color
    if (mappedColor) {
      return mappedColor.hexColor;
    }
  }
  return colors.black;
};

export const getCardTitle = (asset: AssetType, titleKey: string) => {
  if (asset && asset[titleKey]) return String(asset[titleKey]);
  else return '';
};

export const convertActionButtonToAction = (
  actionButton: ActionButton,
  buttonDef?: CardActionButton & ActionButtonState,
  colorMapper?: (color: ColorDef) => string
): Action => {
  const color = buttonDef && buttonDef.color && colorMapper ? colorMapper(buttonDef.color) : undefined;
  return {
    ...actionButton,
    icon: actionButton.icon ? (
      <ActionIcon
        name={actionButton.icon}
        size="1x"
        style={{ fontSize: '13px', marginRight: '-4px', display: 'flex', alignItems: 'center' }}
      />
    ) : undefined,
    disabled: Boolean(buttonDef?.state === 'disabled'),
    tooltipText: buttonDef?.disabledMessage ? String(buttonDef?.disabledMessage) : '',
    color,
  };
};

export const convertAssetToStringRecord = (asset: AssetType, attributes?: DefinedCardDefinition['otherAttributes']) =>
  attributes?.reduce<Record<string, string>>((result, attribute) => {
    result[attribute.itemKey] = getStringAttributeValue(asset[attribute.itemKey], attribute.placeholder);
    return result;
  }, {}) ?? {};
