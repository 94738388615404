import { useState, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import CompletedJobRow from './CompletedJobRow';
import PendingJobRow from './PendingJobRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FileToView from './FileToView';
import { QueryResult } from '../../containers/ImportContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { UseConditionalImport } from '../../hooks/useConditionalImports';

export interface SelectedImportType {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  success?: boolean;
  filename: string;
  metadata?: object;
  target: string;
  timestamp: Date;
  type: string;
  version: string;
}

type UserInfo = Record<string, any>;

export interface Import {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  success?: boolean;
  isintegration?: boolean;
  filename: string;
  metadata?: {
    userInfo?: UserInfo;
  };
  target: string;
  timestamp: Date;
  type: string;
  version: string;
  commandError?: number;
  commandErrorMessage?: string;
}

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 800,
  },
  fileCell: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    maxWidth: 300,
    padding: '11px 0px 11px 11px',
  },
  cellFontStyle: {
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  cellHeaderFontStyle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: 11,
  },
  fixedRoot: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
  },
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    backgroundColor: '#fff',
  },
  body: {
    width: '100%',
    minHeight: '200%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },
}));

interface Props {
  data?: QueryResult | undefined;
  commit: (x: string) => void;
  fileIdToView: string | undefined;
  setFileIdToView: (x: string | undefined) => void;
  onToggle: () => void;
  buttons: UseConditionalImport[] | undefined;
}

const ImportHistory = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { data, commit, onToggle, fileIdToView, setFileIdToView, buttons } = props;

  const [selectedImport, setSelectedImport] = useState<SelectedImportType>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [listOrder, setListOrder] = useState<string>('Newest');

  const [completedJobsAnchorEl, setCompletedJobsAnchorEl] = useState<null | HTMLElement>(null);
  const [completedJobsOrder, setCompletedJobsOrder] = useState<string>('Newest');

  const sortedPendingJobs = useMemo(() => {
    if (data?.imports.length === 0 || !data?.imports) return [];
    const tempOrder: Import[] = [...data.imports];
    const pendingJobs: Import[] = tempOrder.filter(x => x?.success === null);
    if (listOrder === 'Newest') {
      pendingJobs.sort((a: Import, b: Import) => String(b.timestamp).localeCompare(String(a.timestamp)));
    } else if (listOrder === 'Oldest') {
      pendingJobs.sort((a: Import, b: Import) => String(a.timestamp).localeCompare(String(b.timestamp)));
    }
    return pendingJobs;
  }, [data?.imports, listOrder]);

  const sortedCompletedJobs = useMemo(() => {
    if (data?.imports.length === 0 || !data?.imports) return [];
    const tempOrder: Import[] = [...data.imports];
    const completedJobs: Import[] = tempOrder.filter(x => x?.success !== null);
    if (completedJobsOrder === 'Newest') {
      completedJobs.sort((a: Import, b: Import) => String(b.timestamp).localeCompare(String(a.timestamp)));
    } else if (completedJobsOrder === 'Oldest') {
      completedJobs.sort((a: Import, b: Import) => String(a.timestamp).localeCompare(String(b.timestamp)));
    }
    return completedJobs;
  }, [data?.imports, completedJobsOrder]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: string) => {
    if (value) {
      setListOrder(value);
    }
    setAnchorEl(null);
  };

  const handleCompletedJobsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCompletedJobsAnchorEl(event.currentTarget);
  };

  const handleCompletedJobsClose = (value?: string) => {
    if (value) {
      setCompletedJobsOrder(value);
    }
    setCompletedJobsAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      {!fileIdToView ? (
        <div className={classes.body}>
          <div style={{ flex: 3 }}></div>
          <div style={{ flex: 20, width: '100%' }}>
            <div>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  fontSize: 40,
                  lineHeight: '47px',
                  color: `${theme.palette.secondary.main || '#56CCF2'}`,
                }}
              >
                Import History
              </Typography>
              <div style={{ width: '100%', minHeight: '30%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 24,
                    lineHeight: '28px',
                    color: '#656565',
                    marginRight: 20,
                    marginTop: '26px',
                    marginBottom: '13px',
                  }}
                >
                  Pending Jobs
                </Typography>
                <Typography
                  onClick={handleClick}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: '18px',
                    color: '#777777',
                    marginRight: 20,
                    marginTop: '26px',
                    marginBottom: '13px',
                  }}
                >
                  {listOrder} <ArrowDropDownIcon style={{}} />
                </Typography>
                <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose()}>
                  <MenuItem onClick={() => handleClose('Newest')}>Newest</MenuItem>
                  <MenuItem onClick={() => handleClose('Oldest')}>Oldest</MenuItem>
                </Menu>
              </div>
              {sortedPendingJobs.length !== 0 ? (
                <div style={{ width: '100%', overflow: 'auto', maxHeight: '30vh' }}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: '#E9E9E9' }}>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            File
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Type
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Status
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Email
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Date
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedPendingJobs.map((job: Import, index: number) => {
                          const propData = {
                            selectedImport,
                            setSelectedImport,
                            imp: sortedPendingJobs,
                            setFileIdToView,
                          };
                          return <PendingJobRow data={propData} index={index} style={{}} key={index} />;
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div
                  style={{
                    height: 80,
                    width: '100%',
                    backgroundColor: '#FAFAFA',
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '1px solid grey',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: 24,
                      lineHeight: '28px',
                      color: '#656565',
                      marginLeft: 40,
                    }}
                  >
                    No pending jobs
                  </Typography>
                </div>
              )}

              <div style={{ width: '100%', display: 'flex', marginTop: '40', justifyContent: 'space-between' }}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 23,
                    lineHeight: '28px',
                    color: '#656565',
                    marginRight: 20,
                    marginTop: '26px',
                    marginBottom: '13px',
                  }}
                >
                  Completed Jobs
                </Typography>
                <Typography
                  onClick={handleCompletedJobsClick}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: '18px',
                    color: '#777777',
                    marginRight: 20,
                    marginTop: '26px',
                    marginBottom: '13px',
                  }}
                >
                  {completedJobsOrder} <ArrowDropDownIcon style={{}} />
                </Typography>
                <Menu
                  id="simple-menu"
                  anchorEl={completedJobsAnchorEl}
                  open={Boolean(completedJobsAnchorEl)}
                  onClose={() => handleCompletedJobsClose()}
                >
                  <MenuItem onClick={() => handleCompletedJobsClose('Newest')}>Newest</MenuItem>
                  <MenuItem onClick={() => handleCompletedJobsClose('Oldest')}>Oldest</MenuItem>
                </Menu>
              </div>
              {sortedCompletedJobs.length !== 0 ? (
                <div style={{ width: '100%', paddingBottom: 50, maxHeight: '40vh', overflow: 'auto' }}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: '#E9E9E9' }}>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            File
                          </TableCell>
                          <TableCell className={classes.cellHeaderFontStyle}>Type</TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Status
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Email
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Date
                          </TableCell>
                          <TableCell align={'center'} className={classes.cellHeaderFontStyle}>
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedCompletedJobs.map((job: Import, index: number) => {
                          const propData = {
                            selectedImport,
                            setSelectedImport,
                            imp: sortedCompletedJobs,
                            setFileIdToView,
                          };
                          return <CompletedJobRow data={propData} index={index} style={{}} key={index} />;
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div
                  style={{
                    height: 80,
                    width: '100%',
                    backgroundColor: '#FAFAFA',
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '1px solid grey',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: 23,
                      lineHeight: '28px',
                      color: '#656565',
                      marginLeft: 40,
                    }}
                  >
                    No completed jobs
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div style={{ flex: 3 }}></div>
        </div>
      ) : (
        <div className={classes.body}>
          <div style={{ flex: 2 }}></div>
          <FileToView
            fileIdToView={fileIdToView}
            setFileIdToView={setFileIdToView}
            commit={commit}
            onToggle={onToggle}
            buttons={buttons}
          />
        </div>
      )}
    </div>
  );
};

export default ImportHistory;
