import React from 'react';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import AssetCardBackdrop from './AssetCardBackdrop';
import AssetCardContainer from './AssetCardContainer';

const PositionedAssetCardContainer = (props: { assetId: string; onCloseClick?: () => void; mapEditor?: boolean; asset?: MapAssetType; relative?: boolean, onFormSubmit?: ()=>void }) => {
  const {relative,...rest} = props;
  return !props.mapEditor ? (
    <AssetCardBackdrop onCloseClick={props.onCloseClick} mapEditor={props.mapEditor} relative={relative}>
      <AssetCardContainer {...rest} />
    </AssetCardBackdrop>
  ) : null;
};

export default PositionedAssetCardContainer;
