import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import magicText from 'i18next';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { Button, Typography } from '@material-ui/core';
import { gql, useQuery, useMutation } from '@apollo/client';
import TGAlertDialogWithIcon from '../TGAlertDialogWithIcon';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { DOC_API_URL } from '../../utils/docApiURL';
import { AuthConnector } from '@terragotech/gen5-shared-components';
import { getFileExtensionFromBlob } from '../../utils/fileHelper';
import formLevelErrorsIcon from '../../images/importPage/formLevelErrorsIcon.svg';
import formLevelWarningsIcon from '../../images/importPage/formLevelWarningsIcon.svg';
import formLevelUpIcon from '../../images/importPage/formLevelUpIcon.svg';
import formLevelDownIcon from '../../images/importPage/formLevelDownIcon.svg';
import formLevelErrorsMenuIcon from '../../images/importPage/formLevelErrorsMenuIcon.svg';
import formLevelWarningsMenuIcon from '../../images/importPage/formLevelWarningsMenuIcon.svg';
import { CancelImportResponse, CancelImportVariables } from './PendingJobRow';
import SheetTable from './SheetTable';
import { UseConditionalImport } from '../../hooks/useConditionalImports';
import { CommandAction, compileKeysOfObjectArray } from '@terragotech/gen5-shared-components';

//Example query to get a single import record that includes processed data
export interface IndividualQueryResult {
  import: readonly {
    id: string;
    errorsWarnings: readonly {
      message: string;
      is_error: boolean;
      is_form_level: boolean;
      row: number;
      column: number;
      sheet: string;
      column_name: string;
    }[];
    aggregateType: string;
    commandName: string;
    committed?: boolean;
    filename: string;
    metadata?: object;
    data?: object;
    target: string;
    timestamp: Date;
    type: string;
    version: string;
    commandError?: number;
    commandErrorMessage?: string;
  }[];
}

export interface IndividualImportResult {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  success?: boolean;
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  isintegration?: boolean;
  filename: string;
  metadata?: object;
  data?: object;
  target: string;
  timestamp: Date;
  type: string;
  version: string;
  commandError?: number;
  commandErrorMessage?: string;
}

export interface ErrorWarningType {
  message: string;
  is_error: boolean;
  is_form_level: boolean;
  row: number;
  column: number;
  sheet: string;
  column_name: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
  },
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },
  underLineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  highlightOnHover: {
    color: `${theme?.palette?.primary?.main || '#56CCF2'}`,
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: `${theme?.palette?.primary?.main || '#56CCF2'}`,
      color: '#FFFFFF',
    },
  },
}));

interface Props {
  onBack?: () => void;
  fileIdToView: string;
  setFileIdToView: (x: string | undefined) => void;
  commit: (x: string) => void;
  onToggle: () => void;
  buttons: UseConditionalImport[] | undefined;
}

export const QUERY = (importId: string) => gql`
       query getImport{
         import(id: "${importId}") {
          id
          errorsWarnings {
            message
            is_error
            is_form_level
            row
            column
            sheet
            column_name
          }
          aggregateType
          commandName
          committed
          success
          isintegration
          filename
          metadata
          data
          target
          timestamp
          type
          version
          commandError
          commandErrorMessage
        }
      }
    `;

const CANCEL_IMPORT = gql`
  mutation cancelImport($importId: String!) {
    cancelImport(importId: $importId) {
      message
    }
  }
`;

export const downloadFile = async (fileUrl: string, fileName: string, isReview?: boolean, fileExtension?: string) => {
  const token = await AuthConnector.getToken();
  const response = await fetch(fileUrl, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
    }),
  });
  const blob = await response.blob();
  fileExtension = fileExtension || (await getFileExtensionFromBlob(blob));
  if (!fileExtension) {
    if (isReview) {
      fileExtension = 'xlsx';
    } else {
      fileExtension = 'csv';
    }
  }
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const FileToView = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { fileIdToView, setFileIdToView, commit, onToggle, buttons } = props;
  const { data, loading } = useQuery<IndividualQueryResult>(QUERY(fileIdToView), {
    fetchPolicy: 'network-only',
  });
  const [cancelImport] = useMutation<CancelImportResponse, CancelImportVariables>(CANCEL_IMPORT);

  const [fetchedData, setFetchedData] = useState<IndividualImportResult>();
  const [sheetNumber, setSheetNumber] = useState<number>(0);
  const [errors, setErrors] = useState<ErrorWarningType[]>([]);
  const [warnings, setWarnings] = useState<ErrorWarningType[]>([]);
  const [formLevelErrors, setFormLevelErrors] = useState<ErrorWarningType[]>([]);
  const [formLevelWarnings, setFormLevelWarnings] = useState<ErrorWarningType[]>([]);
  const [formLevelErrorsOpen, setFormLevelErrorsOpen] = useState<boolean>(false);
  const [formLevelWarningsOpen, setFormLevelWarningsOpen] = useState<boolean>(false);
  const [commandDefinition, setCommandDefinition] = useState<UseConditionalImport>();

  const sheets = useMemo(() => {
    let sheetNames: {
      label: string;
      key: string;
    }[] = [];
    const groupArray = (commandDefinition?.action as CommandAction)?.command?.template?.order;
    if (groupArray) {
      sheetNames = groupArray.map(comp => {
        return {
          key: comp,
          label: (commandDefinition?.action as CommandAction)?.command?.template?.components[comp]?.label || comp,
        };
      });
    } else {
      sheetNames = Object.keys(fetchedData?.data || {}).map(comp => {
        return {
          key: comp,
          label: comp,
        };
      });
    }
    return sheetNames;
  }, [commandDefinition, fetchedData]);

  useEffect(() => {
    if (data && data?.import && !loading) {
      //@ts-ignore
      setFetchedData(data?.import);
    }
  }, [data, loading]);

  useEffect(() => {
    if (fetchedData?.data && fetchedData?.errorsWarnings) {
      let errorsWarnings = [...fetchedData?.errorsWarnings];
      // Form level errors + warnings
      let formLevelWarnings = errorsWarnings.filter(x => !x.is_error && x.is_form_level);
      let formLevelErrors = errorsWarnings.filter(x => x.is_error && x.is_form_level);
      // Row errors + warnings by sheet
      let warningsArray = errorsWarnings.filter(
        x => !x.is_error && !x.is_form_level && sheets[sheetNumber].key === x.sheet
      );
      let errorsArray = errorsWarnings.filter(
        x => x.is_error && !x.is_form_level && sheets[sheetNumber].key === x.sheet
      );
      setFormLevelErrors(formLevelErrors);
      setFormLevelWarnings(formLevelWarnings);
      setWarnings(warningsArray);
      setErrors(errorsArray);
    }
  }, [fetchedData, sheetNumber, commandDefinition, sheets]);

  const length = useMemo(() => {
    if (fetchedData) {
      if (fetchedData?.data) {
        let totalLength = 0;
        Object.values(fetchedData?.data).forEach((x, index) => {
          if (index === sheetNumber) totalLength += x.length;
        });
        return totalLength;
      }
    }
    return 0;
  }, [fetchedData, sheetNumber]);

  const totalLength = useMemo(() => {
    if (fetchedData) {
      if (fetchedData?.data) {
        let totalLength = 0;
        Object.values(fetchedData?.data).forEach(x => {
          totalLength += x.length;
        });
        return totalLength;
      }
    }
    return 0;
  }, [fetchedData]);

  const hasErrors = useMemo(() => {
    if (
      (fetchedData && fetchedData?.errorsWarnings.filter(x => x?.is_error === true).length > 0) ||
      totalLength === 0
    ) {
      return true;
    }
    return false;
  }, [fetchedData, totalLength]);

  const hasWarnings: boolean = useMemo(() => {
    if (fetchedData && fetchedData?.errorsWarnings.filter(x => x?.is_error === false).length > 0) {
      return true;
    } else {
      return false;
    }
  }, [fetchedData]);

  const isCompleted = useMemo(() => {
    //@ts-ignore
    if (fetchedData?.success !== null) {
      return true;
    }
    return false;
  }, [fetchedData]);

  const isPending = useMemo(() => {
    if (fetchedData?.success === null) {
      return true;
    }
    return false;
  }, [fetchedData]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  const Tabs = useMemo(() => {
    if (fetchedData && fetchedData?.data) {
      return sheets.map((tabName, index: number) => (
        <div key={index}>
          {index === sheetNumber ? (
            <div
              style={{
                borderTop: `6px solid ${theme?.palette?.primary?.main || '#56CCF2'}`,
                borderRight: '1px solid #D9D9D9',
                width: '159px',
                marginTop: '-1px',
                height: '36px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
              }}
              key={index}
            >
              <Typography
                style={{
                  color: `${theme?.palette?.primary?.main || '#56CCF2'}`,
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '27px',
                  textAlign: 'center',
                }}
              >
                {tabName.label}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                cursor: 'pointer',
                boxSizing: 'border-box',
                border: '1px solid #D9D9D9',
                width: '159px',
                height: '41px',
                backgroundColor: '#F4F4F4',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={(event: React.MouseEvent<HTMLElement>) => setSheetNumber(index)}
              key={index}
            >
              <Typography
                style={{
                  color: '#000000',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '27px',
                  textAlign: 'center',
                }}
              >
                {tabName.label}
              </Typography>
            </div>
          )}
        </div>
      ));
    } else {
      return <div></div>;
    }
  }, [fetchedData, sheetNumber, commandDefinition, sheets]);

  const tableHeaders: {
    label: string;
    key: string;
  }[] = useMemo(() => {
    const gettingData: object[] = Object.values(fetchedData?.data || {});

    const commandDefinitionCalc = buttons?.find(
      button =>
        //@ts-ignore
        (button.action?.commandName || '') === fetchedData?.type &&
        //@ts-ignore
        button?.action?.commandVersion === fetchedData?.version
    );
    setCommandDefinition(commandDefinitionCalc);
    if (commandDefinitionCalc) {
      //@ts-ignore
      const commandTemplate = commandDefinitionCalc?.action?.command?.template;
      const groupValue: string = (commandTemplate?.order)[sheetNumber];
      const groupDefinition = commandTemplate?.components[groupValue];
      // If group exists
      if (groupDefinition) {
        const keyArray = groupDefinition?.template?.order;
        const labelArray = keyArray?.map((keyString: string) => {
          if (groupDefinition?.template?.components[keyString]) {
            return {
              label: groupDefinition?.template?.components[keyString]?.label || keyString,
              key: keyString,
            };
          }
        });
        return labelArray;
      }
    }
    // If labelArray not defined, compile keys of data objects
    if (gettingData && gettingData[sheetNumber]) {
      return compileKeysOfObjectArray(gettingData[sheetNumber] as object[]).map(str => {
        return {
          key: str,
          label: str,
        };
      });
    }
    return [];
  }, [sheetNumber, fetchedData, buttons]);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '82%',
          marginLeft: '9%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            className={classes.underLineOnHover}
            onClick={() =>
              downloadFile(
                `${DOC_API_URL}${fileIdToView}`,
                `${fetchedData?.filename.substring(0, fetchedData?.filename.lastIndexOf('.')) || fetchedData?.filename}`
              )
            }
            style={{
              cursor: 'pointer',
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: 40,
              lineHeight: '47px',
              color: `${theme?.palette?.primary?.main || '#56CCF2'}`,
            }}
          >
            {fetchedData?.filename}
          </Typography>
        </div>
        {fetchedData?.errorsWarnings.length !== 0 && (
          <Button
            onClick={() =>
              downloadFile(
                `${DOC_API_URL}commented-${fileIdToView}`,
                `${
                  fetchedData?.filename.substring(0, fetchedData?.filename.lastIndexOf('.')) || fetchedData?.filename
                } - Review`,
                true
              )
            }
            color="primary"
            className={classes.highlightOnHover}
            variant="outlined"
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <VerticalAlignBottomIcon />
              DOWNLOAD REVIEW
            </div>
          </Button>
        )}
      </div>
      {formLevelErrors.length >= 1 && (
        <div
          onClick={() => setFormLevelErrorsOpen(prev => !prev)}
          style={{ cursor: 'pointer', backgroundColor: '#FFE1E1', width: '82%', marginLeft: '9%', marginTop: '11px' }}
        >
          <div style={{ height: 32, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={formLevelErrorsIcon} alt="Error Icon" style={{ marginLeft: 15, marginRight: 10 }} />
              <Typography style={{ fontWeight: 300, fontSize: 16 }}> Form Level Error(s)</Typography>
            </div>
            <div style={{ marginTop: '-5px' }}>
              {formLevelErrorsOpen ? (
                <img
                  src={formLevelUpIcon}
                  alt="Open Errors Icon"
                  style={{ marginLeft: 15, marginRight: 10, marginTop: '-5px' }}
                />
              ) : (
                <img
                  src={formLevelDownIcon}
                  alt="Close Errors Icon"
                  style={{ marginLeft: 15, marginRight: 10, marginTop: '-5px' }}
                />
              )}
            </div>
          </div>
          {formLevelErrorsOpen &&
            formLevelErrors.map(x => (
              <div style={{ marginLeft: '45px', height: 32, display: 'flex', alignItems: 'center' }}>
                <img src={formLevelErrorsMenuIcon} alt="Open Errors Icon" style={{ marginLeft: 15, marginRight: 10 }} />
                <Typography style={{ fontWeight: 300, fontSize: 16 }}> {x.message}</Typography>
              </div>
            ))}
        </div>
      )}

      {formLevelWarnings.length >= 1 && (
        <div
          onClick={() => setFormLevelWarningsOpen(prev => !prev)}
          style={{ cursor: 'pointer', backgroundColor: '#FFF7DD', width: '82%', marginLeft: '9%', marginTop: '11px' }}
        >
          <div style={{ height: 32, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={formLevelWarningsIcon} alt="Warning Icon" style={{ marginLeft: 15, marginRight: 10 }} />
              <Typography style={{ fontWeight: 300, fontSize: 16 }}> Form Level Warning(s)</Typography>
            </div>
            <div style={{ marginTop: '-5px' }}>
              {formLevelWarningsOpen ? (
                <img src={formLevelUpIcon} alt="Open Warnings Icon" style={{ marginLeft: 15, marginRight: 10 }} />
              ) : (
                <img src={formLevelDownIcon} alt="Close Warnings Icon" style={{ marginLeft: 15, marginRight: 10 }} />
              )}
            </div>
          </div>
          {formLevelWarningsOpen &&
            formLevelWarnings.map(x => (
              <div style={{ marginLeft: '45px', height: 32, display: 'flex', alignItems: 'center' }}>
                <img
                  src={formLevelWarningsMenuIcon}
                  alt="Open Errors Icon"
                  style={{ marginLeft: 15, marginRight: 10 }}
                />
                <Typography style={{ fontWeight: 300, fontSize: 16 }}> {x.message}</Typography>
              </div>
            ))}
        </div>
      )}
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 14 }}>
        <div style={{ border: '1px solid #CFCFCF', width: '82%' }}>
          <div style={{ width: '100%', height: 41, display: 'flex', backgroundColor: '#F4F4F4' }}>{Tabs}</div>
          <div>
            <SheetTable
              tableHeaders={tableHeaders}
              length={length}
              fetchedData={fetchedData}
              errors={errors}
              warnings={warnings}
              sheetNumber={sheetNumber}
              sheets={sheets}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 90, marginTop: 32, width: '91%' }}>
        <Button
          disabled={isCompleted}
          onClick={() => setDialogOpen(true)}
          style={
            isCompleted
              ? { width: 178, color: 'white', marginRight: 20, borderRadius: 7, backgroundColor: '#EEEEEE' }
              : { width: 178, color: 'white', marginRight: 20, borderRadius: 7, backgroundColor: '#848E97' }
          }
          variant="contained"
        >
          Cancel Import
        </Button>
        <Button
          disabled={!isPending || hasErrors}
          style={
            !isPending
              ? { backgroundColor: '#C2CACC', width: 178, color: 'white', borderRadius: 7 }
              : { width: 178, color: 'white', borderRadius: 7 }
          }
          variant="contained"
          color="primary"
          onClick={() => setImportDialogOpen(true)}
        >
          Import
        </Button>
      </div>
      <TGAlertDialogWithIcon
        open={importDialogOpen}
        title={'Confirm'}
        warningIcon={hasWarnings}
        onCancel={() => setImportDialogOpen(false)}
        onConfirm={() => {
          commit(fileIdToView);
          setFileIdToView(undefined);
        }}
        bodyText={hasWarnings ? magicText.t('import.warningConfirm') : magicText.t('import.confirm')}
        confirmButtonTitle={'Proceed'}
        theme={theme}
      />
      <TGAlertDialogWithIcon
        open={dialogOpen}
        title={'Confirm'}
        warningIcon={true}
        onCancel={() => setDialogOpen(false)}
        onConfirm={async () =>
          await cancelImport({ variables: { importId: fileIdToView } })
            .then(() => setDialogOpen(false))
            .then(() => setFileIdToView(undefined))
            .then(() => onToggle())
        }
        bodyText={magicText.t('import.cancelConfirm')}
        confirmButtonTitle={'Discard'}
        theme={theme}
      />
    </div>
  );
};

export default FileToView;
