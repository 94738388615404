import { createStyles, Theme, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState, useContext, useRef } from 'react';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import SideBar from './SideBar';
import AssetDetailSection from '../containers/AssetDetailSection';
import './TopSplitter.css';
import { MultiSelectContextProvider } from 'use-multiselect';
import AssetsTableContainer from '../containers/AssetsTableContainer';
import BottomRecordTypeSelectorContainer from '../containers/BottomRecordTypeSelectorContainer';
import SettingsHeader from './SettingsHeader';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { switchPointPercentage } from '../pages/AssetsDashboard/AssetsDashboard';
import clsx from 'clsx';

const ANIMATION_TIME_IN_SECONDS = 0.3;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100%',
    },
    topSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
    leftSection: {
      flex: 1,
    },
    topRightSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
    splitter2: {
      '& > .layout-pane:nth-child(1)': {
        transition: `width ${ANIMATION_TIME_IN_SECONDS}s`,
      },
    },
  })
);

export interface TopSplitterProps {
  i?: Array<any>;
  inProp: boolean;
  onExited?: () => void;
  fullScreen?: boolean;
  height?: any;
  width: number;
  setWidth: any;
  onDragEnd?: (verticalPercentage: number) => void;
  minimizeAndExpandTable?: (value: number) => void;
}

const TopSplitter: FunctionComponent<TopSplitterProps> = props => {
  const { height, width, setWidth, onDragEnd, minimizeAndExpandTable } = props;
  const classes = useStyles();
  //Added triggerWidth, which is reserved for triggering resize from Map onDragEnd
  const [triggerWidth, setTriggerWidth] = useState(width);
  const { verticalPercentage, setVerticalPercentage, fullscreen } = useContext(AssetsDashboardContext);
  const splitterLayoutRef = useRef<SplitterLayout | null>(null);

  const handleDragEnd = () => {
    if (verticalPercentage >= switchPointPercentage) {
      splitterLayoutRef.current && splitterLayoutRef.current.setState({ secondaryPaneSize: 100 });
    }
    onDragEnd && onDragEnd(verticalPercentage!);
  };

  return (
    <SplitterLayout
      customClassName={clsx('custom-splitter2', classes.splitter2)}
      primaryIndex={1}
      secondaryInitialSize={width}
      onSecondaryPaneSizeChange={setWidth}
      onDragEnd={() => {
        setTriggerWidth(width);
        try {
          localStorage.setItem('width', width.toString());
        } catch (e) {}
      }}
    >
      <div className={classes.leftSection}>
        <div style={{ backgroundColor: 'red' }} />
        <SideBar />
      </div>
      <div style={{ width: window.innerWidth - width }} className={classes.topRightSection}>
        {!fullscreen ? (
          <>
            <div style={{ backgroundColor: 'blue' }} />
            {/* this tells the map the splitter height and width has changed so it can force resize, it does not actually use the height or width values */}
            <AssetDetailSection width={triggerWidth} height={height} />
          </>
        ) : (
          <SplitterLayout
            ref={splitterLayoutRef}
            percentage={true}
            secondaryInitialSize={verticalPercentage}
            vertical={true}
            customClassName="custom-splitter"
            onSecondaryPaneSizeChange={setVerticalPercentage}
            onDragEnd={handleDragEnd}
          >
            <SettingsHeader />
            <div className={classes.bottomSection}>
              {
                // @ts-ignore
                <MultiSelectContextProvider>
                  <AssetsTableContainer
                    height={(verticalPercentage / 100) * window.innerHeight}
                    minimizeAndExpandTable={minimizeAndExpandTable}
                  />
                </MultiSelectContextProvider>
              }
              <BottomRecordTypeSelectorContainer />
            </div>
          </SplitterLayout>
        )}
      </div>
    </SplitterLayout>
  );
};

export default TopSplitter;
