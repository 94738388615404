import React, { useState, useMemo } from 'react';
import { settingsSection, SettingsItem } from '../../components/MapRightDrawer/SettingsSection';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DrawerItem } from '../../components/TGDrawer/TGDrawer';
import { useAccountModal } from '../../contexts/accountModalContext';
import magicText from 'i18next';
import { useConfig, useUserInfo } from '@terragotech/gen5-shared-components';
import { useAnalyticsModal } from '../../contexts/analyticsModalContext';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useImportModal } from '../../contexts/importModalContext';
import { UseConditionalImports } from '../useConditionalImports';

export interface UseSettingsSection {
  section: DrawerItem;
  isAboutOpen: boolean;
  setIsAboutOpen: (value: boolean) => void;
}

export interface UseSettingsSectionArgs {
  name: string;
  email: string;
}

export const useSettingsSection = (props: UseSettingsSectionArgs): UseSettingsSection => {
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const { openUserAndRoles } = useAccountModal();
  const { openImport } = useImportModal();
  const { openAnalytics } = useAnalyticsModal();
  const { buttons } = UseConditionalImports();

  const { isAnalyticsUser, isAdmin } = useUserInfo();
  const uiConfig = useConfig();
  const analyticsEnabled = useMemo(() => uiConfig.enabledFeatures?.analyticsModule, [uiConfig]);

  const items: SettingsItem[] = useMemo(() => {
    const results: SettingsItem[] = [];

    if (isAnalyticsUser && analyticsEnabled) {
      results.push({
        title: magicText.t('map.settings.analytics'),
        icon: <BarChartIcon color="primary" fontSize="small" />,
        clickHandler: () => {
          openAnalytics();
        },
      });
    }

    if (isAdmin) {
      results.push({
        title: magicText.t('map.settings.users_and_roles'),
        icon: <PeopleIcon color="primary" fontSize="small" />,
        clickHandler: () => {
          openUserAndRoles();
        },
      });
    }

    if (buttons && buttons.length) {
      results.push({
        title: magicText.t('Settings.importText'),
        icon: <CloudUploadIcon color="primary" fontSize="small" />,
        clickHandler: () => {
          openImport();
        },
      });
    }

    results.push({
      title: magicText.t('map.settings.about'),
      icon: <InfoIcon color="primary" fontSize="small" />,
      clickHandler: () => {
        setIsAboutOpen(true);
      },
    });
    return results;
  }, [buttons, isAdmin, isAnalyticsUser, analyticsEnabled, openUserAndRoles, openAnalytics, openImport]);

  const section = settingsSection({
    ...props,
    items,
  });
  return {
    section,
    isAboutOpen,
    setIsAboutOpen,
  };
};
