import React, { FunctionComponent, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Input, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { ReactComponent as TableFilterIcon } from '../../images/tableFilterIcon.svg';
import { ReactComponent as TableFilterActiveIcon } from '../../images/tableFilterActiveIcon.svg';
import SetFilter from '../Filters/SetFilter';
import CalendarFilter from '../Filters/CalendarFilter';
import { FilterContext } from '../../contexts/FilterContext/filterContext';
import _ from 'lodash';
import magicText from 'i18next';
import { ValueType } from '../../hooks/useTable';
import { useAggregates } from '../../contexts/AggregatesContext';
import { useRecordType } from '../../contexts/recordTypeContext';

const DATETIME = 'DateTime' as const;
const DATE = 'Date' as const;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    assetSearchBar: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[200],
      // padding: '3px 0px 3px 10px',
      display: 'inline-flex',
      width: '100%',
    },
    searchIcon: {
      height: 15,
      width: 15,
      color: theme.palette.grey[400],
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 13,
      fontWeight: 400,
    },
    filterIcon: {
      paddingRight: 5,
      paddingLeft: 'auto',
      // paddingTop: 8,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[50],
      fill: theme.palette.primary.main,
      cursor: 'pointer',
    },
  });
const getValueFromValueType = (value: ValueType | null, key: 'id' | 'label') => {
  return value && typeof value === 'object' && !Array.isArray(value) ? value[key] : value;
};
export interface ColumnSearchProps extends WithStyles<typeof styles> {
  displayName: string;
  field: string;
}
/**
 * ColumnSearch - Lies at the top of each column in AgGrid.
 *
 * Has the functionality to set the filter state for each column
 *
 * Contains logic for obtaining unique values to filter by...
 *
 * @param displayName
 * @param field
 * @param classes
 */

let focusedFilter = '';
const ColumnSearch: FunctionComponent<ColumnSearchProps> = props => {
  const { displayName, field, classes } = props;
  const [filterOpen, setFilterOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { selectedRecordTypeDefinition } = useRecordType();
  const assetInfo = useAggregates();
  let unfilteredAssets = assetInfo.assets;

  const fieldType: string = useMemo(() => {
    const temp = selectedRecordTypeDefinition.propertyDefinitions.find(column => column.field === field);
    return temp?.type || '';
  }, [selectedRecordTypeDefinition, field]);

  const filterContext = useContext(FilterContext);
  const filterState = filterContext.filterState;
  const setFilterState = filterContext.setFilterState;
  const isQuickSearchFocus = filterContext.isQuickSearchFocus;
  const setQuickSearchFocus = filterContext.setQuickSearchFocus;
  focusedFilter = isQuickSearchFocus ? '' : focusedFilter;

  useEffect(() => {
    setIsLoading(false);
  }, [filterContext]);

  const [quickFilterText, setQuickFilterText] = useState(filterState[`${field}-QuickFilter`] || '');
  useEffect(() => {
    if (Object.keys(filterState).length === 0) {
      setQuickFilterText('');
    }
  }, [filterState]);

  const isFilterActive = (field: string) => {
    return filterState[field] !== undefined;
  };

  const findFilterFields = (field: string) => {   
    const fieldValues = unfilteredAssets
      .filter(asset => {
        return (
          asset.recordTypeKey === selectedRecordTypeDefinition.queryKey &&
          asset[field] !== undefined &&
          asset[field] !== ' '
        );
      })
      .map(filteredAsset => filteredAsset[field]);
    return _.sortBy(
      _.uniqBy(fieldValues, value => getValueFromValueType(value, 'id')),
      value => getValueFromValueType(value, 'label')
    );
  };

  const handleQuickFilterText = _.debounce(
    useCallback((text: string) => {
      focusedFilter = field;
      setQuickSearchFocus(false);
      setIsLoading(true);
      if (text === '') {
        setFilterState((prevState: any) => {
          if (prevState[`${field}-QuickFilter`]) {
            delete prevState[`${field}-QuickFilter`];
          }
          return { ...prevState };
        });
      } else {
        setFilterState((prevState: any) => {
          return { ...prevState, [`${field}-QuickFilter`]: text };
        });
      }
    }, []),
    1000
  );
  const onChangeText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(e.target.value);
    handleQuickFilterText(e.target.value);
  }, []);

  return (
    <div className={classes.root}>
      {fieldType === DATETIME || fieldType === DATE ? (
        <CalendarFilter
          open={filterOpen}
          setFilterOpen={setFilterOpen}
          displayName={displayName}
          setFilterState={setFilterState}
          field={field}
          filterState={filterState}
        />
      ) : (
        <SetFilter
          open={filterOpen}
          setFilterOpen={setFilterOpen}
          displayName={displayName}
          field={field}
          uniqueFieldGetter={() => findFilterFields(field)}
        />
      )}
      <div className={classes.assetSearchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          autoFocus={focusedFilter === field}
          placeholder={magicText.t('Search')}
          className={classes.input}
          disableUnderline={true}
          value={quickFilterText}
          onChange={onChangeText}
          disabled={isLoading}
        />
      </div>
      <div className={classes.filterIcon}>
        {isFilterActive(field) ? (
          <TableFilterActiveIcon onClick={() => setFilterOpen(true)} />
        ) : (
          <TableFilterIcon onClick={() => setFilterOpen(true)} />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ColumnSearch);
