import { makeStyles } from '@material-ui/core';
import { EDITOR_Z_INDEX } from '../../../utils/layers';
import { StyledTableEditorProps} from '../../../hooks/tableHooks/useColumns';

const useStyles = makeStyles((theme) => ({
  editor: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    height: '20px',
    backgroundColor: '#FFF',
    boxSizing: 'border-box',
    paddingLeft: '2px',
    margin: '0px 4px',
    marginTop: 1.5,
    width: '-webkit-fill-available',
    zIndex: EDITOR_Z_INDEX,
    position: 'absolute',
    border: `solid 1px grey`,
    '& > div, > div > div': {
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  clickable: {
    cursor: 'pointer',
    '& *': {
      cursor: 'pointer',
    },
  },
  switchRoot: {
    height: '24px',
    width: '40px',
    padding: 0,
  },
  switchBase: {
    height: '24px',
    padding: '0',
  },
  switchTrack: {
    height: '12px',
    margin: '6px 0',
  },
  switchThumb: {
    height: '20px',
    width: '20px',
    padding: 0,
  },
  input: {
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 0,
    verticalAlign: 'middle',
  },
  icon: {
    width: '15px',
    height: '15px',
  },
}));

export const getClasses = (
  props: StyledTableEditorProps,
  classes: { editor: string; clickable: string},
  clickable = true
) => [
  classes.editor,
  ...(clickable ? [classes.clickable] : []),
];

export default useStyles;
