import React, { useState, useMemo } from 'react';
import TGOptionSelectorModal, { TGOptionSelectorModalProps } from '../Common/Modals/TGOptionSelectorModal';
import { TextField } from '@material-ui/core';
import ActionIcon from '../ActionIcon';

type Option = { label: string; value: string };
type SelectorModalProps = Omit<
  TGOptionSelectorModalProps,
  'open' | 'onDone' | 'onCancel' | 'filterPlaceHolder' | 'title' | 'options' | 'value' | 'setOpen'
> & {
  onChange: (value: unknown) => void;
  label: string;
  error?: string;
  options: Option[];
  value: Option[];
  allSelectedMessage: string;
};

const SelectorModal = (props: SelectorModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCancel = () => setIsOpen(false);
  const handleDone = (value: unknown) => {
    setIsOpen(false);
    const finalValue: Option[] = (value as string[]).map((value) => ({
      value,
      label: props.options.find((option) => option.value === value)?.label ?? value,
    }));
    props.onChange(finalValue);
  };
  const valuesArray = useMemo(() => props.value.map((option) => option.value), [props.value]);
  const labelsArray = useMemo(() => props.value.map((option) => option.label), [props.value]);
  return (
    <div>
      <TextField
        placeholder={props.label}
        onClick={() => setIsOpen(true)}
        error={!!props.error}
        helperText={props.error}
        value={labelsArray.length === props.options.length ? props.allSelectedMessage : labelsArray.join(', ')}
        InputProps={{
          endAdornment: <ActionIcon name="fa-caret-right" color="#6D6D6D" />,
        }}
      />
      <TGOptionSelectorModal
        {...props}
        value={valuesArray}
        open={isOpen}
        onCancel={handleCancel}
        onDone={handleDone}
        filterPlaceHolder={'Search ...'}
        title={props.label}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default SelectorModal;
