import { Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, useMemo } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import { colors } from '../styles/theme';
import ActionIcon from './ActionIcon';
import { AlertDialog } from '@terragotech/gen5-shared-components';
import TGTooltip from './TGTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
    },
    topMenuItem: {
      cursor: 'unset',
    },
    menuItem: {
      minWidth: 250,
    },
    divider: {
      width: '100%',
      height: 2,
      backgroundColor: colors.grayLine,
      alignSelf: 'center',
    },
    exportLink: {
      textDecoration: 'none',
    },
    text: {
      color: colors.black,
    },
    selectedText: {
      color: colors.grayDescription,
    },
    close: {
      padding: theme.spacing(0.5),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIcon: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    iconHolder: {
      marginRight: 13,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fill: colors.grayDescription,
      height: 18,
    },
  })
);
export interface ActionsMenuButton {
  state?: string;
  disabledMessage?: string;
  label?: string;
  icon?: string;
  onClick: () => void;
  color?: string;
  limitMaxOccurrence?: number;
}
interface ActionsMenuProps {
  anchorEl: any;
  onClose: () => void;
  statusText?: string;
  actions: ReadonlyArray<ActionsMenuButton>;
  selectedCount?: number;
}

/**
 * ActionsMenu
 *
 * Displays options to either 'delete' or 'export .csv' assetData
 *
 * @param headers
 * @param assetData
 * @param anchorEl
 * @param handleClose
 */

const ActionsMenu: FunctionComponent<ActionsMenuProps> = props => {
  const { anchorEl, onClose, statusText, actions, selectedCount } = props;
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'actions-menu' : undefined;

  const { selectedRecordTypeDefinition } = useRecordType();
  const [statusTextModel, setStatusTextModel]: any = React.useState(0);
  const menuOptionActions = useMemo(() => {
    return (actions || []).filter(x => !(x?.state === 'hidden'));
  }, [actions]);

  const showAlertPopup = (e: any) => {
    setStatusTextModel(e);
  };
  return (
    <React.Fragment>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {statusText && (
          <MenuItem
            className={classes.menuItem}
            classes={{ root: classes.topMenuItem }}
            style={{ backgroundColor: 'rgba(0,0,0,0.0)' }}
          >
            <Typography className={classes.selectedText}>{statusText}</Typography>
          </MenuItem>
        )}
        {statusText && <Divider className={classes.divider} />}
        {menuOptionActions.map((menuOption, index) => {
          return (
            <TGTooltip key={index} title={menuOption?.disabledMessage || ''} placement="right">
              <div className={`MuiButton-root MuiButton-textSizeSmall Mui-disabled`}>
                <MenuItem
                  className={classes.menuItem}
                  disabled={menuOption?.state === 'disabled'}
                  onClick={() =>
                    menuOption?.limitMaxOccurrence !== null &&
                    menuOption?.limitMaxOccurrence !== undefined &&
                    (selectedCount || 0) > menuOption?.limitMaxOccurrence
                      ? showAlertPopup(index + 1)
                      : menuOption?.onClick()
                  }
                >
                  <div className={classes.iconHolder}>
                    {menuOption?.icon && <ActionIcon name={menuOption?.icon} color={menuOption?.color || '#8c8c8c'} />}
                  </div>
                  <Typography
                    style={{
                      color: menuOption?.color,
                    }}
                    className={classes.text}
                  >
                    {menuOption?.label}
                  </Typography>
                </MenuItem>
              </div>
            </TGTooltip>
          );
        })}
      </Menu>
      {statusTextModel !== 0 && (
        <AlertDialog
          title={`Error, too many ${selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} selected`}
          okText={'Ok'}
          cancelText={'OK'}
          onOkPress={() => {
            setStatusTextModel(0);
          }}
        >
          <div>
            You currently have {selectedCount} {selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} selected, but
            you can only run this command on {menuOptionActions[statusTextModel - 1]?.limitMaxOccurrence}{' '}
            {selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} at a time. Please select fewer{' '}
            {selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} and try again.
          </div>
        </AlertDialog>
      )}
    </React.Fragment>
  );
};

export default ActionsMenu;
