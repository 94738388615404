import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ListItemText, ListItem, Typography } from '@material-ui/core/';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import openFolder from '../images/iconFolderOpen.png';
import closedFolder from '../images/iconFolderClosed.png';
import clsx from 'clsx';
import _ from 'lodash';
import magicText from 'i18next';
import { colors } from '../styles/theme';
import './FlatFolderList.css';
import useAnimation from '../hooks/useAnimation';

const styles = (theme: Theme) =>
  createStyles({
    folder: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 0,
    },
    openFolder: {
      background: 'linear-gradient(#e0e0e0, #e0e0e0) 26px 100% / 2px 50%  no-repeat, #fff',
    },
    iconSection: {
      display: 'flex',
      width: 25,
    },
    folderName: {
      fontWeight: 400,
      paddingLeft: 5,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14,
    },
    folderIcon: {
      width: '21px',
      height: '16px',
    },
    folderWithSelectedProject: {
      fontWeight: 410,
    },
    folderBadge: {
      position: 'absolute',
      left: '25px',
      top: '3px',
      lineHeight: '18px',
      textAlign: 'center',
      background: colors.warning,
      border: '1px solid #FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '50%',
    },
    subFolderBadge: {
      left: '53px',
      top: '0px',
    },
    folderBadgeText: {
      color: 'white',
      fontSize: '12px',
      fontWeight: 410,
      paddingRight: '5px',
      paddingLeft: '5px',
    },
    folderBadgeAboveTen: {
      top: '2px',
      left: '24px',
    },
    folderBadgeAbove99: {
      left: '24px',
      top: '0px',
      lineHeight: '21px',
      height: '23px',
      width: '23px',
    },
    folderBadgeTextAboveTen: {
      paddingLeft: '3px',
      paddingRight: '3px',
      paddingBottom: '1px',
      paddingTop: '1px',
    },
    folderBadgeTextAbove99: {
      fontSize: '10px',
      padding: '0px',
    },
    project: {
      marginLeft: 26,
      paddingLeft: 18,
      height: '100%',
      overflow: 'hidden',
      background:
        'linear-gradient(#e0e0e0, #e0e0e0) 0px 0% / 2px 100%  no-repeat, linear-gradient(#e0e0e0, #e0e0e0) 0px 30% / 16px 2px no-repeat',
    },
    lastProject: {
      background:
        'linear-gradient(#e0e0e0, #e0e0e0) 0px 0% / 2px 30%  no-repeat, linear-gradient(#e0e0e0, #e0e0e0) 0px 30% / 16px 2px no-repeat',
    },
    subFolderListItem: {
      height: 35,
      paddingTop: 0,
      paddingBottom: 0,
    },
    subFolder: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
      paddingLeft: 18,
      height: '100%',
      overflow: 'hidden',
      background:
        'linear-gradient(#e0e0e0, #e0e0e0) 0px 0% / 2px 100%  no-repeat, linear-gradient(#e0e0e0, #e0e0e0) 0px 40% / 16px 2px no-repeat',
    },
    lastSubFolder: {
      background:
        'linear-gradient(#e0e0e0, #e0e0e0) 0px 0% / 2px 40%  no-repeat, linear-gradient(#e0e0e0, #e0e0e0) 0px 40% / 16px 2px no-repeat',
    },
    backButtonText: {
      padding: 0,
      height: 35,
      color: theme.palette.primary.main,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    projectListItem: {
      padding: 0,
      height: 45,
      '& .MuiListItemText-primary': {
        color: theme.palette.primary.main,
        fontWeight: 410,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: 14,
      },
      '& .MuiListItemText-secondary': {
        color: theme.palette.grey[700],
        fontSize: 13,
      },
    },
    selectedProjectListItem: {
      background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) 0px 0% / 3px 100%  no-repeat, ${theme.palette.grey[100]}`,
      '& .MuiListItemText-primary': {
        color: 'black',
      },
      '& .MuiListItemText-secondary': {
        color: theme.palette.grey[800],
      },
    },
    projectListItemText: {
      marginTop: 3,
      marginBottom: 3,
    },
    projectListWrapper: {
      flex: '1',
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
    },
    listWrapper: {
      flex: '1',
      minHeight: 0,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    listStatusWrapper: {
      flex: '0',
    },
    arrowBack: { height: 17, width: 18, color: theme.palette.primary.main },
  });

interface FolderProp {
  id: string;
  label: string;
  projects: Array<{
    id: string;
    label: string;
    totalCount: number;
  }>;
  subFolders?: any;
  parentFolderId?: any;
  open: boolean;
}

interface FoldersListProps extends WithStyles<typeof styles> {
  onRouteClick: (projectId: string) => void;
  onFolderClick: (folderName: string) => void;
  selectedProject: Array<string>;
  folders: Array<FolderProp>;
  setScrolling: Function;
  scrolling: boolean;
  searchText: string;
  lookUpObj: any;
}
interface FlatFolderItem {
  id: string;
  type: 'Folder' | 'Project' | 'SubFolder';
  title: string;
  count: number;
  open?: boolean;
  parentFolderId?: string;
}

/**
 * FoldersList - has folder prop passed to it, which it displays as a list using AutoSizer and Row.
 *
 * In addition, when a scroll event is detected, determines animation/styling in SideBarInner.tsx
 */

const hasSearchText = (value: string, searchText: string): boolean => {
  if (!value || !searchText) {
    return true;
  }
  return value.toLowerCase().includes(searchText.toLowerCase());
};

const FlatFoldersList: FunctionComponent<FoldersListProps> = (props) => {
  const { onFolderClick, selectedProject, folders, classes, setScrolling, scrolling, searchText } = props;
  const [currentSubFolder, setCurrentSubFolder]: any = useState();

  const getParentFolderIds = (currentFolderId: string, folderArray: Array<FolderProp>): Array<string> => {
    let ids: Array<string> = [];
    if (!currentFolderId) {
      //current root level so get all root folders.
      let rootFolders = folderArray.filter((x) => x.parentFolderId === null);
      (rootFolders || []).forEach((folder) => {
        ids.push(folder.id);
      });
    }
    //get all sub folder parent folder ids.
    const selectedFolder = folderArray.find((x) => x.id === currentFolderId);
    ids.push(selectedFolder?.parentFolderId);
    if (selectedFolder?.parentFolderId) {
      ids = ids.concat(getParentFolderIds(selectedFolder?.parentFolderId, folderArray));
    }
    return ids.filter((id) => !!id);
  };

  const folderOrFolderChildrenContainSelectedProjects = (
    projectId: string,
    currentFolderId: string,
    folderArray: Array<FolderProp>
  ): boolean => {
    let found = false;
    if (!folderArray) {
      return found;
    }
    const selectedFolder = folderArray.find((x) => x.id === currentFolderId);
    if ((selectedFolder?.projects || []).find((x) => x.id === projectId)) {
      found = true;
    }
    let matchingSubfolders = folderArray.filter((childFolder) => childFolder?.parentFolderId === currentFolderId);
    (matchingSubfolders || []).forEach((matchingChild) => {
      if (!found) {
        found = folderOrFolderChildrenContainSelectedProjects(projectId, matchingChild.id, folderArray);
      }
    });
    return found;
  };

  const folderOrFolderChildrenContainSearchText = (
    searchText: string,
    currentFolderId: string,
    folderArray: Array<FolderProp>
  ): boolean => {
    let found = false;

    if (!folderArray) {
      return found;
    }
    const selectedFolder = folderArray.find((x) => x.id === currentFolderId);
    if (
      hasSearchText(selectedFolder?.label || '', searchText) ||
      (selectedFolder?.projects || []).find((x) => hasSearchText(x.label, searchText))
    ) {
      found = true;
    }
    let matchingSubfolders = folderArray.filter((childFolder) => childFolder?.parentFolderId === currentFolderId);
    (matchingSubfolders || []).forEach((matchingChild) => {
      if (!found) {
        found = folderOrFolderChildrenContainSearchText(searchText, matchingChild.id, folderArray);
      }
    });
    return found;
  };

  const doesFolderOrFolderChildrenContainAProjects = (folder: FolderProp, folderArray: Array<FolderProp>) => {
    if ((folder?.projects || []).length > 0) {
      return true;
    }
    let found = false;
    let matchingSubfolders = folderArray.filter((childFolder) => childFolder?.parentFolderId === folder.id);
    if (!matchingSubfolders) return false;
    matchingSubfolders.forEach((matchingChild) => {
      if (!found) {
        found = doesFolderOrFolderChildrenContainAProjects(matchingChild, folderArray);
      }
    });
    return found;
  };

  const filteredFolders: Array<FolderProp> = useMemo(() => {
    //build up folder tree, get all parent folders for current folder.
    let parentFolderIds: Array<string> = getParentFolderIds(currentSubFolder?.id, folders);
    //add current folder to tree.
    if (currentSubFolder) {
      parentFolderIds.push(currentSubFolder.id);
    }
    let filtered: Array<FolderProp> =
      folders.filter((x) => {
        return parentFolderIds.includes(x.id);
      }) || [];

    let currentFolderSubfolder = currentSubFolder?.id
      ? folders.filter((x) => x.parentFolderId === currentSubFolder.id)
      : folders.filter((x) => parentFolderIds.includes(x.parentFolderId));
    currentFolderSubfolder.forEach((folder) => {
      let folderAlreadyExits = filtered.findIndex((x) => x.id === folder.id) > -1;
      if (folderOrFolderChildrenContainSearchText(searchText, folder.id, folders)) {
        //check if folder subfolder contains a project.
        let containsOneOrMoreProjects = doesFolderOrFolderChildrenContainAProjects(folder, folders);
        if (!folderAlreadyExits && containsOneOrMoreProjects) {
          filtered.push(folder);
        }
      }
    });
    return filtered;
  }, [folders, searchText, currentSubFolder]);

  const flattenedFolders: FlatFolderItem[] = useMemo(() => {
    const tempFolders: FlatFolderItem[] = [];

    let rootFolders = filteredFolders ? _.orderBy(filteredFolders, [(x) => x.label?.toLowerCase()]) : [];
    rootFolders.forEach((folder) => {
      if (currentSubFolder?.id && folder.id != currentSubFolder?.id) {
        return;
      }

      //TODO: Refactor all logic for showing folders and project in a single location.
      const folderProjectCount =
        (folder?.projects || []).length < 1
          ? -1
          : folder.projects.reduce(
              (acc, proj) => acc + (hasSearchText(proj.label, searchText) ? proj.totalCount : 0),
              0
            );

      const folderSubFolderCount = filteredFolders.filter((x) => x.parentFolderId === folder?.id)?.length || 0;

      if (
        ((folderProjectCount >= 0 || folderSubFolderCount > 0) &&
          hasSearchText(folder.label, searchText) &&
          !folder?.parentFolderId) ||
        currentSubFolder?.id
      ) {
        tempFolders.push({
          id: folder.id,
          title: folder.label,
          open: currentSubFolder?.id ? true : folder.open,
          type: 'Folder',
          count: folder.projects?.length || 0,
        });
      }
      let logic =
        ((folder.open || !!searchText.length) && !folder?.parentFolderId) ||
        (!!currentSubFolder?.id && folder?.id === currentSubFolder?.id);
      if (logic) {
        let subFolders = folder.subFolders ? _.orderBy(folder.subFolders, [(x) => x.label.toLowerCase()]) : [];
        subFolders.forEach((subFolder: any) => {
          if (filteredFolders.findIndex((x) => x.id === subFolder.id) !== -1) {
            tempFolders.push({
              id: subFolder.id,
              type: 'SubFolder',
              title: subFolder.label,
              open: false,
              count: subFolder?.projects ? subFolder?.projects.length : 0,
              parentFolderId: subFolder?.parentFolderId ? subFolder.parentFolderId : null,
            });
          }
        });

        let projects = folder.projects ? _.orderBy(folder.projects, [(x) => x.label.toLowerCase()]) : [];
        projects.forEach((project) => {
          if (hasSearchText(project.label, searchText)) {
            tempFolders.push({
              id: project.id,
              type: 'Project',
              title: project.label,
              count: project.totalCount,
            });
          }
        });
      }
    });
    return tempFolders;
  }, [filteredFolders, searchText, currentSubFolder, setCurrentSubFolder, currentSubFolder?.id]);
  const getItemSize = useCallback(
    (index: number) => {
      return flattenedFolders[index].type === 'Project' ? 45 : 35;
    },
    [flattenedFolders]
  );
  //adds getItemSize at every index
  const getTotalHeight = useCallback(
    () => _.range(flattenedFolders.length).reduce((total, num) => total + getItemSize(num), 0),
    [flattenedFolders, getItemSize]
  );
  const [totalHeight, setTotalHeight] = React.useState<number>(getTotalHeight());
  React.useEffect(() => {
    setTotalHeight(getTotalHeight());
  }, [filteredFolders, getTotalHeight]);
  const handleScroll = ({ scrollOffset }: { scrollOffset: number }, height: number) => {
    //if the content of the list is not at least 20 pixels taller than the height of the increased div,
    //dont set scroll. If scrolling is true, height no longer refers to the smaller div
    if (scrollOffset !== 0 && (scrolling || totalHeight > height + 60 + 20)) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const listRef = React.createRef<any>();
  React.useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [searchText, listRef]);
  const handleFolderClick = (index: number, id: string) => {
    onFolderClick(id);
    listRef.current.resetAfterIndex(index);
  };
  const { animationClasses, setToggle } = useAnimation(300);
  const handleSubFolderClick = (index: number, id: string) => {
    setToggle({ state: true, type: 'subfolder' });
    setCurrentSubFolder(flattenedFolders.find((folder) => folder.id === id));
    //Just in case AutoSizer needs this
    listRef.current.resetAfterIndex(0, true);
  };

  const selectedProjectCountBadge = (selectedCount: number, isSubFolder: boolean) => {
    if (!selectedCount) {
      return null;
    }
    return (
      <div
        className={clsx(
          selectedCount > 0 && classes.folderBadge,
          isSubFolder && classes.subFolderBadge,
          selectedCount > 9 && classes.folderBadgeAboveTen,
          selectedCount > 99 && classes.folderBadgeAbove99
        )}
      >
        <div
          className={clsx(
            classes.folderBadgeText,
            selectedCount > 9 && classes.folderBadgeTextAboveTen,
            selectedCount > 99 && classes.folderBadgeTextAbove99
          )}
        >
          {selectedCount > 99 ? '99+' : selectedCount}
        </div>
      </div>
    );
  };

  const Row = (innerProps: ListChildComponentProps) => {
    const { index, style } = innerProps;
    const item = flattenedFolders[index];
    const { title, type, open, count, id } = item;
    let selectedCount = 0;

    if (type === 'Folder' || type === 'SubFolder') {
      (selectedProject || []).forEach((selectedId: string) => {
        if (folderOrFolderChildrenContainSelectedProjects(selectedId, item.id, folders)) {
          selectedCount++;
        }
      });
      // Uncomment for 99+ UI check...
      //selectedCount = 100;
    }

    const selected = selectedProject.includes(id); // we even do this for folders, but it shouldn't really matter
    const lastItem = !flattenedFolders[index + 1] || flattenedFolders[index + 1].type === 'Folder';
    const FlatFoldersMarkup = () => (
      <div key={index} style={{ ...style }}>
        {type === 'Folder' && (
          <ListItem
            button
            onClick={() => handleFolderClick(index, id)}
            className={clsx(classes.folder, open && count && classes.openFolder)}
          >
            <div className={classes.iconSection}>
              <img src={(open && openFolder) || closedFolder} alt="Folder" className={classes.folderIcon} />
              {selectedProjectCountBadge(selectedCount, false)}
            </div>
            <ListItemText
              classes={{
                primary: clsx(classes.folderName, selectedCount > 0 && classes.folderWithSelectedProject),
              }}
              primary={title}
              style={{ paddingLeft: '10px', width: '100%' }}
            />
          </ListItem>
        )}
        {type === 'SubFolder' && (
          <ListItem button onClick={() => handleSubFolderClick(index, id)} className={clsx(classes.subFolderListItem)}>
            <div className={clsx(classes.subFolder, lastItem && classes.lastSubFolder)}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
                <img
                  // Update open or closed icon different logic
                  src={(open && openFolder) || closedFolder}
                  alt="Folder"
                  className={classes.folderIcon}
                />
                {selectedProjectCountBadge(selectedCount, true)}

                <ListItemText classes={{ primary: classes.folderName }} primary={title} style={{}} />
              </div>
            </div>
          </ListItem>
        )}
        {type === 'Project' && (
          <ListItem
            button
            onClick={() => props.onRouteClick(id)}
            className={clsx(classes.projectListItem, selected && classes.selectedProjectListItem)}
          >
            <div className={clsx(classes.project, lastItem && classes.lastProject)}>
              <ListItemText
                title={title}
                className={classes.projectListItemText}
                primary={title}
                secondary={`${count ? count.toLocaleString() : 0} ${
                  count - 1 ? magicText.t('Simple.Records') : magicText.t('Simple.Record')
                }`}
              />
            </div>
          </ListItem>
        )}
      </div>
    );
    return <FlatFoldersMarkup />;
  };

  const parentLabel: any = useMemo(() => {
    if (!!currentSubFolder?.parentFolderId) {
      const parent = filteredFolders.find((folder) => folder.id === currentSubFolder?.parentFolderId);
      return parent?.label;
    }
    return null;
  }, [currentSubFolder, currentSubFolder?.parentFolderId, filteredFolders]);

  return (
    <div className={props.classes.projectListWrapper}>
      <div className={animationClasses} />
      <div className={props.classes.listWrapper} id="list">
        {currentSubFolder?.id && (
          <div
            style={{ display: 'flex', alignItems: 'center', paddingLeft: 16, cursor: 'pointer' }}
            className={classes.backButtonText}
            onClick={() => {
              setToggle({ state: true, type: 'back' });
              let parent = filteredFolders.find((folder) => folder.id === currentSubFolder?.parentFolderId);
              !!parent?.parentFolderId ? setCurrentSubFolder(parent) : setCurrentSubFolder(null);
            }}
          >
            <ArrowBackIcon className={classes.arrowBack} />
            <Typography
              style={{
                letterSpacing: 0.4,
                fontSize: 14,
                lineHeight: 20,
                fontWeight: 500,
                paddingLeft: 3,
                textTransform: 'uppercase',
              }}
            >
              {parentLabel}
            </Typography>
          </div>
        )}
        <div style={{height:'100%'}}>
        <AutoSizer >
          {({ height, width }) => (
            <VariableSizeList
              height={height}
              width={width}
              itemSize={getItemSize}
              itemCount={flattenedFolders.length}
              onScroll={(props) => handleScroll(props, height)}
              ref={listRef}
            >
              {Row}
            </VariableSizeList>
          )}
        </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(FlatFoldersList);
