import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useAggregates } from '../../../contexts/AggregatesContext';
import _ from 'lodash';

const HideNonSelectableButtons = ({
  selectableAggregateTypes,
  buttonCaption = 'non selectable records',
  paddingRight = false,
  paddingBottom = false
}: {
  selectableAggregateTypes?: string[];
  buttonCaption?: string;
  paddingRight?: boolean | number;
  paddingBottom?: boolean | number;
}) => {
  const { setVisibleAggregateTypesNames, visibleAggregateTypesNames } = useAggregates();
  const [hiding, setHiding] = useState(false);
  const [defaultTypes] = useState(visibleAggregateTypesNames);
  const handleHidingNonSelectableRecords = () => {
    if (!!hiding) {
      // Make all types visable
      setVisibleAggregateTypesNames([...defaultTypes]);
      setHiding(false);
    } else {
      //  hide non clickable
      selectableAggregateTypes &&
        setVisibleAggregateTypesNames([...selectableAggregateTypes.filter((type) => defaultTypes.includes(type))]);
      setHiding(true);
    }
  };

  return (
    <Button
      onClick={handleHidingNonSelectableRecords}
      style={{
        position: 'absolute',
        zIndex: 100,
        right: _.isNumber(paddingRight) ? `${paddingRight}px` : !!paddingRight ? '25px' : '5px',
        bottom: _.isNumber(paddingBottom) ? `${paddingBottom}px` : !!paddingBottom ? '25px' : '5px',
        cursor: 'pointer',
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
        margin: '0 10px 10px 0',
      }}
    >
      {hiding === true ? 'Show' : 'Hide'} {buttonCaption}
    </Button>
  );
};

export default HideNonSelectableButtons;
