import React from 'react';
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import TGTooltip from '../TGTooltip';

export interface WorkflowCommandButtonProps {
  disabled: boolean;
  disabledMessage?: string;
  label?: string;
  onClick: () => void;
}

const WorkflowCommandButton = ({ disabled, disabledMessage, label, onClick }: WorkflowCommandButtonProps) => {
  const classes = useStyles();

  if (disabled && disabledMessage) {
    return (
      <TGTooltip title={disabledMessage} placement="right">
        <div
          className={`MuiButton-root MuiButton-textSizeSmall Mui-disabled ${classes.commandText} ${classes.disabledButton}`}
        >
          {label}
        </div>
      </TGTooltip>
    );
  }
  return (
    <Button size="small" color="primary" className={classes.commandText} onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commandText: {
      display: 'block',
      padding: 0,
      marginBottom: 10,
      textAlign: 'left',
      lineHeight: `17px`,
    },
    disabledButton: {
      width: 'fit-content',
      cursor: 'default',
    },
  })
);

export default WorkflowCommandButton;
