import { createTheme } from '@material-ui/core/styles';

// Using our own 'colors' value because MaterialUI's 'PaletteOptions' type def doesn't allow us to add custom colors to the palette
export const colors = {
  success: '#3B953E',
  grayPlaceholder: '#CCCCCC',
  danger: '#EB5757',
  white: '#FFFFFF',
  grayLine: '#EEEEEE',
  info: '#58C8FD',
  backgroundLight: '#F8F8F8',
  black: '#1D1D1F',
  grayDescription: '#8C8C8C',
  warning: '#EEA345',
  title: '#AAAAAA',
  black54: 'rgba(0, 0, 0, 0.54)',
  black10: 'rgba(0, 0, 0, 0.10)',
  seashell: '#f2f2f2',
};

const theme = (primaryColor: string = '#56CCF2', secondaryColor: string = '#455157') => {
  return createTheme({
    //@ts-ignore
    listIconWidth: 43,
    palette: {
      primary: {
        main: primaryColor,
      },
      info: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      grey: {
        50: '#f8f8f8',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      },
      error: {
        main: colors.danger,
        contrastText: colors.white,
      },
      success: {
        main: colors.success,
        contrastText: colors.white,
      },
    },
    typography: {
      h1: {
        fontSize: 24,
      },
      h2: {
        fontSize: 20,
      },
      h3: {
        fontSize: 16,
      },
      h4: {
        fontSize: 14,
        lineHeight: 'normal',
      },
      h5: {
        fontSize: 12,
      },
      fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    },
    overrides: {
      MuiListItem: {
        root: {
          '&:hover': {
            color: 'unset',
          },
        },
      },
      MuiTypography: {
        h3: {
          color: 'unset',
        },
      },
      MuiListItemText: {
        root: {
          padding: '0 5px',
        },
      },
      MuiInputAdornment: {
        positionStart: {
          marginRight: '4px',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'none',
          },
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: colors.white,
        },
      },
      MuiFormLabel: {
        root: {
          '& .MuiFormLabel-asterisk': {
            display: 'none',
          },
        },
      },
    },
  });
};

export default theme;
