import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { HeaderRendererProps } from '@terragotech/react-data-grid';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  draggableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: '0 10px',
    '&.dragging': {
      opacity: 0.5,
    },
    '&.over': {
      backgroundColor: '#ececec',
    },
    '& .dragIcon': {
      display: 'none',
    },
    '&:hover .dragIcon': {
      display: 'block',
    },
  },
  iconsContainer: {
    height: '15px',
    width: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .dragIcon': {
      cursor: 'move',
      height: '15px',
      width: '15px',
      transform: 'rotate(90deg)',
      marginLeft: '10px',
    },
  },
  icon: {
    width: '15px',
    height: '15px',
    '& path': {
      fill: colors.white,
    },
  },
}));

interface ColumnDragObject {
  key: string;
}

function wrapRefs<T>(...refs: React.Ref<T>[]) {
  return (handle: T | null) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(handle);
      } else if (ref !== null) {
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
        (ref as React.MutableRefObject<T | null>).current = handle;
      }
    }
  };
}

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  ...props
}: HeaderRendererProps<R, unknown> & {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
  sortDirection?: string;
}) {
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: props.column.key },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: ColumnDragObject) {
      onColumnsReorder(key, props.column.key);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={wrapRefs(drag, drop)}
      className={`${classes.draggableHeader} ${isDragging ? 'dragging' : ''} ${isOver ? 'over' : ''}`}
    >
      <span>{props.column.name}</span>
      <div className={classes.iconsContainer}>
        {props.sortDirection === 'ASC' ? (
          <ArrowUpward color="primary" className={classes.icon} />
        ) : props.sortDirection === 'DESC' ? (
          <ArrowDownward color="primary" className={classes.icon} />
        ) : (
          ''
        )}
        <div className={'dragIcon'}>
          <MenuIcon color="primary" className={classes.icon} />
        </div>
      </div>
    </div>
  );
}
