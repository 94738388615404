import React, { useState, useCallback, useMemo } from 'react';
import useCarousel from '../../../hooks/useCarousel';
import { makeStyles } from '@material-ui/core';
import BottomBar from './MultipleAssetCards/BottomBar';
import CardHolder from './MultipleAssetCards/CardHolder';
import { colors } from '../../../styles/theme';
import { useEffect } from 'react';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';

const GAP = 15;
const RATIO = 0.03;
export const ANIMATION_TIME_IN_SECONDS = 0.75;

export interface MultipleAssetCardsProps {
  assets: MapAssetType[];
  onCloseClick?: () => void;
  onCardChanged?: (assetId: string) => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  onFormSubmit?: ()=>void;
}

const MultipleAssetCards = (props: MultipleAssetCardsProps) => {
  const classes = useStyles();
  const { assets, onCloseClick, onCardChanged, onSelect, selectedAggregateId, selectableAggregateTypes, onFormSubmit } = props;

  const { currentIndex, size, hasPrev, hasNext, getPosition, goToNext, goToPrev } = useCarousel(assets.map(c=>c.id));
  useEffect(() => {
    onCardChanged && onCardChanged(assets[currentIndex].id);
  }, [currentIndex, onCardChanged, assets]);
  const [sizes, setSizes] = useState(assets.map((_) => ({ width: 0, height: 0 })));

  const setElementSize = useCallback((index: number, width: number, height: number) => {
    const newSize = { width, height };
    setSizes((sizes) => [
      ...sizes.slice(0, index),
      newSize,
      ...sizes.slice(index + 1).map((size) => (size.width === 0 ? newSize : size)),
    ]);
  }, []);

  const leftStartShift = useMemo(() => (size - 1) * GAP, [size]);
  const height = useMemo(() => Math.max(...sizes.map((x) => x.height)), [sizes]);
  const width = useMemo(() => Math.max(...sizes.map((x) => x.width)) + leftStartShift * 2, [sizes, leftStartShift]);
  const currentCardSize = useMemo(() => sizes[currentIndex], [sizes, currentIndex]);

  const getStyles = useCallback(
    (index: number): React.CSSProperties => {
      const scale = 1 - Math.abs(getPosition(index)) * RATIO;
      const leftShift = leftStartShift - currentIndex * GAP;
      const indexShift = index * GAP;
      const zIndex = size - Math.abs(index - currentIndex);

      return {
        left: leftShift + indexShift,
        transform: `scale(${scale})`,
        zIndex,
        width: index === currentIndex ? 'auto' : sizes[currentIndex].width,
        height: index === currentIndex ? 'auto' : sizes[currentIndex].height,
      };
    },
    [currentIndex, size, getPosition, leftStartShift, sizes]
  );

  const setSize = useCallback((width: number, height: number) => setElementSize(currentIndex, width, height), [
    currentIndex,
    setElementSize,
  ]);

  const getClasses = useCallback(
    (index: number) => {
      const result = [];
      if (index === currentIndex) {
        result.push(classes.frontCardContainer);
      } else {
        result.push('MuiPaper-elevation2');
        result.push('MuiPaper-rounded');
        result.push(classes.backgroundCardContainer);
      }
      result.push(classes.cardContainer);
      return result.join(' ');
    },
    [currentIndex, classes]
  );
  return (
    <div>
      <div className={classes.cardsRoot} style={{ height, width }}>
        {assets.map((asset, index) => (
          <CardHolder
            key={index}
            setSize={index === currentIndex ? setSize : () => {}}
            style={getStyles(index)}
            classes={{
              container: getClasses(index),
            }}
            assetId={asset.id}
            asset={asset}
            onCloseClick={onCloseClick}
            onSelect={onSelect}
            mapEditor={props.mapEditor}
            selectedAggregateId={selectedAggregateId}
            selectableAggregateTypes={selectableAggregateTypes}
            onFormSubmit={onFormSubmit}
          />
        ))}
      </div>
      <BottomBar
        width={currentCardSize.width}
        left={leftStartShift}
        hasPrev={hasPrev}
        hasNext={hasNext}
        goToNext={goToNext}
        goToPrev={goToPrev}
        numberOfElements={size}
        currentIndex={currentIndex}
      />
    </div>
  );
};

//TODO: move this utility up to somewhere more accessible, since it seems very useful
export const convertToCSSTransition = (properties: string[], timeInSeconds: number = ANIMATION_TIME_IN_SECONDS) =>
  properties.map((property) => `${property} ${timeInSeconds}s`).join(', ');

const useStyles = makeStyles((theme) => ({
  cardsRoot: {
    position: 'relative',
  },
  cardContainer: {
    bottom: 0,
    transition: convertToCSSTransition(['left', 'background-color', 'transform']),
  },
  frontCardContainer: {},
  backgroundCardContainer: {
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRight: `${GAP}px solid ${colors.white}`,
    boxSizing: 'border-box',
  },
}));

export default MultipleAssetCards;
