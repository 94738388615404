import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { noop } from 'lodash';
import { useRecordType } from '../recordTypeContext';
import { useConfig } from '@terragotech/gen5-shared-components';
import { FilterContextType } from './types';
import { useQuickSearch } from './useQuickSearch';
import { useFilterState } from './useFiltersState';
import { useMapExtentFilter } from './useMapExtentFilter';
import useDrawFilter from './useDrawFilter';

export const initialState = {};

export const FilterContext = createContext<FilterContextType>({
  quickSearch: '',
  setQuickSearch: noop,
  getQuickSearchForRecordType: () => '',
  filterState: initialState,
  setFilterState: noop,
  resetAllFilters: noop,
  mapFilterState: {
    extentFilterEnabled: false,
  },
  drawFilter: {
    enabled: false,
    features: [],
  },
  setMapBounds: noop,
  setMapExtentFiltering: noop,
  filterCount: 0,
  getFilterState: () => ({}),
  setQuickSearchForMap: noop,
  setDrawFilter: noop,
  enableDrawFilter: noop,
  setDrawtoolFilter: val => ({}),
  drawtoolFilter: false,
  isQuickSearchFocus: false,
  setQuickSearchFocus: noop,
});

const FilterContextProvider = (props: any) => {
  const [mapBounds, setMapBounds] = useState<any>();
  const { selectedRecordType } = useRecordType();
  const { aggregateDefinitions } = useConfig();
  const { filterState, setFilterState, getFilterState, resetAllFilters } = useFilterState({
    selectedRecordType,
    aggregateDefinitions,
  });
  const [drawtoolFilter, setDrawtoolFilter] = useState(false);
  const [isQuickSearchFocus, setQuickSearchFocus] = useState(false);
  const {
    quickSearch,
    setQuickSearch,
    getQuickSearchForRecordType,
    setQuickSearchForMap,
    resetQuickSearch,
  } = useQuickSearch({
    selectedRecordType,
    aggregateDefinitions,
  });
  const { mapExtentFiltering, setMapExtentFiltering } = useMapExtentFilter();

  const { drawFilter, setDrawFilter, enableDrawFilter } = useDrawFilter();

  useEffect(() => {
    setQuickSearchFocus(quickSearch.length > 0);
  }, [quickSearch]);

  const resetAllContextFilters = useCallback(() => {
    resetAllFilters();
    resetQuickSearch();
  }, [resetAllFilters, resetQuickSearch]);

  const filterContext: FilterContextType = useMemo(() => {
    return {
      filterState,
      mapFilterState: {
        extentFilterEnabled: mapExtentFiltering,
        mapBounds,
      },
      quickSearch,
      drawFilter,
      setQuickSearch,
      resetAllFilters: resetAllContextFilters,
      setFilterState,
      setMapBounds,
      setMapExtentFiltering,
      setDrawFilter,
      enableDrawFilter,
      filterCount: Object.keys(filterState).length + (mapExtentFiltering ? 1 : 0) + (quickSearch === '' ? 0 : 1),
      getFilterState,
      getQuickSearchForRecordType,
      setQuickSearchForMap,
      setDrawtoolFilter,
      drawtoolFilter,
      isQuickSearchFocus,
      setQuickSearchFocus,
    };
  }, [
    filterState,
    mapBounds,
    mapExtentFiltering,
    drawFilter,
    quickSearch,
    resetAllContextFilters,
    getFilterState,
    getQuickSearchForRecordType,
    setFilterState,
    setQuickSearch,
    setMapExtentFiltering,
    setQuickSearchForMap,
    setDrawFilter,
    enableDrawFilter,
    setDrawtoolFilter,
    drawtoolFilter,
    isQuickSearchFocus,
    setQuickSearchFocus,
  ]);

  return <FilterContext.Provider value={filterContext} {...props} />;
};
export const useFilter = () => useContext(FilterContext);
export default FilterContextProvider;
