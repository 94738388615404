import { Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AssetData from '../components/AssetData';
import TGAttachedAssets from '../components/TGAttachedAssets';
import TGBreadcrumb from '../components/TGBreadcrumb';
import { useConfig, colors } from '@terragotech/gen5-shared-components';
import { useRecordType } from '../contexts/recordTypeContext';
import { AssetDataEntry, AssetDataQueryResult } from './WorkflowPageContainer';
import WorkflowCommands from '../components/AssetEditor/WorkflowCommands';
import { ApolloQueryResult } from '@apollo/client';
import magicText from 'i18next';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    body: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    breadcrumbs: {
      minHeight: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: `${theme.palette.grey[200]} 1px solid`,
      paddingLeft: 15,
    },
    button: {
      color: colors.white,
      '& input': {
        color: theme.palette.primary.main,
      },
      justifySelf: 'end',
    },
    assetDataContainer: {
      display: 'grid',
      gridTemplate: '1fr / 220px 1fr',
      height: '100%',
      overflowY: 'hidden',
    },
    assetDataLeftSection: {
      paddingLeft: 20,
      paddingRight: 20,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.grey[50],
      overflowY: 'auto',
    },
    listItemPrimaryText: {
      paddingTop: 13,
      fontWeight: 500,
    },
    attributeText: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
    assetDataWrapper: {
      margin: 20,
      marginTop: 0,
    },
    header: {
      height: 46,
      minHeight: 46,
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: 12,
      display: 'grid',
      gridTemplate: '1fr / 200px 1fr 200px',
      alignItems: 'center',
    },
    headerText: {
      color: '#fff',
      textTransform: 'none',
      fontSize: 18,
      textAlign: 'center',
    },
  });

export interface AssetDataContainerProps extends WithStyles<typeof styles> {
  aggregateId: string;
  aggregateType: string;
  aggregateData: AssetDataEntry;
  crumbs: { label: string; onClick: () => void }[];
  refetch: () => Promise<ApolloQueryResult<AssetDataQueryResult>>;
}
type RelationType = { id: string; label: string };
/**
 * AssetDataContainer - Performs GraphQl fetch of data of prop assetId
 *
 * @param assetId
 */
const AssetDataContainer: React.FunctionComponent<AssetDataContainerProps> = (props) => {
  const { classes, aggregateType, crumbs, aggregateData, aggregateId } = props;
  const configContext = useConfig();
  const { selectedRecordType } = useRecordType();
  const history = useHistory();
  const aggregateDefinition = configContext.aggregateDefinitions.find((d) => d.name === aggregateType);
  
  // make a list of relationships to display on the left
  //TODO: See if we can move this into the child component <AttachedAssetsList />
  const relationships = aggregateDefinition?.propertyDefinitions.filter((property) => property.isRelationship);
  const attributeLabels = aggregateDefinition?.propertyDefinitions.reduce<Record<string, string>>((acc, val) => {
    return { ...acc, [val.field]: val.label };
  }, {});

  const attachedAssets =
    relationships?.map((relationship) => {
      const field = aggregateData[relationship.field];
      let output = {
        excludeLink: relationship.field === 'project',
        typeLabel: relationship.label,
        type: relationship.type,
        attachedItems: [] as RelationType[],
      };
      if (Array.isArray(field)) {
        output.attachedItems = field as Array<RelationType>;
      } else {
        output.attachedItems = [field as RelationType];
      }
      return output;
    }) || [];
  const handleClose = () => {
    history.goBack();
  };
  const displayName = `${aggregateDefinition?.singular}: ${aggregateData ? aggregateData.label : 'Editor'}`;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {/* Empty div for proper header alignment */}
        <div></div>
        <div className={classes.headerText}>{displayName}</div>
        <Button className={classes.button} onClick={handleClose}>
          <> {magicText.t('Common.doneLabel')}</>
        </Button>
      </div>
      <div className={classes.body}>
        <div className={classes.breadcrumbs}>
          <TGBreadcrumb crumbs={crumbs} />
        </div>
        <div className={classes.assetDataContainer}>
          <div className={classes.assetDataLeftSection}>
            <WorkflowCommands aggregateType={aggregateType} aggregateId={aggregateId} />
            <TGAttachedAssets types={attachedAssets} />
          </div>
          <AssetData assetData={aggregateData} attributeLabels={attributeLabels} aggregateDefinition={aggregateDefinition}/>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AssetDataContainer);
