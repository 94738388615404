import { Input, InputAdornment, Typography, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { FunctionComponent } from 'react';
import { useConfig, colors } from '@terragotech/gen5-shared-components';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import citydarklights from '../images/city-dark-lights-8047.jpg';
// imported as a backup in case a logourl isn't  specified in config
import streetlightsLogo from '../images/terrago-streetlights-logo-512.png';
import magicText from 'i18next';

const transitionTime = '0.25s';
const styles = (theme: Theme) =>
  createStyles({
    selectedProjectsContainer: {
      transition: transitionTime,
      height: (props: ScrollingProps) => (props.scrolling ? 85 : 145),
    },
    logoCentering: {
      display: 'flex',
      marginTop: '-50px',
      justifyContent: (props: ScrollingProps) => (props.scrolling ? 'flex-start' : 'center'),
      transition: transitionTime,
    },
    logoDiv: {
      width: 68,
      height: 68,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.primary.main}`,
      transition: transitionTime,
      transform: (props: ScrollingProps) => (props.scrolling ? 'scale(0.53, 0.53)' : ''),
      position: (props: ScrollingProps) => (props.scrolling ? 'relative' : 'static'),
      top: (props: ScrollingProps) => (props.scrolling ? -17 : 0),
      left: (props: ScrollingProps) => (props.scrolling ? -8 : 0),
    },
    logo: {
      borderRadius: '50%',
      height: 62,
      width: 62,
    },
    sideBarSearchWrapper: {
      transition: transitionTime,
      padding: (props: ScrollingProps) => (props.scrolling ? 0 : '5px 10px 10px 10px'),
      transform: (props: ScrollingProps) => (props.scrolling ? 'translate(0px, -43px)' : ''),
    },
    input: {
      backgroundColor: '#FFF',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      fontSize: 14,
      transition: transitionTime,
      width: '100%',
    },
    searchIcon: {
      height: 16,
      width: 16,
      paddingLeft: 5,
      color: theme.palette.grey[400],
    },
    deselectIconBackground: {
      backgroundColor: '#FFF',
      borderRadius: '50%',
      marginLeft: 5,
      height: 14,
      width: 14,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
    },
    deselectIcon: {
      color: theme.palette.primary.main,
      padding: 0,
      margin: 0,
      height: 18,
      fontWeight: 500,
      cursor: 'pointer',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 10px',
    },
    deselectWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition: transitionTime,
      color: (props: ScrollingProps) => (props.scrolling ? '#fff' : '#929292'),
      position: (props: ScrollingProps) => (props.scrolling ? 'fixed' : 'static'),
      top: (props: ScrollingProps) => (props.scrolling ? 26 : 0),
      left: (props: ScrollingProps) => (props.scrolling ? 49 : 0),
    },
    listItemHeader: {
      transition: transitionTime,
      color: (props: ScrollingProps) => (props.scrolling ? '#fff' : colors.black),
      fontWeight: (props: ScrollingProps) => (props.scrolling ? 'bold' : 500),
      transform: (props: ScrollingProps) => (props.scrolling ? 'translate(45px, -68px)' : ''),
    },
    secondaryHeader: {
      fontSize: 12,
      textAlign: 'right',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      marginLeft: '5px',
    },
    cityLights: {
      backgroundImage: `url('${citydarklights}')`,
      backgroundRepeat: 'no-repeat',
      height: 68,
      marginTop: '-16px',
      backgroundPositionY: 'top',
      backgroundSize: 'cover',
      transition: transitionTime,
      transform: (props: ScrollingProps) => (props.scrolling ? 'scaleY(0.88)' : ''),
      borderBottom: (props: ScrollingProps) =>
        props.scrolling ? `4px solid ${theme.palette.primary.main}` : `7px solid ${theme.palette.primary.main}`,
    },
  });

interface ScrollingProps {
  scrolling: boolean;
}

export interface SideBarInnerProps extends WithStyles<typeof styles> {
  handleSearchText: any;
  documentation?: boolean;
}

/**
 * SidebarInner - called from Sidebar
 *
 * @remarks SideBarInner contains form to change search text, using handler from Sidebar
 *
 * @param handleSearchText - handler passed from Sidebar
 * @param documentation - boolean that determines if component should try loading logo image from context
 * @param scrolling - boolean value that determines styling of SidebarInner
 *
 * Everytime there is a change in the input, handleSearchText is called to update the search results list.
 *
 * Handles shrinkin the header when scrolling through the list
 *
 */

const SideBarInner: FunctionComponent<SideBarInnerProps> = (props) => {
  const { classes, handleSearchText, documentation } = props;

  const configContext = useConfig();
  const { selectedProjects, setSelectedProjects } = useSelectedProject();
  let selectedProject = selectedProjects || [];

  const onDeselectProjects = () => {
    setSelectedProjects([]);
  };
  const projectLabel = configContext.aggregateDefinitions.find((def) => def.name === 'Project');

  return (
    <div className={classes.selectedProjectsContainer}>
      <div className={classes.cityLights} />
      <div className={classes.logoCentering}>
        <div className={classes.logoDiv}>
          <img
            src={
              (!documentation && configContext.theme.logoUrl.length > 0 && configContext.theme.logoUrl) ||
              streetlightsLogo
            }
            alt="logo"
            className={props.classes.logo}
          />
        </div>
      </div>
      <div className={classes.headerWrapper}>
        <Typography variant="h4" className={classes.listItemHeader}>
          {projectLabel?.plural.toUpperCase()}
        </Typography>
        <div className={classes.deselectWrapper}>
          <Typography variant="body1" className={classes.secondaryHeader}>
            {`${selectedProject.length} ${magicText.t('Selected')}`}
          </Typography>
          {selectedProject.length > 0 && (
            <Tooltip title={`${magicText.t('Deselect All')}`} placement="top-end">
              <div className={classes.deselectIconBackground}>
                <HighlightOffIcon className={classes.deselectIcon} onClick={onDeselectProjects} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={props.classes.sideBarSearchWrapper}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          disableUnderline
          placeholder={magicText.t('Search')}
          className={classes.input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSearchText(e.target.value.toLowerCase());
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(SideBarInner);
