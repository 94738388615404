import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { makeStyles, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import { ReactComponent as FilterIcon } from '../../images/tableFilterIcon.svg';
import { ReactComponent as TableFilterActiveIcon } from '../../images/tableFilterActiveIcon.svg';
import { useDialog } from '../../hooks/useDialog';
import FilterDialogWrapper from './FilterDialogWrapper';
import { debounce, uniq } from 'lodash';
import Search from '@material-ui/icons/Search';
import { TableDataContext } from '../../hooks/useTableData';
import { ColumnFilterInputOptions } from '../../hooks/tableHooks/useColumns';
import { ValueType } from '../../hooks/useTable';

const useStyles = makeStyles(theme => ({
  filterInput: {
    width: '100%',
    height: '29px',
    boxSizing: 'content-box',
    '& input': {
      fontSize: '13px',
      height: '100%',
      boxSizing: 'content-box',
      lineHeight: '100%',
      color: '#000',
    },
    backgroundColor: '#F8F8F8',
    color: '#000',
    marginLeft: '-16px',
    padding: '0 16px',
  },
  filterInputIcon: {
    width: '15px',
    height: '15px',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}));

export interface StyledTableFilterInputProps {
  name: string;
  keyName: string;
  options?: ColumnFilterInputOptions;
}

const StyledTableFilterInput = (props: StyledTableFilterInputProps) => {
  const classes = useStyles();

  const { keyName: key, name, options } = props;
  const { setFilter, data, filters } = useContext(TableDataContext);
  const dialog = useDialog();

  const [value, setValue] = useState(filters[key] ?? '');

  const lookup = useMemo(() => uniq(
    data.reduce((acc, row) => [
      ...acc,
      ...(row[key] ? (
        options?.valueDelimiter
          ? row[key].toString().split(options.valueDelimiter)
        : (Array.isArray(row[key]) && (row[key] as ValueType[]).length)
          ? (row[key] as ValueType[]).map(x => x.toString())
        : [row[key].toString()]
      ) : [''])
    ], [] as string[])
  ), [data, key]);

  const textValue = useMemo(() => (Array.isArray(value) ? value.join(', ') : value), [value]);

  const areFiltersActive = useMemo(() => Array.isArray(filters[key]) && filters[key].length > 0, [filters, key]);

  const onInputChange = useCallback(
    debounce((val: string) => {
      setFilter(key, val);
    }, 300),
    [setFilter, key]
  );

  const handleDialogClose = useCallback(
    (values: ReadonlyArray<string>) => {
      setFilter(key, [...values]);
      dialog.closeDialog();
    },
    [dialog, setFilter, key]
  );

  const handleIconClick = useCallback(() => {
    dialog.openDialog(
      <FilterDialogWrapper
        title={`${name} dialog`}
        elements={lookup}
        selectedElements={Array.isArray(filters[key]) ? (filters[key] as string[]) : []}
        onClose={handleDialogClose}
        inputOptions={options}
      />
    );
  }, [lookup, dialog, handleDialogClose, value, name]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(event.target.value);
      onInputChange(event.target.value);
    },
    [onInputChange]
  );

  useEffect(() => {
    if (value && !filters[key]) {
      setValue('');
      onInputChange('');
    }
  }, [filters, key]); // Excluding value from dependencies; we only want to update when filters change first.

  const endAdornment = useMemo(
    () => (
      <Tooltip title="Filter">
        <InputAdornment position="end" onClick={handleIconClick}>
          {areFiltersActive && <TableFilterActiveIcon color="primary" className={classes.filterInputIcon} />}
          {!areFiltersActive && <FilterIcon color="primary" className={classes.filterInputIcon} />}
        </InputAdornment>
      </Tooltip>
    ),
    [classes, handleIconClick, areFiltersActive]
  );

  return (
    <TextField
      className={classes.filterInput}
      value={textValue}
      onChange={handleChange}
      placeholder="Search"
      inputProps={{ 'aria-label': `filter data` }}
      InputProps={{
        endAdornment,
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.filterInputIcon} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

export default StyledTableFilterInput;
