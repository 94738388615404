import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { Symbol } from '../../../components/Legend';
import LegendContainer from '../../../containers/LegendContainer';
import MapRightDrawerContainer, { OPENED_DRAWER_WIDTH } from '../../../containers/MapRightDrawerContainer';
import { useMapServiceSection } from '../../../hooks/sectionsHooks/useMapServiceSection';
import { useMapTypeSection } from '../../../hooks/sectionsHooks/useMapTypeSection';
import useUserPreferences from '../../../hooks/useUserPreferences';
import { CLOSED_DRAWER_WIDTH, DRAWER_TRANSITION_TIME } from '../../TGDrawer/TGDrawer';

const DRAWER_PREFERENCE_KEY = 'drawerConfiguration';

export interface RightControlsProps {
  symbols?: Symbol[];
  limitedRightControls?: boolean;
  wmsControls?: boolean;
}

const RightControls: FunctionComponent<RightControlsProps> = props => {
  const { limitedRightControls, symbols, wmsControls } = props;
  const classes = useStyles();

  const { getPreferenceValue, setPreferenceValue } = useUserPreferences();

  const [open, setOpen] = useState(
    limitedRightControls ? false : Boolean(getPreferenceValue<boolean>(DRAWER_PREFERENCE_KEY) ?? true)
  );

  useEffect(() => {
    if (!limitedRightControls) {
      setPreferenceValue(DRAWER_PREFERENCE_KEY, open);
    }
  }, [open, setPreferenceValue, limitedRightControls]);

  const { section } = useMapTypeSection();
  const { section: wmsSection } = useMapServiceSection();

  return (
    <div className={limitedRightControls ? classes.mapLimitedRightControls : classes.mapRightControls}>
      <div
        className={clsx(classes.mapLegend, {
          [classes.mapLegendDrawerOpened]: open,
          [classes.mapLegendDrawerClosed]: !open,
        })}
        style={limitedRightControls ? { right: 20, pointerEvents: 'all' } : {}}
      >
        <LegendContainer symbols={symbols} symbolsOnly={limitedRightControls && !wmsControls} />
      </div>
      {limitedRightControls && (
        <div className={classes.mapType}>
          <div className={classes.baseMapStyle}>Map</div>
          {section.content}
        </div>
      )}
      {wmsControls && (
        <div className={classes.mapType2}>
          <div className={classes.baseMapStyle}>Layers</div>
          <div style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '50vh' }}>{wmsSection.content}</div>
        </div>
      )}
      {!limitedRightControls && <MapRightDrawerContainer open={open} setOpen={setOpen} />}
    </div>
  );
};

const LEGEND_RIGHT_MARGIN = 12;

const useStyles = makeStyles(theme => ({
  mapRightControls: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    minWidth: OPENED_DRAWER_WIDTH,
  },
  mapLimitedRightControls: {
    position: 'relative',
    top: '-100%',
    height: '100%',
    pointerEvents: 'none',
  },
  mapLegend: {
    transition: `right ${DRAWER_TRANSITION_TIME}`,
    bottom: 32,
    position: 'absolute',
    zIndex: 999,
    marginRight: LEGEND_RIGHT_MARGIN,
  },
  mapType: {
    transition: `right ${DRAWER_TRANSITION_TIME}`,
    top: 20,
    right: 20,
    position: 'absolute',
    zIndex: 999,
    marginRight: LEGEND_RIGHT_MARGIN,
    backgroundColor: 'white',
    padding: 5,
    pointerEvents: 'all',
    [theme.breakpoints.down('sm')]: {
      top: 68,
    },
    [theme.breakpoints.down('xs')]: {
      right: '5vw',
      marginRight: 0,
    },
  },
  mapType2: {
    transition: `right ${DRAWER_TRANSITION_TIME}`,
    top: 100,
    right: 20,
    position: 'absolute',
    zIndex: 999,
    marginRight: LEGEND_RIGHT_MARGIN,
    backgroundColor: 'white',
    padding: 5,
    pointerEvents: 'all',
  },
  mapLegendDrawerOpened: {
    right: OPENED_DRAWER_WIDTH,
  },
  mapLegendDrawerClosed: {
    right: CLOSED_DRAWER_WIDTH,
  },
  zoomRotateControls: {
    background: 'rgb(249, 249, 249)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginTop: 35,
    marginLeft: 0,
  },
  baseMapStyle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '14px',
    borderBottom: '2px solid #f5f5f5',
    padding: '5px',
    minWidth: 130,
  },
}));

export default RightControls;
