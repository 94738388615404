import * as React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import AccountModal from '../components/AccountModal';
import { AuthContext } from '@terragotech/gen5-shared-components';
import { StyledComponentProps, MenuClassKey } from '@material-ui/core';
import { ACCOUNT_MODAL_Z_INDEX } from '../utils/layers';

const styles = (theme: Theme) =>
  createStyles({
    accountModalContainer: {
      paddingRight: 24,
      zIndex: ACCOUNT_MODAL_Z_INDEX,
    },
  });

export interface AccountModalContainerProps extends WithStyles<typeof styles> {
  menuClasses?: StyledComponentProps<MenuClassKey>['classes'];
}

export interface AccountModalContainerState {
  menu: any;
  openChangePasswordModal: boolean;
  openAbout: boolean;
}

class AccountModalContainer extends React.Component<AccountModalContainerProps, AccountModalContainerState> {
  constructor(props: AccountModalContainerProps) {
    super(props);
    this.state = {
      menu: null,
      openChangePasswordModal: false,
      openAbout: false,
    };
  }
  handleClick = (event: any) => {
    this.setState({
      menu: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ menu: null });
  };

  handleOpenChangePasswordModal = () => {
    this.setState({
      openChangePasswordModal: true,
    });
  };

  handleCloseChangePasswordModal = () => {
    this.setState({
      openChangePasswordModal: false,
    });
  };

  handleOpenAboutModal = () => {
    this.setState({
      openAbout: true,
    });
  };

  handleCloseAboutModal = () => {
    this.setState({
      openAbout: false,
    });
  };

  public render() {
    const { menu, openChangePasswordModal, openAbout } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.accountModalContainer}>
        <AuthContext.Consumer>
          {(auth) => {
            return (
              <AccountModal
                menu={menu}
                user={auth.name || ''}
                username={auth.username || ''}
                permissionStatus={auth.permissionStatus || ''}
                onClose={this.handleClose}
                onClick={this.handleClick}
                openChangePasswordModal={openChangePasswordModal}
                openPasswordModal={this.handleOpenChangePasswordModal}
                closePasswordModal={this.handleCloseChangePasswordModal}
                openAbout={openAbout}
                openAboutModal={this.handleOpenAboutModal}
                closeAboutModal={this.handleCloseAboutModal}
                onLogoutClick={auth.clearToken}
                menuClasses={this.props.menuClasses}
              />
            );
          }}
        </AuthContext.Consumer>
      </div>
    );
  }
}

export default withStyles(styles)(AccountModalContainer);
