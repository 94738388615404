import React, { FunctionComponent } from 'react';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { useRecordType } from '../../contexts/recordTypeContext';
import { makeStyles } from '@material-ui/core';

export interface SymbolCellRendererProps {
  value: string;
  data: {
    id: string;
    primaryLocation?: {
      coordinates?: any;
      type?: any;
    };
  };
  handleSymbolClick: (assetId: string, recordType: string) => void;
}

//TODO: Remove SymbolCellRenderer Component
/**
 * SymbolCellRenderer - Not currently being used in the project, slotted for possible deletion
 *
 * @param props
 */

const SymbolCellRenderer: FunctionComponent<SymbolCellRendererProps> = (props) => {
  const { value, data, handleSymbolClick } = props;
  const classes = useStyles();
  const { selectedRecordType } = useRecordType();

  return (
    <div className={classes.container}>
      {data.primaryLocation?.type && data.primaryLocation?.coordinates ? (
        <img
          src={getSvgImageString(value, { globalScaleFactor: 1, embedFont: true })}
          height={25}
          alt="symbol key"
          onClick={() => handleSymbolClick(data.id, selectedRecordType)}
          className={classes.symbol}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingLeft: '10px',
  },
  symbol: {
    filter: 'drop-shadow(1px 1px 2px #ccc )',
    cursor: 'pointer',
  },
}));

export default SymbolCellRenderer;
