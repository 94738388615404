import { Box, Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Polygon, Properties } from '@turf/helpers';
import { Feature as MapFeature } from 'geojson';
import * as MapboxGl from 'mapbox-gl';
import { MapLayerEventType } from 'mapbox-gl';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState, useContext } from 'react';
import { Marker } from 'react-mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { useAggregates } from '../../../contexts/AggregatesContext/index';
import { LatLon, useConfig, useCurrentLocation, MapBoundsContext } from '@terragotech/gen5-shared-components';
import Map, { MapItem } from '../../Map/component/Map';
import { BaseLocationMapProps, useLocationStyles } from './CommonEditorUtils';
interface TGPointEditorProps extends BaseLocationMapProps {
  capturedLocation: GeoJSON.Point | null | undefined;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | null | undefined>>;
  setManualMode: Dispatch<SetStateAction<boolean>>;
}

export const TGPointEditor: React.FC<TGPointEditorProps> = props => {
  const { setCapturedLocation, capturedLocation, setManualMode } = props;

  // get all assets to display based on filters
  const configContext = useConfig();
  const { aggregateDefinitions } = useConfig();
  const { visibleAggregateTypesNames, filteredAssets } = useAggregates();
  const { mapCenter, currentZoomLevel } = useContext(MapBoundsContext);
  const visibleRecordKeys = useMemo(
    () => visibleAggregateTypesNames.map(name => aggregateDefinitions.find(agg => agg.name === name)?.queryKey),
    [visibleAggregateTypesNames, aggregateDefinitions]
  );
  const [desiredZoom, setDesiredZoom] = useState(18);

  const mapItems: Array<MapItem> = filteredAssets
    .filter(
      (asset: any) =>
        asset.id &&
        asset.primaryLocation?.type &&
        asset.primaryLocation?.coordinates &&
        visibleRecordKeys.includes(asset.recordTypeKey)
    )
    .map(asset => {
      return {
        location: asset.primaryLocation,
        styleKey: asset.symbolKey,
        id: asset.id,
        selected: false,
        aggregateType: asset.recordTypeKey,
      };
    });
  const [mapViewBox, setMapViewBox] = useState<MapFeature<Polygon, Properties>>();
  const mapRef = useRef<MapboxGl.Map | null>(null);
  const locationStyles = useLocationStyles();
  const currentLocation = useCurrentLocation();

  const onMapLoad: MapEvent = map => {
    mapRef.current = map;
    mapRef.current?.setCenter(getCenter());
  };

  //Various event handlers
  const onSymbolClick = useCallback((e: MapLayerEventType['click' | 'touchend' | 'touchstart']) => {
    const feature = e.features?.[0];
    // select item, highlight, set once listener?
    if (feature) {
      // do something
    }
  }, []);

  // add to Map API?
  const onClick = (map: MapboxGl.Map, evt: MapboxGl.EventData) => {
    setManualMode(false);
    const { lat, lng } = evt.lngLat;
    setCapturedLocation({
      type: 'Point',
      coordinates: [lng, lat],
    });
  };

  const flyTo = (center: LatLon) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [center?.longitude, center?.latitude],
      });
    }
  };

  const captureDeviceLocation = () => {
    setManualMode(false);
    if (currentLocation) {
      flyTo(currentLocation);
      const { latitude, longitude } = currentLocation;
      setCapturedLocation({
        type: 'Point',
        coordinates: [longitude, latitude],
      });
      //    setIsValidLocation(true);
    }
  };

  const geoJsonToCoordinates = useCallback((geoJson: any): [number, number] => {
    if (geoJson.type === 'Point') {
      if (
        geoJson.coordinates &&
        typeof geoJson.coordinates[0] === 'number' &&
        typeof geoJson.coordinates[1] === 'number'
      )
        return [geoJson.coordinates[0], geoJson.coordinates[1]];
    }
    if (geoJson && typeof geoJson.lat === 'number' && typeof geoJson.lon === 'number') {
      return [geoJson.lon, geoJson.lat];
    }
    return [parseFloat(configContext.initialMapExtents.lon) || 0, parseFloat(configContext.initialMapExtents.lat) || 0];
  }, []);

  //Determine Center Captured Location -> Default Config Lat Lon
  const getCenter = useCallback((): [number, number] => {
    if (!!capturedLocation) {
      return geoJsonToCoordinates(capturedLocation);
    }
    setDesiredZoom(currentZoomLevel);
    return [
      parseFloat(mapCenter[0]) || parseFloat(configContext.initialMapExtents.lon) || 0,
      parseFloat(mapCenter[1]) || parseFloat(configContext.initialMapExtents.lat) || 0,
    ];
  }, [capturedLocation]);

  return (
    <>
      <Box className={`${locationStyles.mapContainer} ${locationStyles.formPointMapContainer}`}>
        <Map
          desiredZoom={desiredZoom}
          // desiredCenter={getCenter()}
          items={mapItems}
          onStyleLoad={onMapLoad}
          onSymbolClick={onSymbolClick}
          onClick={onClick}
          setMapViewBox={setMapViewBox}
          mapViewBox={mapViewBox}
        >
          {!!capturedLocation &&
            capturedLocation?.coordinates &&
            capturedLocation.coordinates[0] &&
            capturedLocation.coordinates[1] && (
              <Marker coordinates={[capturedLocation?.coordinates[0], capturedLocation?.coordinates[1]]}>
                <LocationOnIcon className={locationStyles.captureLocationIcon} />
              </Marker>
            )}
        </Map>
        {currentLocation ? (
          <Button
            className={`${locationStyles.captureLocationBtn} ${locationStyles.bottomRightBtn}`}
            variant="contained"
            onClick={captureDeviceLocation}
          >
            capture device location
          </Button>
        ) : (
          <Tooltip
            className={`${locationStyles.captureLocationBtnDis} ${locationStyles.bottomRightBtn}`}
            title="This device does not have a location"
            placement="top"
          >
            <Button color="default" variant="contained">
              capture device location
            </Button>
          </Tooltip>
        )}
      </Box>
    </>
  );
};
