import React, { useCallback } from 'react';
import AddNewDialog, { AddNewDialogProps, AddNewFormField } from './AddNewDialog';
import { Role } from '../../containers/RolesContainer/types';
import magicText from 'i18next';

export type AddNewRoleDialogProps = Omit<AddNewDialogProps, 'fields' | 'title' | 'message' | 'onDone'> & {
  onDone: (role: Role) => void;
};

export interface NewRoleFormData {
  roleTitle: string;
  data: boolean;
}

const AddNewRoleDialog = (props: AddNewRoleDialogProps) => {
  const { onDone } = props;

  const handleDone = useCallback(
    (_data: unknown) => {
      const data = _data as NewRoleFormData;
      const role: Role = {
        id: -1,
        name: data.roleTitle,
        data: data.data,
        dashboards: [],
      };
      onDone(role);
    },
    [onDone]
  );

  const fields: AddNewFormField[] = [
    { type: 'text', label: magicText.t('UsersAdministration.role_title'), required: true, name: 'roleTitle' },
    { type: 'checkbox', label: 'Is Data Role?', name: 'data', initialValue: false, value: 'data' },
  ];
  return (
    <AddNewDialog
      {...props}
      onDone={handleDone}
      title={magicText.t('UsersAdministration.add_role')}
      message={magicText.t('UsersAdministration.required_role_info')}
      fields={fields}
    />
  );
};

export default AddNewRoleDialog;
