import { useState, useCallback, useRef, useEffect } from 'react';
import { OperationVariables, ApolloQueryResult, QueryOptions } from '@apollo/client';
import { DocumentNode, GraphQLError } from 'graphql';
import { useApolloClient } from '@apollo/client';

export type DataBasic = Record<string, unknown>;

export interface UseLazyQueriesReturnObject<TData extends DataBasic> {
  loading: boolean;
  error: Error | ReadonlyArray<GraphQLError> | null;
  called: boolean;
  refetch: (variables: VariablesRecord) => void;
  variables: VariablesRecord | null;
  data: TData | null;
}

// a keyed record of the query key to variables
export type VariablesRecord = Record<string, OperationVariables>;

export const useLazyQueries = <TData extends DataBasic>(
  //queries are a keyed record of query keys to the actual query
  queries: Record<string, DocumentNode>,
  options: Partial<QueryOptions<OperationVariables>>,
  initialData: TData
): UseLazyQueriesReturnObject<TData> => {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | ReadonlyArray<GraphQLError> | null>(null);
  const [called, setCalled] = useState(false);
  const [variables, setVariables] = useState<VariablesRecord | null>(null);
  const [data, setData] = useState<TData | null>(null);
  const existing = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      existing.current = false;
    };
  }, []);

  const refetch = useCallback(
    (refetchVariablesRecord: VariablesRecord) => {
      if (loading) {
        throw new Error('Cannot refetch while waiting for results');
      }
      //Don't let any previous data taint the current query
      setData(null);
      setVariables(refetchVariablesRecord);
      // if we have at least one key to query
      if (Object.keys(refetchVariablesRecord).length > 0) {
        setLoading(true);
        //keep track of the number of query keys we're trying to query against
        const currentQueries = Object.entries(refetchVariablesRecord).map(
          ([key, currentVariables]): Promise<{ key: string; result: ApolloQueryResult<any> }> => {
            return client
              .query({
                ...options,
                query: queries[key],
                variables: currentVariables,
              })
              .then((result) => {
                return { key, result };
              }).catch((e)=>{
                console.log('fetch error',e);
                return { key, result:{
                  errors: e,
                  data: {[key]: {edges: [], pageInfo: {hasNextPage: false}}},
                  loading: false,
                  networkStatus: 7
                } };
              });
          }
        );
        Promise.all(currentQueries).then((results) => {
          const newData: DataBasic = {} as TData;
          results.forEach((resultRecord) => {
            const { key, result } = resultRecord;
            if (result.errors) {
              setError(result.errors);
            }
            newData[key] = result.data;
          });
          setData(newData as TData);
          //TODO: this hack is here to make sure the data is processed before we let anything know that we're finished.
          // This prevents recalling this query and changing the variables before the data is processed.
          // If the data is processed before the variables are updated, then the data results will be applied to the wrong variables.
          setTimeout(() => {
            setLoading(false);
            setCalled(true);
          }, 0);
        });
      }
    },
    [client, loading, options, queries]
  );

  return {
    loading,
    error,
    called,
    variables,
    data,
    refetch,
  };
};
