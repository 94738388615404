import React, { FunctionComponent, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from '@terragotech/gen5-shared-components';

interface ProtectedRouteProps {
  path: string;
  children?: React.ReactNode;
}

/**
 * ProtectedRoute Component - assists with Routing...
 *
 * The component's role is to access the AuthState using AuthContext to verify if the user is logged in or not.
 * Depending on whether authState.token exists
 *      Exists - displays prop Component, currently <AssetsDashboard />
 *      DNE - Redirects the unlogged in user to "/login"
 *
 */
const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ children }) => {
  const { token, ready, loadExistingAuth } = useAuthContext();

  useEffect(() => {
    if (!token) {
      loadExistingAuth();
    }
  }, [token, loadExistingAuth]);

  if (!ready) return null;
  if (!token) {
    return <Redirect to="/login" />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
