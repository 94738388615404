import React from 'react';
import { withStyles } from '@material-ui/core';
import { colors } from '../styles/theme';

export interface ModalTitleProps {
  title: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
  classes: Record<'DialogTitle' | 'component', string>;
}

const ModalTitle = withStyles((theme) => ({
  DialogTitle: {
    backgroundColor: theme.palette.secondary.main,
    color: colors.white,
    height: 46,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  component: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}))((props: ModalTitleProps) => {
  const { classes, title } = props;
  return (
    <div className={classes.DialogTitle}>
      <div className={classes.component}>{props.leftComponent}</div>
      {title}
      <div className={classes.component}>{props.rightComponent}</div>
    </div>
  );
});

export default ModalTitle;
