import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, MouseEventHandler } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { colors } from '../styles/theme';
import magicText from 'i18next';

export interface TGAttachedAssetsProps {
  types: {
    typeLabel: string;
    type: string;
    attachedItems: { label: string; id: string; onClick?: MouseEventHandler<Element> }[];
    excludeLink?: boolean;
  }[];
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    color: colors.black,
  },
  typeTitle: {
    color: theme.palette.grey[600],
    marginTop: 15,
    marginBottom: 5,
    fontSize: 13,
    textAlign: 'left',
  },
  link: {
    fontWeight: 500,
    display: 'block',
    padding: 0,
    marginBottom: 10,
    textAlign: 'left',
    lineHeight: `17px`,
  },
  nothingAttached: {
    color: '#c7c7c7',
    marginTop: 10,
    fontSize: 14,
  },
  nothingAttachedType: {
    color: '#c7c7c7',
    fontSize: 14,
  },
}));

const TGAttachedAssets: React.FC<TGAttachedAssetsProps> = (props) => {
  const { types } = props;
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <div>
      <Typography color="textPrimary" className={classes.title}>
        <>{magicText.t('Simple.Attached Records')}</>
      </Typography>
      {types.length ? (
        types.map((type, i) => {
          const attachedItems = type.attachedItems.filter((item) => item);
          return (
            <Fragment key={i}>
              <p className={classes.typeTitle}>{type.typeLabel}</p>
              {attachedItems.length ? (
                attachedItems.map((item) =>
                  type.excludeLink ? (
                    <Typography key={item.id} className={classes.link}>
                      {item.label}
                    </Typography>
                  ) : (
                    <Button
                      key={item.id}
                      size="small"
                      color="primary"
                      component={RouterLink}
                      className={classes.link}
                      to={`${pathname}/${type.type}/${item.id}`}
                    >
                      {item.label}
                    </Button>
                  )
                )
              ) : (
                <Typography className={classes.nothingAttachedType}>None attached</Typography>
              )}
            </Fragment>
          );
        })
      ) : (
        <Typography className={classes.nothingAttached}>None attached</Typography>
      )}
    </div>
  );
};

export default TGAttachedAssets;
