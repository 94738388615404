import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Typography, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import warningCheckFilled from '../../images/importPage/warningCheckFilled.svg';
import errorCheckFilled from '../../images/importPage/errorCheckFilled.svg';
import successCheckFilled from '../../images/importPage/successCheckFilled.svg';
import { ErrorWarningType } from './FileToView';
import { makeStyles } from '@material-ui/core/styles';

interface AlertDialogProps {
  open: boolean;
  title: string;
  warningIcon?: boolean;
  bodySubtext?: string;
  children?: React.ReactNode;
  successErrorsOrWarnings: 'Success' | 'Errors' | 'Warnings';
  onConfirm: () => void;
  onCancel?: () => void;
  rowSuccess: string[];
  rowWarnings: ErrorWarningType[];
  rowErrors: ErrorWarningType[];
}

const useStyles = makeStyles((theme) => ({
  dialogText: {
    marginLeft: '10px',
    fontFamily: 'Roboto',
    fontWeight: 200,
    fontSize: 16,
    lineHeight: '16px',
    color: '#000000',
  },
  titleText: {
    marginLeft: '20px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 30,
    lineHeight: '35px',
    color: 'black',
  },
  bodyText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 16,
    maxWidth: 800,
    lineHeight: '25px',
    color: '#000000',
  },
}));

export default function SuccessErrorsAndWarningsDialog({
  open,
  title,
  children,
  onConfirm,
  onCancel,
  rowSuccess,
  rowWarnings,
  rowErrors,
  successErrorsOrWarnings,
}: AlertDialogProps) {
  const classes = useStyles();
  const calculatedTitle = useMemo(() => {
    if (title == 'Success') {
      return 'No Issues Found';
    } else if (title === 'Warnings') {
      return 'Warning(s)';
    } else if (title === 'Errors') {
      return 'Error(s)';
    }
  }, [successErrorsOrWarnings, title]);

  const calculatedBodyText = useMemo(() => {
    if (successErrorsOrWarnings === 'Success') return 'There are no issues found on the record. No action needed.';
    else if (successErrorsOrWarnings === 'Warnings')
      return (
        rowWarnings?.length +
        ' warning(s) found. Please correct and reupload corrected file to import records without warning.'
      );
    else if (successErrorsOrWarnings === 'Errors')
      return rowErrors?.length + ' errors(s) found. Please correct and reupload corrected file to import file.';

    return '';
  }, [successErrorsOrWarnings, rowWarnings, rowErrors]);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={false} maxWidth={'md'}>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          height: 66,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography className={classes.titleText}>{calculatedTitle}</Typography>
        <IconButton style={{ marginRight: '20px' }} aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div>
          <Typography className={classes.bodyText}>{calculatedBodyText}</Typography>
        </div>
        <div style={{ display: 'flex', width: 750, marginTop: 15, backgroundColor: '#FAFAFA' }}>
          <div style={{ width: 500 }}>
            {successErrorsOrWarnings === 'Success' &&
              rowSuccess?.map((successString: string, index: number) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                  <img src={successCheckFilled} alt="Success Icon" />
                  <Typography className={classes.dialogText}>{successString}</Typography>
                </div>
              ))}
            {successErrorsOrWarnings === 'Warnings' &&
              rowWarnings?.map((warningObj: ErrorWarningType, index: number) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                  <img src={warningCheckFilled} alt="Warning Icon" />
                  <Typography className={classes.dialogText}>
                    {warningObj?.column_name} {'>'} {warningObj?.message}
                  </Typography>
                </div>
              ))}
            {successErrorsOrWarnings === 'Errors' &&
              rowErrors?.map((errorObj: ErrorWarningType, index: number) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                  <img src={errorCheckFilled} alt="Error Icon" />
                  <Typography className={classes.dialogText}>
                    {errorObj?.column_name} {'>'} {errorObj?.message}
                  </Typography>
                </div>
              ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginTop: '20px' }}>
          <Button
            onClick={onConfirm}
            style={{ marginLeft: '10px', color: '#FFFFFF', borderRadius: 7, width: 142, height: 40, marginRight: 10 }}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
