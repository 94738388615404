import { CircularProgress, createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { useMemo, useCallback, useEffect } from 'react';
import FlatFoldersList from '../components/FlatFoldersList';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import Loading from '../images/notebook-loading.gif';
import magicText from 'i18next';
import useUserPreferences from '../hooks/useUserPreferences';
import { useProjects, ProjectType } from '../contexts/projectsContext';
import { cloneDeep } from 'lodash';

const PREFERENCE_KEY = 'openFolders';

const styles = (theme: Theme) =>
  createStyles({
    typography: {
      color: theme.palette.grey[600],
      padding: '0px 5px',
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    circle: {
      marginRight: 8,
    },
    notebookDiv: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
    },
    notebookLoading: {
      marginTop: 10,
      marginBottom: 5,
    },
  });

type sortTypes = 'name' | 'date' | 'assetCount';
export interface FoldersListContainerProps extends WithStyles<typeof styles> {
  searchText: string;
  sortValue: sortTypes;
  onSearchChange: (val: string) => void;
  setScrolling: Function;
  scrolling: boolean;
}

export interface FoldersListContainerState {}

/**
 * FoldersListContainer - contains FoldersList.
 *
 * It returns a loading screen and animation while data is fetched using graphql query.
 *
 * After 'projects' is populated with fetched data, passes folders as prop to and returns FoldersList
 */

const FoldersListContainer: React.FunctionComponent<FoldersListContainerProps> = (props) => {
  const { sortValue, searchText, setScrolling, scrolling } = props;
  const { selectedProjects, toggleSelectedProject, setSelectedProjects, setProjectRecordCounts } = useSelectedProject();
  const { getPreference, setPreference } = useUserPreferences();
  const [openFolders, setOpenFolders] = React.useState<any>(getPreference(PREFERENCE_KEY) || {});
  const handleFolderClick = (folderName: string) => {
    setOpenFolders({ ...openFolders, [folderName]: !openFolders[folderName] });
  };
  const { folders, projects } = useProjects();

  const createLookUp = useCallback(() => {
    let lookUpObj: { [key: string]: object } = {};
    folders.map((folder) => {
      let folderId = folder.id;
      lookUpObj[folderId] = [];
      lookUpObj[folderId] = folder.projects.map((project) => {
        return project.id;
      });
      return folder;
    });
    return lookUpObj;
  }, [folders]);

  const foldersToProjects = useMemo(() => createLookUp(), [createLookUp]);

  React.useEffect(() => {
    setPreference(PREFERENCE_KEY, openFolders);
  }, [openFolders, setPreference]);

  const sortFunction = useMemo(
    () => (a: ProjectType, b: ProjectType) => {
      if (sortValue === 'assetCount') {
        return b.totalCount - a.totalCount;
      } else if (sortValue === 'date') {
        return 0;
      } else if (sortValue === 'name') {
        return a.label ? a.label.localeCompare(b.label) : -1;
      }
      return 0;
    },
    [sortValue]
  );
  //since selectedProjects now grabs from localStorage, make sure we dont have any selected that no longer exist
  React.useEffect(() => {
    if (projects && projects.length > 0) {
      var savedSelectedProjects = selectedProjects;
      const projectIds = projects.map((project) => project.id);
      savedSelectedProjects = savedSelectedProjects.filter((name) => projectIds.includes(name));
      if (selectedProjects.length !== savedSelectedProjects.length) {
        setSelectedProjects(savedSelectedProjects);
      }
    }
  }, [projects, selectedProjects, setSelectedProjects]);

  const localFolders = useMemo(() => {
    if (folders.length === 0 && projects.length > 0) {
      return [{ label: 'Unsorted', id: 'Unsorted', projects: projects, open: !!openFolders['Unsorted'] }];
    } else if (folders.length > 0) {
      return folders.map((folder) => {
        return {
          ...folder,
          projects: folder.projects,
          subFolders: folder.subFolders,
          parentFolderId: folder?.parentFolderId,
          open: !!openFolders[folder.id],
        };
      });
    } else {
      return [];
    }
  }, [folders, projects, openFolders, sortFunction, searchText]);

  useEffect(() => {
    setProjectRecordCounts(val => {
      const counts = cloneDeep(val);
      projects.forEach(p => counts[p.id] = p.totalCount);
      return counts;
    });
  }, [setProjectRecordCounts, projects]);

  if (localFolders.length > 0) {
    return (
      <FlatFoldersList
        onRouteClick={toggleSelectedProject}
        onFolderClick={handleFolderClick}
        selectedProject={selectedProjects}
        folders={localFolders}
        setScrolling={setScrolling}
        scrolling={scrolling}
        searchText={searchText}
        lookUpObj={foldersToProjects}
      />
    );
  } else {
    return (
      <div>
        <Typography variant="h4" className={props.classes.typography}>
          <>
            {' '}
            {magicText.t('Loading')} <CircularProgress size={14} className={props.classes.circle} />
          </>
        </Typography>
        <div className={props.classes.notebookDiv}>
          <img src={Loading} alt="" className={props.classes.notebookLoading} />
          <img src={Loading} alt="" className={props.classes.notebookLoading} />
          <img src={Loading} alt="" className={props.classes.notebookLoading} />
        </div>
      </div>
    );
  }
};

export default withStyles(styles)(FoldersListContainer);
