import React, { createContext, FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useUserPreferences } from '../hooks/useUserPreferences';

const PREFERENCE_KEY = 'fullscreen';

interface FullscreenObject {
  fullscreen: boolean;
}
interface AssetsDashboardType {
  fullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
  verticalPercentage: number;
  setVerticalPercentage: (percents: number) => void;
  prevVerticalPercentage: number;
  setPrevVerticalPercentage: (prevVerticalPercent: number) => void;
}

export const AssetsDashboardContext = createContext<AssetsDashboardType>({
  fullscreen: false,
  setFullscreen: () => {},
  verticalPercentage: 43,
  setVerticalPercentage: () => {},
  prevVerticalPercentage: 43,
  setPrevVerticalPercentage: () => {},
});

export const AssetsDashboardProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const { setPreference, getPreference } = useUserPreferences();
  const [verticalPercentage, setVerticalPercentage] = useState(43);
  const [fullscreen, setFullscreen] = useState(false);
  const [prevVerticalPercentage, setPrevVerticalPercentage] = useState<number>(43);

  useEffect(() => {
    const fullscreenObject = getPreference(PREFERENCE_KEY) as FullscreenObject;
    if (fullscreenObject) {
      setFullscreen(fullscreenObject.fullscreen);
    } else {
      setFullscreen(false);
    }
  }, [getPreference]);

  useEffect(() => {
    setPreference(PREFERENCE_KEY, { fullscreen });
    if (fullscreen === true) {
      setVerticalPercentage(100);
    }
  }, [fullscreen, setVerticalPercentage, setPreference]);

  const value = useMemo(() => {
    return {
      fullscreen,
      verticalPercentage,
      setFullscreen,
      setVerticalPercentage,
      prevVerticalPercentage,
      setPrevVerticalPercentage
    };
  }, [fullscreen, verticalPercentage, prevVerticalPercentage]);

  return <AssetsDashboardContext.Provider value={value}>{props.children}</AssetsDashboardContext.Provider>;
};
