const PendingJobsIcon = (props: {fill: string, size: number}) => {
    return <svg width={props.size} height={props.size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={props.size} height={props.size} fill="#E5E5E5" />
        <g clipPath="url(#clip0_1559_0)">
            <rect width="1440" height="900" transform="translate(-133 -282)" fill="white" />
            <rect x="-40" y="-27" width="1226" height="243" rx="8" fill="#FAFAFA" />
            <path d="M17.1993 10.1958L25.5944 1.80078V10.1958H17.1993ZM8.80424 15.7943L0.40918 24.1894V15.7943H8.80424Z" fill={props.fill} />
            <path d="M13.0018 0.40625C6.05748 0.40625 0.40918 6.05718 0.40918 12.9988H3.20841C3.20841 7.59714 7.6027 3.20548 13.0018 3.20548C16.8976 3.20548 20.2609 5.49838 21.8376 8.80131H24.8598C23.1257 3.91906 18.4717 0.40625 13.0018 0.40625ZM13.0018 22.7922C9.10594 22.7922 5.74267 20.4993 4.16597 17.1964H1.14375C2.87785 22.0786 7.53186 25.5914 13.0018 25.5914C19.9461 25.5914 25.5944 19.9431 25.5944 12.9988H22.7951C22.7951 18.3979 18.4008 22.7922 13.0018 22.7922Z" fill={props.fill} />
        </g>
        <defs>
            <clipPath id="clip0_1559_0">
                <rect width="1440" height="900" fill="white" transform="translate(-133 -282)" />
            </clipPath>
        </defs>
    </svg>
};

export default PendingJobsIcon;