import React from 'react';
import magicText from 'i18next';
import ImportTitleWithToggle, { ImportTitleWithToggleProps } from './ImportTitleWithToggle';
import { Button, withStyles } from '@material-ui/core';
import { colors } from '../../styles/theme';

export interface ModalDoneTitleProps {
  title: ImportTitleWithToggleProps['title'];
  leftComponent?: ImportTitleWithToggleProps['leftComponent'];
  onDone?: () => void;
  onCancel?: () => void;
  onToggle?: () => void;
  fileIdToView?: boolean;
  setFileIdToView?: (x: string | undefined) => void;
}
const ImportDoneCancelTitleWithToggle = withStyles((theme) => ({
  doneButton: {
    color: colors.white,
    margin: '0 -8px',
  },
}))((props: ModalDoneTitleProps & { classes: { doneButton: string } }) => (
  <ImportTitleWithToggle
    leftComponent={
      props.onCancel ? (
        <Button className={props.classes.doneButton} onClick={props.onCancel}>
          <> {magicText.t('Cancel')}</>
        </Button>
      ) : undefined
    }
    title={props.title}
    onToggle={props.onToggle}
    fileIdToView={props.fileIdToView}
    setFileIdToView={props.setFileIdToView}
    rightComponent={
      props.onDone ? (
        <Button className={props.classes.doneButton} onClick={props.onDone}>
          Exit
        </Button>
      ) : undefined
    }
  />
));

export default ImportDoneCancelTitleWithToggle;
