import React from 'react';
import { FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import magicText from 'i18next';

export interface OnOffSectionProps {
  onIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  title: string;
  onToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isOn: boolean;
  tooltip?: string;
  disable?: boolean;
}

export const onOffSection = (props: OnOffSectionProps): DrawerItem => ({
  icon: props.onIcon && props.offIcon && (props.isOn ? props.onIcon : props.offIcon),
  title: props.title,
  content: (
    <React.Fragment>
      <Tooltip title={props.tooltip || ''} placement="top-start">
        <FormControlLabel
          control={<Switch checked={props.isOn} onChange={props.onToggle} color="primary" disabled={props.disable} />}
          style={{ textTransform: 'capitalize' }}
          label={<>{props.isOn ? magicText.t('Common.on') : magicText.t('Common.off')}</>}
        />
      </Tooltip>
    </React.Fragment>
  ),
  iconClickHandler: props.onToggle,
});
