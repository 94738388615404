import React, { createContext, FunctionComponent, useState, useCallback, useContext, useMemo } from 'react';
import { noop } from 'lodash';
import useUserPreferences from '../hooks/useUserPreferences';
import { useConfig, AlertDialog } from '@terragotech/gen5-shared-components';
import magicText from 'i18next';

const PREFERENCE_KEY = 'selectedProjects';

interface SelectedProjectsType {
  selectedProjects: Array<string>;
  toggleSelectedProject: (projectId: string) => void;
  interacted: boolean;
  setSelectedProjects: (projs: Array<string>) => void;
  setProjectRecordCounts: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}
export const SelectedProjectContext = createContext<SelectedProjectsType>({
  selectedProjects: [],
  toggleSelectedProject: noop,
  interacted: false,
  setSelectedProjects: noop,
  setProjectRecordCounts: noop,
});

export const SelectedProjectProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = props => {
  const { getPreference, setPreference } = useUserPreferences();
  const [selectedProjects, setSelectedProjects] = useState<Array<string>>(
    (getPreference(PREFERENCE_KEY) as Array<string>) || []
  );
  const [hasInteracted, setHasInteracted] = useState(false);
  const uiConfig = useConfig();
  const selectedProjectsMaxRecords = useMemo(() => uiConfig.selectedProjectsMaxRecords, [uiConfig]);
  const [projectRecordCounts, setProjectRecordCounts] = useState<Record<string, number>>({});
  const [showSelectedProjectsAlert, setShowSelectedProjectsAlert] = useState(false);

  const selectedProjectsTotalRecords = useMemo(() => {
    return selectedProjects.reduce((acc, id) => {
      return acc + (projectRecordCounts[id] ?? 0);
    }, 0);
  }, [selectedProjects, projectRecordCounts]);

  const toggleSelectedProject = useCallback(
    (projectId: string) => {
      let projectIndex = selectedProjects.findIndex(proj => proj === projectId);
      if (projectIndex >= 0) {
        setSelectedProjects([...selectedProjects.slice(0, projectIndex), ...selectedProjects.slice(projectIndex + 1)]);
      } else {
        const projectRecords = projectRecordCounts[projectId] ?? 0;
        if (selectedProjects.length > 0 && projectRecords + selectedProjectsTotalRecords > selectedProjectsMaxRecords) {
          setShowSelectedProjectsAlert(true);
        } else {
          setSelectedProjects([...selectedProjects, projectId]);
        }
      }
      if (!hasInteracted) {
        setHasInteracted(true);
      }
    },
    [
      selectedProjects,
      setSelectedProjects,
      setHasInteracted,
      hasInteracted,
      projectRecordCounts,
      selectedProjectsTotalRecords,
      selectedProjectsMaxRecords,
      setShowSelectedProjectsAlert,
    ]
  );

  React.useEffect(() => {
    setPreference(PREFERENCE_KEY, selectedProjects);
  }, [selectedProjects, setPreference]);

  return (
    <SelectedProjectContext.Provider
      value={{
        selectedProjects,
        toggleSelectedProject,
        interacted: hasInteracted,
        setSelectedProjects,
        setProjectRecordCounts,
      }}
    >
      {props.children}
      {showSelectedProjectsAlert && (
        <AlertDialog
          title={magicText.t('selectedProjects.limitReached')}
          onOkPress={() => setShowSelectedProjectsAlert(false)}
        >
          <>
            {magicText.t('selectedProjects.limitReachedInfo', {
              selectedProjectsTotalRecords,
              selectedProjectsMaxRecords,
            })}
          </>
        </AlertDialog>
      )}
    </SelectedProjectContext.Provider>
  );
};
export const useSelectedProject = () => useContext(SelectedProjectContext);
