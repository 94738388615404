import { Button, createStyles, Input, InputAdornment, Theme, withStyles, WithStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import React, { FunctionComponent } from 'react';
import { colors } from '../../../styles/theme';
import magicText from 'i18next';

interface ColumnFilterHeaderProps extends WithStyles<typeof styles> {
  handleDone: () => void;
  handleSelectAll: () => void;
  handleSearchText: (value: string) => void;
  areAllSelected: boolean;
  totalKeyCount: number;
  selectedKeyCount: number;
}

const ColumnFilterHeader: FunctionComponent<ColumnFilterHeaderProps> = (props) => {
  const {
    classes,
    handleDone,
    handleSelectAll,
    areAllSelected,
    handleSearchText,
    totalKeyCount,
    selectedKeyCount,
  } = props;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <>{magicText.t('ColumnFilter.AssetAttributes')}</>
        </div>
        <Button className={classes.button} onClick={handleDone}>
          <> {magicText.t('ColumnFilter.Done')}</>
        </Button>
      </div>
      <div className={classes.selectAll}>
        <Button className={classes.text} onClick={handleSelectAll}>
          <>
            {' '}
            {areAllSelected
              ? magicText.t(`DESELECT ALL (${selectedKeyCount})`)
              : magicText.t(`SELECT ALL (${totalKeyCount})`)}
          </>
        </Button>
      </div>
      <div className={classes.searchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          disableUnderline
          placeholder={magicText.t('ColumnFilter.Search')}
          className={classes.input}
          onChange={(e) => handleSearchText(e.target.value)}
        />
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      height: 46,
      minHeight: 46,
      backgroundColor: theme.palette.secondary.main,
      justifyContent: 'space-between',
      paddingLeft: 12,
    },
    headerText: {
      color: colors.white,
      textTransform: 'none',
      alignSelf: 'center',
      fontSize: 18,
    },
    selectAll: {
      display: 'flex',
      alignItems: 'center',
      height: 46,
      minHeight: 46,
      backgroundColor: theme.palette.grey[50],
      paddingLeft: 4,
    },
    searchBar: {
      backgroundColor: colors.white,
      color: theme.palette.grey[200],
      padding: '3px 0px',
      display: 'inline-flex',
      width: '100%',
      height: 46,
      minHeight: 46,
      borderTop: `1px solid ${colors.grayLine}`,
      borderBottom: `1px solid ${colors.grayLine}`,
    },
    searchIcon: {
      height: 24,
      width: 24,
      paddingLeft: '12px',
      color: theme.palette.grey[400],
    },
    input: {
      width: '100%',
      fontSize: 16,
      fontWeight: 'normal',
    },
    button: {
      color: colors.white,
      '& input': {
        color: colors.white,
      },
    },
    text: {
      color: colors.black,
    },
  });

export default withStyles(styles)(ColumnFilterHeader);
