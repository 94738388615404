import React, { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';
import { TableData } from '../../hooks/useTable';
import FluidStyledTableWithData from '../StyledTable/FluidStyledTableWithDataManagement';
import UserRolesLoading from './UserRolesLoading';
import { Column, ID_COLUMN_KEY } from '../../hooks/tableHooks/useColumns';
import { StyledTableProps } from '../StyledTable/StyledTable';
import gearColumn from '../../utils/gearColumn';
import ThreeDotMenuCellRenderer from '../StyledTable/ThreeDotsMenuCell';
import UsersAndRolesSearchBar from './SearchBar/UsersAndRolesSearchBar';
import { makeStyles, Fab, Checkbox, Slide, Typography } from '@material-ui/core';
import ActionIcon from '../ActionIcon';
import { DIALOG_Z_INDEX } from '../../utils/layers';
import { capitalCase } from 'change-case';
import { colors } from '../../styles/theme';
import { ApolloError } from '@apollo/client';
import { useErrorDialog } from '../../contexts/errorDialogContext';
import { useAccountModal } from '../../contexts/accountModalContext';
import { ReactComponent as RoleAddIcon } from './roles-plus.svg';
import ActionsMenuUI, { ActionsMenuButton } from '../ActionsMenuUI';
import { ArrowDropDown } from '@material-ui/icons';
import magicText from 'i18next';
import { useTheme } from '@material-ui/core';
import { TableDataContext } from '../../hooks/useTableData';
import { EditModeContext } from '../../contexts/editModeContext';
import { isArray } from 'lodash';

const SEARCH_BAR_HEIGHT = 32;

interface UsersAndRolesTableProps<DataType extends TableData> {
  height: number;
  onChange: StyledTableProps<DataType>['onChange'];
  onEditModeOn: (row: DataType) => void;
  data: readonly DataType[];
  columns: readonly Column<DataType>[];
  loading: boolean;
  error: ApolloError | null;
  getActions: (rows: DataType[]) => readonly ActionsMenuButton[];
  fabIconComponent?: React.ReactElement;
  fabIconName?: string;
  onFabClick?: () => void;
  singular: string;
  isEditModeOn?: boolean;
}

const addGearColumn = <DataType extends TableData>(
  getActions: UsersAndRolesTableProps<DataType>['getActions'],
  options: {
    columns: readonly Column<DataType>[];
    isMultiSelectActive: boolean;
    isSelected: (row: DataType) => boolean;
    toggleSelected: (row: DataType) => void;
    selectedRows: DataType[];
    singular: string;
    plural?: string;
    numberOfSelected: number;
    selectAll: boolean;
    setSelectAll: (arg0: boolean) => void;
    setSelectedRows: (argo: any) => void;
    allRowData: readonly DataType[];
    rowToCheck: DataType | null;
    showMulti: boolean;
    primaryColor: string;
    secondaryColor: string;
    setUpdateSelectedRows: (arg0: any) => void;
  }
) => {
  const plural = options.plural || `${options.singular}s`;
  const statusText = `${options.numberOfSelected === 0 ? 1 : options.numberOfSelected} ${capitalCase(
    options.numberOfSelected === 1 ? options.singular : plural
  )} Selected:`;
  const {
    primaryColor,
    secondaryColor,
    isMultiSelectActive,
    selectedRows,
    toggleSelected,
    setUpdateSelectedRows,
  } = options;

  return [
    gearColumn<DataType>(
      // handle how the cell body is rendered
      ({ row }) => {
        const selectAllIsActive = !!selectedRows.some((selected: DataType) => {
          let nameToCompare = selected.username ?? selected.name;
          let rowToCompare = row.username ?? row.name;
          return nameToCompare === rowToCompare;
        });

        return (
          <ThreeDotMenuCellRenderer
            isSelected={selectAllIsActive}
            isMultiSelectActive={isMultiSelectActive}
            toggleSelected={() => toggleSelected(row)}
            actions={!!row.isSingleSignOnUser ? [getActions([row])[0]] : getActions([row])}
            statusText={statusText}
          />
        );
      },
      // handle how the cell header is rendered
      () => {
        if (isMultiSelectActive) {
          const { allRowData, setSelectedRows, selectAll, setSelectAll } = options;
          const indeterminateIsActive =
            selectAll && selectedRows.length !== allRowData.length && selectedRows.length > 0;
          const handleCheckboxClick = () => {
            if (!selectAll && isMultiSelectActive) {
              setUpdateSelectedRows(true);
            } else {
              setSelectAll(false);
              setSelectedRows([]);
            }
          };
          return (
            <Checkbox
              // className={indeterminateIsActive ? classes.primaryColor : classes.primaryColor2}
              checked={!!selectAll && selectedRows.length > 0}
              onClick={handleCheckboxClick}
              indeterminate={indeterminateIsActive}
              style={{ color: indeterminateIsActive ? primaryColor : secondaryColor }}
            />
          );
        }
        return <></>;
      }
    ),
    ...options.columns,
  ];
};

const getRowId = <DataType extends TableData>(row: DataType) => row[ID_COLUMN_KEY] as number;

const UsersAndRolesTable = <DataType extends TableData>(props: UsersAndRolesTableProps<DataType>) => {
  const {
    onChange,
    data,
    columns,
    onEditModeOn,
    loading,
    error,
    height,
    getActions,
    fabIconName,
    onFabClick,
    singular,
    isEditModeOn,
  } = props;

  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState<Record<number, boolean | undefined>>({});
  const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [rowToCheck, setRowToCheck] = useState<DataType | null>(null);
  const [showMulti, setShowMulti] = useState<boolean>(false);
  const isRowSelected = useCallback((row: DataType) => !!selected[getRowId(row)], [selected]);
  const { closeUserAndRoles } = useAccountModal();
  const { setErrorDialogMessage, setErrorDialogTitle, setOnConfirm } = useErrorDialog();
  const theme = useTheme();
  const [updateSelectedRows, setUpdateSelectedRows] = useState(false);
  const { filteredData } = useContext(TableDataContext);
  const { editModeActive, editModeData } = useContext(EditModeContext);

  const editRowComparator = useCallback((row: DataType) => isArray(editModeData) ? editModeData.some(x => x.id === row.id) : row.id === editModeData?.id, [editModeData]);

  useEffect(() => {
    if (updateSelectedRows === true) {
      if (filteredData.length >= 1) {
        setSelectedRows(filteredData as DataType[]);
        setSelectAll(true);
      }
      setUpdateSelectedRows(false);
    }
  }, [updateSelectedRows]);

  const columnsWithGear = useMemo(() => {
    const filterDuplicates = (row: DataType) => Array.from(new Set([...selectedRows, row]));
    return addGearColumn(getActions, {
      columns,
      isMultiSelectActive: checked,
      isSelected: (row: DataType) => isRowSelected(row),
      toggleSelected: (row: DataType) => {
        const noDuplicates = !selectedRows.some((i: DataType) => {
          let nameToCompare = i.username ?? i.name;
          let rowToCompare = row.username ?? row.name;
          return nameToCompare === rowToCompare;
        });
        setSelected((prev) => ({
          ...prev,
          [getRowId(row)]: !isRowSelected(row),
        }));
        if (isRowSelected(row)) {
          setSelectedRows(selectedRows.filter((selectedRow) => getRowId(row) !== getRowId(selectedRow)));
        }
        if (noDuplicates) {
          setSelectedRows(filterDuplicates(row));
        }
        setRowToCheck(row);
      },
      selectedRows,
      singular,
      numberOfSelected: selectedRows.length,
      selectAll,
      setSelectAll,
      setSelectedRows,
      allRowData: data,
      rowToCheck,
      showMulti,
      primaryColor: theme.palette.primary.main,
      secondaryColor: theme.palette.secondary.main,
      setUpdateSelectedRows,
    });
  }, [selectedRows, checked, showMulti, isRowSelected, data]);

  const searchBar = useCallback(
    () => (
      <UsersAndRolesSearchBar
        height={SEARCH_BAR_HEIGHT}
        checked={checked}
        isLoadingData={loading}
        setChecked={setChecked}
        setSelectedRows={setSelectedRows}
        setSelected={setSelected}
      />
    ),
    [loading, checked]
  );

  useEffect(() => {
    if (error || !data) {
      setOnConfirm(() => {
        return () => closeUserAndRoles();
      });
      setErrorDialogMessage(error?.message ?? `Application was not able to fetch any data`);
      setErrorDialogTitle(`Could not load data`);
    }
  }, [error, data, setOnConfirm, setErrorDialogMessage, setErrorDialogTitle, closeUserAndRoles]);

  useEffect(() => {
    const selectionsExist = Object?.values(selected)?.some((i: boolean | undefined) => i === true);
    if (selectAll && selectionsExist) {
      const newData = selectedRows.filter((i: DataType) => {
        let nameToCheck = i.username ?? i.name;
        let rowNameToCheck = rowToCheck?.username ?? rowToCheck?.name;
        return nameToCheck !== rowNameToCheck;
      });
      if (newData && rowToCheck && isRowSelected(rowToCheck)) {
        setSelectedRows(newData);
      }
    }
  }, [rowToCheck]);

  const refForModalAnchor = useRef(null);
  const actions = useMemo(() => getActions(selectedRows), [getActions, selectedRows]);
  const [action1, , action3] = actions;

  if (loading) {
    return <UserRolesLoading />;
  }

  const openMultiActionsModal = () => setShowMulti(!showMulti);
  const areAnyActionsSelected = selectedRows.some((i: DataType) => isRowSelected(i));
  const check = checked && selectAll;
  const statusText = `${selectedRows.length > 1 ? selectedRows.length : 1} 
  ${capitalCase(selectedRows.length > 1 ? `${props.singular}s` : props.singular)} Selected:`;

  return (
    <div className={classes.container}>
      <>
        <Slide direction="right" in={check || areAnyActionsSelected} mountOnEnter unmountOnExit>
          <div ref={refForModalAnchor} className={classes.slide}>
            <Typography style={{ fontWeight: 500, marginRight: 10 }} onClick={openMultiActionsModal}>
              <> {magicText.t('ACTIONS')}</>
            </Typography>
            <ArrowDropDown onClick={openMultiActionsModal} />
          </div>
        </Slide>
        {showMulti && (
          <ActionsMenuUI
            statusText={statusText}
            actions={[action1, action3].filter((i: ActionsMenuButton) => !!i)}
            anchorEl={refForModalAnchor.current}
            onClose={() => setShowMulti(false)}
          />
        )}
      </>
      <FluidStyledTableWithData<DataType>
        data={data}
        columns={columnsWithGear}
        searchBar={searchBar}
        height={height}
        searchBarHeight={SEARCH_BAR_HEIGHT}
        onEditModeOn={onEditModeOn}
        onChange={onChange}
        editRowComparator={editModeActive ? editRowComparator : undefined}
        onCheckCellIsEditable={(_event) => true}
      />
      {fabIconName && (
        <Fab className={classes.fab} color="primary" onClick={onFabClick} disabled={isEditModeOn}>
          {/**Special case for roles add since it's not a font awesome icon */}
          {fabIconName === 'roles-plus' && <RoleAddIcon />}
          {fabIconName.startsWith('fa-') && <ActionIcon name={fabIconName} color={colors.white} />}
        </Fab>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  fab: {
    position: 'absolute',
    bottom: -20,
    right: 20,
    zIndex: DIALOG_Z_INDEX,
  },
  slide: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '33px',
    width: '135px',
    zIndex: 10,
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
}));

export default UsersAndRolesTable;
