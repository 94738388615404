import React, { FunctionComponent, useCallback, useContext, useEffect, useState, createContext, useRef } from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
  Button,
  Input,
  InputAdornment,
  Theme,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useMultiSelect } from 'use-multiselect';
import { FilterContext } from '../../contexts/FilterContext/filterContext';
import magicText from 'i18next';
import { colors } from '../../styles/theme';
import { moveNullToBeginning } from '@terragotech/gen5-shared-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import Row from './FilterRow';
import _ from 'lodash';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    dialogPaper: {
      minHeight: '60vh',
    },
    header: {
      display: 'flex',
      height: 46,
      backgroundColor: theme.palette.secondary.main,
      justifyContent: 'space-between',
      paddingLeft: 12,
    },
    headerText: {
      color: '#fff',
      textTransform: 'none',
      alignSelf: 'center',
      fontSize: 18,
    },
    selectAll: {
      display: 'flex',
      alignItems: 'center',
      height: 46,
      backgroundColor: '#f8f8f8',
      paddingLeft: 4,
    },
    searchBar: {
      backgroundColor: '#fff',
      color: theme.palette.grey[200],
      padding: '3px 0px',
      display: 'inline-flex',
      width: '100%',
      height: 46,
      borderTop: '1px solid #eee',
      borderBottom: '1px solid #eee',
    },
    searchIcon: {
      height: 24,
      width: 24,
      paddingLeft: '12px',
      color: theme.palette.grey[400],
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 16,
      fontWeight: 'normal',
    },
    button: {
      color: colors.white,
      '& input': {
        color: colors.white,
      },
    },
  });
export interface SetFilterDialogProps extends WithStyles<typeof styles> {
  displayName: string;
  field: string;
  onClose: () => void;
  uniqueFieldGetter?: () => any[];
}
export interface SizeMapArray {
  [index: number]: number;
}
export const SizeMapContext = createContext({
  setSize: (index: number, size: number) => {},
});
/**
 * SetFilterDialog -
 *
 * Basically the 'dialog' that the user interacts with when specifically seleting what filters to apply
 *
 * @param displayName
 * @param field
 * @param classes
 * @param onClose
 * @param uniqueFieldGetter
 */
const BLANK_FIELD = '(Blanks)';
const getSafeValue = (ob: object | null | undefined) => {
  if (_.isObject(ob)) {
    return _.get(ob, 'label', '');
  }
  return ob || BLANK_FIELD;
};

const SetFilterDialog: FunctionComponent<SetFilterDialogProps> = (props) => {
  const { displayName, field, classes, onClose, uniqueFieldGetter } = props;
  const theme: { palette: { primary: { main: string } } } = useTheme();
  const [fields] = useState(uniqueFieldGetter ? uniqueFieldGetter() : []);
  const listRef = useRef({}) as React.MutableRefObject<any>;

  const { filterState, setFilterState } = useContext(FilterContext);
  const [allSelected, setAllSelected] = useState(false);

  const getExceptions = useCallback(() => {
    const exceptions: string[] = [];
    if (filterState[field]) {
      filterState[field].forEach((value: any) => exceptions.push(getSafeValue(value)));
    } else {
      fields.forEach((field: any) => exceptions.push(getSafeValue(field)));
    }
    return exceptions;
  }, [filterState, fields]);

  const { isSelected, setSelected, getAllSelectedKeys, selectAll, deSelectAll } = useMultiSelect({
    isMultiSelectActive: true,
    allSelected: false,
    exceptions: getExceptions(),
  });

  const handleDone = () => {
    let values = getAllSelectedKeys(_.map(fields, (f) => getSafeValue(f)));
    if (values.length !== fields.length) {
      setFilterState((prevState: any) => {
        values.forEach((value) => !value && values.push(' '));
        const selections = _.filter(fields, (value) => _.includes(values, getSafeValue(value)));
        return { ...prevState, [field]: selections };
      });
    } else {
      setFilterState((prevState: any) => {
        if (prevState[field]) {
          delete prevState[field];
        }
        return { ...prevState };
      });
    }
    onClose();
  };
  const handleSelectAll = () => {
    if (allSelected) {
      deSelectAll();
    } else {
      selectAll();
    }
  };

  const [shownFields, setShownFields] = React.useState(fields);
  useEffect(() => {
    setShownFields(moveNullToBeginning(fields));
  }, [fields]);

  let selectedFields = getAllSelectedKeys(shownFields);
  useEffect(() => {
    if (getAllSelectedKeys(shownFields.map(f => getSafeValue(f))).length === fields.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedFields, getAllSelectedKeys, shownFields, fields]);

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    // use fields so we search all available, null attributes check for the string '(Blanks)' instead
    let newFields = fields.filter((attr: any) => {
      const filterableAttr = getSafeValue(attr);
      if (!filterableAttr) {
        return BLANK_FIELD.toLowerCase().includes(e.target.value.toLowerCase());
      } else {
        return filterableAttr?.toString().toLowerCase().includes(e.target.value.toLowerCase());
      }
    });
    setShownFields(moveNullToBeginning(newFields));
  };

  const sizeMap: { current: SizeMapArray } = useRef({});

  const setSize = useCallback((index: number, size: number) => {
    listRef.current.resetAfterIndex(0);
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);

  const getSize = useCallback((index: number) => {
    const height: number = sizeMap.current[index] ? sizeMap.current[index] : 0;
    return height;
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerText}>{displayName}</div>
        <Button className={classes.button} onClick={handleDone}>
          <> {magicText.t('Done')}</>
        </Button>
      </div>
      <div className={classes.selectAll}>
        <Button onClick={handleSelectAll}>
          <>{allSelected ? magicText.t('Deselect All') : magicText.t('Select All')}</>
        </Button>
      </div>

      <div className={classes.searchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          placeholder={`${magicText.t('Search')} ${displayName}`}
          className={classes.input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterSearch(e)}
        />
      </div>

      <SizeMapContext.Provider value={{ setSize }}>
        <div style={{ height: '90vh' }}>
          <div style={{ height: '100%', overflowX: 'hidden' }}>
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  ref={listRef}
                  height={height}
                  itemCount={shownFields.length || 0}
                  itemSize={getSize}
                  width={width}
                >
                  {({ index, style }) => (
                    <div style={style} key={index}>
                      <Row
                        index={index}
                        value={getSafeValue(shownFields[index])}
                        style={style}
                        isSelected={isSelected}
                        setSelected={setSelected}
                        theme={theme}
                        classes={classes}
                        field={field}
                      />
                    </div>
                  )}
                </VariableSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </SizeMapContext.Provider>
    </div>
  );
};
export default withStyles(styles)(SetFilterDialog);
