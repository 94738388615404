/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, withStyles } from '@material-ui/core/styles';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Polygon, Properties } from '@turf/helpers';
import { Feature, Position } from 'geojson';
import { History } from 'history';
import * as MapboxGl from 'mapbox-gl';
import { GeolocateControl, MapLayerEventType } from 'mapbox-gl';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AssetCardRenderer from '../components/Map/AssetCard/AssetCardRenderer';
import Map, { MaxZoomClickHandler, MAX_ZOOM } from '../components/Map/component/Map';
import { useAggregates } from '../contexts/AggregatesContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { useAssetCards } from '../contexts/assetCardContext';
import { MapBoundsContext, AggregateDefinition, useConfig, trimTrailingSlash } from '@terragotech/gen5-shared-components';
import { useRecordType } from '../contexts/recordTypeContext';
import { ValueType } from '../hooks/useTable';
import { useFilter } from '../contexts/FilterContext/filterContext';
import useDrawFeature from '../components/Map/component/useDrawFeature';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import distance from '@turf/distance';
import moment from 'moment';
import { mapLabelSelector } from '../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { DATETIME_TOKEN_CONVERSION, getDateFormat, getDateTimeFormat, MAP_SERVICE_TYPE } from '@terragotech/gen5-shared-utilities';
import { arcGisParams, rasterSourceData, wmsParams } from '../utils/mapInfo';

export interface MapOverviewContainerProps {
  height?: unknown;
  width?: number;
  classes: any;
}

/**
 * @remarks Obtains variables for props, returns Map with props
 *
 * @param width
 * @param height
 * @param searchText
 */
const MapOverviewContainer: React.FunctionComponent<MapOverviewContainerProps> = (props) => {
  const { height, width } = props;
  const { selectedRecordType } = useRecordType();
  const mapLabelVisibility = useRecoilValue(mapLabelSelector);


  const match: { params: { assetId?: string } } = useRouteMatch({
    path: `/${selectedRecordType}/:assetId?/:comparison(map|data|edit)?`,
  }) || { params: {} };

  const { setAssetsIds, setCoordinates } = useAssetCards();
  const history: History = useHistory();
  const [mapInstance, setMapInstance] = useState<MapboxGl.Map | null>(null);
  const [mapViewBox, setMapViewBox] = useState<Feature<Polygon, Properties>>();
  const [mapRasterSources, setMapRasterSources] = useState<Record<string, MapboxGl.RasterSource>>();
  const { applyDrawFilter, undoRecentChanges, clearAll, isDrawActive } = useDrawFeature(mapInstance);
  const { setMapCenter, currentZoomLevel } = useContext(MapBoundsContext);
  // Adding custom mapbox map control for current location button, because it is not available in our mapbox-react library
  // https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol.event:trackuserlocationstart
  const onMapLoad: MapEvent = (map) => {
    setMapInstance(map);
    map.addControl(
      new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'top-left'
    );
  };

  const configContext = useConfig();
  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const [zoom] = useState(configContext.initialMapExtents.zoom);
  const lat = parseFloat(configContext.initialMapExtents.lat) || 0;
  const lon = parseFloat(configContext.initialMapExtents.lon) || 0;

  const [center] = useState<[number, number]>([lon, lat]);
  const assetRecord = useAggregates();
  let assetData = assetRecord.filteredAssets;

  const isDate = (name: ValueType, field: string) => {
    aggregateDefinitions?.map((j) => {
      j?.propertyDefinitions?.map((i) => {
        if (i.type === 'DateTime' && field === i.field && name) {
          const dateObj = moment(String(name));
          if (dateObj.isValid()) {
            name = dateObj ? moment(String(dateObj)).format(getDateTimeFormat(
              defaultDateTimeFormat?.dateFormatType,
              defaultDateTimeFormat?.dateFormat,
              defaultDateTimeFormat?.dateSeperator,
              defaultDateTimeFormat?.timeFormat,
              { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS },
            )) : '';
          }
        } else if (i.type === 'Date' && field === i.field && name) {
          const dateObj = moment(String(name));
          if (dateObj.isValid()) {
            name = dateObj ? moment.utc(String(dateObj)).format(getDateFormat(
              defaultDateTimeFormat?.dateFormatType,
              defaultDateTimeFormat?.dateFormat,
              defaultDateTimeFormat?.dateSeperator,
            )) : '';
          }
        }
      });
    });
    return name;
  };

  let getMapLabelDisplay = (asset: MapAssetType, aggregateDefinition: AggregateDefinition | undefined) => {
    let assetMapLabels: Array<{ label: string | undefined; value: ValueType }> = [];
    if (!mapLabelVisibility) {
      return null;
    }
    if (
      !aggregateDefinition ||
      !aggregateDefinition.mapLabelProperties ||
      aggregateDefinition.mapLabelProperties.length < 1
    ) {
      return null;
    }
    (aggregateDefinition?.mapLabelProperties || []).forEach((x) => {
      let val = asset[x.name];
      val = isDate(val, x.name);
      const showemptyMapvalues = !!aggregateDefinition?.showEmptyMapLabels;
      // value is empty and showemptylabel is false, don't add to the list
      if ((showemptyMapvalues && !val) || val)
        assetMapLabels.push({
          label: aggregateDefinition?.showMapLabelTitles
            ? aggregateDefinition?.propertyDefinitions.find((t) => t.field === x.name)?.label
            : undefined,
          value: val ? val : showemptyMapvalues ? '(empty)' : '',
        });
    });
    return assetMapLabels;
  };

  let getShowMeasurementOnMap = (asset: MapAssetType, aggregateDefinition: AggregateDefinition | undefined) => {
    return (asset.primaryLocation.type !== 'Point' && aggregateDefinition?.showMeasurementOnMap) || false;
  };

  const includeLine = function (location: any) {
    if (location?.type === 'LineString') {
      let dist = 0;
      let previousCoord: Position;
      let minLat = -200,
        minLong = -200,
        maxLat = 200,
        maxLong = 200;
      location.coordinates.forEach((coord: Position) => {
        if (previousCoord) {
          const d = distance(previousCoord, coord, { units: 'meters' });
          dist += d;
          minLat = Math.min(coord[0], minLat);
          maxLat = Math.max(coord[0], maxLat);
          minLong = Math.min(coord[1], minLong);
          maxLong = Math.max(coord[1], maxLong);
        } else {
          minLat = coord[0];
          maxLat = coord[0];
          minLong = coord[1];
          maxLong = coord[1];
        }
        previousCoord = coord;
      });

      const minLinePixelLength = 20;
      const minPixelArea = 5;
      //Calculating approximate pixel length by taking length in meters divided by the meters per pixel based on current zoom
      //This is the most accurate to true number of pixels around 40 latitude, so it should be decent for most of the US.
      //It will underestimate the line length severely if the line is drawn close to the north or south pole and slightly overestimate near the equator
      //Documentation about zoom levels of mapbox can be found at https://docs.mapbox.com/help/glossary/zoom-level/
      return (
        // First condition - Calculating the approximate length of the displayed line in pixels
        (dist / (60000 * Math.pow(2, -1 * currentZoomLevel)) > minLinePixelLength &&
          // Second condition - Calculating the approximate span of the line in pixels
          // This will be a limiting factor if the line is very long but in a small, contained area
          distance([minLat, minLong], [maxLat, maxLong], { units: 'meters' }) /
            (60000 * Math.pow(2, -1 * currentZoomLevel)) >
            minPixelArea) ||
        currentZoomLevel >= MAX_ZOOM
      ); //If we are at max zoom, display all lines even if they are tiny.
    }
    return true;
  };

  const inBounds = function (location: any, boundsGeometry?: Feature<Polygon, Properties>) {
    if (!boundsGeometry || location?.type !== 'Point') {
      return true;
    }
    return booleanPointInPolygon(location, boundsGeometry);
  };

  const mapItems = useMemo(() => {
    return assetRecord?.filteredAssets
      .filter(
        (asset: any) =>
          inBounds(asset.primaryLocation, mapViewBox) &&
          includeLine(asset.primaryLocation) &&
          asset.id &&
          asset.primaryLocation?.type &&
          asset.primaryLocation?.coordinates &&
          !aggregateDefinitions.find((d) => d.queryKey === asset.recordTypeKey)?.hiddenFromMapView
      )
      .map((asset) => {
        const aggregateDefinition = aggregateDefinitions.find((d) => d.queryKey === asset.recordTypeKey);
        return {
          location: asset.primaryLocation,
          styleKey: asset.symbolKey,
          id: asset.id,
          selected: asset.id === match.params.assetId,
          aggregateType: asset.recordTypeKey,
          mapLabelProperties: getMapLabelDisplay(asset, aggregateDefinition),
          showMeasurementOnMap: getShowMeasurementOnMap(asset, aggregateDefinition),
        };
      });
  }, [assetRecord?.filteredAssets, mapViewBox]);

  //Various event handlers
  const handleSymbolClick = useCallback(
    (e: MapLayerEventType['click' | 'touchend' | 'touchstart']) => {
      const feature = e.features?.[0];
      if (feature) {
        let recordType = selectedRecordType;
        const assetId = feature.properties?.assetId ?? '';
        if (assetId) {
          const type = assetData.find((x) => x.id === assetId)?.recordTypeKey;
          if (type) {
            recordType = aggregateDefinitions.find((x) => x.queryKey === type)!.name;
          }
        }
        if (history.location.pathname === `/${recordType}/${assetId}`) {
          history.push(`/${recordType}`);
        } else {
          history.push(`/${recordType}/${assetId}`);
        }
      }
    },
    [history, selectedRecordType, assetData, aggregateDefinitions]
  );
  const handleMaxZoomClusterClick = useCallback<MaxZoomClickHandler>((map, feature, coordinate) => {
    if (map && feature && feature.id && feature.geometry.type === 'Point') {
      const clusterId = typeof feature.id === 'string' ? parseFloat(feature.id) : feature.id;
      const source = map.getSource('symbolSource') as MapboxGl.GeoJSONSource;
      source.getClusterLeaves(clusterId, Infinity, 0, (error, features) => {
        if (error) {
          console.error(error);
          return;
        }
        const assetIds = features.map((feature) => (feature.properties ? feature.properties.assetId : ''));
        setAssetsIds(assetIds);
        setCoordinates(coordinate);
      });
    }
  }, []);

  useEffect(() => {
    // For now, handling all layers as individual sources so each layer can be toggled.
    const rasterSources: Record<string, MapboxGl.RasterSource> = {};
    assetRecord.mapServiceLayers.forEach((sl) => {
      let serviceParams: Partial<MapboxGl.RasterSource> = {};
      switch (sl.serviceType) {
        case MAP_SERVICE_TYPE.WMS:
          serviceParams = {
            // Use the tiles option to specify a WMS tile source URL. https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
            tiles: [
              `${sl.serviceUri}?bbox=${wmsParams.bbox}&${new URLSearchParams({
                ...Object.fromEntries(Object.entries(wmsParams).filter(([k,v]) => k !== 'bbox' && v != null)) as Record<string, string>,
                layers: sl.layer.layerName,  // sl.layers.map(x => x.id).join(','), // Reimplement when multiple layer names (within each toggle) are supported by the aggregate.
                //styles: sl.layers.map(x => x.style || '').join(','), // Reimplement when styles are added supported by the aggregate.
              }).toString()}`,
            ],
          };
          break;
        case MAP_SERVICE_TYPE.ArcGIS:
          serviceParams = {
            tiles: [
              `${trimTrailingSlash(sl.serviceUri)}/export?bbox=${arcGisParams.bbox}&${new URLSearchParams({
                ...Object.fromEntries(Object.entries(arcGisParams).filter(([k,v]) => k !== 'bbox' && v != null)) as Record<string, string>,
                layers: `show:${sl.layer.layerName}`,  // `show:${s.layers.map(x => x.id).join(',')}`, // Reimplement when multiple layer names (within each toggle) are supported by the aggregate.
                //styles: s.layers.map(x => x.style || '').join(','), // Reimplement when styles are added supported by the aggregate.
              }).toString()}`,
            ],
          };
          break;
        default:
          console.error(`Map Service Layer ${sl.id} has an invalid service type: ${sl.serviceType}`);
          break;
      }

      if (Object.keys(serviceParams).length) {
        rasterSources[sl.id] = { ...rasterSourceData, ...serviceParams };
      }
    });
    setMapRasterSources(rasterSources);
  }, [assetRecord.mapServiceLayers, setMapRasterSources]);

  return (
    <>
      <Map
        onMaxZoomClusterClick={handleMaxZoomClusterClick}
        height={height}
        width={width}
        desiredZoom={zoom}
        desiredCenter={center}
        items={mapItems}
        onStyleLoad={onMapLoad}
        onSymbolClick={handleSymbolClick}
        showMapControls={true}
        showRightControls={true}
        showMapLabels={mapLabelVisibility}
        setMapViewBox={setMapViewBox}
        mapViewBox={mapViewBox}
        setMapCenter={setMapCenter}
        mapRasterSources={mapRasterSources}
        mapVisibleMapServiceKeys={assetRecord.visibleMapServiceKeys}
      />
      <AssetCardRenderer selectedAssetId={match.params.assetId} mapInstance={mapInstance} items={assetRecord.filteredAssets} />
      <DrawFilterActions onApply={applyDrawFilter} onCancel={undoRecentChanges} onClear={clearAll} isDrawActive={isDrawActive} />
    </>
  );
};

interface DrawActionProps {
  onClear: () => void;
  onCancel: () => void;
  onApply: () => void;
  isDrawActive: boolean;
}
const DrawFilterActions: React.FunctionComponent<DrawActionProps> = (props) => {
  const { drawtoolFilter, drawFilter } = useFilter();
  const { onClear, onCancel, isDrawActive } = props;
  useEffect(() => {
    if (!drawtoolFilter) {
      onCancel();
    }
  }, [drawtoolFilter]);

  if (drawFilter.enabled) {
    return (
      <Card
        style={{
          position: 'absolute',
          zIndex: 999,
          opacity: 0.95,
          bottom: 20,
          padding: 0,
          marginLeft: '30%',
          width: 450,
        }}
      >
        <CardContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 0,
            padding: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Typography style={{ width: 350 }}>
            {isDrawActive ? 'Double-click to complete the drawn shape.' : 'Draw Shapes on the map to filter your data.'}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={onCancel} style={{ height: 40 }} disabled={isDrawActive}>
              CANCEL
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
  if (drawFilter.apply && drawFilter.features.length) {
    return (
      <Button
        variant="contained"
        onClick={onClear}
        style={{
          position: 'absolute',
          zIndex: 999,
          bottom: 33,
          right: 340,
        }}
      >
        CLEAR DRAWING
      </Button>
    );
  }
  return null;
};
const styles = () =>
  createStyles({
    popupWrapper: {
      '& .mapboxgl-popup-content': {
        backgroundColor: '#333333',
        padding: '5px',
      },
      '& .mapboxgl-popup-tip': {
        borderTopColor: '#333333',
      },
      '&:hover': {
        zIndex: `99999 !important`,
      },
    },
    popupItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#fff',
    },
    popupItemValue: {
      fontSize: '13px',
    },
    emptyValue: {
      color: '#A8A8A8',
      fontSize: '13px',
    },
  });
export default withStyles(styles)(MapOverviewContainer);
