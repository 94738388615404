import React, { useState, useMemo, useCallback } from 'react';
import { TableData, ValueType } from './useTable';

type FiltersType = Record<string, string | string[]>;

const TableDataContext = React.createContext({
  setData: (data: readonly TableData[]) => {},
  setFilter: (key: string | null, value: string | string[]) => {},
  filteredData: [] as readonly TableData[],
  data: [] as readonly TableData[],
  filters: {} as Record<string, string | string[]>,
  globalFilter: '' as string | string[],
});

const filterDataByKey = (key: string, value: string | string[], data: readonly TableData[]) => {
  if (Array.isArray(value)) {
    return data.filter((row) =>
      value.reduce<boolean>((result, pattern) => result || row[key].toString() === pattern || (
        Array.isArray(row[key])
          ? (row[key] as ValueType[]).some(x => x.toString() === pattern)
        : (key === 'adminPermissions')  // Special case to avoid pattern search.
          ? row[key].toString().split(', ').some(x => x === pattern)
        : false
      ), false)
    );
  } else {
    const pattern = value.toLowerCase();
    return data.filter((row) => row[key].toString().toLowerCase().includes(pattern));
  }
};

const filterUsingGlobalFilter = (data: readonly TableData[], globalFilter: string) =>
  globalFilter === ''
    ? data
    : data.filter((row) =>
        Object.values(row).reduce<boolean>(
          (result, value) => result || value.toString().toLowerCase().includes(globalFilter.toLowerCase()),
          false
        )
      );

const filterData = (data: readonly TableData[], globalFilter: string, filters: FiltersType) =>
  Object.entries(filters).reduce<readonly TableData[]>(
    (result, filterRecord) => filterDataByKey(filterRecord[0], filterRecord[1], result),
    filterUsingGlobalFilter(data, globalFilter)
  );

const TableDataProvider = (props: { children: React.ReactNode }) => {
  const [filters, setFilters] = useState({} as FiltersType);
  const [globalFilter, setGlobalFilter] = useState('' as string);
  const [data, setData] = useState([] as readonly TableData[]);

  const setFilter = useCallback(
    (key: string | null, value: string | string[]) => {
      if (key === null) {
        if (Array.isArray(value)) {
          throw new Error('Global filter must be string');
        }
        setGlobalFilter(value);
      } else {
        setFilters((prevFilters) => {
          if (value.length === 0) {
            if (prevFilters[key]) {
              delete prevFilters[key];
            }
          } else {
            prevFilters[key] = value;
          }
          return { ...prevFilters };
        });
      }
    },
    [setFilters, setGlobalFilter]
  );

  const filteredData = useMemo(() => filterData(data, globalFilter, filters), [data, filters, globalFilter]);

  const value = useMemo(() => ({ setFilter, setData, filteredData, data, filters, globalFilter }), [
    setFilter,
    filteredData,
    data,
    filters,
    globalFilter,
  ]);

  return (
    <TableDataContext.Provider value={value} {...props}>
      {props.children}
    </TableDataContext.Provider>
  );
};

export { TableDataContext, TableDataProvider };
