import React, { useState, useCallback, useEffect, useContext } from 'react';
import { initFormRootRenderer, OnSubmit } from '@terragotech/form-renderer';
import ControlProvider from './ControlProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import _ from 'lodash';
import {
  CommandAction,
  useConfig,
  AlertDialog,
  AlertDialogProps,
  FieldHeader,
  FIELD_HEADER_HEIGHT,
  InfoBarCollection,
  InfoBarTypeEnum,
  InfoBar,
  getErrorsAndWarnings,
} from '@terragotech/gen5-shared-components';
import { v4 } from 'uuid';
import { DialogContentText, DialogContent } from '@material-ui/core';
import useSubmitCommands, { CommandType } from '../../hooks/useSubmitCommand';
import { useWorkflowDataMapping } from '../../hooks/useWorkflowDataMapping';
import { AssetType } from '../../contexts/AggregatesContext/types';
import { useFileUpload } from '../../hooks/useFileUpload';
import { FormParentValueContext } from '../../contexts/formParentValueContext';
import { useSelectedLocation } from '../../contexts/selectedLocationContext';

const FormRootRendererInitiated = initFormRootRenderer(ControlProvider);

interface WorkflowPageProps {
  title: string;
  openWorkflow: boolean;
  target?: AssetType | AssetType[];
  onCancelPress: () => void;
  onDonePress: () => void;
  command: CommandAction;
  aggregateType: string;
  creationAction?: boolean;
}

const WorkflowPage: React.FC<WorkflowPageProps> = props => {
  const {
    openWorkflow,
    target,
    onCancelPress,
    command: commandAction,
    onDonePress,
    aggregateType,
    title,
    creationAction,
  } = props;
  const { handleFormParentValueChange } = useContext(FormParentValueContext);
  const { submitCommands } = useSubmitCommands();
  const { functionDefinitions } = useConfig();
  const dataMapping = useWorkflowDataMapping(target);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [alertDialog, setAlertDialog] = useState<AlertDialogProps | null>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const styles = useStyles();
  const { uploadFile } = useFileUpload();
  const { setSelectedLocation } = useSelectedLocation();

  const handleFormSubmission = useCallback<OnSubmit>(
    args => {
      const caller: OnSubmit = async ({ data, files }) => {
        if (!submitted && !submitting) {
          try {
            setSubmitting(true);
            const commands: CommandType[] = [];
            //If we have any files at all, let's just upload them all
            if (files && files.length > 0) {
              await Promise.all(files.map(file => uploadFile(file)));
            }

            if (creationAction) {
              commands.push({
                target: v4(),
                aggregateType: aggregateType,
                commandName: commandAction.commandName,
                version: _.toNumber(commandAction.commandVersion),
                commandData: data,
                isSynchronous: commandAction.isSynchronous,
                hasNoAggregateIdCommand: commandAction.hasNoAggregateIdCommand,
              });
            } else {
              const targetArray = Array.isArray(target) ? target : [target || { id: v4() }];
              commands.unshift(
                ...targetArray.map(tgt => {
                  return {
                    target: tgt.id,
                    aggregateType: aggregateType,
                    commandName: commandAction.commandName,
                    version: _.toNumber(commandAction.commandVersion),
                    commandData: data,
                    isSynchronous: commandAction.isSynchronous,
                    hasNoAggregateIdCommand: commandAction.hasNoAggregateIdCommand,
                  };
                })
              );
            }
            //if target is undefined, then we are likely in a creation command
            // create a command for each target
            await submitCommands(commands);
            setSubmitted(true);
          } catch (e) {
            console.error(e);
          } finally {
            setSubmitting(false);
            // Fixes component unmounted error
            setSelectedLocation(undefined);
            setTimeout(() => onDonePress());
          }
        }
      };
      caller(args);
    },
    [
      aggregateType,
      commandAction.commandName,
      commandAction.commandVersion,
      commandAction.isSynchronous,
      commandAction.hasNoAggregateIdCommand,
      creationAction,
      onDonePress,
      submitCommands,
      target,
      uploadFile,
    ]
  );
  const setErrorAlert = (title: string) => {
    setAlertDialog({
      title: title,
      cancelText: 'Cancel',
    });
  };
  const setWarningAlert = () => {
    setAlertDialog({
      title: 'Warning!',
      cancelText: 'Cancel',
      okText: 'Continue anyway',
    });
  };
  const getBars = (errors: any, warnings: any, fieldWarnings: any) => {
    const bars: InfoBar[] = [];
    if (!_.isEmpty(errors)) {
      Object.keys(errors).map(key => bars.push({ title: 'Error!', message: errors[key], type: InfoBarTypeEnum.ERROR }));
    }
    if (!_.isEmpty(warnings)) {
      Object.keys(warnings).map(key =>
        bars.push({ title: 'Form Level Warning!', message: warnings[key], type: InfoBarTypeEnum.WARNING })
      );
    }
    return bars;
  };
  interface ErrorsAndWarnings {
    formErrors: string[];
    fieldErrors: string[];
    formWarnings: string[];
    fieldWarnings: string[];
  }
  const renderContent = (errorsAndWarnings: ErrorsAndWarnings) => {
    const { formErrors, fieldErrors, formWarnings, fieldWarnings } = errorsAndWarnings;

    // Build a list of errors & warnings to show to the user if there's a problem when they click "done"
    let alertMessages: string[] = [];
    const formHasErrors = !!formErrors.length || !!fieldErrors.length;

    // if we have errors then show those errors
    if (formHasErrors) {
      alertMessages = [...formErrors, ...fieldErrors];
    }

    // if we have no errors but we have warnings then show those warnings
    if (!formHasErrors) {
      alertMessages = [...formWarnings, ...fieldWarnings];
    }

    if (!alertDialog) {
      return null;
    }

    return (
      <DialogContent style={{ marginBottom: 4 }}>
        {[...alertMessages].map((value, index) => (
          <DialogContentText className={styles.alertText} key={index}>
            - {value}
          </DialogContentText>
        ))}
      </DialogContent>
    );
  };

  useEffect(() => {
    if (target) {
      const temp: any = target;
      handleFormParentValueChange(temp?.geom);
    }
    return function cleanup() {
      handleFormParentValueChange(null);
    };
  }, []);

  return (
    <Dialog open={openWorkflow} maxWidth={'xl'} classes={{ paper: styles.dialogPaper }}>
      {commandAction.command && (
        <FormRootRendererInitiated
          onSubmit={handleFormSubmission}
          dataMappingContext={dataMapping}
          template={commandAction.command.template}
          functionDefinitions={functionDefinitions ?? {}}
          render={({ handleSubmit, renderForm, formChanged, errors, warnings, fieldLevelWarnings }) => {
            const { formErrors, fieldErrors, formWarnings, fieldWarnings } = getErrorsAndWarnings(
              errors,
              warnings,
              fieldLevelWarnings
            );

            return (
              <FormRendererWrapper
                submitting={submitting}
                title={title}
                onDonePress={() => {
                  if (formErrors.length > 0 || fieldErrors.length > 0) {
                    handleSubmit();
                    return setErrorAlert('Error');
                  } else if (formWarnings.length > 0 || fieldWarnings.length > 0) {
                    return setWarningAlert();
                  } else if (commandAction.confirmationText) {
                    return setConfirmationDialogOpen(true);
                  }
                  handleSubmit();
                }}
                onCancelPress={()=>{setSelectedLocation(undefined); onCancelPress();}}
                canSave={true}
                bars={getBars(formErrors, formWarnings, fieldWarnings)}
              >
                {renderForm()}
                {alertDialog && (
                  <AlertDialog
                    title={alertDialog.title}
                    okText={alertDialog.okText}
                    cancelText={alertDialog.cancelText}
                    onOkPress={
                      _.isEmpty(errors)
                        ? () => {
                            if (commandAction.confirmationText) {
                              return setConfirmationDialogOpen(true);
                            } else {
                              return handleSubmit();
                            }
                          }
                        : undefined
                    }
                    onCancelPress={() => {
                      setAlertDialog(null);
                    }}
                  >
                    {renderContent({
                      formErrors,
                      fieldErrors,
                      formWarnings,
                      fieldWarnings,
                    })}
                  </AlertDialog>
                )}
                {confirmationDialogOpen && (
                  <AlertDialog
                    title={'Are you sure?'}
                    okText={'Continue'}
                    cancelText={'Cancel'}
                    onOkPress={handleSubmit}
                    onCancelPress={() => setConfirmationDialogOpen(false)}
                  >
                    {commandAction.confirmationText}
                  </AlertDialog>
                )}
              </FormRendererWrapper>
            );
          }}
        />
      )}
    </Dialog>
  );
};

interface FomrRendererWrapperProps {
  title: string;
  children: React.ReactNode;
  onDonePress: () => void;
  onCancelPress?: () => void;
  canSave?: boolean;
  submitting: boolean;
  bars: InfoBar[];
}
export const FormRendererWrapper: React.FC<FomrRendererWrapperProps> = ({
  title,
  children,
  canSave,
  onDonePress,
  onCancelPress,
  submitting,
  bars,
}) => {
  const wrapperStyles = useStyles();
  return (
    <div className={wrapperStyles.wrapperContainer}>
      <FieldHeader
        title={title}
        canSave={canSave}
        submitting={submitting}
        onDonePress={onDonePress}
        onCancelPress={onCancelPress}
      />
      <InfoBarCollection bars={bars} fullwidth={false} />
      <div className={wrapperStyles.content}>{children}</div>
    </div>
  );
};

export const useStyles = makeStyles(theme => ({
  wrapperContainer: {
    height: '100%',
  },
  content: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100% - ${FIELD_HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  dialogPaper: {
    top: '2vh',
    height: '96vh',
    maxHeight: '100%',
    width: '650px',
    maxWidth: '650px',
    '@media (max-width: 650px)': {
      width: '100%',
      margin: 0,
    },
  },
  alertText: {
    marginBottom: 4,
  },
}));

export default WorkflowPage;
