export const isLatitudeLongitudeValid = (latitude?: number, longitude?: number): boolean => {
  if (!latitude || !longitude || latitude > 90 || latitude < -90 || longitude > 180 || longitude < -180) {
    return false;
  }
  return true;
};

export const midPointFinder =(coords1:[number,number],coords2:[number,number]):[number,number]=>{
  return [(coords1[0]+coords2[0])/2,(coords1[1]+coords2[1])/2]
}
export function getLighterColorCode(color: string, opacity: number) {
  return '#' + color.replace(/^#/, '').replace(/../g, (color: any) => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + opacity)).toString(16)).substr(-2));
}

