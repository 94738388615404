import * as React from 'react';

const SearchIcon: React.FunctionComponent<any> = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82666 10.9229L10.9304 9.81909L15.6214 14.5101L14.5177 15.6139L9.82666 10.9229Z"
        fill="#616161"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2439 12.4878C9.69231 12.4878 12.4878 9.69231 12.4878 6.2439C12.4878 2.79549 9.69231 0 6.2439 0C2.79549 0 0 2.79549 0 6.2439C0 9.69231 2.79549 12.4878 6.2439 12.4878Z"
        fill="#616161"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1045 12.2305L12.2083 11.1267L15.6023 14.5208L14.4986 15.6245L11.1045 12.2305Z"
        fill="#37474F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24407 11.3172C9.0459 11.3172 11.3172 9.0459 11.3172 6.24407C11.3172 3.44223 9.0459 1.1709 6.24407 1.1709C3.44223 1.1709 1.1709 3.44223 1.1709 6.24407C1.1709 9.0459 3.44223 11.3172 6.24407 11.3172Z"
        fill="#64B5F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9364 3.98071C8.27298 3.20023 7.29737 2.73193 6.24371 2.73193C5.19006 2.73193 4.21445 3.20023 3.55103 3.98071C3.39493 4.13681 3.43396 4.40998 3.59006 4.52706C3.74615 4.68315 4.01932 4.64413 4.1364 4.48803C4.68274 3.86364 5.4242 3.51242 6.24371 3.51242C7.06323 3.51242 7.80469 3.86364 8.35103 4.48803C8.42908 4.56608 8.54615 4.64413 8.66323 4.64413C8.74128 4.64413 8.85835 4.6051 8.89737 4.56608C9.05347 4.40998 9.05347 4.13681 8.9364 3.98071Z"
        fill="#BBDEFB"
      />
    </svg>
  );
};
export default SearchIcon;
