/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent } from 'react';
import SlideDialog from '../SlideDialog';
import SetFilterDialog from './SetFilterDialog';

export interface SetFilterProps {
  open: boolean;
  setFilterOpen: (value: boolean) => void;
  uniqueFieldGetter?: () => any[];
  displayName: string;
  field: string;
}
/**
 * SetFilter - appears at the top of each column for every asset that is not a Date
 *
 * All columns have either SetFilter or CalendarFilter
 *
 * Finds all unique values and gives option to filter by any of them, such as: Status: "Complete", etc.
 *
 * @param open
 * @param setFilterOpen
 * @param classes
 * @param field
 * @param displayName
 * @param uniqueFieldGetter
 */
const SetFilter: FunctionComponent<SetFilterProps> = (props) => {
  const { open, setFilterOpen, field, displayName, uniqueFieldGetter } = props;

  const handleClose = () => {
    setFilterOpen(false);
  };

  return (
    <SlideDialog open={open} onClose={handleClose}>
      <SetFilterDialog
        displayName={displayName}
        field={field}
        onClose={handleClose}
        uniqueFieldGetter={uniqueFieldGetter}
      ></SetFilterDialog>
    </SlideDialog>
  );
};

export default SetFilter;
