import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    position: 'fixed',
  },
}));

const StyledTableEmpty = () => {
  const classes = useStyles();
  return <div className={classes.root}>No data to be shown</div>;
};

export default StyledTableEmpty;
