import { createStyles, Slide, Theme, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import 'react-splitter-layout/lib/index.css';
import './TopSplitter.css';
import AccountModalContainer from '../containers/AccountModalContainer';
import { colors } from '../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallSettingsSection: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: colors.white,
      height: '35px',
      width: '100%',
    },
  })
);

export interface SettingsHeaderProps {
  width?: number;
  height?: number;
}

const SettingsHeader: FunctionComponent<SettingsHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.smallSettingsSection}>
      <Slide direction="left" in={true}>
        <AccountModalContainer />
      </Slide>
    </div>
  );
};

export default SettingsHeader;
