import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isCoordinatesValid } from '@terragotech/gen5-shared-utilities';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ActionIcon from '../../ActionIcon';
import { BaseLocationMapProps } from './CommonEditorUtils';
import TGCoordinateTextField from './TGCoordinateTextField';

interface TGMultipleLocationSidebarProps extends BaseLocationMapProps {
  value: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null | undefined;
  capturedLocation: GeoJSON.Point | GeoJSON.Point[] | null | undefined;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | GeoJSON.Point[] | null | undefined>>;
  setManualMode: Dispatch<SetStateAction<boolean>>;
  maximum: number | undefined;
  unlimited: boolean | undefined;
  selectedPoint:
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined;
  setSelectedPoint: React.Dispatch<
    React.SetStateAction<
      | {
          coordinates: number[];
          indexOfCoords: number;
        }
      | undefined
    >
  >;
  selectedPointRef: React.MutableRefObject<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >;
  setOpenAddLocationAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0em',
    color: '#777777',

    '&.disabled': {
      color: '#D7D7D7',
    },
  },
  addLocationIcon: {
    '&.disabled': {
      filter: 'sepia(0%) saturate(5424%) hue-rotate(322deg) brightness(136%)',
    },
  },
}));

const TGMultipleLocationSidebar = (props: TGMultipleLocationSidebarProps) => {
  const {
    maximum,
    unlimited,
    capturedLocation,
    setCapturedLocation,
    setIsValidLocation,
    selectedPoint,
    setSelectedPoint,
    selectedPointRef,
    setOpenAddLocationAlert,
  } = props;
  const classes = useStyles();
  const [blankField, setBlankField] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (Array.isArray(capturedLocation)) {
      let invalidLocation = capturedLocation.some(
        (location: GeoJSON.Point) =>
          !location || !isCoordinatesValid(location.coordinates[1], location.coordinates[0]) || location.type !== 'Point'
      );
      if (invalidLocation) {
        setIsValidLocation(false);
      } else {
        setIsValidLocation(capturedLocation.length > 0);
      }
    }
  }, [capturedLocation, blankField]);

  const canCaptureLocation = useMemo(() => {
    // Location must be array or null or undefined
    return (capturedLocation && Array.isArray(capturedLocation)) || !capturedLocation;
  }, [capturedLocation]);

  const canAddLocation = useMemo(() => {
    // If maximum exists, and capturedLocation is not null or undefined, the length must be less than the maximum. Unless unlimited is true
    const maxCheck =
      (!!maximum && Array.isArray(capturedLocation) && (capturedLocation || []).length < maximum) ||
      !capturedLocation ||
      unlimited;
    return canCaptureLocation && maxCheck;
  }, [canCaptureLocation, capturedLocation, maximum, unlimited]);

  useEffect(() => {
    if (!canAddLocation) {
      setBlankField(false);
    }
  }, [blankField, canAddLocation]);

  const calculatedHeight = useMemo(() => {
    if (capturedLocation && Array.isArray(capturedLocation)) {
      return capturedLocation.length * 48;
    } else {
      return 0;
    }
  }, [capturedLocation]);

  return (
    <div style={{ maxHeight: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingTop: '26px',
          paddingBottom: '17px',
        }}
      >
        <Typography className={classes.text} style={{ paddingLeft: '26px' }}>
          Coordinates
        </Typography>
        <Typography className={classes.text} style={{ paddingRight: '26px' }}>
          {Array.isArray(capturedLocation) ? capturedLocation.length : 0}/{unlimited ? '∞' : maximum}
        </Typography>
      </div>
      <div style={{ borderTop: '1px solid #DADADA' }}></div>
      <div style={{ width: '100%', paddingTop: 4 }}>
        {
          <DragDropContext
            onDragStart={() => {
              setIsDragging(true);
            }}
            onDragEnd={(result, provided) => {
              setIsDragging(false);
              if (!result.destination) {
                return;
              }
              let temp;
              if (!Array.isArray(capturedLocation) && capturedLocation) {
                temp = [capturedLocation];
              } else {
                temp = capturedLocation;
              }
              if (!temp) return;
              const newItems = [...temp];
              const [removed] = newItems.splice(result.source.index, 1);
              newItems.splice(result.destination.index, 0, removed);
              setCapturedLocation(newItems);
            }}
          >
            <Droppable droppableId="uniqueDroppableId">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: calculatedHeight }}>
                  {capturedLocation &&
                    Array.isArray(capturedLocation) &&
                    (capturedLocation || []).map((x: GeoJSON.Point, index: number) => {
                      return (
                        <Draggable index={index} draggableId={String(index)} key={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <TGCoordinateTextField
                                index={index}
                                capturedLocation={capturedLocation}
                                setCapturedLocation={setCapturedLocation}
                                specificLocation={x}
                                setBlankField={setBlankField}
                                selectedPoint={selectedPoint}
                                setSelectedPoint={setSelectedPoint}
                                selectedPointRef={selectedPointRef}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
        {blankField && canAddLocation && (
          <TGCoordinateTextField
            index={Array.isArray(capturedLocation) ? capturedLocation.length : 0}
            capturedLocation={capturedLocation}
            setCapturedLocation={setCapturedLocation}
            specificLocation={undefined}
            blankField={blankField}
            setBlankField={setBlankField}
            setSelectedPoint={setSelectedPoint}
            selectedPointRef={selectedPointRef}
          />
        )}
        {isDragging === false && (
          <div style={{ marginLeft: 35, marginTop: 10 }}>
            <Button
              className={classes.text}
              color="default"
              aria-label="Add Location"
              disabled={blankField || !canCaptureLocation}
              onClick={() => {
                if (canAddLocation) {
                  setBlankField(true);
                } else {
                  setOpenAddLocationAlert(true);
                }
              }}
            >
              <ActionIcon name="fa-circle-plus" />{' '}
              <span style={{ paddingLeft: 5, textTransform: 'none' }}>Add Location</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TGMultipleLocationSidebar;
