import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { colors } from '../../styles/theme';
import magicText from 'i18next';
import { useActionsExecutor } from '../../hooks/useActionsExecutor';
import WorkflowCommandButton from './WorkflowCommandButton';
import { useAggregates } from '../../contexts/AggregatesContext';
import { useConditionalEditWorkflows } from '../../hooks/useConditionalEditWorkflows';

interface Props {
  aggregateType: string;
  aggregateId: string;
}
const WorkflowCommands: FunctionComponent<Props> = (props) => {
  const { aggregateId, aggregateType } = props;
  const classes = useStyles();
  const { assets } = useAggregates();
  const { processAction } = useActionsExecutor();

  const asset = assets.find((asset) => asset.id === aggregateId);
  const visibleWorkflows = useConditionalEditWorkflows(asset);

  if (!asset) {
    return (
      <div className={classes.root}>
        <Typography className={classes.title}>
          <>{magicText.t('Simple.Workflows')}</>
        </Typography>
        <div className={classes.commandContainer}>
          <Typography className={classes.unavailable}>None available</Typography>
        </div>
      </div>
    );
  }

  const actionsButtons = visibleWorkflows.map((workflow) => {
    const action = processAction(workflow, [asset], aggregateId, undefined, undefined, aggregateType);
    if (action) {
      return {
        ...workflow,
        ...action,
      };
    }
    return null;
  });

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        <>{magicText.t('Simple.Workflows')}</>
      </Typography>
      <div className={classes.commandContainer}>
        {actionsButtons.length ? (
          actionsButtons.map((workflow) => {
            return (
              workflow && (
                <WorkflowCommandButton
                  key={workflow.label}
                  label={workflow.label}
                  disabled={workflow.state !== 'enabled'}
                  disabledMessage={workflow.disabledMessage as string | undefined}
                  onClick={workflow.onClick}
                />
              )
            );
          })
        ) : (
          <Typography className={classes.unavailable}>None available</Typography>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 15,
      marginBottom: 30,
    },
    title: {
      fontWeight: 500,
      color: colors.black,
    },
    commandContainer: {
      marginTop: 10,
      flexDirection: 'column',
    },
    unavailable: {
      color: '#c7c7c7',
      fontSize: 14,
    },
  })
);

export default WorkflowCommands;
