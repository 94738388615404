import React, { useCallback, useContext } from 'react';
import { FormDataMappingContextType } from '@terragotech/form-renderer';
import {
  useAuthContext,
  useCurrentLocation,
  useUserInfo,
  useOnlineDataLookup,
  useOnlineProximityLookup,
  useOnlineUserLookup,
} from '@terragotech/gen5-shared-components';
import { AssetType } from '../contexts/AggregatesContext/types';
import { NetworkStatusContext } from '../contexts/networkStatusContext';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import { useSelectedLocation } from '../contexts/selectedLocationContext';

/**
 * This hook provides the metadata needed for form validation and conditionals
 * @param target The asset currently being edited
 */
//TODO: This isn't really the right context type to use for this. We should probably create a local one
export const useDataMapping = (): ((target?: AssetType) => FormDataMappingContextType) => {
  const [timeOpened] = React.useState(new Date()); // we collect this data for analytics
  const bestLocation = useCurrentLocation(); // best location is used to determine proximity
  const authContextInfo = useAuthContext();
  const { isOnline } = useContext(NetworkStatusContext);
  const { roles, roleString, permissionString, permissions, username, email, firstName, lastName } = useUserInfo();
  const onlineDataLookup = useOnlineDataLookup();
  const onlineProximityLookup = useOnlineProximityLookup();
  const onlineUserLookup = useOnlineUserLookup();
  const { selectedProjects } = useSelectedProject();
  const { selectedLocation } = useSelectedLocation();

  return useCallback<(target?: AssetType) => FormDataMappingContextType>(
    target => ({
      accessors: {
        ONLINE_STATUS: () => ({ networkAvailable: true, serverResponding: true }), // We don't yet support offline mode for web
        ONLINE_ATTRIB_LOOKUP: onlineDataLookup,
        METADATA: () => {
          return {
            isOnline: isOnline,
            timeFormOpened: timeOpened,
            latitude: bestLocation?.latitude,
            longitude: bestLocation?.longitude,
            selectedLatitude: selectedLocation?.lat,
            selectedLongitude: selectedLocation?.lon,
            locationAccuracy: bestLocation?.accuracy,
            userInfo: {
              userName: username,
              email: email,
              familyName: lastName,
              givenName: firstName,
              roles,
              roleString,
              authPayload: authContextInfo,
              authExpiration: authContextInfo && authContextInfo.authExpiration,
              permissionString,
              permissions,
            },
            selectedProjects,
          };
        },
        STATE: () => target || {},
        ONLINE_PROXIMITY_LOOKUP: onlineProximityLookup,
        ONLINE_USER_LOOKUP: onlineUserLookup,
      },
    }),
    [
      onlineDataLookup,
      onlineProximityLookup,
      onlineUserLookup,
      isOnline,
      timeOpened,
      bestLocation?.latitude,
      bestLocation?.longitude,
      bestLocation?.accuracy,
      username,
      email,
      lastName,
      firstName,
      roles,
      roleString,
      authContextInfo,
      permissionString,
      permissions,
    ]
  );
};
