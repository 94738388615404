import React, { FunctionComponent, useCallback } from 'react';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as ThreeDotMenu } from '../../images/threeDotMenu.svg';
import ActionsMenuUI, { ActionsMenuButton } from '../ActionsMenuUI';
export interface ThreeDotMenuCellProps {
  actions: readonly ActionsMenuButton[];
  statusText?: string;
  isMultiSelectActive: boolean;
  isSelected: boolean;
  toggleSelected: () => void;
  onOpen?: () => void;
  isClosed?: boolean;
}

const ThreeDotMenuCellRenderer: FunctionComponent<ThreeDotMenuCellProps> = (props) => {
  const { actions, statusText, isMultiSelectActive, isSelected, toggleSelected, onOpen, isClosed } = props;

  // Handle Actions Menu
  const [actionsMenuAnchor, setActionsMenuAnchor] = React.useState<Element | null>(null);
  const handleActionsMenuClose = useCallback(() => {
    setActionsMenuAnchor(null);
  }, []);

  // Handle Action Click
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setActionsMenuAnchor(event.currentTarget);
    onOpen && onOpen();
  };

  return isMultiSelectActive ? (
    <Checkbox checked={isSelected} onClick={toggleSelected} />
  ) : (
    <>
      <ThreeDotMenu onClick={handleClick} style={{ minWidth: 28, cursor: 'pointer' }} />
      <ActionsMenuUI
        statusText={statusText}
        actions={actions}
        anchorEl={isClosed ? null : actionsMenuAnchor}
        onClose={handleActionsMenuClose}
      />
    </>
  );
};
export default ThreeDotMenuCellRenderer;
