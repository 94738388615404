import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Slide,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { colors, trimTrailingSlash, useConfig } from '@terragotech/gen5-shared-components';
import { MAP_SERVICE_TYPE } from '@terragotech/gen5-shared-utilities';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import magicText from 'i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAggregates } from '../contexts/AggregatesContext';
import { ArcGisMapServerLegendResponse, MapServiceType } from '../contexts/AggregatesContext/types';
import SwipeableTabs, { SwipeableTabPanel } from './Common/SwipeableTabs';

export const LEGEND_WIDTH = 145;
const ICON_PIN_SIZE = 20;

const styles = (theme: Theme) =>
  createStyles({
    legendWrapper: {
      background: colors.white,
      height: '100%',
      boxShadow: '0px 2px rgba(0, 0, 0, 0.1)',
      maxWidth: LEGEND_WIDTH,
    },
    mapLoad: {
      boxShadow: '0px 2px rgba(0, 0, 0, 0.1)',
      background: colors.white,
      width: 165,
      height: 65,
      alignSelf: 'flex-end',
    },
    text: {
      margin: 0,
      textAlign: 'center',
    },
    title: {
      padding: '12px 0 7px 0',
      fontWeight: 500,
      textAlign: 'center',
    },
    legendListItem: {
      height: 30,
      display: 'flex',
      justifyContent: 'left',
      paddingTop: 7,
      zIndex: 1,
      width: '100%',
      alignItems: 'center',
    },
    icon: {},
    legendList: {
      display: 'flex',
      maxHeight: 258,
      flexDirection: 'column',
      flex: '1',
      zIndex: 4,
      overflowY: 'auto',
      // padding: 15,
    },
    iconPin: {
      width: ICON_PIN_SIZE,
      height: ICON_PIN_SIZE,
      alignSelf: 'center',
      padding: 5,
    },
    assetsStatus: {
      marginLeft: 8,
    },
    dropDown: {
      borderTop: `2px solid ${theme.palette.grey[100]}`,
      color: theme.palette.primary.main,
      borderRadius: 0,
      minWidth: `${LEGEND_WIDTH}px !important`,
      minHeight: '24px !important',
      textAlign: 'center',
      padding: '3px 0px !important',
      width: '100%',
    },
    arrow: {
      color: theme.palette.grey[900],
    },
    legendLine: {
      width: 26,
      height: 0,
      margin: 2,
    },
    legendHeader: {
      padding: '18px 0 18px 0',
      fontWeight: 600,
      textAlign: 'center',
    },
    legendPopover: {
      '& > div.MuiPopover-paper': {
        maxWidth: 320,
      },
    },
    legendItemIndented: {
      marginLeft: 16,
    },
    legendImage: {
      padding: 8,
    },
    progressLabel: {
      color: theme.palette.grey[600],
      padding: '0px 5px',
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    legendBoarderSymbolsOnly: {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      padding: 14,
      fontSize: 14,
    },
  });

export type Symbol = { name: string; symbolKey: string };
type SymbolComponentProps = { symbol: Symbol; index: number };
type Base64IconProps = { contentType: string; imageData: string; label: string; indent?: boolean; index: number };

interface LegendProps extends WithStyles<typeof styles> {
  symbols: Array<Symbol>;
  symbolsOnly?: boolean;
}

const Legend: React.FunctionComponent<LegendProps> = ({ symbols, classes, symbolsOnly }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  function handleLegendClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }
  const { mapServices, visibleMapServiceKeys } = useAggregates();
  const scrollToLocation = useRef<HTMLUListElement | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [isImgLoading, setIsImgLoading] = useState(true);
  const config = useConfig();

  const [arcGisLegends, setArcGisLegends] = useState<Record<string, ArcGisMapServerLegendResponse>>({});
  const arcGisMapServices = useMemo(() => mapServices.filter(s => s.serviceType === MAP_SERVICE_TYPE.ArcGIS), [
    mapServices,
  ]);
  useEffect(() => {
    const fetchLegends = async () => {
      const newLegends: typeof arcGisLegends = {};
      let updated = false;
      await Promise.all(
        arcGisMapServices.map(async s => {
          if (!arcGisLegends[s.id]) {
            const response = await fetch(
              `${trimTrailingSlash(s.serviceUri)}/legend?f=json&size=${ICON_PIN_SIZE},${ICON_PIN_SIZE}`
            );
            const data = response.ok && (await response.json());
            if (data && data.layers && data.layers[0].legend) {
              newLegends[s.id] = data;
              updated = true;
            }
          }
        })
      );

      if (updated) {
        setArcGisLegends(prevState => ({ ...prevState, ...newLegends }));
      }
    };

    fetchLegends().catch(console.error);
  }, [arcGisMapServices, setArcGisLegends]);

  const mapServicesWithLegends = useMemo(() => {
    const hasLegend = (serviceId: string, layer: MapServiceType['layers'][number]) =>
      !!layer.layerLegendUri ||
      arcGisLegends[serviceId]?.layers.some(ld => layer.layerName.split(',').some(ln => ln === `${ld.layerId}`));
    return mapServices
      .filter(
        s =>
          visibleMapServiceKeys.some(k => k === s.id) ||
          s.layers.some(l => hasLegend(s.id, l) && visibleMapServiceKeys.some(k => k === `${s.id}-${l.layerName}`))
      )
      .map(({ layers, ...rest }) => ({
        layers: layers
          .filter(l => hasLegend(rest.id, l) && visibleMapServiceKeys.some(k => k === `${rest.id}-${l.layerName}`))
          .map(l => ({ ...l, id: `${rest.id}-${l.layerName}` })),
        ...rest,
      }));
  }, [mapServices, visibleMapServiceKeys, arcGisLegends]);

  interface MapServiceLegendFlatListItem {
    id: string;
    parentId?: string;
    label: string;
    legendUri?: string;
    legendData?: ArcGisMapServerLegendResponse['layers'];
  }
  const mapServiceLegendsFlatList = useMemo(() => {
    const flatList: MapServiceLegendFlatListItem[] = [];
    mapServicesWithLegends.forEach(s => {
      flatList.push({ id: s.id, parentId: undefined, label: s.label });
      s.layers.forEach(l => {
        const layerItem = { id: l.id, parentId: s.id, label: l.layerTitle ?? l.layerName };
        flatList.push(layerItem);
        if (l.layerLegendUri) {
          flatList.push({ ...layerItem, parentId: l.id, legendUri: l.layerLegendUri });
        } else if (s.serviceType === MAP_SERVICE_TYPE.ArcGIS && arcGisLegends[s.id]) {
          flatList.push({
            ...layerItem,
            parentId: l.id,
            legendData: arcGisLegends[s.id].layers.filter(ld =>
              l.layerName.split(',').some(ln => ln === `${ld.layerId}`)
            ),
          });
        }
      });
    });
    return flatList;
  }, [mapServicesWithLegends]);

  const [selectedMapServiceLegend, setSelectedMapServiceLegend] = React.useState<MapServiceLegendFlatListItem>();

  useEffect(() => {
    if (!mapServiceLegendsFlatList.some(x => x.id === selectedMapServiceLegend?.id)) {
      setSelectedMapServiceLegend(undefined);
    }
  }, [mapServiceLegendsFlatList]);

  const handleChangeSelectedMapServiceLegend = useCallback(
    (selected?: MapServiceLegendFlatListItem) => {
      if (!selected && selectedMapServiceLegend?.parentId) {
        const parent = mapServiceLegendsFlatList.find(x => x.id === selectedMapServiceLegend.parentId);
        setSelectedMapServiceLegend(parent);
      } else {
        setSelectedMapServiceLegend(selected);
      }
      scrollToLocation.current?.firstElementChild?.scrollIntoView();
      setIsImgLoading(true);
    },
    [mapServiceLegendsFlatList, selectedMapServiceLegend, setSelectedMapServiceLegend]
  );

  const handleLegendClose = useCallback(() => {
    setAnchorEl(null);
    // setSelectedMapServiceLegend(undefined); // Uncomment to reset selected Map Service Legend when closing legend popup.
  }, [setAnchorEl, setSelectedMapServiceLegend]);

  const Symbol = ({ symbol, index }: SymbolComponentProps) => (
    <div className={classes.legendListItem} key={index}>
      <img
        src={getSvgImageString(symbol.symbolKey, { globalScaleFactor: 1, embedFont: true })}
        height={64}
        alt="iconPin"
        className={classes.iconPin}
      />
      <Typography variant="h4" className={classes.assetsStatus}>
        {symbol.name}
      </Typography>
    </div>
  );

  const Line = ({ symbol, index }: SymbolComponentProps) => {
    const symbolParts = symbol.symbolKey.split('_');
    return (
      <div className={classes.legendListItem} key={index}>
        <div
          className={classes.legendLine}
          style={{
            borderTopStyle: symbolParts[1] as 'solid' | 'dashed',
            borderTopWidth: `${symbolParts[2]}px`,
            borderTopColor: `#${symbolParts[3]}`,
          }}
        />
        <Typography variant="h4" className={classes.assetsStatus}>
          {symbol.name}
        </Typography>
      </div>
    );
  };

  const Base64Icon = ({ contentType, imageData, label, indent, index }: Base64IconProps) => (
    <div className={classes.legendListItem} key={index}>
      <img
        src={`data:${contentType};base64, ${imageData}`}
        height={64}
        alt={`Legend image for ${label}`}
        className={`${classes.iconPin} ${indent ? classes.legendItemIndented : ''}`}
      />
      <Typography variant="h4" className={classes.assetsStatus}>
        {label}
      </Typography>
    </div>
  );

  const symbolLegend = (
    <div className={classes.legendList}>
      {symbols.map((symbol: Symbol, index: number) => {
        const isSymbol = symbol.symbolKey.startsWith('symbol');
        const isLine = symbol.symbolKey.startsWith('line');
        if (isSymbol) return <Symbol symbol={symbol} index={index} key={index} />;
        if (isLine) return <Line symbol={symbol} index={index} key={index} />;
        return null;
      })}
    </div>
  );

  return (
    <Slide direction="left" in={true}>
      <div className={classes.legendWrapper}>
        <div>
          <Typography variant="h4" className={classes.title}>
            <>{magicText.t('map.legend.mapLegend')}</>
          </Typography>
          {anchorEl ? (
            <Button className={classes.dropDown} onClick={handleLegendClose}>
              <>{magicText.t('map.legend.hide')}</>
              <ArrowDropUp className={classes.arrow} />
            </Button>
          ) : (
            <Button className={classes.dropDown} onClick={handleLegendClick}>
              <> {magicText.t('map.legend.show')}</>
              <ArrowDropDown className={classes.arrow} />
            </Button>
          )}
        </div>
        <Popover
          id="legend"
          open={Boolean(anchorEl)}
          onClose={handleLegendClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={anchorEl}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          className={classes.legendPopover}
        >
          <Typography
            variant="h3"
            className={[classes.legendHeader, symbolsOnly ? classes.legendBoarderSymbolsOnly : null].join(' ')}
          >
            <>{magicText.t('map.legend.mapLegend')}</>
          </Typography>
          {!symbolsOnly ? (
            <SwipeableTabs
              hideTabs={!config.integrations?.mapServices}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            >
              <SwipeableTabPanel label={magicText.t('map.legend.symbol')}>{symbolLegend}</SwipeableTabPanel>
              <SwipeableTabPanel
                label={magicText.t('map.legend.mapServices')}
                hideTab={!config.integrations?.mapServices}
              >
                {mapServicesWithLegends.length === 0 && (
                  <Box m={1}>
                    <Typography variant="h3">No legend information available for currently selected layers.</Typography>
                  </Box>
                )}
                {selectedMapServiceLegend?.id && (
                  <>
                    <Grid container alignItems="center">
                      <Grid item xs={1}>
                        <IconButton size="small" edge="start" onClick={() => handleChangeSelectedMapServiceLegend()}>
                          <ChevronLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="h4" className={classes.legendHeader}>
                          {selectedMapServiceLegend.label}
                        </Typography>
                      </Grid>
                    </Grid>
                    <hr />
                  </>
                )}
                <List ref={scrollToLocation} className={classes.legendList}>
                  {mapServiceLegendsFlatList
                    .filter(msl => msl.parentId === selectedMapServiceLegend?.id)
                    .map(msl =>
                      msl.legendUri ? (
                        <React.Fragment key={`${msl.id}-legendUri`}>
                          <div style={{ display: isImgLoading ? 'none' : 'inherit' }} className={classes.legendList}>
                            <img
                              src={msl.legendUri}
                              onLoad={e => setIsImgLoading(false)}
                              alt={`Legend image for ${msl.label}`}
                              className={classes.legendImage}
                            />
                          </div>
                          <div style={{ display: !isImgLoading ? 'none' : 'inherit' }} className={classes.legendList}>
                            <Typography variant="h4" className={classes.progressLabel}>
                              <>
                                {' '}
                                {magicText.t('Loading')} <CircularProgress size={14} />
                              </>
                            </Typography>
                          </div>
                        </React.Fragment>
                      ) : msl.legendData && msl.legendData.length ? (
                        <React.Fragment key={`${msl.id}-legendData`}>
                          {msl.legendData.map(ld => (
                            <React.Fragment key={`${msl.id}-legendData-${ld.layerId}`}>
                              {msl.legendData!.length > 1 && ld.legend.length > 1 ? (
                                <div className={classes.legendListItem}>
                                  <Typography variant="h3" className={classes.assetsStatus}>
                                    {ld.layerName}
                                  </Typography>
                                </div>
                              ) : (
                                <></>
                              )}
                              {ld.legend.map((ldl, ldli) => (
                                <Base64Icon
                                  key={`${msl.id}-legendData-${ld.layerId}-${ldl.url}-${ldl.values?.join('-')}`}
                                  contentType={ldl.contentType}
                                  imageData={ldl.imageData}
                                  label={ldl.label || ld.layerName || msl.label}
                                  indent={msl.legendData!.length > 1 && ld.legend.length > 1}
                                  index={ldli}
                                />
                              ))}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ) : (
                        <ListItem key={msl.id} button onClick={() => handleChangeSelectedMapServiceLegend(msl)}>
                          <ListItemText primary={msl.label} />
                          <IconButton edge="end">
                            <ChevronRight />
                          </IconButton>
                        </ListItem>
                      )
                    )}
                </List>
              </SwipeableTabPanel>
            </SwipeableTabs>
          ) : (
            <div style={{ paddingRight: 5 }}>{symbolLegend}</div>
          )}
        </Popover>
      </div>
    </Slide>
  );
};

export default React.memo(withStyles(styles)(Legend));
