import React, { useContext, useEffect } from 'react';
import { createStyles, Button, makeStyles, Theme, Slide, useTheme } from '@material-ui/core';
import { EditModeContext } from '../../contexts/editModeContext';
import { colors } from '../../styles/theme';
import magicText from 'i18next';
import { BOTTOM_BAR_Z_INDEX } from '../../utils/layers';
import TGAlertDialog from '../TGAlertDialog';
import { useTableColumns } from '../../contexts/TableColumnContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomButtonBar: {
      display: 'flex',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      flexDirection: 'column',
      fontSize: 14,
      backgroundColor: colors.white,
      color: colors.black,
      zIndex: BOTTOM_BAR_Z_INDEX,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 53,
    },
    divider: {
      height: 5,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.25)',
    },
    button: {
      height: 38,
      fontWeight: 500,
    },
    saveButton: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      marginLeft: 10,
    },
    disabledButton: {
      backgroundColor: '#E6E6E6',
      color: `${colors.white} !important`,
    },
    discardButton: {
      backgroundColor: colors.white,
      color: colors.black,
      border: `1px solid ${colors.grayPlaceholder}`,
      marginLeft: 10,
    },
    rootDialog: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 700,
        width: 550,
        paddingBottom: 10,
      },
    },
  })
);

export interface BottomActionBarProps {
  onDiscardClick: () => void;
  onSaveClick: () => void;
  label?: string;
  bottomBarHeight?: number;
  changedRecords?: Array<string>;
}

/**
 * BottomActionBar Decides whether to SAVE or DELETE updated assets
 *
 * Has functions that links to the buttons it displays...
 *
 * @param onDiscardClick
 * @param onSaveClick
 * @param changedAssetsCount
 * @param bottomBarHeight
 */
const BottomActionBar: React.FC<BottomActionBarProps> = props => {
  const { onDiscardClick, onSaveClick, label, bottomBarHeight, changedRecords } = props;
  const classes = useStyles();
  const [changedNames, setChangedNames] = React.useState<string[] | undefined>([]);
  const { columns } = useTableColumns();
  const theme = useTheme();
  useEffect(() => {
    const changedColumnNames =
      changedRecords &&
      changedRecords.map(changedRecord => {
        const foundColumn = columns.find(column => column.key === changedRecord);
        return foundColumn ? foundColumn?.name : '';
      });
    setChangedNames(changedColumnNames);
  }, [columns, changedRecords]);
  const { editModeActive } = useContext(EditModeContext);
  const [alert, setAlert] = React.useState(false);
  return (
    <>
      {alert && (
        <TGAlertDialog
          className={classes.rootDialog}
          title="Confirm"
          onConfirm={() => {
            onSaveClick();
            setAlert(!alert);
          }}
          onCancel={() => setAlert(false)}
          open={alert}
          titleStyle={{
            fontSize: 24,
            color: 'gray',
            cursor: 'pointer',
            position: 'absolute',
            right: '15px',
          }}
          maintitleStyle={{ padding: '0px', color: theme?.palette?.primary?.main }}
          alertIcon="fa-solid fa-circle-question"
          closeIcon="fa-solid fa-xmark"
          rootStyle={{ display: 'flex', padding: '8px 25px', alignItems: 'center' }}
          buttonStyle={{
            border: `1px solid ${theme?.palette?.primary?.main}`,
            padding: '2px 30px',
            textTransform: 'none',
          }}
          contentStyle={{ marginBottom: 0, padding: '8px 24px 0px 24px' }}
          onClose={() => setAlert(!alert)}
          primaryColor={theme?.palette?.primary?.main}
          divider
        >
          <>
            <div style={{ fontWeight: 'bold' }}>Would you like to update {label}?</div>
            <div style={{ fontSize: 15, marginTop: 5 }}>The following attribute(s) will be updated.</div>
            {changedNames && (
              <ul style={{ margin: 0, fontSize: 15, marginLeft: -20 }}>
                {changedNames && changedNames.map((changedRecordname: any, i) => <li key={`${changedRecordname}-${i}`}>{changedRecordname}</li>)}
              </ul>
            )}
          </>
        </TGAlertDialog>
      )}

      <Slide in={editModeActive} direction="up">
        <div className={classes.bottomButtonBar} style={{ height: bottomBarHeight ? bottomBarHeight : 48 }}>
          <div className={classes.divider} />
          <div className={classes.buttonContainer}>
            <p>
              <span style={{ fontWeight: 'bold' }}>{label}</span>
              <> will be updated</>
            </p>
            <span>
              <Button
                disabled={!(changedRecords && changedRecords.length > 0)}
                onClick={() => {
                  setAlert(!alert);
                }}
                classes={{ disabled: classes.disabledButton }}
                className={`${classes.button} ${classes.saveButton}`}
              >
                <>{magicText.t('Common.SAVE')}</>
              </Button>
            </span>
            <span>
              <Button onClick={onDiscardClick} className={`${classes.button} ${classes.discardButton}`}>
                <> {magicText.t('Common.DISCARD')}</>
              </Button>
            </span>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default BottomActionBar;
