import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfig, AggregateDefinition } from '@terragotech/gen5-shared-components';
import { useRecordType } from '../contexts/recordTypeContext';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import BottomSelectionBar from '../components/BottomSelectionBar';
import { useAggregates } from '../contexts/AggregatesContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';
/*moved main logic of component to function so it can be run more than once

  finalCount is a mapping of each type name to the total number of records selected for that type
  typeMap is a list of all the types that the records from each project in the projects array span
  recordTypeNames is the typeMap list where each type is mapped just to its name
*/
const getTypeInformationForProjects = (
  assets: Array<MapAssetType>,
  aggregateTypes: Array<AggregateDefinition>
): {
  finalCount: Record<string, number>;
  typeMap: (AggregateDefinition | undefined)[];
  recordTypeNames: (string | undefined)[];
} => {
  // Build the final count by adding together the results from all of the projects currently selected
  const finalCount = aggregateTypes.reduce((totals: Record<string, number>, aggDef) => {
    totals[aggDef.name] = assets.filter((asset) => asset.recordTypeKey === aggDef.queryKey).length;
    return totals;
  }, {});
  //filter out any types that have 0 count and map back to the aggregate type
  const typeMap = Object.keys(finalCount)
    .filter((key) => finalCount[key] > 0)
    .map((key) => aggregateTypes.find((aggDef) => aggDef.name === key));
  /*  END Get the names of the types with >0 count from previous step */
  const recordTypeNames = typeMap.map((recordType) => recordType && recordType.name);
  return { finalCount, typeMap, recordTypeNames };
};

const BottomRecordTypeSelectorContainer: React.FC<{}> = (props) => {
  const { selectedRecordType } = useRecordType();
  const { aggregateDefinitions } = useConfig();
  const history = useHistory();
  const { selectedProjects } = useSelectedProject();
  const [tabValue, setTabValue] = useState<string | null>(null);
  const assetInfo = useAggregates();
  //TODO: Build a graphql query to return what this section calculates
  /* BEGIN Get the item count information for the available aggregate Types */
  const aggregateTypes = aggregateDefinitions.filter(
    (aggDef) => aggDef.name !== 'Folder' && aggDef.name !== 'Project' && !!!aggDef.hiddenFromTableView
  );

  const { finalCount, typeMap, recordTypeNames } = getTypeInformationForProjects(assetInfo.assets, aggregateTypes);

  const { typeMap: lastProjectTypeMap } = getTypeInformationForProjects(assetInfo.assets, aggregateTypes);
  //clear tab override when a project is selected
  useEffect(() => {
    setTabValue(null);
  }, [selectedProjects]);

  //unless the user has clicked a tab recently (tabValue != null), select the first type from
  //the last selected project
  //TODO: This causes the first tab to be selected often when it shouldn't be.
  const type = lastProjectTypeMap[0];
  const typesInProject = lastProjectTypeMap.map((proj) => proj?.name);
  useEffect(() => {
    if (!tabValue && type !== undefined && !typesInProject.includes(selectedRecordType)) {
      history.push(`/${type.name}`);
    }
  }, [history, type, tabValue, selectedRecordType, typesInProject]);

  const options = useMemo(
    () =>
      (typeMap.filter((aggregateDefinition) => !!aggregateDefinition) as AggregateDefinition[]).map(
        (aggregateDefinition) => ({
          value: aggregateDefinition.name,
          label: `${aggregateDefinition.plural} (${
            finalCount[aggregateDefinition.name] ? finalCount[aggregateDefinition.name].toLocaleString() : 0
          })`,
        })
      ),
    [typeMap, finalCount]
  );

  const value = useMemo(() => recordTypeNames.includes(selectedRecordType) && selectedRecordType, [
    selectedRecordType,
    recordTypeNames,
  ]);

  const handleSelect = useCallback(
    (value: string) => {
      if (aggregateDefinitions) {
        history.push(`/${value}`);
        setTabValue(value);
      }
    },
    [aggregateDefinitions, history]
  );

  return <BottomSelectionBar value={value} options={options} onSelect={handleSelect} />;
};

export default BottomRecordTypeSelectorContainer;
