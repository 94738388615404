import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { withStyles, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useConfig, colors, AggregateDefinition } from '@terragotech/gen5-shared-components';
import InputBase from '@material-ui/core/InputBase';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useAggregates } from '../../../../contexts/AggregatesContext';
import { MapAssetType } from '../../../../contexts/AggregatesContext/types';

const NoBorderInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      border: 'none',
      paddingLeft: 15,
    },
  })
)(InputBase);

interface AttributeSearchOnMapSelectProps {
  mapSearchType: string;
  setMapSearchType: (value: string) => void;
}

const getTypeInformationForProjects = (
  assets: Array<MapAssetType>,
  aggregateTypes: Array<AggregateDefinition>
): (AggregateDefinition | undefined)[] => {
  // Build the final count by adding together the results from all of the projects currently selected
  const finalCount = aggregateTypes.reduce((totals: Record<string, number>, aggDef) => {
    totals[aggDef.name] = assets.filter((asset) => asset.recordTypeKey === aggDef.queryKey).length;
    return totals;
  }, {});
  //filter out any types that have 0 count and map back to the aggregate type
  const typeMap = Object.keys(finalCount)
    .filter((key) => finalCount[key] > 0)
    .map((key) => aggregateTypes.find((aggDef) => aggDef.name === key));
  /*  END Get the names of the types with >0 count from previous step */

  return typeMap;
};

const AttributeSearchOnMapSelect: FunctionComponent<AttributeSearchOnMapSelectProps> = (props) => {
  const { mapSearchType, setMapSearchType } = props;
  const classes = useStyles();

  const uiConfig = useConfig();
  const { aggregateDefinitions } = useConfig();
  const assetInfo = useAggregates();
  const typeMap = useMemo(() => getTypeInformationForProjects(assetInfo.assets, aggregateDefinitions), [assetInfo]);
  useEffect(() => {
    const isExistType = typeMap.find((asset) => {
      return asset?.name == mapSearchType;
    });
    if (!isExistType) {
      setMapSearchType('location');
    }
  }, [assetInfo.assets]);

  const handleMapSearchTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMapSearchType(event.target.value as string);
  };
  return (
    <div>
      {uiConfig.enabledFeatures?.attributeSearchOnMap && (
        // Only show map search type dropdown if it's enabled in gen5config
        <FormControl variant={'outlined'}>
          <Select
            className={classes.mapSearchDataSelect}
            value={mapSearchType}
            input={<NoBorderInput />}
            onChange={handleMapSearchTypeChange}
          >
            <MenuItem value="location">Locations</MenuItem>
            {typeMap.map((aggDef, index) => {
              if (aggDef?.name === 'Project' || aggDef?.name === 'Folder') return null;
              return (
                <MenuItem value={aggDef?.name} key={index}>
                  {aggDef?.plural}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  mapSearchDataSelect: {
    marginLeft: 15,
    background: colors.white,
    height: 45,
    boxShadow: '0px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    zIndex: 999,
  },
}));

export default AttributeSearchOnMapSelect;
