import React, { FunctionComponent, useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme, useTheme, ThemeProvider } from '@material-ui/core/styles';
import { Slide, Button, Dialog, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { subYears } from 'date-fns';
import { TransitionProps } from '@material-ui/core/transitions';
import magicText from 'i18next';
import { colors } from '../../styles/theme';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    dialogPaper: {
      top: '2vh',
      height: '96vh',
      maxHeight: '100%',
      width: '650px',
      maxWidth: '650px',
      '@media (max-width: 650px)': {
        width: '100%',
        margin: 0,
      },
    },
    header: {
      display: 'flex',
      height: 46,
      backgroundColor: theme.palette.secondary.main,
      justifyContent: 'space-between',
      paddingLeft: 20,
    },
    headerText: {
      color: colors.white,
      textTransform: 'none',
      alignSelf: 'center',
    },
    selectBox: {
      display: 'flex',
      height: 46,
      borderBottom: '1px solid #EEEEEE',
      width: 300,
      margin: 20,
    },
    calendars: {
      display: 'flex',
      flexDirection: 'row',
      '@media (max-width: 796px)': {
        flexDirection: 'column',
      },
      marginLeft: 15,
      marginRight: 15,
    },
    calendarLabelText: {
      fontWeight: 500,
      fontSize: '16px',
      color: colors.black,
      marginBottom: 5,
    },
    calendarBlock: {
      width: 300,
      padding: '10px 5px',
      alignSelf: 'center',
    },
    button: {
      color: colors.white,
      marginTop: 5,
      marginRight: 5,
    },
  });

interface CalendarModalProps extends WithStyles<typeof styles> {
  open: boolean;
  setFilterOpen: any;
  displayName: any;
  setFilterState: any;
  field: any;
  filterState: any;
}
/**
 * CalenderFilter - filters assets in columns with dates by selecting date range criteria, such as
 *
 * Last Month, Last Year, and Custom Date Range
 *
 * @param classes
 * @param open
 * @param setFilterOpen
 * @param displayName
 * @param field
 * @param setFilterState
 * @param filterState
 */
const CalendarModal: FunctionComponent<CalendarModalProps> = props => {
  const { classes, open, setFilterOpen, displayName, field, setFilterState, filterState } = props;

  const theme = useTheme();
  const calendarTheme = createTheme({
    palette: {
      primary: {
        main: theme.palette.secondary.main,
        dark: theme.palette.secondary.main,
        light: colors.white,
      },
    },
    overrides: {
      // @ts-ignore
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: 'rgba(255, 255, 255, 0.54)',
        },
        toolbarBtnSelected: {
          color: '#fff',
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          minWidth: 300,
        },
      },
      MuiTypography: {
        h4: {
          fontSize: 34,
        },
      },
    },
  });

  const currentDate = new Date();
  const [startValue, setStartDate] = useState(currentDate);
  const [endValue, setEndDate] = useState(currentDate);
  const [selectValue, setSelectValue] = useState('All Time');

  useEffect(() => {}, [startValue, endValue]);

  useEffect(() => {
    if (filterState && !filterState[field]) {
      setSelectValue('All Time');
    }
  }, [filterState, field]);

  const changeStartDate = (date: any) => {
    setStartDate(moment(date).startOf('day').toDate());
  };
  const changeEndDate = (date: any) => {
    setEndDate(moment(date).endOf('day').toDate());
  };
  const handleClose = () => {
    setFilterOpen(false);
  };

  const handleApply = () => {
    if (selectValue === 'All Time') {
      setFilterState((prevState: any) => {
        if (prevState[field]) {
          delete prevState[field];
          return { ...prevState };
        }
        return { ...prevState };
      });
      handleClose();
    } else {
      const values = [startValue, endValue];
      setFilterState((prevState: any) => {
        return { ...prevState, [field]: values };
      });
      handleClose();
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectValue(e.target.value as string);
    switch (e.target.value) {
      case 'Today':
        changeStartDate(currentDate);
        changeEndDate(currentDate);
        break;
      case 'Yesterday':
        changeStartDate(moment(currentDate).subtract(1, 'days').startOf('day').toDate());
        changeEndDate(moment(currentDate).subtract(1, 'days').endOf('day').toDate());
        break;
      case 'Month to date':
        changeStartDate(moment(currentDate).startOf('month').toDate());
        changeEndDate(currentDate);
        break;
      case 'Last Month':
        changeStartDate(moment(currentDate).subtract(1, 'months').startOf('month').toDate());
        changeEndDate(moment(currentDate).subtract(1, 'months').endOf('month').toDate());
        break;
      case 'Year to date':
        changeStartDate(moment(currentDate).startOf('year').toDate());
        changeEndDate(currentDate);
        break;
      case 'Last Year':
        changeStartDate(moment(currentDate).subtract(1, 'years').startOf('year').toDate());
        changeEndDate(moment(currentDate).subtract(1, 'years').endOf('year').toDate());
        break;
      case 'Custom Date Range':
        changeStartDate(currentDate);
        changeEndDate(currentDate);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleApply}
      classes={{ paperScrollPaper: classes.dialogPaper }}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerText}>{displayName}</div>
          <div>
            <Button className={classes.button} onClick={handleApply}>
              <> {magicText.t('Common.doneLabel')}</>
            </Button>
          </div>
        </div>
        <FormControl className={classes.selectBox}>
          <InputLabel htmlFor="date-selection">{`${magicText.t('AssetTable.filters.filterBy')}:`}</InputLabel>
          <Select
            value={selectValue}
            onChange={handleSelectChange}
            inputProps={{
              id: 'date-selection',
            }}
          >
            <MenuItem value="All Time">{magicText.t('AssetTable.filters.All Time')}</MenuItem>
            <MenuItem value="Today">{magicText.t('AssetTable.filters.Today')}</MenuItem>
            <MenuItem value="Yesterday">{magicText.t('AssetTable.filters.Yesterday')}</MenuItem>
            <MenuItem value="Month to date">{magicText.t('AssetTable.filters.Month to date')}</MenuItem>
            <MenuItem value="Last Month">{magicText.t('AssetTable.filters.Last Month')}</MenuItem>
            <MenuItem value="Year to date">{magicText.t('AssetTable.filters.Year to date')}</MenuItem>
            <MenuItem value="Last Year">{magicText.t('AssetTable.filters.Last Year')}</MenuItem>
            <MenuItem value="Custom Date Range">{magicText.t('AssetTable.filters.Custom Date Range')}</MenuItem>
          </Select>
        </FormControl>
        {selectValue === 'Custom Date Range' && (
          <div className={props.classes.calendars}>
            <ThemeProvider theme={calendarTheme}>
              <div className={props.classes.calendarBlock}>
                <div className={props.classes.calendarLabelText}>
                  <>{magicText.t('Common.fromLabel')}</>
                </div>
                <DatePicker
                  orientation="portrait"
                  variant="static"
                  onChange={changeStartDate}
                  value={startValue}
                  minDate={subYears(currentDate, 70)}
                />
              </div>
              <div className={props.classes.calendarBlock}>
                <div className={props.classes.calendarLabelText}>
                  <>{magicText.t('Common.toLabel')}</>
                </div>
                <DatePicker
                  orientation="portrait"
                  variant="static"
                  onChange={changeEndDate}
                  value={endValue}
                  minDate={startValue}
                />
              </div>
            </ThemeProvider>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(CalendarModal);
