import React, { useMemo, useState } from 'react';
import {
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Box,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useStyles } from './Common';
import clsx from 'clsx';
import AggregateSelectorMapContainer, { AggregateSelection } from '../../containers/AggregateSelectorMapContainer';
import DialogueWrapper, { ModalProps } from '../Common/DialogueWrapper';
import { useAggregates } from '../../contexts/AggregatesContext';
import HideNonSelectableButtons from '../Map/component/HideNonSelectableButton';
import { TGLabelWrapper, TGLabelWrapperProps, FieldHeader } from '@terragotech/gen5-shared-components';

export interface TGMapAggregateSelectorFieldProps extends TGLabelWrapperProps {
  value: AggregateSelection | null | undefined;
  label: string;
  placeholder?: string;
  onChange: (newValue: AggregateSelection | null) => void;
  readOnly?: boolean;
  required?: boolean;
  error?: boolean;
  warning?: boolean;
  helperText?: string;
  selectableAggregateTypes?: string[];
}

const SelectableAggregateTypesToast = (props: { isOpen: boolean; setTypesToastIsShowing: (arg0: boolean) => void }) => {
  const { isOpen, setTypesToastIsShowing } = props;
  const toastStyles = useAggregateTypesToastStyles();

  const Markup = () => (
    <div className={toastStyles.container}>
      <span className={toastStyles.text}>
        You have selectable record types that are currently hidden from this view. To view all available records, adjust
        record visibility n the home screen.
      </span>
      <span onClick={() => setTypesToastIsShowing(false)} className={toastStyles.close}>
        X
      </span>
    </div>
  );

  return isOpen ? <Markup /> : <></>;
};

const TGMapAggregateSelectorField: React.FC<
  TGMapAggregateSelectorFieldProps & { toggleModal: ModalProps['toggleModal'] }
> = props => {
  const { value, label, onChange, toggleModal, selectableAggregateTypes } = props;
  const aggregates = useAggregates();
  const [typesToastIsShowing, setTypesToastIsShowing] = useState(
    !!selectableAggregateTypes &&
      selectableAggregateTypes.some(type => !aggregates.visibleAggregateTypesNames.includes(type))
  );

  const [capturedAggregate, setCapturedAggregate] = useState<AggregateSelection | null | undefined>(value);
  const locationStyles = useLocationStyles();

  const onDonePress = () => {
    if (capturedAggregate) {
      onChange(capturedAggregate);
    }
    toggleModal && toggleModal();
  };
  const onCancelPress = () => {
    toggleModal && toggleModal();
  };
  return (
    <FormControl>
      <FieldHeader title={label} canSave={true} onDonePress={onDonePress} onCancelPress={onCancelPress} />
      <Box m={1} pt={1}>
        <SelectableAggregateTypesToast isOpen={typesToastIsShowing} setTypesToastIsShowing={setTypesToastIsShowing} />
        <HideNonSelectableButtons selectableAggregateTypes={selectableAggregateTypes} />
        <Box mb={2}>
          <Typography>Tap a point to make a selection</Typography>
        </Box>
        <Box className={locationStyles.mapContainer}>
          <AggregateSelectorMapContainer
            selectableAggregateTypes={selectableAggregateTypes}
            onAggregateSelected={(aggregate: AggregateSelection) => {
              setCapturedAggregate(curAggregate => {
                if (curAggregate?.id === aggregate?.id) {
                  return null;
                } else {
                  return aggregate;
                }
              });
            }}
            selectedAggregate={capturedAggregate}
          />
          ;
        </Box>
      </Box>
    </FormControl>
  );
};

const DefaultView: React.FC<TGMapAggregateSelectorFieldProps> = props => {
  const { label, value, required, readOnly, error, warning, helperText, info, onChange } = props;
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);
  const aggregates = useAggregates();
  const valueLabel = useMemo(() => {
    if (value?.label) {
      return value.label;
    } else {
      if (value?.id) {
        const linkedAsset = aggregates.assets.find(agg => agg.id === value.id);
        return linkedAsset?.label;
      } else {
        return value?.id;
      }
    }
  }, [aggregates.assets, value]);

  return (
    <div onMouseEnter={() => readOnly && setTooltip(true)} onMouseLeave={() => readOnly && setTooltip(false)}>
      <DialogueWrapper
        {...props}
        clearValue={() => onChange(null)}
        type="aggregateSelector"
        position={'top'}
        renderHeader={() => {
          return (
            <InputLabel error={error} className={clsx(warning && classes.warningText)}>
              <TGLabelWrapper required={required} readOnly={readOnly} label={label} info={info} tooltip={tooltip} />
            </InputLabel>
          );
        }}
        renderValue={() => {
          return (
            <>
              {!!value && <Typography className={classes.value}>{`${valueLabel}`}</Typography>}
              <FormHelperText error={error} className={clsx(warning && classes.warningText)}>
                {helperText}
              </FormHelperText>
            </>
          );
        }}
        renderComponent={toggleModal => {
          return <TGMapAggregateSelectorField {...props} toggleModal={toggleModal} />;
        }}
      />
    </div>
  );
};

export default DefaultView;

const useLocationStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: '80vh',
    },
  })
);

const useAggregateTypesToastStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      zIndex: 100,
      cursor: 'pointer',
      borderRadius: '4px',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
      margin: '0 10px 10px 0',
      color: 'white',
      backgroundColor: 'rgb(47, 51, 55, 1)',
      width: '55%',
      left: '22%',
      bottom: '-35px',
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
    },
    text: { flex: '15', display: 'flex', alignItems: 'center', fontSize: '10px' },
    close: { flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' },
  })
);
