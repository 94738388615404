import React from 'react';
import magicText from 'i18next';
import ModalTitle, { ModalTitleProps } from './ModalTitle';
import { Button, withStyles } from '@material-ui/core';
import { colors } from '../styles/theme';

export interface ModalDoneTitleProps {
  title: ModalTitleProps['title'];
  leftComponent?: ModalTitleProps['leftComponent'];
  onDone?: () => void;
  onCancel?: () => void;
}
const ModalDoneCancelTitle = withStyles((theme) => ({
  doneButton: {
    color: colors.white,
    margin: '0 -8px',
  },
}))((props: ModalDoneTitleProps & { classes: { doneButton: string } }) => (
  <ModalTitle
    leftComponent={
      props.onCancel ? (
        <Button className={props.classes.doneButton} onClick={props.onCancel}>
          <>{magicText.t('Cancel')}</>
        </Button>
      ) : undefined
    }
    title={props.title}
    rightComponent={
      props.onDone ? (
        <Button className={props.classes.doneButton} onClick={props.onDone}>
          <> {magicText.t('Done')}</>
        </Button>
      ) : undefined
    }
  />
));

export default ModalDoneCancelTitle;
