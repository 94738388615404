import { createStyles, Typography, withStyles, WithStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { colors } from '../../../styles/theme';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import magicText from 'i18next';
import ColumnFilterListElement, { ColumnFilterListElementProps } from './ColumnFilterListElement';

interface ColumnFilterTopListProps extends WithStyles<typeof styles> {
  isSelected: ColumnFilterListElementProps['isSelected'];
  setIsSelected: ColumnFilterListElementProps['setIsSelected'];
  columns: ReadonlyArray<Column<AssetType>>;
  id: string;
  togglePinned: (key: string) => void;
}

const ColumnFilterTopList: FunctionComponent<ColumnFilterTopListProps> = (props) => {
  const { classes, columns, isSelected, setIsSelected, togglePinned, id } = props;

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <div className={classes.stickyItems}>
            <Typography variant="body1" className={classes.stickyText}>
              <> {magicText.t('ColumnFilter.Pinned')}</>
            </Typography>
            {columns.length === 0 && (
              <Typography variant="body1" className={classes.emptyDisplayText}>
                <> {magicText.t('ColumnFilter.NothingSticky')}</>
              </Typography>
            )}
            {columns.map<React.ReactElement>((column, index: number) => (
              <ColumnFilterListElement
                column={column}
                index={index}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                togglePinned={togglePinned}
                key={column.key}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    stickyItems: {
      backgroundColor: theme.palette.grey[50],
      margin: '0px 25px 15px 20px',
      padding: 5,
      minHeight: 65,
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    stickyText: {
      position: 'relative',
      fontSize: 13,
      fontWeight: 500,
      color: colors.grayDescription,
      top: 10,
      left: 10,
      paddingBottom: 10,
      textTransform: 'uppercase',
    },
    emptyDisplayText: {
      position: 'relative',
      fontSize: 16,
      color: colors.grayDescription,
      top: 4,
      left: 10,
    },
  });

export default withStyles(styles)(ColumnFilterTopList);
