import { createStyles, makeStyles, Slide, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useContext } from 'react';
import { EditModeContext } from '../contexts/editModeContext';
import { colors } from '../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomButtonBar: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,
      backgroundColor: colors.white,
      color: colors.black,
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 53,
    },
    tab: {
      borderRight: `2px solid ${colors.grayLine}`,
    },
    divider: {
      height: 5,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.25)',
    },
    button: {
      height: 38,
      fontWeight: 500,
    },
    saveButton: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      marginRight: 10,
      marginLeft: 10,
    },
    discardButton: {
      backgroundColor: colors.white,
      color: colors.black,
      border: `1px solid ${colors.grayPlaceholder}`,
    },
  })
);
function a11yProps(index: unknown) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export interface BottomSelectionBarProps {
  options: { value: string; label: string }[];
  onSelect: (option: string) => void;
  value: string | false;
}

const BottomSelectionBar: React.FC<BottomSelectionBarProps> = (props) => {
  const classes = useStyles();
  const { options, onSelect, value } = props;
  const { editModeActive } = useContext(EditModeContext);

  return (
    <Slide in={!editModeActive} direction="up" timeout={500}>
      <div className={classes.bottomButtonBar}>
        <div className={classes.buttonContainer}>
          <AppBar position="static" color="default">
            <Tabs
              variant="scrollable"
              value={value}
              onChange={(_, newValue) => {
                onSelect(newValue);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {options.map((option) => {
                return (
                  <Tab
                    classes={{ root: classes.tab }}
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    {...a11yProps(0)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        </div>
      </div>
    </Slide>
  );
};

export default BottomSelectionBar;
