import React, { FunctionComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import ColumnFilterListElement, { ColumnFilterListElementProps } from './ColumnFilterListElement';

interface ColumnFilterBottomListProps {
  isSelected: ColumnFilterListElementProps['isSelected'];
  setIsSelected: ColumnFilterListElementProps['setIsSelected'];
  columns: ReadonlyArray<Column<AssetType>>;
  id: string;
  togglePinned: (key: string) => void;
}

const ColumnFilterBottomList: FunctionComponent<ColumnFilterBottomListProps> = (props) => {
  const { columns, isSelected, setIsSelected, togglePinned, id } = props;

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {columns.map<React.ReactElement>((column, index: number) => (
            <ColumnFilterListElement
              column={column}
              index={index}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              togglePinned={togglePinned}
              key={column.key}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ColumnFilterBottomList;
