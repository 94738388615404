import React, { createContext, FunctionComponent, useContext, useState, useMemo } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useRecordType } from './recordTypeContext';

export interface Coordinates {
  lng: number;
  lat: number;
}

interface AssetCardContextConfig {
  assetIds: string[];
  setAssetsIds: (ids: string[]) => void;
  coordinates: Coordinates | null;
  setCoordinates: (coordinates: Coordinates | null) => void;
  areCardsDefined: boolean;
}

export const AssetCardContext = createContext<AssetCardContextConfig>({
  assetIds: [],
  setAssetsIds: () => {},
  coordinates: null,
  setCoordinates: () => {},
  areCardsDefined: false,
});
export const MultipleAssetsProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedAssetsCoordinates, setSelectedAssetsCoordinates] = useState<Coordinates | null>(null);
  const { aggregateDefinitions } = useConfig();
  const { selectedRecordType } = useRecordType();
  const aggregateDefinition = useMemo(() => aggregateDefinitions.find((d) => d.name === selectedRecordType), [
    aggregateDefinitions,
    selectedRecordType,
  ]);
  const areCardsDefined = useMemo(() => !!aggregateDefinition?.cardDefinition?.titleKey, [aggregateDefinition]);


  const value: AssetCardContextConfig = {
    assetIds: selectedAssets,
    setAssetsIds: setSelectedAssets,
    coordinates: selectedAssetsCoordinates,
    setCoordinates: setSelectedAssetsCoordinates,
    areCardsDefined,
  };

  return <AssetCardContext.Provider value={value}>{props.children}</AssetCardContext.Provider>;
};
export const useAssetCards = () => useContext(AssetCardContext);
