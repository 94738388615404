import { ProjectState, ProjectQueryResult, WithAggregateName, MapAssetType, QueryResultDataType } from './types';
import { OperationVariables } from '@apollo/client';
import { VariablesRecord } from './useLazyQueries';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { REFERENCE_TYPE_AGGREGATE } from '@terragotech/gen5-shared-utilities';
export const INITIAL_CHUNK_SIZE = 500;
export const MAX_CHUNK_SIZE = 2000;
/**
 * Update the project state to not fetched and clear all of the records. This is no longer called
 * @param projectState
 */
export const resetProjectState = (projectState: ProjectState): ProjectState => {
  const newProjectState = { ...projectState };
  Object.keys(newProjectState).forEach(key => {
    newProjectState[key] = { records: new Map(), hasFetched: false };
  });
  return newProjectState;
};
/**
 * Takes the given projects and adds any that are currently missing from the given project state
 * @param projectState
 * @param projects
 */
const addMissingProjectsToProjectState = (projectState: ProjectState, projects: string[]): ProjectState => {
  const newProjectState = { ...projectState };
  projects.forEach(project => {
    if (!projectState[project]) {
      newProjectState[project] = { records: new Map(), hasFetched: false };
    }
  });
  return newProjectState;
};

/**
 * Returns a new project State which has removed any projects not included in the given projects list
 * @param projectState
 * @param projects
 */
const removeUnnecessaryProjectsFromProjectState = (projectState: ProjectState, projects: string[]): ProjectState => {
  const newProjectState = { ...projectState };
  Object.keys(projectState).forEach(key => {
    if (!projects.includes(key)) {
      delete newProjectState[key];
    }
  });
  return newProjectState;
};

/**
 * Synchronizes the project state to match the given list of projects
 * @param projectState
 * @param selectedProjects
 */
export const recomputeProjectState = (projectState: ProjectState, selectedProjects: string[]) => {
  const projectStateWithNewProjects = addMissingProjectsToProjectState(projectState, selectedProjects);
  const updatedProjectState = removeUnnecessaryProjectsFromProjectState(projectStateWithNewProjects, selectedProjects);
  return updatedProjectState;
};
/**
 * Synchronizes all of the project states for all aggregate types to match the given projects list
 * @param projectStates
 * @param selectedProjects
 */

const isAggregateReferenceType = (AggregateDefinition: AggregateDefinition[], name: string) => {
  if (AggregateDefinition.filter(x => x.queryKey === name && x.isReferenceType).length > 0) {
    return true;
  }
  return false;
};

export const recomputeProjectStates = (
  projectStates: WithAggregateName<ProjectState>,
  selectedProjects: string[],
  aggregateDefinitions: AggregateDefinition[]
): WithAggregateName<ProjectState> => {
  const keys = Object.keys(projectStates);
  const newProjectStates: WithAggregateName<ProjectState> = {};

  keys
    .filter(x => !isAggregateReferenceType(aggregateDefinitions, x))
    .forEach(key => {
      newProjectStates[key] = recomputeProjectState(projectStates[key], selectedProjects);
    });

  //get reference types
  const referenceTypeAggregates = aggregateDefinitions.filter(x => x.isReferenceType);
  referenceTypeAggregates.forEach(x => {
    // projectStates[x.name] = {};
    newProjectStates[x.queryKey] = recomputeProjectState(projectStates[x.queryKey], [REFERENCE_TYPE_AGGREGATE]);
  });

  return newProjectStates;
};
/**
 * Given a project state, return the first project that has not been fully fetched.
 * @param projectState
 */
export const getFirstProjectNotFullyFetched = (projectState: ProjectState): string | undefined =>
  Object.keys(projectState).find(key => !projectState[key].hasFetched || projectState[key].refetchVars);

/**
 * Given the new project data downloaded from a query, adds the data to the records collection in the projectState
 * Also updates the cursor and the hasFetched
 * @param projectState
 * @param projectId
 * @param data
 * @param recordTypeKey
 */
export const addNewProjectData = (
  projectState: ProjectState,
  projectId: string,
  data: ProjectQueryResult,
  recordTypeKey: string
): ProjectState => {
  const edges = data?.edges || [];
  const existingProject = projectState[projectId];
  if (existingProject) {
    const recordMap = existingProject.records;
    edges.forEach(edge => {
      recordMap.set(edge.node.id, {
        ...edge.node,
        recordTypeKey,
        primaryLocation: edge.node.primaryLocation?.geojson || undefined,
      });
      for(const pId in projectState){
        if(pId!==projectId){
          projectState[pId].records.delete(edge.node.id);
        }
      }
    });
    return {
      ...projectState,
      [projectId]: {
        records: recordMap,
        cursor: (data && data.pageInfo.endCursor) || existingProject.cursor || undefined,
        hasFetched: existingProject.refetchVars || (data && !data.pageInfo.hasNextPage),
        refetchVars:
          existingProject.refetchVars && data && data.pageInfo.hasNextPage
            ? { ...existingProject.refetchVars, after: data.pageInfo.endCursor }
            : undefined,
      },
    };
  }
  return projectState;
};

/**
 * Updates the project state such that all of the projects will be redownloaded.
 * @param projectState current project state
 */
export const resetFetchedInfo = (projectState: ProjectState, retainCursor?: boolean): ProjectState => {
  const newProjectState = { ...projectState };
  Object.keys(newProjectState).forEach(
    key =>
      (newProjectState[key] = {
        ...newProjectState[key],
        hasFetched: false,
        cursor: retainCursor ? newProjectState[key].cursor : undefined,
      })
  );
  return newProjectState;
};

export const areAllProjectsFetched = (projectState: ProjectState): boolean =>
  Object.keys(projectState).reduce<boolean>((acc, key) => projectState[key].hasFetched && acc, true);

export const createVariablesForProjectState = (
  projectState: ProjectState,
  called: boolean,
  projectId?: string
): OperationVariables | undefined => {
  const nextProject = projectId || getFirstProjectNotFullyFetched(projectState);
  //If there's something to load and we aren't currently loading anything, then get more records
  if (nextProject) {
    return {
      filters: {
        projectId: { equalTo: nextProject },
      },
      first: called ? MAX_CHUNK_SIZE : INITIAL_CHUNK_SIZE,
      after: called ? projectState[nextProject].cursor : null,
    };
  }
  return undefined;
};

export const createVariablesForReferenceTypeProjectState = (
  projectState: ProjectState,
  called: boolean
): OperationVariables | undefined => {
  return {
    filters: { label: { notEqualTo: '' } },
    first: called ? MAX_CHUNK_SIZE : INITIAL_CHUNK_SIZE,
    after: called ? projectState[REFERENCE_TYPE_AGGREGATE].cursor : null,
  };
};

/**
 * This creates the variables to be used in the project queries based on the current project states
 * @param projectStates current project states
 * @param called
 */
export const createVariablesForProjectStates = (
  projectStates: WithAggregateName<ProjectState>,
  called: boolean,
  aggregateDefinitions: AggregateDefinition[]
): OperationVariables => {
  const aggregateKeys = Object.keys(projectStates);
  const keysOfNotFetchedRecordTypes = aggregateKeys.filter(key => getFirstProjectNotFullyFetched(projectStates[key]));

  const projectBasedAggregates = keysOfNotFetchedRecordTypes.filter(
    x => !isAggregateReferenceType(aggregateDefinitions, x)
  );

  if (projectBasedAggregates.length > 0) {
    // We need to work on one project at a time for now, due to how the variables work
    const projectKeys = Object.keys(projectStates[projectBasedAggregates[0]]);
    //now find the first project ID that isn't fully fetched.
    let projectId: string = '';
    //Project type aggregates
    projectKeys.forEach(projectKey => {
      projectBasedAggregates.forEach(aggType => {
        if (
          (!projectId && !projectStates[aggType][projectKey].hasFetched) ||
          projectStates[aggType][projectKey]?.refetchVars
        ) {
          projectId = projectKey;
        }
      });
    });
    if (projectId) {
      return projectBasedAggregates.reduce<Record<string, VariablesRecord>>((result, recordType) => {
        if (projectStates[recordType][projectId].hasFetched && !projectStates[recordType][projectId].refetchVars) {
          return { ...result };
        } else {
          return {
            ...result,
            [recordType]:
              projectStates[recordType][projectId].refetchVars ||
              createVariablesForProjectState(projectStates[recordType], called, projectId || undefined)!,
          };
        }
      }, {});
    }
  }

  return {};
};

export const createVariablesForAggregateReferenceTypeProjectStates = (
  projectStates: WithAggregateName<ProjectState>,
  called: boolean,
  aggregateDefinitions: AggregateDefinition[]
): OperationVariables => {
  const aggregateKeys = Object.keys(projectStates);
  const keysOfNotFetchedRecordTypes = aggregateKeys.filter(key => getFirstProjectNotFullyFetched(projectStates[key]));
  const referenceTypeAggregates = keysOfNotFetchedRecordTypes.filter(x =>
    isAggregateReferenceType(aggregateDefinitions, x)
  );

  if (referenceTypeAggregates.length > 0) {
    // We need to work on one project at a time for now, due to how the variables work
    const projectKeys = Object.keys(projectStates[referenceTypeAggregates[0]]);
    //now find the first project ID that isn't fully fetched.
    let projectId: string = '';
    //Project type aggregates
    projectKeys.forEach(projectKey => {
      referenceTypeAggregates.forEach(aggType => {
        if (
          (!projectId && !projectStates[aggType][projectKey].hasFetched) ||
          projectStates[aggType][projectKey]?.refetchVars
        ) {
          projectId = projectKey;
        }
      });
    });
    if (projectId) {
      return referenceTypeAggregates.reduce<Record<string, VariablesRecord>>((result, recordType) => {
        if (projectStates[recordType][projectId].hasFetched && !projectStates[recordType][projectId].refetchVars) {
          return { ...result };
        } else {
          return {
            ...result,
            [recordType]:
              projectStates[recordType][projectId].refetchVars ||
              createVariablesForReferenceTypeProjectState(projectStates[recordType], called)!,
          };
        }
      }, {});
    }
  }

  return {};
};

export const getAssetsFromProjectStates = (projectStates: WithAggregateName<ProjectState>): MapAssetType[] => {
  let newAssets: MapAssetType[] = [];
  Object.keys(projectStates).forEach(aggregateKey => {
    const aggregateProjectState = projectStates[aggregateKey];
    Object.keys(aggregateProjectState).forEach(projectId => {
        newAssets = [...newAssets, ...Array.from(aggregateProjectState[projectId].records.values())];
    });
  });
  return newAssets;
};

/**
 * This takes the current projects state for all aggregate types and adds newly received data.
 * @param projectStates Current project states
 * @param data The new data/assets that have been recceived
 * @param variables The variables used to get the new data(really just the projectId that we care about)
 * @param selectedProjects The currently selected projectsßßß
 */
export const addProjectsToProjectStates = (
  projectStates: WithAggregateName<ProjectState>,
  data: QueryResultDataType,
  variables: VariablesRecord,
  selectedProjects: string[]
) => {
  const aggregateKeys = Object.keys(data);
  const result = { ...projectStates };
  aggregateKeys.forEach(key => {
    const newData = data[key][key];
    const projectId = variables[key]?.filters.projectId?.equalTo || REFERENCE_TYPE_AGGREGATE;
    if (projectId === REFERENCE_TYPE_AGGREGATE || selectedProjects.includes(projectId)) {
      result[key] = addNewProjectData(result[key], projectId, newData, key);
    }
  });

  return result;
};
