import React, { useState } from 'react';
import { Dialog, Theme, IconButton } from '@material-ui/core';
import EditComponentButton, { IconType } from './EditComponentButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TGLocationFieldProps } from '../FormFields/TGLocationField/TGLocationField';
import { TGPolylineFieldProps } from '../FormFields/TGLocationField/TGPolylineField';
import { useStyles } from '../FormFields/Common';
import DeleteIcon from '@material-ui/icons/Delete';
import { TGMapAggregateSelectorFieldProps } from '../FormFields/TGMapAggregateSelectorField';
import { TGBarcodeInputProps, MediaFieldProps, MediaFileFieldProps } from '@terragotech/gen5-shared-components';
export interface ModalProps {
  renderComponent?: (toggleModal: ToggleModal) => React.ReactNode;
  renderValue?: () => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  position?: 'top' | 'bottom';
  toggleModal?: ToggleModal;
  type: IconType;
  clearValue?: () => void;
}

export type ToggleModal = () => void;
/**
  DialogueWrapper - wraps Media Fields and Location Modals
  Different from DialogWrapper which wraps lazy loaded lists and has search functionality
*/
type DialogProps = (
  | TGBarcodeInputProps
  | TGLocationFieldProps
  | TGPolylineFieldProps
  | MediaFieldProps
  | MediaFileFieldProps
  | TGMapAggregateSelectorFieldProps
) &
  ModalProps;
export const DialogWrapper: React.FC<DialogProps> = props => {
  const classes = useStyles();

  const { type, value, renderComponent, position, renderValue, renderHeader, readOnly, clearValue } = props;
  const dialogStyles = useDialogStyles(props);
  const [open, setOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setOpen(!open);
  };

  const isNotEmpty = Array.isArray(value) ? value.length > 0 : !!value;

  return (
    <div className={classes.formElementWrapper}>
      <Dialog open={open} classes={{ paper: dialogStyles.dialogPaper }}>
        {renderComponent && renderComponent(toggleModal)}
      </Dialog>
      {renderHeader && renderHeader()}
      {position === 'top' && renderValue && renderValue()}
      <EditComponentButton readOnly={!!readOnly} type={type} mode={value ? 'edit' : 'add'} onClick={toggleModal} />
      {isNotEmpty && (
        <IconButton aria-label="clear" onClick={clearValue} disabled={readOnly}>
          <DeleteIcon />
        </IconButton>
      )}
      {position === 'bottom' && renderValue && renderValue()}
    </div>
  );
};

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: (props: DialogProps) => ({
      top: '2vh',
      height: '96vh',
      maxHeight: '100%',
      width: '100vw',
      maxWidth: '100%',
      '@media (max-width: 650px)': {
        width: '100%',
        margin: 0,
      },
    }),
  })
);

export default DialogWrapper;
