import { gql } from '@apollo/client';
import { pascalCase } from 'change-case';
import { AggregateDefinition, getAggregateDataFields } from '@terragotech/gen5-shared-components';

// eslint-disable-next-line import/no-anonymous-default-export
export default (aggregateDefinition: AggregateDefinition) => gql`query assetsQuery($filters: ${pascalCase(
  aggregateDefinition.name
)}Filter, $first: Int, $after: Cursor){
    ${aggregateDefinition.queryKey}: ${
  aggregateDefinition.queryKey
}Connection(filter: $filters, first: $first, after: $after, orderBy: LAST_MODEL_REFRESH_ASC) {
      edges {
        node{
          ${getAggregateDataFields(aggregateDefinition)}
          id
          label
          symbolKey
          primaryLocation {
            geojson
          }
        }
      }
      pageInfo{
        hasNextPage
        endCursor
      }
    }
  }`;
