import React from 'react';
import { Theme, createStyles, withStyles, WithStyles, Typography, CircularProgress } from '@material-ui/core';
import magicText from 'i18next';
import { colors } from '../../../styles/theme';

interface InfoSectionProps extends WithStyles<typeof styles> {
  isLoadingData: boolean;
  numberOfFilters: number;
  numberOfElements: number;
  numberOfVisible: number;
  handleFilterReset: () => void;
}

const InfoSection = ({
  classes,
  numberOfFilters,
  isLoadingData,
  numberOfElements,
  numberOfVisible,
  handleFilterReset,
}: InfoSectionProps) => {
  return (
    <div className={classes.showingSection}>
      <Typography variant="h4" className={classes.typography}>
        {!!numberOfFilters && (
          <div className={classes.activeFilterSection}>
            <span>{`${magicText.t('SearchBar.ACTIVE FILTERS')}: `}</span>
            <span>
              (<span className={classes.filterNumberSpan}>{numberOfFilters}</span>)
            </span>
          </div>
        )}
        {`${magicText.t('SearchBar.Showing')}: `}
        {isLoadingData && (
          <span className={classes.progressSpinnerSection}>
            <CircularProgress size={13} />
          </span>
        )}
        {`${isLoadingData ? '...' : ''}${numberOfVisible} ${magicText.t('SearchBar.of')} ${numberOfElements}`}
        {numberOfFilters ? (
          <span className={classes.clearFilters} onClick={handleFilterReset}>
            <> {magicText.t('SearchBar.CLEAR ALL FILTERS')}</>
          </span>
        ) : (
          ''
        )}
      </Typography>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    showingSection: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 25,
      paddingRight: 25,
    },
    typography: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.grey[600],
    },
    activeFilterSection: {
      fontWeight: 'bold',
      color: colors.black,
      marginRight: 5,
    },
    progressSpinnerSection: {
      marginLeft: 5,
      marginRight: 5,
      marginTop: 3,
    },
    filterNumberSpan: {
      color: colors.danger,
    },
    clearFilters: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 14,
      marginLeft: 10,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });

export default withStyles(styles)(InfoSection);
