import React from 'react';
import { OptionObject as Option } from '../TGOptionSelectorModal';
import { TGLazyModalRowRendererProps } from '../TGLazyModalList';
import { areOptionsEqual, getValue, getLabel } from '../getterUtils';
import { ListItem, Typography, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import NoAnimationRadio from '../NoAnimationRadio';

export interface GeneralProps {
  multiSelect: boolean;
  pathToValue?: string;
  pathToLabel?: string;
}

export const ROW_HEIGHT = 35;

const RowRenderer = <DataType extends Option>(
  props: TGLazyModalRowRendererProps<DataType, DataType[], GeneralProps>
) => {
  const { generalProps, state, setState, refresh } = props;
  const classes = useStyles();
  const multiSelect = generalProps?.multiSelect;

  const isOptionSelected = (option: DataType) => {
    return !!state.find((value) => areOptionsEqual(value, option, generalProps?.pathToValue));
  };

  const onChange = (option: DataType) => {
    const newState = (() => {
      if (multiSelect) {
        const isChecked = isOptionSelected(option);
        if (!isChecked) {
          return [...state, option];
        } else {
          return state.filter((value) => !areOptionsEqual(value, option, generalProps?.pathToValue));
        }
      } else {
        return [option];
      }
    })();

    refresh();
    setState(newState);
  };

  const option = props.row;
  if (option === undefined) {
    return (
      <ListItem>
        <Typography>undefined</Typography>
      </ListItem>
    );
  }
  const control = multiSelect ? (
    <Checkbox checked={isOptionSelected(option)} onChange={() => onChange(option)} color="primary" />
  ) : (
    <NoAnimationRadio checked={isOptionSelected(option)} onClick={() => onChange(option)} />
  );
  return (
    <ListItem dense className={classes.item}>
      <FormControlLabel
        className={classes.item}
        classes={{ label: classes.label }}
        value={getValue(option, generalProps?.pathToValue)}
        control={control}
        label={getLabel(option, generalProps?.pathToLabel)}
      />
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  item: {
    height: ROW_HEIGHT,
  },
  label: {
    lineHeight: '16px',
    fontSize: 16,
  },
}));

export default RowRenderer;
