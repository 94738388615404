import { useCallback, useContext, useMemo } from 'react';
import { FilterContext } from '../FilterContext/filterContext';
import { filterByMap } from './Filters/mapFilter';
import { MapAssetType } from './types';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { filterByColumns } from './Filters/columnFilter';
import { filterByQuickSearch } from './Filters/quickSearch';
import { useRecordType } from '../recordTypeContext';
import _ from 'lodash';
import { point } from '@turf/helpers';
import inside from '@turf/boolean-point-in-polygon';

export interface UseFilteredAssetsArgs {
  aggregateDefinitions: AggregateDefinition[];
  visibleAggregateTypesKeys: string[];
  allAssets: MapAssetType[];
}

export interface UseFilteredAssetsReturnType {
  allFilteredAssets: MapAssetType[];
  currentTypeFilteredAssets: MapAssetType[];
  getCountOfRecordType: (queryKey: string) => number;
}

export const useFilteredAssets = (props: UseFilteredAssetsArgs): UseFilteredAssetsReturnType => {
  const { aggregateDefinitions, visibleAggregateTypesKeys, allAssets } = props;

  const filters = useContext(FilterContext);
  const { selectedRecordTypeDefinition } = useRecordType();

  const getFilterStateFromKey = useCallback(
    (key: string) => {
      const aggregateName = aggregateDefinitions.find(aggregate => aggregate.queryKey === key)?.name;
      if (aggregateName) {
        return filters.getFilterState(aggregateName);
      }
      return {};
    },
    [aggregateDefinitions, filters]
  );
  const filterOnDraw = (assets: any, drawFilter: any) => {
    if (drawFilter.apply && !_.isEmpty(drawFilter.features)) {
      return assets.filter((asset: any) => {
        let canExist = false;
        drawFilter.features.forEach((item: any) => {
          if (item.bbox && !canExist) {
            if (
              asset.lon >= item.bbox[0] &&
              asset.lat >= item.bbox[1] &&
              asset.lon <= item.bbox[2] &&
              asset.lat <= item.bbox[3]
            ) {
              if (inside(point([asset.lon, asset.lat]), item)) {
                canExist = true;
              }
            }
          }
        });
        return canExist;
      });
    }
    return assets;
  };
  const getQuickSearchFromKey = useCallback(
    (key: string) => {
      const aggregateName = aggregateDefinitions.find(aggregate => aggregate.queryKey === key)?.name;
      if (aggregateName) {
        return filters.getQuickSearchForRecordType(aggregateName) ?? '';
      }
      return '';
    },
    [aggregateDefinitions, filters]
  );

  // FIRST FILTERING STEP => MAP
  // IF NO MAP FILTER PRESENT RETURN ALL ASSETS
  const mapFilteredAssets = useMemo(() => filterByMap(allAssets, filters), [filters, allAssets]);

  // SECOND STEP USE COLUMN FILTERING
  // IF NO FILTER IT WILL BE EQUAL TO THE RESULT OF FIRST STEP
  const assetValues = useMemo(() => filterByColumns(mapFilteredAssets, getFilterStateFromKey, aggregateDefinitions), [
    mapFilteredAssets,
    getFilterStateFromKey,
  ]);

  // THIRD STEP => QUICK SEARCH
  // if any quick search applies it will filter the STEP 2 RESULT
  // ELSE IT WILL BE EQUAL to step 2
  const filteredByQuickSearchAssets = useMemo(
    () => filterByQuickSearch(filterOnDraw(assetValues, filters.drawFilter), getQuickSearchFromKey),
    [assetValues, getQuickSearchFromKey, filters.drawFilter]
  );

  const currentRecordTypeAssets = useMemo(
    () => filteredByQuickSearchAssets.filter(asset => asset.recordTypeKey === selectedRecordTypeDefinition.queryKey),
    [filteredByQuickSearchAssets, selectedRecordTypeDefinition]
  );

  //For obtaining count of record type not currently selected
  const getCountOfRecordType = useCallback(
    (queryKey: string) => {
      let matchingRecords = filteredByQuickSearchAssets.filter(asset => asset.recordTypeKey === queryKey);
      return matchingRecords.length;
    },
    [filteredByQuickSearchAssets, filters.drawFilter]
  );

  // Apply aggregate visibility
  const visibleAssets = useMemo(
    () => filteredByQuickSearchAssets.filter(asset => visibleAggregateTypesKeys.includes(asset.recordTypeKey)),
    [visibleAggregateTypesKeys, filteredByQuickSearchAssets]
  );

  return {
    allFilteredAssets: visibleAssets,
    currentTypeFilteredAssets: currentRecordTypeAssets,
    getCountOfRecordType: getCountOfRecordType,
  };
};
