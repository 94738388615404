import { Card, CardContent, makeStyles, Typography, IconButton, Paper, Button, Box } from '@material-ui/core';
import AssetAttributeElement, { AssetAttribute, Asset } from './AssetAttributeElement';
import MiddleCropText from './MiddleCropText';
import AssetCardActionsRow from './AssetCardActionsRow';
import { Action } from './AssetCardAction';
import AssetCardPrimaryAction from './AssetCardPrimaryAction';
import CloseIcon from '@material-ui/icons/Close';
import { convertToCSSTransition } from './MultipleAssetCards';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { useConfig, graphqlToAssetTransform } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import PagePage from '../../Page/PagePage';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import moment from 'moment';
import {
  DATETIME_TOKEN_CONVERSION,
  getDateFormat,
  getDateTimeFormat,
  middleTruncate,
} from '@terragotech/gen5-shared-utilities';
import { useMemo } from 'react';
import { colors } from '../../../styles/theme';

const CARD_WIDTH = 360;
const ASSET_ICON_SIZE = 45;

export interface AssetCardProps {
  title: string;
  description?: string;
  attributes: readonly AssetAttribute[];
  asset: Asset | null;
  primaryAction?: Action;
  secondaryActions: Action[];
  contentOpacity?: number;
  onTitleClick?: () => void;
  onCloseClick?: () => void;
  labelValue?: string;
  assetId?: string;
  symbolKey?: string;
  aggregateType?: string;
  onSelect?: (props: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  pageName?: string;
  fullAsset?: MapAssetType;
}

const AssetCard = (props: AssetCardProps) => {
  const classes = useStyles();
  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const {
    asset,
    labelValue,
    symbolKey,
    mapEditor,
    assetId,
    selectedAggregateId,
    selectableAggregateTypes,
    pageName,
    fullAsset,
  } = props;

  const record = useMemo(
    () =>
      graphqlToAssetTransform({
        flattenRelationships: true,
        result: fullAsset as any,
        aggDef: aggregateDefinitions.find(d => d.queryKey === props.aggregateType),
      }),
    [fullAsset, aggregateDefinitions]
  );

  if (!asset) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography>No asset found</Typography>
        </CardContent>
      </Card>
    );
  }

  if (!props.title && props.secondaryActions.length === 0 && props.attributes.length === 0 && !pageName) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography>No card definition found for this asset type</Typography>
        </CardContent>
      </Card>
    );
  }

  const isSelectableType = () => {
    const definition = aggregateDefinitions.find(d => d.queryKey === props.aggregateType);
    if (_.isEmpty(selectableAggregateTypes)) {
      return true;
    }
    return _.includes(selectableAggregateTypes, definition?.name);
  };

  const isDate = (asset: Asset) => {
    let updatedAsset: Asset = { ...asset };
    const definition = aggregateDefinitions.find(d => d.queryKey === props.aggregateType);
    if (definition?.propertyDefinitions) {
      _.mapKeys(asset, function (value, key) {
        const property = _.find(definition.propertyDefinitions, function (o) {
          return o.label === key || o.field === key;
        });
        if (property && property.type === 'DateTime' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment(String(dateValue)).format(
                getDateTimeFormat(
                  defaultDateTimeFormat?.dateFormatType,
                  defaultDateTimeFormat?.dateFormat,
                  defaultDateTimeFormat?.dateSeperator,
                  defaultDateTimeFormat?.timeFormat,
                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                )
              )
            : '';
          updatedAsset[key] = dateValue;
        } else if (property && property.type === 'Date' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment
                .utc(String(dateValue))
                .format(
                  getDateFormat(
                    defaultDateTimeFormat?.dateFormatType,
                    defaultDateTimeFormat?.dateFormat,
                    defaultDateTimeFormat?.dateSeperator
                  )
                )
            : '';
          updatedAsset[key] = dateValue;
        }
      });
    }
    return updatedAsset;
  };
  return (
    <Paper elevation={2} className={classes.card}>
      <CardContent className={classes.cardContent} style={{ opacity: props.contentOpacity }}>
        <div className={classes.topRow}>
          <div className={classes.titleGroup}>
            {symbolKey && (
              <img
                className={classes.assetIcon}
                src={getSvgImageString(symbolKey, { globalScaleFactor: 1, embedFont: true })}
              />
            )}
            <Box className={classes.header}>
              <Typography className={classes.aggregateType}>{_.capitalize(props.aggregateType)}</Typography>
              <MiddleCropText
                className={mapEditor ? classes.title : classes.linkTitle}
                onClick={mapEditor ? () => {} : props.onTitleClick}
                label={!mapEditor ? labelValue : ''}
              >
                {middleTruncate(props.title, 20) as string}
              </MiddleCropText>
            </Box>
          </div>
          <IconButton classes={{ root: classes.closeButton }} onClick={props.onCloseClick}>
            <CloseIcon />
          </IconButton>
        </div>
        {props.description && (
          <Typography className={classes.description} component="div">
            {props.description}
          </Typography>
        )}
        {pageName ? (
          <PagePage target={record} page={pageName} isAssetCard fabContainerKey="AssetCard" />
        ) : (
          <div className={classes.attributes}>
            {props.attributes
              .filter(x => x.isVisible === true)
              .map(attribute => (
                <AssetAttributeElement key={attribute.key} attribute={attribute} asset={isDate(asset)} />
              ))}
          </div>
        )}
        {!mapEditor && props.primaryAction && (
          <div className={classes.primaryActionGroup}>
            <AssetCardPrimaryAction action={props.primaryAction} onClick={props.onCloseClick} />
          </div>
        )}
        {mapEditor && (
          <div className={classes.bottomRow}>
            {isSelectableType() ? (
              <Button
                variant="contained"
                disabled={assetId === selectedAggregateId || !isSelectableType()}
                onClick={() => {
                  props.onSelect &&
                    props.onSelect({
                      assetId,
                      aggregateType: props.aggregateType,
                      label: labelValue,
                    });
                }}
                color="primary"
                style={{ color: '#fff' }}
              >
                {'Select'}
              </Button>
            ) : (
              <Typography className={classes.description} component="div">
                {'Non selectable record'}
              </Typography>
            )}
          </div>
        )}
      </CardContent>
      {!!props.secondaryActions.length && (
        <AssetCardActionsRow
          actions={props.secondaryActions}
          className={classes.actionsRow}
          style={{ opacity: props.contentOpacity }}
        />
      )}
    </Paper>
  );
};

const X_BUTTON_SIZE = 48;
const CARD_PADDING = 16;

const useStyles = makeStyles(theme => ({
  card: {
    width: CARD_WIDTH,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px 0',
    overflow: 'hidden',
    backgroundColor: colors.seashell,
  },
  cardContent: {
    overflow: 'hidden',
    height: 'auto',
    margin: 0,
    padding: 0,
    transition: convertToCSSTransition(['opacity'], 0.4),
    '&:last-child': {
      padding: 0,
    },
  },
  actionsRow: {
    transition: convertToCSSTransition(['opacity'], 0.4),
    margin: 0,
    padding: 10,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(244, 244, 244)',
    padding: '15px 18px',
  },
  description: {
    fontSize: 15,
    color: colors.grayDescription,
  },
  attributes: {
    padding: '12px 18px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    gap: 3,
  },
  closeButton: {
    top: -13,
    width: 24,
    height: 24,
    '& svg': {
      color: colors.black54,
    },
  },
  bottomRow: {
    height: 'auto',
    paddingTop: 18,
    paddingBottom: 10,
  },
  linkTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '17px',
    cursor: 'pointer',
    marginTop: 2,
    letterSpacing: -0.5,
    maxWidth: 500 - X_BUTTON_SIZE - CARD_PADDING * 2,
  },
  aggregateType: {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: -0.5,
    fontFamily: 'Roboto',
  },
  title: {
    color: colors.black,
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    lineHeight: '17px',
    cursor: 'pointer',
    letterSpacing: -0.5,
    maxWidth: 500 - X_BUTTON_SIZE - CARD_PADDING * 2,
  },
  titleGroup: {
    display: 'flex',
  },
  primaryActionGroup: {
    backgroundColor: 'rgb(244, 244, 244)',
    padding: '12px 18px',
  },
  pb0: {
    paddingBottom: 0,
  },
  actionRowGroup: {
    paddingTop: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    gap: 5,
  },
  assetIcon: {
    width: ASSET_ICON_SIZE,
    height: ASSET_ICON_SIZE,
    borderRadius: ASSET_ICON_SIZE / 2,
    backgroundColor: colors.white,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
  },
}));

export default AssetCard;
