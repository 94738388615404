import { Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import magicText from 'i18next';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useMultiSelectWithProvider } from 'use-multiselect';
import ActionsMenuUI from '../components/ActionsMenuUI';
import { useAggregates } from '../contexts/AggregatesContext';
import { useAssetsTableState } from '../contexts/assetsTableStateContext';
import { useConditionalMultiActions } from '../hooks/useConditionalMultiAction';
import useMultiSelectActionsMenu, { ActionsMultiSelectMenuButton } from '../hooks/useMultiSelectActionsMenu';
import MultiActionsDialog, { MultiActionDialogProps } from './MultiActionsDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsButton: {
      height: 30,
      width: 135,
      position: 'absolute',
      top: 36,
      left: 0,
      zIndex: 5,
      background: theme.palette.primary.main,
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  })
);

export type ActionsButtonProps = {};
/**
 * ActionsButton - Handles Mouse Activity (On Click, drops down. On Click off of div, hides menu)
 *
 * Creates assetData to pass to the Actions Menu Component
 * When clicked, displays ActionsMenu
 *
 * @param selected
 */
const ActionsButton: FunctionComponent<ActionsButtonProps> = (props) => {
  const classes = useStyles();
  const { filteredCurrentTypeAssets } = useAggregates();
  const { sortAssets } = useAssetsTableState();
  const { isMultiSelectActive, getSelectedCount, stateHash, isSelected } = useMultiSelectWithProvider();
  const sortedAssets = useMemo(() => sortAssets(filteredCurrentTypeAssets), [sortAssets, filteredCurrentTypeAssets]);
  const showMenu = getSelectedCount(sortedAssets.length) > 0;
  //Actions Menu handling
  const [actionsMenuAnchor, setActionsMenuAnchor] = React.useState<Element | null>(null);
  const handleActionsMenuClose = useCallback(() => {
    setActionsMenuAnchor(null);
  }, [setActionsMenuAnchor]);

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setActionsMenuAnchor(event.currentTarget);
  };
  const data = useMemo(() => {
    if (isMultiSelectActive) {
      if (getSelectedCount(sortedAssets.length) > 0) {
        return sortedAssets.filter((asset) => isSelected(asset.id));
      }
      return [];
    }
    return [];
  }, [sortedAssets, getSelectedCount, isMultiSelectActive, isSelected, stateHash]);

  const [
    actionsMultiSelectMenuButton,
    setActionsMultiSelectMenuButton,
  ] = React.useState<ActionsMultiSelectMenuButton>();

  const [clickVersion, setClickVersion] = useState<number>(0);
  const { exceptionCount, validMultiAssets, version } = useConditionalMultiActions(
    clickVersion,
    actionsMultiSelectMenuButton
  );

  const [multiActionDialogProps, setMultiActionDialogProps] = React.useState<MultiActionDialogProps>(
    {} as MultiActionDialogProps
  );

  const actionCallback = (action: ActionsMultiSelectMenuButton) => {
    setClickVersion((pervious) => pervious + 1);
    if (action.conditionalMap) {
      setActionsMultiSelectMenuButton(action);
    } else {
      setActionsMultiSelectMenuButton(undefined);
      action.onClick();
    }
  };

  useEffect(() => {
    if (!actionsMultiSelectMenuButton || multiActionDialogProps.show) {
      return;
    }
    if (exceptionCount === 0) {
      actionsMultiSelectMenuButton.onClick();
    } else {
      setMultiActionDialogProps({
        show: true,
        validRecords: validMultiAssets,
        exceptionCount: exceptionCount,
        actionsMultiSelectMenuButton: actionsMultiSelectMenuButton,
        reset: () => {
          setMultiActionDialogProps({} as MultiActionDialogProps);
        },
      } as MultiActionDialogProps);
    }
  }, [version]);

  const { statusText, multiSelectActions, selectedCount } = useMultiSelectActionsMenu({
    closeMenu: handleActionsMenuClose,
    target: data,
    actionCallback: actionCallback,
  });

  return (
    <React.Fragment>
      <Slide direction="right" in={showMenu}>
        <div className={classes.actionsButton} onClick={handleClick}>
          <Typography style={{ fontWeight: 500, marginRight: 10 }}>
            <>{magicText.t('ACTIONS')}</>
          </Typography>
          <ArrowDropDown />
        </div>
      </Slide>
      <ActionsMenuUI
        statusText={statusText}
        selectedCount={selectedCount}
        actions={multiSelectActions}
        anchorEl={actionsMenuAnchor}
        onClose={handleActionsMenuClose}
      />
      {multiActionDialogProps.show && multiActionDialogProps.actionsMultiSelectMenuButton && (
        <MultiActionsDialog {...multiActionDialogProps} />
      )}
    </React.Fragment>
  );
};
export default ActionsButton;
