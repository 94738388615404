import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopSearchBar from './TopSearchBar';

interface MapControlsOverlayProps {
  setBounds: (bounds: any) => void;
}
const MapSearchControlOverlay: FunctionComponent<MapControlsOverlayProps> = props => {
  const { setBounds } = props;
  const classes = useStyles();
  return (
    <div className={classes.mapCenterControls}>
      <div className={classes.mapHeader}>
        <TopSearchBar setBounds={setBounds} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mapCenterControls: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  mapHeader: {
    marginTop: 10,
    top: 0,
    zIndex: 1000,
    position: 'absolute',
    justifyContent: 'center',
  },
}));

export default MapSearchControlOverlay;
