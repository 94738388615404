import { Fade, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat, getDateFormat } from '@terragotech/gen5-shared-utilities';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { AggregateDefinition, useConfig, graphqlToAssetTransform, colors } from '@terragotech/gen5-shared-components';
import { useFileViewer } from '../hooks/useFileViewer';
import { usePhotoViewer } from '../hooks/usePhotoViewer';
import { DOC_API_URL } from '../utils/docApiURL';
import { ImageWithHeaders } from './ImageWithHeaders';
import { downloadFile } from '../components/Import/FileToView';
import PagePage from './Page/PagePage';

const useStyles = makeStyles(theme => ({
  dataReviewWrapper: {
    background: colors.white,
    overflowY: 'auto',
  },
  title: {
    alignItems: 'center',
    fontWeight: 500,
    height: 40,
    display: 'flex',
    paddingLeft: 20,
    borderBottom: '#eee 1px solid',
  },
  assetDataWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  },
  attribute: {
    borderRight: '#eee 1px solid',
    borderBottom: '#eee 1px solid',
  },
  listItem: {
    paddingLeft: 20,
  },
  listItemPrimaryText: {
    color: colors.grayDescription,
    fontSize: 13,
  },
  attributeText: {
    color: colors.black,
    fontSize: 16,
    overflow: 'auto',
  },
  attributeImages: {
    display: 'flex',
    overflow: 'auto',
  },
  header: {
    height: 46,
    minHeight: 46,
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 12,
    display: 'grid',
    gridTemplate: '1fr / 200px 1fr 200px',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 18,
    textAlign: 'center',
  },
  button: {
    color: colors.white,
    '& input': {
      color: theme.palette.primary.main,
    },
    justifySelf: 'end',
  },
  photoContainer: {
    height: 80,
    width: 80,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    cursor: 'pointer',
  },
  photo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export interface assetInfo {
  id: string;
  label: string;
  assetAttributes: Record<string, unknown>;
  modifiedBy: string;
  modifiedDateTime: string;
}

interface AssetDataProps {
  assetData: assetInfo;
  attributeLabels?: Record<string, string>;
  aggregateDefinition?: AggregateDefinition | undefined;
}

const AssetData: React.FunctionComponent<AssetDataProps> = props => {
  const { assetData, attributeLabels, aggregateDefinition } = props;
  const classes = useStyles();
  const photoViewer = usePhotoViewer();
  const fileViewer = useFileViewer();
  const { defaultDateTimeFormat } = useConfig();

  const createFieldType = useCallback(() => {
    const tempFieldType: { [key: string]: string } = {};
    (aggregateDefinition?.propertyDefinitions || []).forEach(column => {
      tempFieldType[column.field] = column.type;
    });
    return tempFieldType;
  }, [aggregateDefinition]);
  const fieldType: { [key: string]: string } = createFieldType();
  // TODO: PHOTOS: Remove mock
  let assetAttributes = { ...assetData?.assetAttributes };

  const handlePhotoClick = (assetKey: string, selectedPhoto: string) => {
    photoViewer.open({
      images: assetAttributes[assetKey] as string[],
      selectedPhoto,
    });
  };

  const handleFileClick = (assetKey: string, selectedFile: { id: string; name: string }) => {
    fileViewer.open({
      images: assetAttributes[assetKey] as { id: string; name: string }[],
      selectedFile,
    });
  };

  const record = useMemo(
    () =>
      graphqlToAssetTransform({
        flattenRelationships: true,
        result: assetData as any,
        aggDef: aggregateDefinition,
      }),
    [assetData, aggregateDefinition]
  );

  return (
    <div className={classes.dataReviewWrapper}>
      <Fade in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
        {aggregateDefinition?.detailPage ? (
          <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10 }}>
            <PagePage page={aggregateDefinition.detailPage} target={record} fabContainerKey='AssetData'></PagePage>
          </div>
        ) : (
          <div>
            <div className={classes.title}>Attributes</div>
            <div>
              <div className={classes.assetDataWrapper}>
                {Object.keys(assetAttributes || {}).map((key, index) => {
                  const value = (assetAttributes[key] as unknown) as string;
                  return (
                    <div key={index} className={classes.attribute}>
                      <ListItemText
                        className={classes.listItem}
                        classes={{
                          primary: classes.listItemPrimaryText,
                          secondary:
                            fieldType[key] === 'PhotoCollection' ? classes.attributeImages : classes.attributeText,
                        }}
                        primary={(attributeLabels && attributeLabels[key]) || key}
                        secondary={
                          fieldType[key] === 'PhotoCollection'
                            ? Array.isArray(value) &&
                              value.map((item, index) => (
                                <div
                                  key={'photo' + index}
                                  className={classes.photoContainer}
                                  onClick={() => handlePhotoClick(key, item)}
                                >
                                  <ImageWithHeaders url={DOC_API_URL + item} className={classes.photo} />
                                </div>
                              ))
                            : fieldType[key] === 'FileCollection'
                            ? Array.isArray(value) &&
                              value.map((item, index) => (
                                <div
                                  onClick={() =>
                                    downloadFile(
                                      DOC_API_URL + item.id,
                                      item.name.substring(0, item.name.lastIndexOf('.')),
                                      undefined,
                                      item.name.substring(item.name.lastIndexOf('.') + 1)
                                    )
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    color: '#56CCF2',
                                  }}
                                >
                                  {item.name}
                                </div>
                              ))
                            : fieldType[key] === 'DateTime' && value
                            ? moment(String(value)).format(
                                getDateTimeFormat(
                                  defaultDateTimeFormat?.dateFormatType,
                                  defaultDateTimeFormat?.dateFormat,
                                  defaultDateTimeFormat?.dateSeperator,
                                  defaultDateTimeFormat?.timeFormat,
                                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                                )
                              )
                            : fieldType[key] === 'Date' && value
                            ? moment.utc(String(value))
                                .format(
                                  getDateFormat(
                                    defaultDateTimeFormat?.dateFormatType,
                                    defaultDateTimeFormat?.dateFormat,
                                    defaultDateTimeFormat?.dateSeperator,
                                  )
                                )
                            : value && value !== ' '
                            ? String(value)
                            : 'None'
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Fade>
    </div>
  );
};

export default AssetData;
