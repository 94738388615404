import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UsersTable from './UsersContainer/UsersTableContainer';
import { UsersTableRef } from './UsersContainer/types';
import { EditModeContext, EditModeData } from '../contexts/editModeContext';
import BottomChangesBar from '../components/StyledTable/BottomChangesBar';
import RolesTable from './RolesContainer/RolesTableContainer';
import { RolesTableRef } from './RolesContainer/types';
import { USERS_AND_ROLES_Z_INDEX } from '../utils/layers';
import BottomSelectionBar from '../components/BottomSelectionBar';
import ModalDoneCancelTitle from '../components/ModalDoneCancelTitle';
import ProceedConfirmationDialog from '../components/UsersAndRoles/ProceedConfirmationDialog';
import { ErrorDialogProvider } from '../contexts/errorDialogContext';
import { isArray } from 'lodash';

const TabPanel = (props: { children: React.ReactNode; value: string; currentTab: string }) => (
  <div>{props.value === props.currentTab && props.children}</div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
  },
}));

interface UserAndRolesProps {
  onBack: () => void;
}

const options = [
  { label: 'Users', value: 'users' },
  { label: 'Roles', value: 'roles' },
];

const BOTTOM_BAR_HEIGHT = 48;
const TOP_BAR_HEIGHT = 46;
const TABLE_HEIGHT = window.innerHeight - BOTTOM_BAR_HEIGHT - TOP_BAR_HEIGHT;

const UsersAndRoles = (props: UserAndRolesProps) => {
  const [currentTab, setCurrentTab] = useState(options[0].value);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [version, setVersion] = useState(0); //It is used to reload table
  const [isBackDialogOpen, setIsBackDialogOpen] = useState(false);

  const { setEditModeActive, editModeData, setEditModeData } = useContext(EditModeContext);

  const classes = useStyles({ isEditModeOn });

  const usersTableRef = useRef<UsersTableRef>(null);
  const rolesTableRef = useRef<RolesTableRef>(null);

  const handleUpdate = useCallback((numChanges: number, changedData?: EditModeData) => {
    setNumberOfChanges(numChanges);

    if (changedData) {
      if (editModeData) {
        if (isArray(editModeData)) {
          !editModeData.some(x => x.id === changedData.id) && setEditModeData([...editModeData, changedData]);
        } else {
          editModeData.id !== changedData.id && setEditModeData([editModeData, changedData]);
        }
      } else {
        setEditModeData([changedData]);
      }
    }
  }, [setNumberOfChanges, editModeData, setEditModeData]);

  useEffect(() => {
    handleUpdate(0);
  }, [version]); // It resets the number of changes of every table reload

  useEffect(() => {
    // TODO: Needs to be implemented along with fixes for downstream editor controls (e.g. StyledTableSelectEditor).
    setEditModeActive(isEditModeOn);
  }, [isEditModeOn, setEditModeActive]);

  const handleEditModeOn = useCallback(() => {
    setEditModeData(null);
    setIsEditModeOn(true);
  }, [setIsEditModeOn]);

  const handleTabChange = (newTabValue: string) => {
    if (!isEditModeOn) setCurrentTab(newTabValue);
  };

  const handleDiscard = () => {
    setIsEditModeOn(false);
    setVersion(version + 1);
  };

  const handleBackClick = () => {
    if (!isEditModeOn) {
      props.onBack();
    } else {
      setIsBackDialogOpen(true);
    }
  };

  const handleProceed = () => {
    handleDiscard();
    setTimeout(props.onBack, 1);
  };

  const handleRefresh = () => {
    setVersion(version + 1);
    setTimeout(() => setIsEditModeOn(false), 1);
  };

  const handleSave = () => {
    if (currentTab === 'users') {
      if (usersTableRef.current) {
        usersTableRef.current.save();
      }
    } else if (currentTab === 'roles') {
      if (rolesTableRef.current) {
        setNumberOfChanges(0);
        setIsEditModeOn(false);
        rolesTableRef.current.save();
      }
    }
  };

  return (
    <div className={classes.root}>
      <ErrorDialogProvider onConfirm={handleRefresh}>
        <ModalDoneCancelTitle title="Users administration" onDone={handleBackClick} />
        <TabPanel value={'users'} currentTab={currentTab}>
          <UsersTable
            key={version}
            ref={usersTableRef}
            height={TABLE_HEIGHT}
            onUpdate={handleUpdate}
            onEditModeOn={handleEditModeOn}
            refresh={handleRefresh}
            isEditModeOn={isEditModeOn}
          />
        </TabPanel>
        <TabPanel value={'roles'} currentTab={currentTab}>
          <RolesTable
            key={version}
            ref={rolesTableRef}
            height={TABLE_HEIGHT}
            onUpdate={handleUpdate}
            onEditModeOn={handleEditModeOn}
            refresh={handleRefresh}
            isEditModeOn={isEditModeOn}
          />
        </TabPanel>
        <BottomChangesBar
          open={isEditModeOn}
          numberOfChanges={numberOfChanges}
          singularName={currentTab === 'users' ? 'User(s)' : 'Role(s)'}
          onDiscard={handleDiscard}
          onSave={handleSave}
        />
        <BottomSelectionBar options={options} value={currentTab} onSelect={handleTabChange} />
        <ProceedConfirmationDialog
          open={isBackDialogOpen}
          onCancel={() => setIsBackDialogOpen(false)}
          onProceed={handleProceed}
        />
      </ErrorDialogProvider>
    </div>
  );
};

export default UsersAndRoles;
