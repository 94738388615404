import React, { useContext, useState, useCallback } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import builtInActions from './BuiltInActions';
import { useTableColumns } from '../contexts/TableColumnContext';
import { CommandAction, BuiltInAction, useConfig, useSpinner, AlertDialog, useAuthContext } from '@terragotech/gen5-shared-components';
import useSubmitCommands, { CommandType } from './useSubmitCommand';
import WorkflowPageContainer, { Workflow } from '../containers/WorkflowPageContainer';
import { Column } from './tableHooks/useColumns';
import { ExecutionResult } from 'graphql';
import { AssetType, MapAssetType } from '../contexts/AggregatesContext/types';
import { has } from 'lodash';

export interface ActionButton {
  label?: string;
  icon?: string;
  onClick: () => void | any;
}

interface ActionType {
  label?: string;
  icon?: string;
  action: BuiltInAction | CommandAction;
}
//TODO: allow this to take either a single assetId, or a function that returns an array. Don't allow the array to be passed as it's too slow.
interface ActionsExecutorContextProps {
  processAction: (
    action: ActionType,
    assets: MapAssetType[],
    assetId: string | string[] | (() => AssetType[]),
    onClick?: () => void,
    isCreationAction?: boolean,
    aggregateType?: string, // if not provided, current selection is used
    onFormSubmit?: ()=>void
  ) => ActionButton | null;
}

const defaultContext: ActionsExecutorContextProps = {
  processAction: () => ({ label: '', icon: '', onClick: () => {} }),
};

const ActionsExecutorContext = React.createContext<ActionsExecutorContextProps>(defaultContext);

const useActionsExecutor = () => {
  const context = useContext(ActionsExecutorContext);
  if (!context) {
    throw Error('useDialog must be within a UserProvider');
  }
  return context;
};

const processBuiltInAction = (
  baseAction: ActionType,
  columns: Column<AssetType>[],
  target?: AssetType | AssetType[] | (() => AssetType[]),
  onClickCallback?: () => void,
  toggleSpinner?: (loading: boolean) => void,
  defaultDateTimeFormat?: {
    dateFormatType?: string | undefined;
    dateFormat?: string | undefined;
    dateSeperator: string;
    timeFormat: string;
    isSwitch: boolean;
  }
) => {
  const builtInAction = builtInActions[(baseAction.action as BuiltInAction).actionName];
  if (!builtInAction) {
    return null;
  }
  const onClick = async (newValues?: any) => {
    toggleSpinner && toggleSpinner(true);
    onClickCallback && onClickCallback();
    if (builtInAction && target) {
      const originalValues = typeof target === 'function' ? target() : target;
      const validAssets = newValues || originalValues;
      builtInAction.onClick(validAssets, columns, () => toggleSpinner && toggleSpinner(false), defaultDateTimeFormat);
    }
  };
  const label = baseAction.label || builtInAction.label;
  const icon = baseAction.icon || builtInAction.icon;
  return { label, icon, onClick };
};

interface ProcessCommandArgs {
  baseAction: ActionType;
  onClickCallback?: () => void;
  assetId: string | string[] | (() => AssetType[]);
  aggregateType: string;
  setWorkflow: (workflow: Workflow) => void;
  setAggregateType: (type: string) => void;
  setAggregateId: (id: string | string[]) => void;
  submitCommands: (commands: CommandType[]) => Promise<ExecutionResult<{}> | undefined>;
  setConfirmationTextAlertObj: (status: ProcessCommandArgs | undefined) => void;
  onFormSubmit?: ()=>void
}

const showForm = (args: ProcessCommandArgs) => {
  args.setWorkflow({
    label: args.baseAction.label ?? '',
    action: args.baseAction.action as CommandAction,
    onFormSubmit: args.onFormSubmit
  });
  let values = typeof args.assetId === 'function' ? args.assetId().map(agg => agg.id) : args.assetId;
  args.setAggregateId(values);
  args.setAggregateType(args.aggregateType);
};

const executeCommand = ({ submitCommands, assetId, aggregateType, baseAction, onFormSubmit }: ProcessCommandArgs) => {
  const action = baseAction.action as CommandAction;
  const commands: CommandType[] = [];
  let values = typeof assetId === 'function' ? assetId().map(agg => agg.id) : assetId;
  const assetsIds = Array.isArray(values) ? values : [values];
  assetsIds.forEach(assetId => {
    commands.push({
      commandName: action.commandName,
      version: action.commandVersion,
      target: assetId,
      commandData: {},
      aggregateType: aggregateType,
      isSynchronous: action.isSynchronous,
      hasNoAggregateIdCommand: action.hasNoAggregateIdCommand,
    });
  });
  submitCommands(commands).then(() => {
    if(onFormSubmit){
      onFormSubmit();
    }
    //TODO: Update adminUpdate command to use graphQL response to update the data set rather than requery.
    // Temporarily disabling as no non-form commands require refreshing
    //setTimeout(() => {
    //assetInfo.refetchAll();
    //}, 500);
  });
};

const processCommand = (args: ProcessCommandArgs) => {
  const { baseAction, onClickCallback, setConfirmationTextAlertObj } = args;
  const action = baseAction.action as CommandAction;
  const onClick = (events?: Event, newValues?: Array<AssetType>) => {
    if (newValues && newValues.length > 0) {
      let ids = newValues.map(x => x.id);
      args.assetId = ids;
    }
    onClickCallback && onClickCallback();
    if (action.command) {
      showForm(args);
    } else {
      if (action?.confirmationText) {
        setConfirmationTextAlertObj(args);
      } else {
        executeCommand(args);
      }
    }
  };
  const label = baseAction.label;
  const icon = baseAction.icon;
  return { label, icon, onClick };
};

const ActionsExecutorProvider = (props: { children: React.ReactNode }) => {
  const [workflow, setWorkflow] = useState<Workflow | null>(null);
  const [aggregateId, setAggregateId] = useState<string | string[] | null>(null);
  const [isCreationAction, setIsCreationAction] = useState<boolean | undefined>(false);
  const [confirmationTextAlertObj, setConfirmationTextAlertObj] = useState<ProcessCommandArgs | undefined>();
  const { selectedRecordTypeDefinition } = useRecordType();
  const [cmdAggregateType, setCmdAggregateType] = useState<string>(selectedRecordTypeDefinition?.name);
  const { submitCommands } = useSubmitCommands();
  const { columns } = useTableColumns();
  const { toggleSpinner } = useSpinner();
  const config = useConfig();
  const { defaultDateTimeFormat } = config;
  const {username} = useAuthContext();

  const processAction = useCallback(
    (
      baseAction: ActionType,
      assets: MapAssetType[],
      assetId: string | string[] | (() => AssetType[]),
      onClickCallback?: () => void,
      isCreationAction?: boolean,
      aggregateType?: string,
      onFormSubmit?: ()=>void
    ) => {
      // Not sure how I feel about this use on __typename. Since we're asking for it specifically and it's part of the graphQL spec it should be ok though
      const _onClickCallback = () => {
        setIsCreationAction(isCreationAction);
        onClickCallback && onClickCallback();
      };
      if (baseAction?.action?.__typename === 'BuiltInAction' || has(baseAction?.action, 'actionName' as keyof BuiltInAction)) {
        // when given a function, pass it along as-is
        if (typeof assetId === 'function') {
          return processBuiltInAction(
            baseAction,
            columns,
            assetId,
            _onClickCallback,
            toggleSpinner,
            defaultDateTimeFormat
          );
        }
        // if not a function, map the assetId we were given to the actual asset stored in assetInfo
        const assetsIds = Array.isArray(assetId) ? assetId : [assetId];
        const targets = assetsIds
          .map((assetId) => assets.find((asset) => asset.id === assetId)!)
          .filter((asset) => !!asset);
        return processBuiltInAction(
          baseAction,
          columns,
          targets.length === 1 ? targets[0] : targets,
          _onClickCallback,
          toggleSpinner,
          defaultDateTimeFormat
        );
      } else if (baseAction?.action?.__typename === 'CommandReference' || has(baseAction?.action, 'commandName' as keyof CommandAction)) {
        if(username || baseAction.action.isPublic){
          return processCommand({
            baseAction,
            assetId,
            submitCommands,
            aggregateType: aggregateType ?? selectedRecordTypeDefinition.name,
            onClickCallback: _onClickCallback,
            setWorkflow,
            setAggregateId,
            setAggregateType: setCmdAggregateType,
            setConfirmationTextAlertObj: setConfirmationTextAlertObj,
            onFormSubmit
          });
        }
      }
      return null;
    },
    [columns, submitCommands, selectedRecordTypeDefinition.name, toggleSpinner]
  );
  return (
    <ActionsExecutorContext.Provider
      value={{
        processAction,
      }}
    >
      {props.children}
      {workflow && aggregateId && (
        <WorkflowPageContainer
          aggregateId={aggregateId}
          aggregateType={cmdAggregateType}
          workflow={workflow}
          creationAction={isCreationAction}
          onDonePress={() => {
            setWorkflow(null);
            if(workflow.onFormSubmit) workflow.onFormSubmit();
            // need to be done only when the workflow submitted I guess and when we leave this dialog
            // TODO - refetch data only when leaving the AssetDataEditor if changes was made or not?
            // setTimeout(() => {
            //   assetInfo.refetchAll();
            // }, 500);
          }}
          onCancelPress={() => {
            setWorkflow(null);
            setAggregateId(null);
          }}
        />
      )}
      {confirmationTextAlertObj && (
        <AlertDialog
          title={'Are you sure?'}
          okText={'Continue'}
          cancelText={'Cancel'}
          onOkPress={async () => {
            executeCommand(confirmationTextAlertObj);
            setConfirmationTextAlertObj(undefined);
          }}
          onCancelPress={() => {
            setConfirmationTextAlertObj(undefined);
          }}
        >
          {confirmationTextAlertObj.baseAction?.action?.confirmationText}
        </AlertDialog>
      )}
    </ActionsExecutorContext.Provider>
  );
};

export { ActionsExecutorProvider, useActionsExecutor };
