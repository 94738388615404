import { useRef, useEffect, useState } from 'react';
import PolygonMode from './PolygonMode';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useFilter } from '../../../contexts/FilterContext/filterContext';
import { EventHandler } from './Map';
import bbox from '@turf/bbox';
import * as MapboxGl from 'mapbox-gl';
import _ from 'lodash';
import { Feature } from 'geojson';
//@ts-ignore
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';

interface DrawFeature extends Feature {
  id: string;
}

const useDrawFeature = (mapRef: MapboxGl.Map | null) => {
  const drawRef = useRef<null | MapboxDraw>(null);
  const drawEnabledRef = useRef<boolean>(false);
  const { drawFilter, setDrawFilter, setDrawtoolFilter } = useFilter();
  const drawFilterRef = useRef<Record<string, any> | null>(null);
  const [isDrawActive, setIsDrawActive] = useState(false);
  drawFilterRef.current = drawFilter;
  drawEnabledRef.current = drawFilter.enabled;
  useEffect(() => {
    if (mapRef) {
      mapRef.on('move', (evt: EventHandler) => {
        if (drawRef.current && drawEnabledRef.current && drawRef.current.getMode() !== 'draw_polygon') {
          drawRef.current && drawRef.current.changeMode('draw_polygon');
        }
      });
      mapRef.on('draw.update', (action) => {
        if (drawFilterRef.current && !drawFilterRef.current.apply) {
          const features = drawFilterRef.current.features;
          action.features.bbox = bbox(action.features);
          features.push(action.features);
          setDrawFilter({
            ...drawFilterRef.current,
            features,
            enabled: false,
            apply: true,
          });
          setDrawtoolFilter(true);
        }
      });
      mapRef.on('polygonMode.drawActive', (action) => {
        setIsDrawActive(action.active);
      });
      const Draw = new MapboxDraw({
        modes: { ...MapboxDraw.modes, draw_polygon: PolygonMode, static: StaticMode },
        userProperties: true,
        styles: [
          {
            id: "gl-draw-line",
            type: "line",
            filter: ["all", ["==", "$type", "LineString"]],
            layout: {
              "line-cap": "round",
              "line-join": "round"
            },
            paint: {
              'line-color': ['get', 'user_color'],
              'line-width': 3,
            },
          },
          {
            id: 'gl-draw-polygon-stroke',
            type: 'line',
            filter: ['all', ['==', '$type', 'Polygon']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': ['get', 'user_color'],
              'line-width': 3,
            },
          },
          {
            'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
            'type': 'circle',
            'filter': ['all',
              ['==', 'meta', 'vertex'],
              ['==', '$type', 'Point'],
              ['!=', 'mode', 'static']
            ],
            'paint': {
              'circle-radius': 5,
              'circle-color': '#fff'
            },
          },
          {
            'id': 'gl-draw-polygon-and-line-vertex-inactive',
            'type': 'circle',
            'filter': ['all',
              ['==', 'meta', 'vertex'],
              ['==', '$type', 'Point'],
              ['!=', 'mode', 'static']
            ],
            'paint': {
              'circle-radius': 3,
              'circle-color': '#fbb03b'
            },
          },
        ],
      });
      mapRef.addControl(Draw);
      drawRef.current = Draw;
    }
  }, [mapRef]);
  useEffect(() => {
    if (drawFilter && drawFilter.enabled) {
      drawRef.current && drawRef.current.changeMode('draw_polygon');
    }
    if (mapRef && drawFilter && drawRef.current) {
      let items: any[] = [];
      drawFilter.features.forEach((feature: DrawFeature) => {
        if (feature.id && drawRef.current && !drawRef.current.get(feature.id)) {
          let ob: Record<string, any> = {};
          _.forEach(feature, (value, key) => {
            if (feature.hasOwnProperty(key) && key != 'ctx') {
              ob[key] = value;
            }
          });
          ob.geometry = {
            type: ob.type,
            coordinates: ob.coordinates,
          };
          items.push(ob);
        }
      });
      if (items.length) {
        drawRef.current.add({
          type: 'FeatureCollection',
          features: items,
        });
      }
    }
  }, [drawFilter, drawRef.current, mapRef]);

  const applyDrawFilter = () => {
    setDrawFilter({
      ...drawFilter,
      enabled: false,
      apply: true,
      features: drawFilter.features.map((item: any) => {
        return { ...item, properties: { color: '#00FFFF' } };
      }),
    });
  };
  const undoRecentChanges = () => {
    if (mapRef && drawFilter && drawRef.current) {
      const items = drawFilter.features.map((feature: Record<string, any>) => {
        if (!feature.existing) {
          return feature.id;
        }
      });
      if (items.length) {
        drawRef.current.delete(items);
        drawRef.current.changeMode('static');
      }
      const features = drawFilter.features.filter(
        (item: Record<string, any>) => !(items || []).find((id: string) => id === item.id)
      );
      setDrawFilter({ ...drawFilter, features, enabled: false, apply: true });
    }
  };

  const clearAll = () => {
    if (mapRef && drawFilter && drawRef.current) {
      drawRef.current.deleteAll();
      setDrawFilter({ ...drawFilter, features: [] });
    }
    setDrawtoolFilter(false);
  };
  return { applyDrawFilter, undoRecentChanges, clearAll, isDrawActive };
};

export default useDrawFeature;
