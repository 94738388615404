import React, { useMemo, useCallback } from 'react';
import AddNewDialog, { AddNewDialogProps, AddNewFormField } from './AddNewDialog';
import { Role, UserWithoutId } from '../../containers/UsersContainer/types';
import magicText from 'i18next';

export type AddNewRoleDialogProps = Omit<AddNewDialogProps, 'fields' | 'title' | 'message' | 'onDone'> & {
  roles: readonly Role[];
  onDone: (user: UserWithoutId & { password: string }) => void;
};

export interface NewUserFormData {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  roles: { label: string; value: string }[];
  password: string;
  adminPermissions: { label: string; value: string }[];
}

const AddNewUserDialog = (props: AddNewRoleDialogProps) => {
  const { roles, onDone } = props;

  const handleDone = useCallback(
    (_data: unknown) => {
      const data = _data as NewUserFormData;
      const user: UserWithoutId & { password: string } = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        // Note that we are using the user's email as the username, which may not work for all user pools
        username: data.email,
        roles: data.roles
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map(({ value }) => roles.find((role) => role.id === parseInt(value))!)
          .filter((role) => !!role),
        password: data.password,
        enabled: true,
        adminPermissions: {
          isUserAdmin: false,
          isRoleAndDataAdmin: false,
          isAnalyticsUser: false,
          isMapServicesUser:false
        },
      };
      onDone(user);
    },
    [roles, onDone]
  );

  const fields: AddNewFormField[] = useMemo(
    () => [
      { type: 'email', label: 'Email', required: true, name: 'email' },
      { type: 'text', label: 'First Name', required: true, name: 'firstName' },
      { type: 'text', label: 'Last Name', required: true, name: 'lastName' },
      {
        type: 'selectModal',
        label: 'Roles',
        values: roles.map((role) => ({
          label: role.name,
          value: String(role.id),
        })),
        name: 'roles',
        allSelectedMessage: magicText.t('UsersAdministration.allRoles'),
      },
    ],
    [roles]
  );
  return (
    <AddNewDialog {...props} onDone={handleDone} title="Add User" message="REQUIRED USER INFORMATION" fields={fields} />
  );
};

export default AddNewUserDialog;
