import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import React, { createContext, useContext, useMemo } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';

interface BaseProjectType {
  id: string;
  nodeId: string;
  label: string;
}
type QueryProjectType = BaseProjectType & {
  [index: string]: { totalCount: number };
};
type ProjectsConnection = { nodes: Array<QueryProjectType> };

export type ProjectType = BaseProjectType & {
  [index: string]: string | number;
  totalCount: number;
};
interface FolderType {
  id: string;
  nodeId: string;
  label: string;
  parentFolderId?: string;
  projects: Array<ProjectType>;
  subFolders: Array<FolderType>;
}
interface ProjectContextType {
  projects: Array<ProjectType>;
  folders: Array<FolderType>;
  refetch: () => void;
}

interface FolderRow extends FolderType {
  parentFolderId: string;
  projectsConnection: ProjectsConnection;
}

const ProjectsContext = createContext<ProjectContextType | undefined>(undefined);
/**
 * Converts the output of a graphql query into a format better recognized by the existing components
 * @param connection a graphql connection
 */
const projectsConnectionToProjectArray = (connection: ProjectsConnection): Array<ProjectType> => {
  const outArray: Array<ProjectType> = [];
  connection.nodes.forEach(node => {
    const project: ProjectType = { id: node.id, nodeId: node.nodeId, label: node.label, totalCount: 0 };
    //everything that has a total count should be added ass <name>_count
    let totalCount = 0;
    Object.keys(node).forEach(key => {
      if (typeof node[key] === 'object' && 'totalCount' in node[key]) {
        project[`${key.replace('Connection', '')}_count`] = node[key].totalCount;
        totalCount += node[key].totalCount;
      }
    });
    project.totalCount = totalCount;
    outArray.push(project);
  });
  return outArray;
};

const findSubFolders = (folderId: any, folderArray: Array<any>) => {
  return folderArray.filter(folder => folder.parentFolderId === folderId);
};

//The projects provider will keep track of the list of projects and folders available from the server
//IT will download projects from the server in 100 project chunks
const ProjectsProvider: React.FC<{ children?: React.ReactNode }> = props => {
  const { aggregateDefinitions } = useConfig();
  // use the aggregate definition to figure out if there are folders available
  const hasFolders = aggregateDefinitions.findIndex(def => def.name === 'Folder') >= 0;
  const projectAgg = aggregateDefinitions.find(def => def.name === 'Project');

  const childPropertyDefinitions = projectAgg?.propertyDefinitions.filter(
    prop => prop.relationshipType === 'ONE_TO_MANY' || prop.relationshipType === 'ONE-TO-MANY'
  );

  //build a project fragment
  const projectFragment = gql`
    fragment ProjectFragment on Project {
      nodeId
      label
      id
      ${childPropertyDefinitions?.map(prop => `${prop.field}Connection{ totalCount}`) || ''}
    }
  `;

  const query = useMemo(() => {
    const query = (() => {
      if (hasFolders) {
        return 'folders{ label id nodeId parentFolderId parentFolder { title } projectsConnection { nodes{ ...ProjectFragment } } }';
      } else return 'projectsConnection{nodes{...ProjectFragment}}';
    })();
    return gql`query { ${query} } ${projectFragment}`;
  }, [hasFolders, projectFragment]);

  //query
  const { data, refetch } = useQuery<
    | {
        folders: Array<FolderRow>;
      }
    | { projectsConnection: ProjectsConnection }
  >(query, {
    fetchPolicy: 'network-only',
  });

  const value = useMemo(() => {
    let projects: Array<ProjectType> = [];
    const folders: Array<FolderType> = [];
    if (hasFolders && data && 'folders' in data) {
      data.folders.forEach(folder => {
        const newProjects = projectsConnectionToProjectArray(folder.projectsConnection);
        projects = [...projects, ...newProjects];
        folders.push({
          id: folder.id,
          label: folder.label,
          nodeId: folder.nodeId,
          parentFolderId: folder?.parentFolderId,
          projects: newProjects,
          subFolders: findSubFolders(folder.id, data.folders),
        });
      });
    } else if (data && 'projectsConnection' in data) {
      projects = projectsConnectionToProjectArray(data.projectsConnection);
    }
    return {
      folders: folders,
      projects: projects,
      refetch: refetch,
    };
  }, [data, hasFolders]);

  return <ProjectsContext.Provider value={value} {...props} />;
};

const useProjects = (): ProjectContextType => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjects must be used within an AssetProvider');
  }

  return context;
};

export { ProjectsProvider, useProjects };
