import { useMemo, useState } from 'react';
import magicText from 'i18next';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { CommandAction } from '@terragotech/gen5-shared-components';
import { Button, Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import _ from 'lodash';
import ImportDropZone from './ImportDropZone';
import TGAlertDialogWithIcon from '../TGAlertDialogWithIcon';
import { FileWithPath } from 'react-dropzone';
import { UseConditionalImport } from '../../hooks/useConditionalImports';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
  },
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '23px',
  },
}));

interface Props {
  onBack?: () => void;
  downloadFile: (fileUrl: string, fileName: string) => Promise<void>;
  picked: UseConditionalImport | undefined;
  buttons: UseConditionalImport[] | undefined;
  setPicked: (x: UseConditionalImport) => void;
  index: string;
  setIndex: (x: string) => void;
  downloadTemplate: (aggregateate: string, commandName: string, version: number, filename: string) => Promise<void>;
  isSelected: boolean;
  selectedFile: FileWithPath | undefined;
  changeHandler: (file: FileWithPath[]) => void;
  uploadFile: (fileName: string) => void;
  clearSelection: () => void;
}

const ImportFileScreen = (props: Props) => {
  const {
    picked,
    buttons,
    downloadTemplate,
    setPicked,
    index,
    setIndex,
    isSelected,
    selectedFile,
    clearSelection,
    changeHandler,
    uploadFile,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const WhiteTextButton = withStyles({
    root: {
      color: 'white',
    },
  })(Button);

  const dropZoneDisabled = useMemo(() => {
    return _.isEmpty(index);
  }, [index]);

  const dropZoneTooltip = useMemo(() => {
    return dropZoneDisabled ? <span style={{fontSize: 20}}>Please select an Import Type.</span> : "";
  }, [dropZoneDisabled]);

  return (
    <div className={classes.body}>
      <div style={{ flex: 1 }}></div>
      <div style={{ flex: 4 }}>
        <Typography
          style={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 40,
            lineHeight: '47px',
            color: theme.palette.secondary.main || '#56CCF2',
          }}
        >
          Import
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 20,
            marginBottom: 25,
          }}
        >
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: 24,
              lineHeight: '28px',
              color: '#656565',
              marginRight: 20,
            }}
          >
            Import Type
          </Typography>
          {index !== '' && !picked?.isIntegrationImport && (
            <Typography
              style={{
                cursor: 'pointer',
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '19px',
                color: theme.palette.primary.main || '#56CCF2',
                borderBottom: `1px solid ${theme.palette.primary.main || '#56CCF2'}`,
              }}
              onClick={() => {
                if (picked)
                  downloadTemplate(
                    picked.aggregate.name,
                    (picked.action as CommandAction).commandName,
                    (picked.action as CommandAction).commandVersion,
                    picked.label + ' - Template'
                  );
              }}
            >
              Download Template
            </Typography>
          )}
        </div>
        {buttons && (
          <RadioGroup
            value={index}
            defaultValue={0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = buttons[(e.target.value as unknown) as number];
              setPicked(value);
              setIndex(e.target.value as string);
              clearSelection();
            }}
          >
            {buttons
              .filter((opt: UseConditionalImport) => opt.state === 'enabled')
              .map((option: UseConditionalImport, idx: number) => (
                <FormControlLabel
                  value={String(idx)}
                  control={<Radio color={'primary'} />}
                  label={<Typography className={classes.radioText}>{option.label}</Typography>}
                  key={idx}
                />
              ))}
          </RadioGroup>
        )}
      </div>
      <div style={{ flex: 1 }}></div>
      <div style={{ flex: 11, display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ImportDropZone
            changeHandler={changeHandler}
            isSelected={isSelected}
            selectedFile={selectedFile}
            clearSelection={clearSelection}
            disabled={dropZoneDisabled}
            tooltip={dropZoneTooltip}
            selected={picked || undefined}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {isSelected && index !== '' && (
            <WhiteTextButton onClick={() => setOpenDialog(true)} variant="contained" color="primary">
              UPLOAD
            </WhiteTextButton>
          )}
        </div>
      </div>
      <div style={{ flex: 3 }}></div>
      <TGAlertDialogWithIcon
        title="Confirm"
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={() => {
          if (selectedFile?.path) uploadFile(selectedFile.path);
        }}
        bodyText={magicText.t('import.upload')}
        bodySubtext={magicText.t('import.uploadSubtext')}
        confirmButtonTitle={'Proceed'}
        theme={theme}
      />
    </div>
  );
};

export default ImportFileScreen;
