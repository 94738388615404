import magicText from 'i18next';
import { Role, User } from './types';
import { Column } from '../../hooks/tableHooks/useColumns';
import React from 'react';
import StyledTableSelectEditor from '../../components/StyledTable/Editors/StyledTableSelectEditor';
import AdminPermissionsEditorModal from './AdminPermissionsEditorModal';
import { permissionLabel } from '@terragotech/gen5-shared-utilities';

const roleMapper = (_role: unknown) => {
  const role = _role as Role;
  return { value: role.id.toString(), name: role.name };
};

export const generateColumns: (
  roles: readonly Role[],
  numberOfUsersWithAnalyticsPermissions: number
) => Column<User>[] = (roles, numberOfUsersWithAnalyticsPermissions) => [
  { name: 'Email', key: 'email' },
  { name: 'First Name', key: 'firstName' },
  { name: 'Last Name', key: 'lastName' },
  { name: 'Username', key: 'username', editable: false },
  {
    name: 'Roles',
    key: 'roles',
    cellRenderer: ({ row }) => (
      <div>
        <>
          {row.roles.length > 0 && row.roles.length === roles.length
            ? magicText.t('UsersAdministration.allRoles')
            : row.roles.join(', ')}
        </>
      </div>
    ),
    cellEditor: React.forwardRef((editorProps, ref) => (
      <StyledTableSelectEditor {...editorProps} ref={ref} mapper={roleMapper} lookup={[...roles]} label="Select role" />
    )),
    filterInputOptions: {
      valueDelimiter: ','
    }
  },
  {
    name: 'Permissions',
    key: 'adminPermissions',
    //
    cellRenderer: ({ row }) => <div>{permissionLabel(row.adminPermissions).join(', ')}</div>,
    cellEditor: React.forwardRef((editorProps, ref) => (
      <AdminPermissionsEditorModal
        {...editorProps}
        numberOfUsersWithAnalyticsPermissions={numberOfUsersWithAnalyticsPermissions}
      />
    )),
    filterInputOptions: {
      valueDelimiter: ', '
    }
  },
];
