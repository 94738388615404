import React from 'react';
import { withStyles, Menu, MenuItem } from '@material-ui/core';
import { colors } from '../../styles/theme';
import ImportTriangle from '../../images/importTitleTriangle.svg';
import toggleMenuIcon from '../../images/importPage/toggleMenuIcon.svg';

export interface ImportTitleWithToggleProps {
  title: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
  onToggle?: () => void;
  classes: Record<'DialogTitle' | 'component', string>;
  fileIdToView?: boolean;
  setFileIdToView?: (x: string | undefined) => void;
}

const ImportTitleWithToggle = withStyles((theme) => ({
  DialogTitle: {
    backgroundColor: theme.palette.secondary.main,
    color: colors.white,
    height: 46,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  component: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}))((props: ImportTitleWithToggleProps) => {
  const { classes, title, onToggle, fileIdToView, setFileIdToView } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchAndClose = () => {
    setAnchorEl(null);
    setFileIdToView && setFileIdToView(undefined);
    onToggle && onToggle();
  };

  return (
    <div className={classes.DialogTitle}>
      <div className={classes.component}>{props.leftComponent}</div>
      <div onClick={handleClick} style={onToggle && { cursor: 'pointer' }}>
        {title}
        <img src={ImportTriangle} alt="Triangle" style={{ position: 'relative', top: '-3px', left: '4px' }} />
      </div>
      <Menu
        elevation={3}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {title === 'Import' ? (
          <MenuItem onClick={handleSwitchAndClose}>
            <img src={toggleMenuIcon} alt="Triangle" style={{ marginRight: 6, marginBottom: 2 }} />
            Import History
          </MenuItem>
        ) : fileIdToView && setFileIdToView ? (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                setFileIdToView(undefined);
              }}
            >
              <img src={toggleMenuIcon} alt="Triangle" style={{ marginRight: 6, marginBottom: 2 }} />
              Import History
            </MenuItem>
            <MenuItem onClick={handleSwitchAndClose}>
              <img src={toggleMenuIcon} alt="Triangle" style={{ marginRight: 6, marginBottom: 2 }} />
              Import
            </MenuItem>
          </div>
        ) : (
          <MenuItem onClick={handleSwitchAndClose}>
            <img src={toggleMenuIcon} alt="Triangle" style={{ marginRight: 6, marginBottom: 2 }} />
            Import
          </MenuItem>
        )}
      </Menu>
      <div className={classes.component}>{props.rightComponent}</div>
    </div>
  );
});

export default ImportTitleWithToggle;
