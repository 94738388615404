import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Input, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import * as changeCase from 'change-case';
import magicText from 'i18next';

interface SearchInputProps extends WithStyles<typeof styles> {
  name?: string;
  value: string;
  setValue: (value: string) => void;
}

const SearchInput = ({ classes, name, value, setValue }: SearchInputProps) => (
  <div className={classes.assetSearchBar}>
    <Input
      startAdornment={
        <InputAdornment position="start">
          <Search className={classes.searchIcon} />
        </InputAdornment>
      }
      placeholder={
        magicText.t('Search') + ' ' + changeCase.capitalCase(name ?? magicText.t('SearchBar.placeholderName'))
      }
      value={value}
      className={classes.input}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      }}
    />
  </div>
);

const styles = (theme: Theme) =>
  createStyles({
    assetSearchBar: {
      backgroundColor: '#f8f8f8',
      color: theme.palette.grey[200],
      padding: '4px 0px 3px 15px',
      display: 'inline-flex',
      flexGrow: 1,
      borderLeft: '1px solid #eee',
      borderRight: '1px solid #eee',
    },
    searchIcon: {
      height: 20,
      width: 20,
      color: theme.palette.grey[400],
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 14,
    },
  });

export default withStyles(styles)(SearchInput);
