import { useCallback, useRef } from 'react';

export const useMultiClick = (callback: () => void, num: number = 2) => {
  const counterRef = useRef<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onClick = useCallback(() => {
    clearTimeout(timerRef!.current!);
    if (++counterRef.current < num) {
      //@ts-ignore
      timerRef.current = setTimeout(() => {
        clearTimeout(timerRef!.current!);
        timerRef.current = null;
        counterRef.current = 0;
      }, 500);
    } else {
      timerRef.current = null;
      counterRef.current = 0;
      callback();
    }
  }, [callback, num]);

  return [onClick];
};
