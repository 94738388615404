import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface TGBreadcrumbProps {
  crumbs: { label: string; onClick: () => void }[];
}

const handleClick = (crumb: { label: string; onClick: () => void }) => (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  event.preventDefault();
  crumb.onClick();
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 24,
    overflowY: 'auto',
  },
}));

const TGBreadcrumb: React.FC<TGBreadcrumbProps> = (props) => {
  const { crumbs } = props;
  const classes = useStyles();
  return (
    <Breadcrumbs maxItems={5} aria-label="breadcrumb" className={classes.root}>
      {crumbs.map((val, index) => {
        return index === crumbs.length - 1 ? (
          <Typography color="textPrimary" key={index}>
            {val.label}
          </Typography>
        ) : (
          <Link color={'inherit'} href="#" onClick={handleClick(val)} key={index}>
            {val.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default TGBreadcrumb;
