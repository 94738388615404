import React, { FunctionComponent, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import SideBarInner from './SideBarInner';
import FoldersListContainer from '../containers/FoldersListContainer';
import { useSearch } from '@terragotech/gen5-shared-components';

const styles = (theme: Theme) =>
  createStyles({
    sideBarWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.palette.grey[50],
    },
  });

interface SideBarProps extends WithStyles<typeof styles> {}
type sortTypes = 'name' | 'date' | 'assetCount';

/**
 * Sidebar - contains states for
 *        -searchText, sortValue, and a scrolling boolean
 *
 * Sidebar passes handlers and state values to two Children
 *    SideBarInner - contains input for search
 *    FoldersListContainer -  sorts and displays matching projects based on the search input
 */

const SideBar: FunctionComponent<SideBarProps> = React.forwardRef((props, ref) => {
  const { classes } = props;

  //let sortIcon = useRef(null);
  //let [menuShowing, setMenuShowing] = useState(false);
  let [sortValue] = useState<sortTypes>('name');
  /*const setSort = (newSort: sortTypes) => {
    setMenuShowing(false);
    setSortValue(newSort);
  };*/
  const { searchText, handleSearchText } = useSearch('');
  const [scrolling, setScrolling] = useState(false);

  return (
    <div className={classes.sideBarWrapper}>
      <SideBarInner handleSearchText={handleSearchText} scrolling={scrolling} />
      <FoldersListContainer
        searchText={searchText}
        sortValue={sortValue}
        onSearchChange={handleSearchText}
        setScrolling={setScrolling}
        scrolling={scrolling}
      />
    </div>
  );
});

export default withStyles(styles)(SideBar);
