import React, { useMemo } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import magicText from 'i18next';
import { colors } from '../../../../styles/theme';

export interface TGOptionSelectorModalProps {
  multiSelect: boolean;
  state: unknown[];
  isNothingSelected: boolean;
  totalCount: number;
  handleClearAll: () => void;
  selectAllAssets: () => void;
}

const TGOptionLazyModalActions = (props: TGOptionSelectorModalProps) => {
  const { multiSelect, state, isNothingSelected, totalCount, handleClearAll, selectAllAssets } = props;

  const classes = useStyles();

  const numberOfElements = useMemo(() => {
    if (isNothingSelected) {
      return totalCount;
    }
    return state.length;
  }, [isNothingSelected, totalCount, state]);

  const button = useMemo(() => {
    if (multiSelect) {
      if (isNothingSelected) {
        return (
          <Button onClick={selectAllAssets}>
            <>{magicText.t('Common.selectAllLabel')}</>
          </Button>
        );
      } else {
        return (
          <Button onClick={handleClearAll}>
            <>{magicText.t('Common.deselectAllLabel')}</>
          </Button>
        );
      }
    } else {
      return (
        <Button onClick={handleClearAll}>
          <>{magicText.t('Common.clearSelectionLabel')}</>
        </Button>
      );
    }
  }, [multiSelect, isNothingSelected, handleClearAll, selectAllAssets]);

  // Brackets are inside spans in order to center them vertically with the text (Roboto by default does not do that)
  if (multiSelect) {
    return (
      <div className={classes.actionsContainer}>
        {button}
        <Typography className={classes.numberOfElements}>
          <span>(</span>
          {numberOfElements}
          <span>)</span>
        </Typography>
      </div>
    );
  }

  return button;
};

const useStyles = makeStyles(() => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  numberOfElements: {
    color: colors.grayDescription,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      height: 21.5,
    },
  },
}));

export default TGOptionLazyModalActions;
