import { createContext, FunctionComponent, useContext, useMemo, useState } from 'react';
import Pusher, { Channel } from 'pusher-js';
import { useConfig } from '@terragotech/gen5-shared-components';

export interface PusherInfo {
  subscribe: (channel: string, event: string, callback: Function) => void;
  unSubscribe: (channel: string, event: string) => void;
}
const defaultPusher: PusherInfo = {
  subscribe: () => { },
  unSubscribe: () => { },
};
export const PusherContext = createContext<PusherInfo>(defaultPusher);
export const PusherProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const config = useConfig();
  const [subscriptions, setSubscriptions] = useState<{
    [chan: string]: {
      pusher: Channel;
      events: string[];
    };
  }>({});
  const pusher = useMemo(() => {
    const integrations = config.integrations;
    if (integrations && integrations.pusherKey && integrations.pusherCluster)
      return new Pusher(integrations.pusherKey, {
        cluster: integrations.pusherCluster,
      })
  }, [config]);
  // use memo since I create a new object for now to avoid rerendering
  // TODO: select the first aggreagte type Asset might not exist now
  const configuration = useMemo(() => {
    const integrations = config.integrations;
    if (pusher && integrations) {
      return {
        subscribe: (channel: string, event: string, callback: Function) => {
          channel = integrations.pusherAppId + channel;
          const newCallback = (message: any) => {
            return callback(message);
          }
          if (!subscriptions[channel]) {
            subscriptions[channel] = {
              pusher: pusher.subscribe(channel),
              events: [],
            };
          }
          if (subscriptions[channel].events.includes(event)) {
            subscriptions[channel].pusher.unbind(event);
            subscriptions[channel].pusher.bind(event, newCallback);
          } else {
            subscriptions[channel].pusher.bind(event, newCallback);
            subscriptions[channel].events.push(event);
          }
          setSubscriptions({ ...subscriptions })
        },
        unSubscribe: (channel: string, event: string) => {
          channel = integrations.pusherAppId + channel;
          if (!subscriptions[channel]) {
            return;
          }
          if (subscriptions[channel].events.includes(event)) {
            subscriptions[channel].pusher.unbind(event);
            subscriptions[channel].events = subscriptions[channel].events.filter((e) => e !== event);
          }
          setSubscriptions({ ...subscriptions })
        }
      };
    } else return null;
  }, [config, pusher]);

  return (
    <PusherContext.Provider value={configuration || { subscribe: () => { }, unSubscribe: () => { } }}>
      {props.children}
    </PusherContext.Provider>
  );
};
export const usePusher = () => useContext(PusherContext);
