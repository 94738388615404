import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { filterNonMapAggregates, filterMapServiceAssets, reduceMapServicesToLayers } from './aggregateUtils';
import { AggregatesContextType } from './types';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useAssetsLoader } from './useAssetsLoader';
import { useVisibleAggregatesState } from './useVisibleAggregatesState';
import { useAssetsCount } from './useAssetsCount';
import { useFilteredAssets } from './useFilteredAssets';
import { usePusher } from '../pusherContext';
import _ from 'lodash';
import { useSelectedProject } from '../selectedProjectContext';
import { useMapServiceState } from './useMapServiceState';
import { pusherChannelNotificationName, REFERENCE_TYPE_AGGREGATE } from '@terragotech/gen5-shared-utilities';

const DELAY_BETWEEN_REFRESH_SECONDS = 10;
const AggregatesContext = createContext<AggregatesContextType | undefined>(undefined);

export const AggregatesContextProvider: React.FC<{ children?: React.ReactNode }> = (props: any) => {
  const { aggregateDefinitions } = useConfig();
  const { subscribe, unSubscribe } = usePusher();
  const { selectedProjects } = useSelectedProject();
  const mapAggregateDefinitions = useMemo(() => filterNonMapAggregates(aggregateDefinitions), [aggregateDefinitions]);

  const {
    isDataLoading,
    assets,
    refetchAll,
    refetchSince,
    deleteMultiple,
    deleteMultipleFromProjects,
  } = useAssetsLoader({
    aggregateDefinitions: mapAggregateDefinitions,
  });

  const dataLoadingRef = useRef(isDataLoading);
  useEffect(()=>{
    dataLoadingRef.current = isDataLoading;
    if(!isDataLoading){
      checkRefresh();
    }
  },[isDataLoading])

  const { allAssetsCount, currentTypeAssetsCount } = useAssetsCount({
    assets,
  });

  const {
    visibleAggregateTypesKeys,
    visibleAggregateTypesNames,
    setVisibleAggregateTypesNames,
  } = useVisibleAggregatesState({
    aggregateDefinitions: mapAggregateDefinitions,
  });

  const { currentTypeFilteredAssets, allFilteredAssets, getCountOfRecordType } = useFilteredAssets({
    aggregateDefinitions: mapAggregateDefinitions,
    visibleAggregateTypesKeys,
    allAssets: assets,
  });

  const mapServices = useMemo(() => filterMapServiceAssets(assets), [assets]);
  const mapServiceLayers = useMemo(() => reduceMapServicesToLayers(mapServices), [mapServices]);

  const { visibleMapServiceKeys, visibleMapServiceNames, setVisibleMapServiceKeys } = useMapServiceState({
    mapServices: mapServices,
    mapServiceLayers: mapServiceLayers,
  });

  const toRefresh = useRef<{
    [proj: string]: string[];
  }>({});
  const lastTimestamp = useRef<number>(Date.now());
  const timeoutRef = useRef<{ timeout: NodeJS.Timeout | null }>({ timeout: null });
  const projectSubscriptions = useRef<string[]>([]);

  const executeRefresh = useCallback(() => {
    if(!dataLoadingRef.current){
      const sinceTime = new Date(lastTimestamp.current).toJSON();
      lastTimestamp.current = Date.now();
      if (timeoutRef.current.timeout) {
        clearTimeout(timeoutRef.current.timeout);
        timeoutRef.current.timeout = null;
      }
      for (const proj in toRefresh.current) {
        for (const type of toRefresh.current[proj]) {
          const isReferenceType = !!aggregateDefinitions.find(x => x.name === type && x.isReferenceType);
          refetchSince(type, sinceTime, proj, isReferenceType);
        }
      }
      toRefresh.current = {};
      lastTimestamp.current = Date.now();
      checkRefresh();
    }
  }, [refetchSince, projectSubscriptions]);

  const checkRefresh = useCallback(() => {
    const total = Object.keys(toRefresh.current).length;
    if (
      Date.now() - lastTimestamp.current > DELAY_BETWEEN_REFRESH_SECONDS * 1000 &&
      !timeoutRef.current.timeout &&
      total
    ) {
      executeRefresh();
    } else {
      if (!timeoutRef.current.timeout && total) {
        timeoutRef.current.timeout = setTimeout(() => {
          executeRefresh();
        }, DELAY_BETWEEN_REFRESH_SECONDS * 1000 - Date.now() + lastTimestamp.current);
      }
    }
  }, [toRefresh, lastTimestamp, executeRefresh, timeoutRef]);

  const updateData = useCallback(
    (m: string[], proj: string) => {
      toRefresh.current[proj] = _.union(toRefresh.current[proj], m);
      checkRefresh();
    },
    [toRefresh, checkRefresh]
  );

  useEffect(() => {
    subscribe(pusherChannelNotificationName.AGGREGATE, 'delete', (m: any) => {
      console.log(
        'aggregate delete',
        Object.keys(m),
        Object.keys(m).map(k => m[k].length)
      );
      Object.keys(m).forEach(k => {
        deleteMultiple(k, m[k]);
      });
    });
  }, [subscribe, deleteMultiple]);

  useEffect(() => {
    subscribe(pusherChannelNotificationName.PROJECT_CHANGED, 'change', (m: any) => {
      console.log('project change');
      const toDelete: {
        [proj: string]: string[];
      } = {};
      for (const item of m) {
        const [id, from, to] = item.split(',');
        if (id && from && to) {
          if (selectedProjects.includes(from) && !selectedProjects.includes(to)) {
            if (toDelete[from]) {
              toDelete[from].push(id);
            } else {
              toDelete[from] = [id];
            }
          }
        }
      }
      if (Object.keys(toDelete).length) {
        deleteMultipleFromProjects(toDelete);
      }
    });
  }, [subscribe, deleteMultipleFromProjects, selectedProjects]);

  useEffect(() => {
    const selectedProjectsWithAdhoc = [...selectedProjects, ...[REFERENCE_TYPE_AGGREGATE]];

    const toUnsub = _.without(projectSubscriptions.current, ...selectedProjectsWithAdhoc);
    const toSub = _.without(selectedProjectsWithAdhoc, ...projectSubscriptions.current);
    for (const proj of toSub) {
      subscribe(pusherChannelNotificationName.AGGREGATE, proj.toUpperCase() + '-update', (m: any) => {
        console.log('aggregate update ' + proj, m);
        updateData(m, proj);
      });
    }
    for (const proj of toUnsub) {
      unSubscribe('aggregates', proj.toUpperCase() + '-update');
    }
    projectSubscriptions.current = _.cloneDeep(selectedProjects);
  }, [selectedProjects, subscribe, unSubscribe, projectSubscriptions, updateData]);

  const value: AggregatesContextType = useMemo(() => {
    return {
      filteredAssets: allFilteredAssets,
      filteredCurrentTypeAssets: currentTypeFilteredAssets,
      getCountOfRecordType: getCountOfRecordType,
      assets: assets,
      loading: isDataLoading,
      refetchAll,
      setVisibleAggregateTypesNames,
      visibleAggregateTypesNames,
      assetsCount: allAssetsCount,
      currentTypeAssetsCount,
      mapServices: mapServices,
      mapServiceLayers: mapServiceLayers,
      visibleMapServiceKeys,
      visibleMapServiceNames,
      setVisibleMapServiceKeys,
    };
  }, [
    allFilteredAssets,
    currentTypeFilteredAssets,
    getCountOfRecordType,
    assets,
    isDataLoading,
    refetchAll,
    setVisibleAggregateTypesNames,
    visibleAggregateTypesNames,
    allAssetsCount,
    currentTypeAssetsCount,
    mapServices,
    mapServiceLayers,
    visibleMapServiceKeys,
    visibleMapServiceNames,
    setVisibleMapServiceKeys,
  ]);

  return <AggregatesContext.Provider value={value} {...props} />;
};

export const useAggregates = (): AggregatesContextType => {
  const context = useContext(AggregatesContext);
  if (!context) {
    throw new Error('useAggregates must be used within an MapAssetProvider');
  }

  return context;
};
