import { Checkbox, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as NotPinnedIcon } from '../../../images/notPinnedIcon.svg';
import { ReactComponent as PinnedIcon } from '../../../images/pinnedIcon.svg';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import clsx from 'clsx';

export interface ColumnFilterListElementProps extends WithStyles<typeof styles> {
  column: Column<AssetType>;
  togglePinned: (key: string) => void;
  index: number;
  isSelected: (key: string) => boolean;
  setIsSelected: (key: string, isSelected: boolean) => void;
}

const ColumnFilterListElement: FunctionComponent<ColumnFilterListElementProps> = (props) => {
  const { classes, column, index, isSelected, setIsSelected, togglePinned } = props;

  return (
    <Draggable draggableId={`column-${column.key}`} index={index}>
      {(provided, snapshot) => (
        <div
          className={clsx(classes.filterItemOption, snapshot.isDragging && classes.filterItemOptionDragging)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={`${classes.dragHandle} ${classes.dragHandlePinned}`} />
          <Checkbox
            checked={isSelected(column.key)}
            onChange={(e) => {
              setIsSelected(column.key, e.target.checked);
            }}
          />
          {column.sticky && <PinnedIcon onClick={() => togglePinned(column.key)} className={classes.pinIcon} />}
          {!column.sticky && <NotPinnedIcon onClick={() => togglePinned(column.key)} className={classes.pinIcon} />}
          {column.name}
        </div>
      )}
    </Draggable>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    dragHandle: {
      display: 'inline-block',
      borderTop: `2px solid ${theme.palette.grey[400]}`,
      borderBottom: `2px solid ${theme.palette.grey[400]}`,
      width: '15px',
      height: '5px',
      marginLeft: 20,
      marginRight: 4,
    },
    dragHandlePinned: {
      marginLeft: 10,
    },
    filterItemOption: {
      height: 38,
      display: 'flex',
      alignItems: 'center',
    },
    filterItemOptionDragging: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    },
    pinIcon: {
      marginRight: 8,
      cursor: 'pointer',
    },
  });

export default withStyles(styles)(ColumnFilterListElement);
