import {
  Fade,
  FormControlLabel,
  Input,
  InputAdornment,
  Switch,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { EditModeContext } from '../contexts/editModeContext';
import { FilterContext, useFilter } from '../contexts/FilterContext/filterContext';
import { ReactComponent as EditTable } from '../images/editTable.svg';
import { ReactComponent as MapExtentOn } from '../images/mapExtentOn.svg';
import { ReactComponent as TableFilterIcon } from '../images/tableFilterIcon.svg';
import { ReactComponent as ExpandTableIcon } from '../images/expandTable.svg';
import { ReactComponent as MinimizeTableIcon } from '../images/minimizeTable.svg';
import { ReactComponent as DrawIcon } from '../images/draw.svg';
import RefreshDataButton from './RefreshDataButton';
import magicText from 'i18next';
import { colors } from '../styles/theme';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import useScrollbarSize from 'react-scrollbar-size';
import { useDebounce } from './Map/component/ControlsOverlay/useDebouncedRecordSearch';

const styles = (theme: Theme) =>
  createStyles({
    mainDiv: {
      display: 'inline-flex',
      width: '100%',
      backgroundColor: colors.white,
    },
    searchSection: {
      flex: 1,
      display: 'flex',
      borderTop: '1px solid #eee',
      borderBottom: '1px solid #eee',
    },
    showingSection: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 25,
      paddingRight: 25,
      borderTop: '1px solid #eee',
    },
    buttonSection: {
      flex: 1,
      display: 'flex',
      borderTop: '1px solid #eee',
    },
    typography: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.grey[600],
    },
    activeFilterSection: {
      fontWeight: 'bold',
      color: colors.black,
      marginRight: 5,
    },
    progressSpinnerSection: {
      marginLeft: 5,
      marginRight: 5,
      marginTop: 3,
    },
    filterNumberSpan: {
      color: colors.danger,
    },
    clearFilters: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 14,
      marginLeft: 10,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    clearSpan: {
      color: theme.palette.primary.main,
      marginLeft: 10,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    assetSearchBar: {
      backgroundColor: '#f8f8f8',
      color: theme.palette.grey[200],
      padding: '4px 0px 3px 15px',
      display: 'inline-flex',
      flexGrow: 1,
      borderLeft: '1px solid #eee',
      borderRight: '1px solid #eee',
    },
    searchIcon: {
      height: 20,
      width: 20,
      color: theme.palette.grey[400],
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 14,
    },
    buttonDiv: {
      borderLeft: '1px solid #EEEEEE',
      padding: '0px 14px',
      display: 'flex',
      alignItems: 'center',
    },
    mapExtentButton: {
      marginLeft: 'auto',
    },
    filterButton: {
      display: 'flex',
      backgroundRepeat: 'no-repeat',
      borderRadius: 2,
      cursor: 'pointer',
      width: 24,
      height: 24,
      alignItems: 'center',
    },
    mapFold: {
      background: '#FFECB3',
      width: 8,
      height: 16,
      transform: 'skew(0deg, -20deg)',
    },
    centerMapFold: {
      background: '#FFE082',
      transform: 'skew(0deg, 20deg)',
    },
    filterIcon: {
      marginLeft: -22,
      zIndex: 2,
      width: 21,
      height: 10,
      cursor: 'pointer',
      '& path': {
        fill: '#FF9800',
      },
    },
    filterOnIcon: {
      marginLeft: -22,
      zIndex: 2,
      width: 21,
      height: 20,
      cursor: 'pointer',
    },
    popper: {
      top: '15px !important',
    },
    tableButton: {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
    selectSwitch: {
      marginLeft: 5,
      marginRight: 10,
      color: theme.palette.primary.main,
      width: 120,
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 1,
    },
    buttonDescriptionText: {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 6,
      color: theme.palette.primary.main,
      userSelect: 'none',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  });

interface SearchBarProps extends WithStyles<typeof styles> {
  height: number;
  showing: number;
  totalAssets: number;
  numberOfFilters: number;
  clearAllFilters: () => void;
  handleColumnFilterOpen: any;
  checked: boolean;
  setChecked: Function;
  isLoadingData: boolean;
  minimizeAndExpandTable?: (value: number) => void;
}
/**
 * SearchBar - Main function is acting as a searchBar inside AssetsTableContainer for searching the assets.
 *
 * Uses handleSearchText to change text filter.
 * Also displays filter information next to the searchBar.
 * Clearing all filter button available as well, with handleFilterReset.
 *
 * @param props
 */
const SearchBar: FunctionComponent<SearchBarProps> = props => {
  const {
    classes,
    height,
    showing,
    totalAssets,
    numberOfFilters,
    clearAllFilters,
    handleColumnFilterOpen,
    checked,
    setChecked,
    isLoadingData,
    minimizeAndExpandTable,
  } = props;

  const { editModeActive } = useContext(EditModeContext);
  const { setQuickSearch } = useFilter();

  const filterContext = useContext(FilterContext);
  const extentFilteringEnabled = filterContext.mapFilterState.extentFilterEnabled;
  const scrollBarHeight = useScrollbarSize().height;
  const { verticalPercentage, prevVerticalPercentage, setPrevVerticalPercentage } = useContext(AssetsDashboardContext);
  const [minimizedHeight, setMinimizedHeight] = useState(((174 + scrollBarHeight) / window.innerHeight) * 100);
  const [minimizedTableView, setMinimizedTableView] = useState(false);
  const [tableTopSearchTxt, setTableTopSearchTxt] = useState('');

  const setMapFilter = () => {
    filterContext.setMapExtentFiltering(!extentFilteringEnabled);
  };

  const handleFilterReset = () => {
    clearAllFilters();
    //TODO: add extent filtering to clear all
    filterContext.setMapExtentFiltering(false);
    setTableTopSearchTxt('');
  };

  useEffect(() => {
    setMinimizedTableView(verticalPercentage === minimizedHeight);
  }, [verticalPercentage]);

  // see here for implementation details https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  useDebounce(
    () => {
      setQuickSearch(tableTopSearchTxt);
    },
    200,
    [setQuickSearch, tableTopSearchTxt]
  );

  const minimizeAndMaximizeTable = (newMinimizedTableView: boolean) => {
    const newMinimizedHeight = ((174 + scrollBarHeight) / window.innerHeight) * 100;
    setMinimizedTableView(newMinimizedTableView);
    if (verticalPercentage && minimizeAndExpandTable) {
      setPrevVerticalPercentage(verticalPercentage);
      minimizeAndExpandTable(newMinimizedTableView ? newMinimizedHeight : prevVerticalPercentage);
      setMinimizedHeight(newMinimizedHeight);
    }
  };

  return (
    <div className={classes.mainDiv} style={{ height: height }}>
      <div className={classes.searchSection}>
        <Fade in={!editModeActive} unmountOnExit>
          <FormControlLabel
            label={<>{magicText.t('SearchBar.SELECT')}</>}
            className={classes.selectSwitch}
            classes={{ label: classes.label }}
            control={
              <Switch
                disabled={isLoadingData}
                checked={checked}
                onChange={(e: any) => setChecked(e.target.checked)}
                value="checked"
                color="primary"
              />
            }
          />
        </Fade>
        <div className={classes.assetSearchBar}>
          <Input
            startAdornment={
              <InputAdornment position="start">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            }
            placeholder={magicText.t('Search')}
            value={tableTopSearchTxt}
            className={classes.input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTableTopSearchTxt(e.target.value.toLowerCase());
            }}
          />
        </div>
      </div>
      <div className={classes.showingSection}>
        <Typography variant="h4" className={classes.typography}>
          {!!numberOfFilters && (
            <div className={classes.activeFilterSection}>
              <span>{`${magicText.t('SearchBar.ACTIVE FILTERS')}: `}</span>
              <span>
                (<span className={classes.filterNumberSpan}>{numberOfFilters}</span>)
              </span>
            </div>
          )}
          {`${magicText.t('SearchBar.Showing')}: `}
          {isLoadingData && (
            <span className={classes.progressSpinnerSection}>
              <CircularProgress size={13} />
            </span>
          )}
          {`${isLoadingData ? '...' : ''}${showing.toLocaleString('en-US')} ${magicText.t(
            'SearchBar.of'
          )} ${totalAssets.toLocaleString('en-US')}`}
          {numberOfFilters ? (
            <span className={classes.clearFilters} onClick={handleFilterReset}>
              <>{magicText.t('SearchBar.CLEAR ALL FILTERS')}</>
            </span>
          ) : (
            ''
          )}
        </Typography>
      </div>
      <div className={classes.buttonSection}>
        <Tooltip
          title={<>{magicText.t('SearchBar.Map Extent Filter')!}</>}
          placement="top"
          classes={{ popper: classes.popper }}
        >
          <div className={`${classes.buttonDiv} ${classes.mapExtentButton}`} onClick={setMapFilter}>
            <div className={classes.filterButton}>
              <div className={classes.mapFold} />
              <div className={`${classes.mapFold} ${classes.centerMapFold}`} />
              <div className={classes.mapFold} />
            </div>
            {extentFilteringEnabled ? (
              <MapExtentOn className={classes.filterOnIcon} onClick={setMapFilter} />
            ) : (
              <TableFilterIcon className={classes.filterIcon} onClick={setMapFilter} />
            )}
            <Typography className={classes.buttonDescriptionText}>
              <>
                {' '}
                {extentFilteringEnabled
                  ? magicText.t('SearchBar.DISABLE MAP FILTER')
                  : magicText.t('SearchBar.ENABLE MAP FILTER')}
              </>
            </Typography>
          </div>
        </Tooltip>
        <Fade in={!editModeActive} unmountOnExit>
          <Tooltip title={<>{magicText.t('Enable Draw mode')!}</>} placement="top" classes={{ popper: classes.popper }}>
            <div className={classes.buttonDiv} onClick={filterContext.enableDrawFilter}>
              <DrawIcon className={classes.tableButton} />
              <Typography className={classes.buttonDescriptionText}>{'DRAW FILTER'}</Typography>
            </div>
          </Tooltip>
        </Fade>
        <Fade in={!editModeActive} unmountOnExit>
          <Tooltip
            title={<>{magicText.t('SearchBar.Displayed Column Filter')!}</>}
            placement="top"
            classes={{ popper: classes.popper }}
          >
            <div className={classes.buttonDiv} onClick={handleColumnFilterOpen}>
              <EditTable className={classes.tableButton} />
              <Typography className={classes.buttonDescriptionText}>
                <> {magicText.t('SearchBar.ORGANIZE TABLE')}</>
              </Typography>
            </div>
          </Tooltip>
        </Fade>
        <Fade in={!editModeActive} unmountOnExit>
          <Tooltip title={'Modify Table Proportion'} placement="top" classes={{ popper: classes.popper }}>
            <div className={classes.buttonDiv} onClick={() => minimizeAndMaximizeTable(!minimizedTableView)}>
              {minimizedTableView ? (
                <ExpandTableIcon className={classes.tableButton} />
              ) : (
                <MinimizeTableIcon className={classes.tableButton} />
              )}
              <Typography className={classes.buttonDescriptionText}>
                {minimizedTableView ? 'EXPAND TABLE' : 'MINIMIZE TABLE'}
              </Typography>
            </div>
          </Tooltip>
        </Fade>
        <RefreshDataButton />
      </div>
    </div>
  );
};

export default withStyles(styles)(SearchBar);
