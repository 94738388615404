import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import { Dispatch, SetStateAction } from 'react';

export interface BaseLocationMapProps {
  setIsValidLocation: Dispatch<SetStateAction<boolean>>;
  items?: any;
  onStyleLoad?: any;
}
export const useLocationStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: '50vh',
    },
    fullHeightMapContainer: {
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: 'calc(96vh - 50px)',
    },
    formPointMapContainer:{
      height: '66vh',
    },
    formPolygonMapContainer: {
      height: '80vh',
    },
    captureLocationBtn: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
    },
    captureLocationBtnDis: {
      backgroundColor: '#DCDBDC',
      color: '#B1B1B1',
    },
    bottomCenterBtn: {
      position: 'absolute',
      bottom: 3,
      zIndex: 2,
    },
    bottomRightBtn: {
      position: 'absolute',
      bottom: 3,
      right: 3,
      zIndex: 2,
    },
    bottomTopRightBtn: {
      position: 'absolute',
      bottom: 50,
      right: 3,
      zIndex: 2,
    },
    captureLocationIcon: {
      color: '#2F80ED',
    },
  })
);
