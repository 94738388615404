import React, { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormControl, Typography, Box, InputLabel, FormHelperText, InputBaseComponentProps } from '@material-ui/core';
import { useStyles } from '../Common';
import { ValidationFunction } from '../../Workflow/FormHelpers';
import _ from 'lodash';
import DialogueWrapper, { ModalProps } from '../../Common/DialogueWrapper';
import { TGPointEditor } from './TGPointEditor';
import { TGMultiplePointsAdder } from './TGMultiplePointsAdder';
import { TGLineEditor } from './TGLineEditor';
import HideNonSelectableButton from '../../Map/component/HideNonSelectableButton';
import TGMultipleLocationSidebar from './TGMultipleLocationSidebar';
import magicText from 'i18next';
import {
  LatLon,
  AlertDialog,
  TGLabelWrapper,
  TGLabelWrapperProps,
  TGTextField,
  FieldHeader,
} from '@terragotech/gen5-shared-components';

export interface TGLocationFieldProps extends TGLabelWrapperProps {
  value: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null | undefined;
  label: string;
  placeholder: string;
  onChange: (options: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null) => void;
  readOnly?: boolean;
  validator?: ValidationFunction;
  required?: boolean;
  error?: boolean;
  warning?: boolean;
  helperText?: string;
  multiplePoints?: { enabled: boolean; maximum: number; unlimited: boolean };
}

//to be render at 'enter manually' radion option

const useCoordsValidation = (manualMode: boolean) => {
  const [location, setLocation] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [latLon, setLatLon] = useState<LatLon | null>(null);
  useEffect(() => {
    if (!manualMode) {
      return setError(null);
    }
    if (location) {
      const coords = location.split(',');
      if (coords.length) {
        const lat = _.toNumber(coords[0]);
        const lon = _.toNumber(coords[1]);
        if (lat && lon) {
          if (lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180) {
            setLatLon({
              latitude: lat,
              longitude: lon,
            });
            return setError(null);
          }
        }
      }
      setError(`Uh oh…This isn't a valid location`);
    }
  }, [location, manualMode]);
  return { location, setLocation, error, latLon: latLon, setLatLon: setLatLon };
};

const getLocationStringFromCoords = (location: LatLon) => {
  return `${location.latitude},${location.longitude}`;
};

//to be render at 'enter manually' radio option
interface LocationInputFieldProps {
  capturedLocation: GeoJSON.Point | GeoJSON.LineString | null | undefined;
  manualMode: boolean;
  setManualMode: Dispatch<SetStateAction<boolean>>;
  setIsValidLocation: Dispatch<SetStateAction<boolean>>;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | GeoJSON.LineString | null | undefined>>;
  placeholder: string;
  readOnly?: boolean;
}

const LocationInputFields: React.FC<LocationInputFieldProps> = props => {
  const {
    capturedLocation,
    manualMode,
    setManualMode,
    setCapturedLocation,
    setIsValidLocation,
    placeholder,
    readOnly,
  } = props;
  /* use hook useCoordsValidation once for each text field */
  const { location, setLocation, error, latLon, setLatLon } = useCoordsValidation(manualMode);
  const { location: reEnterLocation, setLocation: setReEnterLocation, error: reEnterError } = useCoordsValidation(
    manualMode
  );
  const locationRef = useRef<InputBaseComponentProps | null>(null);
  const reEnterLocationRef = useRef<InputBaseComponentProps | null>(null);
  const [manualEntryMatchError, setManualEntryMatchError] = useState<string>('');

  /* validating Enter location and re enter location */
  useEffect(() => {
    if (manualMode && reEnterLocation && location && reEnterLocation !== location) {
      setManualEntryMatchError('Uh oh…This entry doesnt match');
    } else {
      setManualEntryMatchError('');
    }
  }, [location, reEnterLocation, manualMode]);

  // Set input validation
  useEffect(() => {
    if (manualMode) {
      if (error || reEnterError || manualEntryMatchError) {
        setIsValidLocation(false);
      } else {
        setIsValidLocation(true);
        if (latLon) {
          if (
            capturedLocation?.coordinates[0] !== latLon.longitude ||
            capturedLocation?.coordinates[1] !== latLon.latitude
          ) {
            setCapturedLocation({
              type: 'Point',
              coordinates: [latLon.longitude, latLon.latitude],
            });
          }
        }
      }
    } else if (capturedLocation && capturedLocation.type === 'Point') {
      const locationString = getLocationStringFromCoords({
        longitude: capturedLocation.coordinates[0],
        latitude: capturedLocation.coordinates[1],
      });
      setLocation(locationString);
      setReEnterLocation(locationString);
      setIsValidLocation(true);
    }
  }, [
    manualMode,
    error,
    reEnterError,
    manualEntryMatchError,
    setIsValidLocation,
    capturedLocation,
    latLon,
    setCapturedLocation,
    setLocation,
    setReEnterLocation,
  ]);
  const onTextInputClick = () => {
    if (!manualMode) {
      if (capturedLocation) {
        let capturedLocationLatLon: LatLon = {
          latitude: Number(capturedLocation.coordinates[0]) || 0,
          longitude: Number(capturedLocation.coordinates[1]) || 0,
        };
        setLatLon(capturedLocationLatLon);
      }
      setManualMode(true);
    }
  };
  return (
    <Box mt={2}>
      <div style={{ width: '50vw' }}>
        <TGTextField
          fullWidth
          label={placeholder}
          inputRef={locationRef}
          value={location}
          error={!!error}
          helperText={error}
          onChange={(x: React.ChangeEvent<HTMLInputElement>) => setLocation(x.target.value)}
          onClick={onTextInputClick}
          InputProps={{
            readOnly,
          }}
        />
        <Box mt={2}>
          <TGTextField
            fullWidth
            label={`Re-enter ${placeholder}`}
            inputRef={reEnterLocationRef}
            value={reEnterLocation}
            error={!!reEnterError || !!manualEntryMatchError}
            helperText={manualEntryMatchError || reEnterError}
            onChange={(x: React.ChangeEvent<HTMLInputElement>) => setReEnterLocation(x.target.value)}
            onClick={onTextInputClick}
            InputProps={{
              readOnly,
            }}
          />
        </Box>
      </div>
    </Box>
  );
};

type LocationFieldProps = TGLocationFieldProps & { toggleModal: ModalProps['toggleModal'] };
const TGLocationField: React.FC<LocationFieldProps> = props => {
  const { value, label, placeholder, onChange, readOnly, toggleModal, multiplePoints } = props;
  const [capturedLocation, setCapturedLocation] = useState<any>(value);
  const [manualMode, setManualMode] = useState<boolean>(false);
  const [isValidLocation, setIsValidLocation] = useState<boolean>(false);
  const multiplePointsLocation: boolean | undefined =
    multiplePoints && multiplePoints?.enabled && (multiplePoints?.unlimited || multiplePoints?.maximum > 1);
  const [openAddLocationAlert, setOpenAddLocationAlert] = useState<boolean>(false);

  const [selectedPoint, setSelectedPoint] = useState<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);

  const onDonePress = () => {
    if (capturedLocation) {
      onChange(capturedLocation);
    }
    toggleModal && toggleModal();
  };
  const onCancelPress = () => {
    toggleModal && toggleModal();
  };

  const selectedPointRef = useRef<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);

  return (
    <FormControl>
      <FieldHeader title={label} canSave={isValidLocation} onDonePress={onDonePress} onCancelPress={onCancelPress} />
      {multiplePointsLocation && (
        <Box m={0} pt={0} pl={0} pr={0} style={{ display: 'flex', height: '100%' }}>
          <Box mb={0} style={{ flex: 3, maxHeight: '90.9vh', minWidth: 400, maxWidth: 400, overflowY: 'auto' }}>
            <TGMultipleLocationSidebar
              value={value}
              capturedLocation={capturedLocation}
              setCapturedLocation={setCapturedLocation}
              maximum={multiplePoints?.maximum}
              unlimited={multiplePoints?.unlimited}
              setIsValidLocation={setIsValidLocation}
              selectedPoint={selectedPoint}
              setSelectedPoint={setSelectedPoint}
              setManualMode={setManualMode}
              selectedPointRef={selectedPointRef}
              setOpenAddLocationAlert={setOpenAddLocationAlert}
            />
          </Box>
          <Box style={{ flex: 10, height: '100%', width: '100%' }}>
            <TGMultiplePointsAdder
              capturedLocation={capturedLocation}
              setCapturedLocation={setCapturedLocation}
              setIsValidLocation={setIsValidLocation}
              maximum={multiplePoints?.maximum}
              unlimited={multiplePoints?.unlimited}
              selectedPoint={selectedPoint}
              setSelectedPoint={setSelectedPoint}
              setManualMode={setManualMode}
              selectedPointRef={selectedPointRef}
              setOpenAddLocationAlert={setOpenAddLocationAlert}
            />
          </Box>
          {openAddLocationAlert && (
            <AlertDialog
              title={magicText.t('multiplePoints.limitReached')}
              onOkPress={() => setOpenAddLocationAlert(false)}
            >
              <>{magicText.t('multiplePoints.limitReachedInfo')}</>
            </AlertDialog>
          )}
        </Box>
      )}
      {!multiplePointsLocation && (
        <Box m={1} pt={1} pl={3} pr={3}>
          <Box mb={2}>
            <Typography>
              To enter a location you can click on the map, capture your devices location, or enter it manually.
            </Typography>
          </Box>
          {!Array.isArray(value) && value?.type !== 'LineString' ? (
            <>
              <TGPointEditor
                capturedLocation={capturedLocation}
                setCapturedLocation={setCapturedLocation}
                setManualMode={setManualMode}
                setIsValidLocation={setIsValidLocation}
              />
              <LocationInputFields
                placeholder={placeholder}
                capturedLocation={capturedLocation}
                manualMode={manualMode}
                setManualMode={setManualMode}
                setIsValidLocation={setIsValidLocation}
                setCapturedLocation={setCapturedLocation}
                readOnly={readOnly}
              />
              <HideNonSelectableButton selectableAggregateTypes={[]} buttonCaption={'other records'} />
            </>
          ) : (
            <TGLineEditor
              lineLocation={capturedLocation}
              setLineLocation={setCapturedLocation}
              setIsValidLocation={setIsValidLocation}
            />
          )}
        </Box>
      )}
    </FormControl>
  );
};

const DefaultView: React.FC<TGLocationFieldProps> = props => {
  const { label, value, required, readOnly, error, warning, helperText, info, onChange } = props;
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);

  return (
    <div onMouseEnter={() => readOnly && setTooltip(true)} onMouseLeave={() => readOnly && setTooltip(false)}>
      <DialogueWrapper
        {...props}
        clearValue={() => onChange(null)}
        type={'location'}
        position={'top'}
        renderHeader={() => {
          return (
            <InputLabel error={error} className={clsx(warning && classes.warningText)}>
              <TGLabelWrapper required={required} readOnly={readOnly} label={label} info={info} tooltip={tooltip} />
            </InputLabel>
          );
        }}
        renderValue={() => {
          return (
            <>
              {!!value && !Array.isArray(value) && value.type === 'Point' && (
                <Typography className={classes.value}>{`${value.coordinates[1]}, ${value.coordinates[0]}`}</Typography>
              )}
              {!!value && Array.isArray(value) && (
                <Typography className={classes.value}>{`${value.length} location(s) selected`}</Typography>
              )}
              <FormHelperText error={error} className={clsx(warning && classes.warningText)}>
                {helperText}
              </FormHelperText>
            </>
          );
        }}
        renderComponent={toggleModal => {
          return <TGLocationField {...props} toggleModal={toggleModal} />;
        }}
      />
    </div>
  );
};

export default DefaultView;
