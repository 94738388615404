import { TableData, TableGeneratedClasses, ValueType } from '../useTable';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Column as RDGColumn, HeaderRendererProps, FormatterProps, Editor2Props } from '@terragotech/react-data-grid';
import { ColumnsReorderData } from '../../components/StyledTable/ColumnOrderDialog/ColumnOrderDialogWrapper';

export type FilterRendererProps<Data extends TableData> = {
  column: Column<Data>;
};

export interface StyledTableEditorRef {
  getInputNode: () => HTMLElement | null;
  getValue: () => ValueType;
}

export type StyledTableEditorProps = Editor2Props<TableData>;

export type Editor = React.ForwardRefExoticComponent<StyledTableEditorProps & React.RefAttributes<any>>;

export type CellRendererProps<Data extends TableData> = Omit<
  FormatterProps<Data, unknown>,
  'isCellSelected' | 'onRowSelectionChange'
>;

export interface ColumnFilterInputOptions {
  labelConverter?: (label: string) => string;
  valueDelimiter?: string;
}

export interface Column<Data extends TableData> {
  key: string;
  name: string;
  dataType?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  sticky?: boolean;
  hidden?: boolean;
  editable?: boolean;
  resizable?: boolean;
  sortable?: boolean;
  cellEditor?: Editor;
  cellRenderer?: (props: CellRendererProps<Data>) => JSX.Element;
  filterRenderer?: ((p: FilterRendererProps<Data>) => JSX.Element) | boolean;
  headerRenderer?: (p: HeaderRendererProps<Data, unknown>) => JSX.Element;
  editorOptions?: RDGColumn<Data>['editorOptions'];
  filterInputOptions?: ColumnFilterInputOptions;
}

interface ColumnsConfig<Data extends TableData> {
  columns: ReadonlyArray<Column<Data>>;
  editable: boolean;
  editor: Editor;
  editorOptions?: Column<Data>['editorOptions'];
  filterRenderer: (p: FilterRendererProps<Data>) => JSX.Element;
  classes: TableGeneratedClasses;
  setEditModeOn?: (row: Data) => void;
}

export const ID_COLUMN_KEY = '__id__';

const idColumn = {
  key: ID_COLUMN_KEY,
  name: ID_COLUMN_KEY,
  sticky: false,
  width: 0,
};

const fullSizeStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

const DefaultCellRenderer = <Data extends TableData>({ row, column }: CellRendererProps<Data>) => (
  <div>
    <>{row[column.key]}</>
  </div>
);

const CellRendererWrapper = <Data extends TableData>(
  props: CellRendererProps<Data>,
  component: Exclude<Column<Data>['cellRenderer'], undefined>,
  setEditModeOn?: (row: Data) => void
) => {
  const { row, rowIdx, column, isRowSelected } = props;

  const filteredProps: typeof props = useMemo(() => ({ row, rowIdx, column, isRowSelected }), [
    row,
    rowIdx,
    column,
    isRowSelected,
  ]);

  const rendered = useMemo(() => component(filteredProps), [filteredProps, component]);

  return (
    <div style={fullSizeStyle} onDoubleClick={() => props.column.editable && setEditModeOn && setEditModeOn(row)}>
      {rendered}
    </div>
  );
};

const useColumns = <Data extends TableData>(props: ColumnsConfig<Data>) => {
  const { editable, editor, columns, filterRenderer, classes, editorOptions, setEditModeOn } = props;

  const [hiddenColumns, setHiddenColumns] = useState([
    ID_COLUMN_KEY,
    ...columns.filter((column) => column.hidden === true).map((column) => column.key),
  ]);
  const [columnsInOrder, setColumnsInOrder] = useState(columns);

  useEffect(() => {
    setHiddenColumns([
      ID_COLUMN_KEY,
      ...columns.filter((column) => column.hidden === true).map((column) => column.key),
    ]);
    setColumnsInOrder(columns);
  }, [columns]);

  const columnMapper: (column: Column<Data>) => RDGColumn<Data> = useCallback(
    (column: Column<Data>) => {
      const renderFilterRenderer = () => {
        const columnFilterRenderer = column.filterRenderer;
        if (columnFilterRenderer === true || columnFilterRenderer === undefined) {
          if (filterRenderer) {
            return () => filterRenderer({ column });
          }
        } else if (columnFilterRenderer !== false) {
          return () => columnFilterRenderer({ column });
        }
        return undefined;
      };
      const addClasses = (column: RDGColumn<Data>) => ({
        ...column,
        cellClass: [classes.cell, column.editable ? classes.editableCell : ''].join(' '),
      });
      return addClasses({
        key: column.key,
        name: column.name,
        resizable: column.resizable ?? true,
        minWidth: column.minWidth,
        width: column.width || column.minWidth || undefined,
        sortable: column.sortable ?? true,
        editable: column.editable ?? editable,
        editor2: (column.editable ?? editable ? column.cellEditor || editor : undefined) as any,
        editorOptions: { ...editorOptions, ...column.editorOptions },
        maxWidth: column.maxWidth,
        filterRenderer: renderFilterRenderer(),
        formatter: (props) => CellRendererWrapper(props, column.cellRenderer ?? DefaultCellRenderer, setEditModeOn),
        headerRenderer: column.headerRenderer,
        frozen: column.sticky,
      });
    },
    [editable, editor, filterRenderer, classes, editorOptions, setEditModeOn]
  );

  const mappedColumns = useMemo(() => {
    return [...columnsInOrder.map(columnMapper), idColumn] as RDGColumn<Data>[];
  }, [columnMapper, columnsInOrder]);

  const visibleColumns = useMemo(() => mappedColumns.filter((column) => !hiddenColumns.includes(column.key)), [
    mappedColumns,
    hiddenColumns,
  ]);

  const handleOrganizeChange = (change: ColumnsReorderData<Data>) => {
    setColumnsInOrder(change.columns);
    setHiddenColumns(change.hiddenColumns);
  };

  return {
    visibleColumns,
    hiddenColumns,
    columns: mappedColumns,
    handleOrganizeChange,
    columnsInOrder,
  };
};

export default useColumns;
