import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formElementWrapper: {
    width: '100%',
  },
  appBar: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    ' & .MuiToolbar-regular': {
      minHeight: '50px',
    },
  },
  // text colors
  textGreen: {
    color: '#50AD55',
  },
  textWhite: {
    color: '#fff',
  },
  textBlue: {
    color: '#56CCF2',
  },
  textDull: {
    color: '#bdbdbd',
  },
  errorText: {
    color: '#EB5757',
  },
  warningText: {
    color: '#F2C94C',
  },
  
  warningBorderBottom: {
    '&:after': {
      borderBottom: '2px solid #F2C94C',
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      position: 'absolute',
      right: 0,
      transform: 'scaleX(1)',
    }
  },

  // font sizes
  fontSizeXSm: {
    fontSize: 13,
  },
  fontSizeSm: {
    fontSize: 14,
  },
  fontSizeNormal: {
    fontSize: 16,
  },
  fontSizeLg: {
    fontSize: 18,
  },
  //margin
  mlSm: {
    // marginLeftSmall
    marginLeft: theme.spacing(1),
  },
  ml: {
    // marginLeftSmall
    marginLeft: theme.spacing(2),
  },
  mlLg: {
    // marginRightSmall
    marginRight: theme.spacing(3),
  },
  mrSm: {
    // marginRightSmall
    marginRight: theme.spacing(1),
  },
  mr: {
    // marginRightSmall
    marginRight: theme.spacing(2),
  },
  mrLg: {
    // marginRightSmall
    marginRight: theme.spacing(3),
  },
  mtSm: {
    // marginTopSmall
    marginTop: theme.spacing(1),
  },
  mt: {
    // marginTopSmall
    marginTop: theme.spacing(2),
  },
  mtLg: {
    // marginTopSmall
    marginTop: theme.spacing(3),
  },
  mbSm: {
    // marginBottomSmall
    marginBottom: theme.spacing(1),
  },
  mb: {
    // marginBottomSmall
    marginBottom: theme.spacing(2),
  },
  mbLg: {
    // marginBottomSmall
    marginBottom: theme.spacing(3),
  },
  centerText: {
    flex: 1,
    textAlign: 'center',
  },
  content: {
    margin: '10px 8px',
  },
  captureLocationBtn: {
    backgroundColor: '#56CCF2',
    color: '#fff',
  },
  captureLocationIcon: {
    color: '#2F80ED',
  },
  lightLabel: {
    left: 0,
    fontFamily: `'Roboto', sans-serif`,
    fontWeight: 'normal',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'translate(0, 1.5px)',
    transformOrigin: 'top left',
    '& .labelWrapper-label': {
      fontSize: '0.75rem',
    },
  },
  value: {
    fontFamily: `'Roboto', sans-serif`,
    fontWeight: 'normal',
    fontSize: 16,
    display: 'flex',
    alignItems: 'flex-end',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  labelShrink: {
    transform: 'translate(0px,-4.5px)',
    '& .labelWrapper-label': {
      fontSize: '0.75rem',
    },
  },
}));
