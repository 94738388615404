import React, { MouseEvent } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { Action } from './AssetCardAction';
import { colors } from '../../../styles/theme';
import Color from 'color';
import TGTooltip from '../../TGTooltip';

interface AssetCardPrimaryActionProps {
  action: Action;
  onClick?: () => void;
}

const AssetCardPrimaryAction = (props: AssetCardPrimaryActionProps) => {
  const { action } = props;
  const classes = useStyles();

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    if (action.onClick) action.onClick();
    if (props.onClick) {
      props.onClick();
    }
  };

  const color: Color = (() => {
    const base = Color(action.color).isLight() ? Color(colors.black) : Color(colors.white);
    if (action.disabled) {
      return base.fade(0.4);
    }
    return base;
  })();

  const styles: React.CSSProperties | undefined = action.color
    ? {
        backgroundColor: action.color,
        color: `rgba(${color.rgb().array().join(',')})`,
      }
    : undefined;

  return (
    <TGTooltip title={action.tooltipText || ''}>
      <span>
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={action.icon}
          color="primary"
          className={classes.button}
          disabled={action.disabled}
          style={styles}
        >
          {action.label}
        </Button>
      </span>
    </TGTooltip>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    color: colors.white,
    width: '100%',
  },
}));

export default AssetCardPrimaryAction;
