import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTableColumns } from '../../../contexts/TableColumnContext';
import ColumnFilterHeader from './ColumnFilterHeader';
import ColumnFilterInstructionBox from './ColumnFilterInstructionBox';
import ColumnFilterLists from './ColumnFilterLists';
import { useColumnFilterState } from '../../../hooks/useColumnFilterState';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';

interface ColumnFilterProps extends WithStyles<typeof styles> {
  open: boolean;
  setColumnFilterOpen: (value: boolean) => void;
}

const ColumnFilter: FunctionComponent<ColumnFilterProps> = (props) => {
  const { classes, setColumnFilterOpen } = props;

  const { setColumnState } = useTableColumns();

  const {
    columns,
    areAllSelected,
    defaultColumns,
    areColumnsChanged,
    isSelected,
    getAllSelectedKeys,
    setColumns,
    setIsSelected,
    setSearchText,
    filterColumnsWithSearchText,
  } = useColumnFilterState();

  // sort selected columns first
  useEffect(() => {
    columns.sort((a: Column<AssetType>, b: Column<AssetType>) => Number(isSelected(b.key)) - Number(isSelected(a.key)));
  }, [columns, isSelected]);

  const selectedKeyCount = getAllSelectedKeys().length;

  const handleDone = () => {
    const selectedColumns = getAllSelectedKeys();
    const columnsState = columns.map((column) => ({
      key: column.key,
      hidden: !selectedColumns.includes(column.key),
      sticky: !!column.sticky,
    }));
    setColumnState(columnsState);
    setColumnFilterOpen(false);
  };

  const setColumnVisibility = useCallback(
    (key: string, isVisible: boolean) => {
      const index = columns.findIndex((column) => column.key === key);
      setColumns([...columns.slice(0, index), { ...columns[index], hidden: !isVisible }, ...columns.slice(index + 1)]);
      setIsSelected(key, isVisible);
    },
    [columns, setColumns, setIsSelected]
  );

  const handleSelectAll = () => {
    const filteredKeys = filterColumnsWithSearchText(columns).map((column) => column.key);
    if (areAllSelected) {
      filteredKeys.forEach((key: string) => {
        setColumnVisibility(key, false);
      });
    } else {
      filteredKeys.forEach((key: string) => {
        setColumnVisibility(key, true);
      });
    }
  };

  const handleReset = () => {
    setColumns(defaultColumns);
    defaultColumns.forEach((column) => {
      setIsSelected(column.key, !column.hidden);
    });
  };

  return (
    <>
      <div className={classes.root}>
        <ColumnFilterHeader
          handleDone={handleDone}
          handleSelectAll={handleSelectAll}
          handleSearchText={setSearchText}
          areAllSelected={areAllSelected}
          totalKeyCount={columns.length}
          selectedKeyCount={selectedKeyCount}
        />
        <div className={classes.filterItems}>
          <ColumnFilterInstructionBox areColumnsChanged={areColumnsChanged} handleReset={handleReset} />
          <ColumnFilterLists
            columns={columns}
            isSelected={isSelected}
            setIsSelected={setColumnVisibility}
            setColumns={setColumns}
            filterColumnsWithSearchText={filterColumnsWithSearchText}
          />
        </div>
      </div>
    </>
  );
};

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    filterItems: {
      height: '100%',
      overflowY: 'auto',
    },
  });

export default withStyles(styles)(ColumnFilter);
