import React, { FunctionComponent, ReactNode } from 'react';
import { ReactComponent as GearHeader } from '../images/gearHeader.svg';
import { Column } from '../hooks/tableHooks/useColumns';
import { TableData } from '../hooks/useTable';

const IconHeader: FunctionComponent<{ children: ReactNode }> = (props) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
    {props.children}
  </div>
);

const gearColumn: <DataType extends TableData>(
  cellRenderer: Column<DataType>['cellRenderer'],
  filterRenderer: Column<DataType>['filterRenderer']
) => Column<DataType> = (cellRenderer, filterRenderer) => ({
  key: 'gear',
  name: '',
  minWidth: 30,
  width: 30,
  resizable: false,
  filterRenderer: (args) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {filterRenderer && typeof filterRenderer === 'function' && filterRenderer(args)}
    </div>
  ),
  editable: false,
  sticky: true,
  sortable: false,
  headerRenderer: () => (
    <IconHeader>
      <GearHeader />
    </IconHeader>
  ),
  cellRenderer: cellRenderer
    ? (args) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {cellRenderer(args)}
        </div>
      )
    : undefined,
});

export default gearColumn;
