//@ts-ignore
import { V2AggregateLoader } from '@terragotech/form-renderer';
import LabelWrapper, {
  TGLabelWrapperProps,
} from '@terragotech/gen5-shared-components/dist/components/PageFields/TGPageLabelWrapper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MapItemsContainer from '../../containers/MapItemsContainer';
import { MapAssetType } from '../../contexts/AggregatesContext/types';
import { Symbol } from '../../components/Legend';
import { gql, useApolloClient } from '@apollo/client';
export type TGMapFieldProps = TGLabelWrapperProps & { value?: any; aggregates: V2AggregateLoader[]; height?: string; symbols?: Symbol[]; includeWMS?: boolean; latSplit?: number, lonSplit?: number, limitPerSection?: number, showLocationSearch?: boolean };

const SUBMIT_REFRESH_TIME = 2 * 1000; //2 seconds

const TGPageMapField: React.FC<TGMapFieldProps> = props => {
  const { aggregates } = props;
  const client = useApolloClient();
  const [assets,setAssets] = useState<MapAssetType[]>([]);
  const [reloadTrigger,setReloadTrigger] = useState<number>(0);
  const [isDataLoading,setIsDataLoading] = useState<boolean>(false);
  const [mapBounds,setMapBounds] = useState<{minLat:number,minLon:number,maxLat:number,maxLon:number} | null>(null);
  const currentLoad = useRef<number>(0);

  useEffect(()=>{
    if(mapBounds){
      const thisLoad = ++currentLoad.current;
      setIsDataLoading(true)
      client
      .query({
        query: gql`
          query aggregateBounds($queryDef: JSON!, $minLon: Float!, $maxLon: Float!, $minLat: Float!, $maxLat: Float!, $latSplit: Float, $lonSplit: Float, $limitPerSection: Float, $value: String, $values: [String]) {
            aggregateBounds(
              filter: {minLat: $minLat, minLon: $minLon, maxLat: $maxLat, maxLon: $maxLon, queryDef: $queryDef, value: $value, values: $values, latSplit: $latSplit, lonSplit: $lonSplit, limitPerSection: $limitPerSection}
            )
          }
          `,
        fetchPolicy: 'no-cache',
        variables: {
          ...mapBounds,
          queryDef: aggregates,
          value: props.value?.value,
          values: props.value?.values,
          latSplit: props.latSplit, 
          lonSplit: props.lonSplit, 
          limitPerSection: props.limitPerSection
        },
      })
      .then((result) => {
        if(thisLoad === currentLoad.current){
          setIsDataLoading(false)
          setAssets(result.data.aggregateBounds)
      }
      }).catch((e)=>{
        console.log('fetch error',e);
      });
    }
  }, [mapBounds,reloadTrigger])


  const handleFormSubmit = useCallback(() => {
    setTimeout(() => {
      setReloadTrigger(p=>p+1)
    }, SUBMIT_REFRESH_TIME);
  }, []);

  return (
    <>
      <LabelWrapper required={props.required} readOnly={true} label={props.label} info={props.info} oneLine={false} />
      <div style={{ height: Number(props.height) || props.height || 400 }}>
        <MapItemsContainer
          onFormSubmit={handleFormSubmit}
          zoom={props.value?.zoom}
          lat={props.value?.lat}
          lon={props.value?.lon}
          symbols={props.symbols}
          assets={assets}
          includeWMS={props.includeWMS}
          setMapBounds={setMapBounds}
          showLoading={isDataLoading}
          showLocationSearch={props.showLocationSearch}
        ></MapItemsContainer>
      </div>
    </>
  );
};

export default TGPageMapField;
