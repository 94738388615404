import React, {FunctionComponent, useCallback, useContext, useState} from 'react';
import { useMultiSelectWithProvider } from 'use-multiselect';
import { AssetType } from '../../contexts/AggregatesContext/types';
 import ThreeDotMenuCellRenderer from '../StyledTable/ThreeDotsMenuCell';
import useConditionalTableActionsMenu from '../../hooks/useConditionalTableActionsMenu';
import {EditModeContext} from "../../contexts/editModeContext";
export interface ThreeDotMenuCellProps {
  data: AssetType;
}

const AssetsThreeDotMenuCellRenderer: FunctionComponent<ThreeDotMenuCellProps> = (props) => {
  const { data } = props;
  const { isMultiSelectActive, isSelected, toggleSelected } = useMultiSelectWithProvider();
  const [isClosed, setIsClosed] = useState(true);
  const { setEditModeActive, editModeActive, setEditModeData } = useContext(EditModeContext);
  const closeMenu = useCallback(() => setIsClosed(true), []);
  const handleOpen = useCallback(() => setIsClosed(false), []);

 const { singleActions, statusText } = useConditionalTableActionsMenu({ closeMenu,isClosed, target: data });
 const onEditClick = () => {
     setEditModeData({id: data.id, label: data.label});
     setEditModeActive(true);
 };
  return (
    <ThreeDotMenuCellRenderer
      isMultiSelectActive={isMultiSelectActive}
      statusText={statusText}
      actions={[...singleActions, {label: 'EDIT', state: editModeActive ? 'disabled' : 'enabled', icon: 'fa-pencil', onClick: onEditClick}]}
      isSelected={isSelected(data.id)}
      toggleSelected={() => toggleSelected(data.id)}
      isClosed={isClosed}
      onOpen={handleOpen}
    />
  );
};
export default AssetsThreeDotMenuCellRenderer;
