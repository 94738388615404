import React from 'react';
import { makeStyles } from '@material-ui/core';

const AssetCardBackdrop = (props: {
  onCloseClick?: () => void;
  children: React.ReactNode;
  multiple?: boolean;
  mapEditor?: boolean;
  relative?: boolean
}) => {
  const classes = useStyles(props);
  return (
    <div className={props.relative ? classes.relativeContainer : classes.container}>
      <div className={classes.overlay} onClick={props.onCloseClick}></div>
      <div className={classes.dialog}>{props.children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  relativeContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 100,
    top:'-100%',
    left:0
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 100,
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  multipleDialog: {
    position: 'absolute',
    left: '45%',
    bottom: '25%',
    transform: 'translateX(-25%)',
  },
  dialog: {
    position: 'absolute',
    left: '50%',
    bottom: '25%',
    transform: 'translateX(-50%)',
  },
}));
export default AssetCardBackdrop;
