import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { DocumentNode } from 'graphql';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useProjects } from '../projectsContext';
import { useSelectedProject } from '../selectedProjectContext';
import { createWithAggregateNameObject } from './aggregateUtils';
import {
  addProjectsToProjectStates,
  areAllProjectsFetched,
  createVariablesForAggregateReferenceTypeProjectStates,
  createVariablesForProjectStates,
  getAssetsFromProjectStates,
  INITIAL_CHUNK_SIZE,
  MAX_CHUNK_SIZE,
  recomputeProjectStates,
  resetFetchedInfo,
} from './projectStateUtils';
import QUERY from './query';
import { MapAssetType, ProjectState, QueryResultDataType, WithAggregateName } from './types';
import { useLazyQueries } from './useLazyQueries';

export interface UseAssetsLoaderArgs {
  aggregateDefinitions: AggregateDefinition[];
}
export interface assetsLoaderFromQueryArgs {
  aggregateDefinitions: AggregateDefinition[];
  queriesProp: WithAggregateName<DocumentNode>;
}

export interface UseAssetsLoaderReturnType {
  assets: MapAssetType[];
  refetchAll: (retainCursor?: boolean) => Promise<void>;
  refetchSince: (aggregateType: string, since: string, project: string, isReferenceType: boolean) => void;
  deleteMultiple: (aggregateType: string, aggregateIds: string[]) => Promise<void>;
  deleteMultipleFromProjects: (toDelete: { [project: string]: string[] }) => Promise<void>;
  isDataLoading: boolean;
}

export interface AssetsLoaderFromQueryReturnType {
  assets: MapAssetType[];
  isDataLoading: boolean;
  refetchSince: (since: string) => void;
}

export const useAssetsLoaderFromQuery = (props: assetsLoaderFromQueryArgs): AssetsLoaderFromQueryReturnType => {
  const { aggregateDefinitions, queriesProp } = props;

  // const client = useApolloClient();
  const [assets, setAllAssets] = useState<Array<MapAssetType>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [fullyLoaded, setFullyLoaded] = useState<boolean>(false);
  const [vs, setVs] = useState<any>();

  const { loading, data, variables, error, called, refetch } = useLazyQueries<QueryResultDataType>(
    queriesProp,
    {
      fetchPolicy: 'no-cache',
    },
    {}
  );

  // any time we aren't loading data, check to see if we should be
  useEffect(() => {
    if (!loading && !error && !loaded) {
      setLoaded(true);
      const vars = Object.keys(queriesProp).reduce(
        (prev, k) => ({
          ...prev,
          [k]: {
            first: INITIAL_CHUNK_SIZE,
            date: new Date(0).toJSON(),
          },
        }),
        {}
      );

      refetch(vars);
    }
    if (loaded && !error && !loading && vs) {
      refetch(vs);
      setVs(null);
    }
  }, [aggregateDefinitions, called, error, loading, refetch, vs]);

  // Now handle any new data
  useEffect(() => {
    // when we have new data, add it to the corresponding projectState
    if (variables && data) {
      const aggregateKeys = Object.keys(data);
      const result: MapAssetType[] = [];
      const newVars: any = {};
      aggregateKeys.forEach(key => {
        Object.keys(data[key]).forEach(agg => {
          const newData = data[key][agg];
          if (newData.pageInfo.hasNextPage) {
            newVars[key] = {
              after: newData.pageInfo.endCursor,
              first: MAX_CHUNK_SIZE,
              date: variables[key].date,
            };
          }
          const edges = newData?.edges || [];
          edges.forEach(edge => {
            result.push({
              ...edge.node,
              recordTypeKey: agg,
              primaryLocation: edge.node.primaryLocation?.geojson || undefined,
            });
          });
        });
      });
      if (Object.keys(newVars).length) {
        setVs(newVars);
      } else {
        setVs(null);
        setFullyLoaded(true);
      }
      if (result.length) {
        setAllAssets(a => {
          result.forEach(k => {
            const idx = _.findIndex(a, itm => itm.id === k.id);
            if (idx > 0) {
              a[idx] = k;
            } else {
              a.push(k);
            }
          });
          return _.cloneDeep(a);
        });
      }
    } else {
      setFullyLoaded(true);
    }
  }, [data, variables]);

  const refetchSince = useCallback(
    (since: string) => {
      if (!vs) {
        const vars = Object.keys(queriesProp).reduce(
          (prev, k) => ({
            ...prev,
            [k]: {
              date: since,
              first: MAX_CHUNK_SIZE,
            },
          }),
          {}
        );
        setVs(vars);
      }
    },
    [aggregateDefinitions]
  );

  return {
    assets,
    isDataLoading: !fullyLoaded,
    refetchSince,
  };
};

export const useAssetsLoader = (props: UseAssetsLoaderArgs): UseAssetsLoaderReturnType => {
  const { aggregateDefinitions } = props;

  const [projectStates, setProjectStates] = useState<WithAggregateName<ProjectState>>(
    createWithAggregateNameObject(aggregateDefinitions, () => ({}))
  );
  const { selectedProjects } = useSelectedProject();
  const { refetch: refetchProjects } = useProjects();
  // const client = useApolloClient();
  const [assets, setAllAssets] = useState<Array<MapAssetType>>([]);
  const [projectChanged, setProjectChanged] = useState<boolean>(true);
  const [reloading, setReloading] = useState<boolean>(true);

  // Generates query for every aggregate definition
  const queries = useMemo(() => createWithAggregateNameObject(aggregateDefinitions, definition => QUERY(definition)), [
    aggregateDefinitions,
  ]);

  const { loading, data, variables, error, called, refetch } = useLazyQueries<QueryResultDataType>(
    queries,
    {
      fetchPolicy: 'no-cache',
    },
    {}
  );

  // whenever projects changes, update our local project state
  useEffect(() => {
    setProjectChanged(true);

    setProjectStates(curProjectState =>
      recomputeProjectStates(curProjectState, selectedProjects, aggregateDefinitions)
    );
  }, [selectedProjects, setProjectChanged, aggregateDefinitions]);

  // any time we aren't loading data, check to see if we should be
  useEffect(() => {
    if (!loading && !error) {
      // Creates variables only for record types that have some projects to fetch
      const variablesProjectState = createVariablesForProjectStates(projectStates, called, aggregateDefinitions);
      const variablesRefrenceTypeProjectState = createVariablesForAggregateReferenceTypeProjectStates(
        projectStates,
        called,
        aggregateDefinitions
      );

      const variables = _.merge(variablesProjectState || {}, variablesRefrenceTypeProjectState || {});

      if (Object.keys(variables).length > 0) {
        const isRefresh = _.find(variables, v => {
          return v.filters?.lastModelRefresh;
        });
        if (isRefresh) {
          //If we are pulling data that is data coming in, update project counts as well
          refetchProjects();
        }
        refetch(variables);
        setProjectChanged(false);
      }
    }
  }, [aggregateDefinitions, called, error, loading, projectStates, refetch, refetchProjects, setProjectChanged]);

  // Now handle any new data
  useEffect(() => {
    // when we have new data, add it to the corresponding projectState
    if (variables && data && !projectChanged) {
      setProjectStates(curProjectState => {
        return addProjectsToProjectStates(curProjectState, data, variables, selectedProjects);
      });
    }
  }, [data, selectedProjects, variables, projectChanged, reloading]);

  // any time the project state changes, update the record/asset state from all of the projects
  useEffect(() => {
    setAllAssets(getAssetsFromProjectStates(projectStates));
  }, [projectStates]);

  const refetchAll = useCallback(
    (retainCursor?: boolean) => {
      aggregateDefinitions.forEach(definition => {
        const key = definition.queryKey;
        setProjectStates(prevState => ({ ...prevState, [key]: resetFetchedInfo(prevState[key], retainCursor) }));
      });
      return Promise.resolve();
    },
    [setProjectStates, aggregateDefinitions]
  );

  const getRefetchVarsObject = (since: string, project: string, isReferenceType: boolean) => {
    if (isReferenceType) {
      return {
        filters: { label: { notEqualTo: '' }, lastModelRefresh: { greaterThan: since } },
        first: 1000,
        after: null,
      };
    }

    return {
      filters: { projectId: { equalTo: project }, lastModelRefresh: { greaterThan: since } },
      first: 1000,
      after: null,
    };
  };

  const refetchSince = useCallback(
    (aggregateType: string, since: string, project: string, isReferenceType: boolean) => {
      const def = aggregateDefinitions.find(agg => agg.name === aggregateType);
      if (!def) {
        return;
      }
      setReloading(true);
      setProjectStates(prev => {
        if (prev[def.queryKey][project].refetchVars) {
          return prev;
        }
        return {
          ...prev,
          [def.queryKey]: {
            ...prev[def.queryKey],
            [project]: {
              ...prev[def.queryKey][project],
              refetchVars: getRefetchVarsObject(since, project, isReferenceType),
            },
          },
        };
      });
    },
    [setProjectStates, aggregateDefinitions, setReloading]
  );

  const deleteMultiple = useCallback(
    (aggregateType: string, aggregateIds: string[]) => {
      const def = aggregateDefinitions.find(agg => agg.name === aggregateType);
      if (!def) {
        console.log('Aggregate deleted for invalid aggregate type');
        return Promise.resolve();
      }
      if (!aggregateIds.length) {
        console.info('No aggregate ids to delete');
        return Promise.resolve();
      }
      setProjectStates(prevState => {
        const newStates = _.cloneDeep(prevState);
        for (const idx in aggregateIds) {
          const aggregateId = aggregateIds[idx];
          for (const pId in newStates[def.queryKey]) {
            newStates[def.queryKey][pId].records.delete(aggregateId);
          }
        }
        return newStates;
      });
      refetchProjects();
      return Promise.resolve();
    },
    [setProjectStates, aggregateDefinitions]
  );

  const deleteMultipleFromProjects = useCallback(
    (toDelete: { [project: string]: string[] }) => {
      setProjectStates(prevState => {
        const newStates = _.cloneDeep(prevState);
        for (const pId of Object.keys(toDelete)) {
          for (const item of toDelete[pId]) {
            for (const agg of Object.keys(newStates)) {
              if (newStates[agg][pId] && newStates[agg][pId].records) {
                newStates[agg][pId].records.delete(item);
              }
            }
          }
        }
        return newStates;
      });
      return Promise.resolve();
    },
    [setProjectStates]
  );

  const isDataLoading = useMemo(
    () =>
      aggregateDefinitions.reduce<boolean>(
        (result, { queryKey }) =>
          result || (projectStates[queryKey] ? !areAllProjectsFetched(projectStates[queryKey]) : false),
        false
      ),
    [projectStates, aggregateDefinitions]
  );

  return {
    assets,
    refetchAll,
    refetchSince,
    deleteMultiple,
    deleteMultipleFromProjects,
    isDataLoading,
  };
};
