import { useMemo, useState } from 'react';
import { useRecordType } from '../recordTypeContext';
import { MapAssetType } from './types';

export interface UseAssetsCountArgs {
  assets: MapAssetType[];
}

export interface UseAssetsCountReturnType {
  allAssetsCount: number;
  currentTypeAssetsCount: number;
}

export const useAssetsCount = (props: UseAssetsCountArgs): UseAssetsCountReturnType => {
  const { assets } = props;
  const [assetsCount, setAssetsCount] = useState(0);
  const [currentTypeAssetsCount, setCurrentTypeAssetsCount] = useState(0);

  const { selectedRecordTypeDefinition } = useRecordType();

  useMemo(() => {
    setAssetsCount(assets.length);
    const currentAssetTypes = assets.filter(
      (asset: any) => asset.recordTypeKey === selectedRecordTypeDefinition.queryKey
    );
    setCurrentTypeAssetsCount(currentAssetTypes.length);
  }, [assets, selectedRecordTypeDefinition]);

  return {
    allAssetsCount: assetsCount,
    currentTypeAssetsCount,
  };
};
