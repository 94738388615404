import React, { useEffect, useRef, useCallback } from 'react';
import logo from './../assets/loading.gif';
import { AuthConnector } from '@terragotech/gen5-shared-components';
import { makeStyles } from '@material-ui/core';

export interface ImageWithHeadersProps {
  url: string;
  className: string;
  onClick?: () => void;
}

export const ImageWithHeaders: React.FC<ImageWithHeadersProps> = ({ url, className, onClick }) => {
  const classes = useStyles();
  const imgEl = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const imageElement = imgEl;
    if (!imageElement?.current) return;
    imageElement.current.className = classes.loader;
    imageElement.current.src = logo;
  }, [url, classes.loader]);

  const fetchWithAuthentication = useCallback(async () => {
    const headers = new Headers();
    const token = await AuthConnector.getToken();
    headers.set('Authorization', `Bearer ${token}`);
    return fetch(url, { headers });
  }, [url]);

  const displayProtectedImage = useCallback(async () => {
    const response = await fetchWithAuthentication();
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    const imageElement = imgEl;

    if (!imageElement?.current) return;

    imageElement.current.className = className;
    imageElement.current.src = objectUrl;
    imageElement.current.onload = () => URL.revokeObjectURL(objectUrl);
  }, [fetchWithAuthentication, className]);

  useEffect(() => {
    displayProtectedImage();
  }, [displayProtectedImage]);

  return (
    <img src={logo} alt={'loading'} className={classes.loader} ref={imgEl} onClick={() => onClick && onClick()}></img>
  );
};

const useStyles = makeStyles(theme => ({
  loader: { height: '20%', width: '20%', maxWidth: '50px', maxHeight: '50px', objectFit: 'contain' },
}));
