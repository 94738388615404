import { MapAssetType } from '../types';

export const filterByQuickSearch = (assets: MapAssetType[], getQuickSearch: (key: string) => string) => {
  return assets.filter((asset) => {
    const quickSearch = getQuickSearch(asset.recordTypeKey);
    if (quickSearch) {
      const includedVals = Object.values(asset).filter((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(quickSearch.toLowerCase() as string);
        } else {
          return false;
        }
      });
      return includedVals.length > 0;
    }
    return true;
  });
};
