import React from 'react';
import { makeStyles, ListItemProps, ListItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

export interface TGDrawerBurgerButtonProps {
  open: boolean;
  onClick: ListItemProps['onClick'];
}

const TGDrawerBurgerButton = (props: TGDrawerBurgerButtonProps) => {
  const { open, onClick } = props;
  const classes = useStyles();

  return (
    <ListItem disableGutters={!open} onClick={onClick} className={open ? classes.container : ''}>
      {/* <IconButton color="primary" aria-label="open drawer" edge="start" className={classes.root}> */}
      {!open && <ArrowLeft color="primary" className={classes.leftIcon} />}
      <MenuIcon color="primary" />
      {open && <ArrowRight color="primary" className={classes.rightIcon} />}
      {/* </IconButton> */}
    </ListItem>
  );
};

const ICON_MARGIN = -9;
const ICON_WIDTH = 24;

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: 0,
  },
  root: {
    width: 2 * ICON_WIDTH + ICON_MARGIN,
    boxSizing: 'content-box',
  },
  leftIcon: {
    marginRight: ICON_MARGIN,
  },
  rightIcon: {
    marginLeft: ICON_MARGIN,
  },
}));

export default TGDrawerBurgerButton;
