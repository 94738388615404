import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Input, InputAdornment, Grow, makeStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';
import SearchIcon from '../symbols/SearchIcon';
import magicText from 'i18next';
import { useFilter } from '../../../../contexts/FilterContext/filterContext';
import AttributeSearchOnMapSelect from './AttributeSearchOnMapSelect';
import { getBoundsArrayFromResult, getBoundsFromLatLng } from './boundsUtils';
import { useAggregates } from '../../../../contexts/AggregatesContext';
import { useConfig, colors } from '@terragotech/gen5-shared-components';
import { useDebounce } from './useDebouncedRecordSearch';
interface TopSearchBarProps {
  setBounds: (bounds: any) => void;
}

const TopSearchBar: FunctionComponent<TopSearchBarProps> = props => {
  const { setBounds } = props;
  const classes = useStyles();
  const assetInfo = useAggregates();
  const { aggregateDefinitions } = useConfig();

  const { setQuickSearchForMap, getQuickSearchForRecordType } = useFilter();

  const [searchText, setSearchText] = React.useState('');
  const [mapSearchType, setMapSearchType] = React.useState('location');
  const isMapSearchTypeLocation = mapSearchType === 'location' ? true : false;
  const searchTypeDefinition = aggregateDefinitions.find(def => def.name === mapSearchType);

  const changeBounds = useCallback(
    (results: google.maps.GeocoderResult[], address: string, coords?: any) => {
      if (coords) {
        setBounds(coords);
      }
      if (!coords) {
        setBounds(getBoundsArrayFromResult(results[0]));
      }
    },
    [setBounds]
  );
  const handleLatLongPress = (searchText: string) => {
    const bounds = getBoundsFromLatLng(searchText);
    if (bounds) {
      setBounds(bounds);
    }
    setSearchText('');
  };
  const handleAddressSelected = useCallback(
    async (address: string, placeId: string) => {
      const bounds = getBoundsFromLatLng(address);
      if (bounds) {
        setBounds(bounds);
        return;
      }
      setSearchText('');
      if (placeId) {
        const getMoreAccurateCoords = async () => {
          const {
            geometry: {
              location: { lat, lng },
            },
          } = (await (geocodeByPlaceId(placeId) as any))[0];
          return [
            [lng(), lat()],
            [lng(), lat()],
          ];
        };

        geocodeByPlaceId(placeId)
          .then(async results => {
            // TODO: Use bounds coords from geocoder result for places
            changeBounds(results, address, await getMoreAccurateCoords());
          })
          .catch(() => window.alert('No results found. Please try a new search.'));
      } else {
        geocodeByAddress(address)
          .then(results => {
            changeBounds(results, address);
          })
          .catch(() => window.alert('No results found. Please try a new search.'));
      }
    },
    [changeBounds]
  );

  const displayResultsCount = useMemo(() => {
    let resultsCount = 0;
    if (searchText !== '' && !isMapSearchTypeLocation && searchTypeDefinition?.queryKey) {
      resultsCount = assetInfo.getCountOfRecordType(searchTypeDefinition.queryKey);
      return (
        <div className={classes.numberOfResults}>
          <>
            {' '}
            {resultsCount} {resultsCount - 1 ? magicText.t('Simple.Results') : magicText.t('Simple.Result')}
          </>
        </div>
      );
    }
  }, [searchText, isMapSearchTypeLocation, searchTypeDefinition, assetInfo, classes.numberOfResults]);

  // see here for implementation details https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  useDebounce(
    () => {
      if (mapSearchType !== 'location') {
        setQuickSearchForMap(searchText, mapSearchType);
      }
    },
    200,
    [setQuickSearchForMap, searchText, mapSearchType]
  );

  useEffect(() => {
    if (mapSearchType !== 'location') {
      let savedQuery = getQuickSearchForRecordType(mapSearchType);
      setSearchText(savedQuery);
    }
  }, [mapSearchType, getQuickSearchForRecordType]);

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
      <div className={classes.searchBarContainer}>
        <div className={classes.mapSearchBar}>
          <PlacesAutocomplete
            // Changing map search to table or location depending on user selection
            value={searchText}
            onChange={setSearchText}
            onSelect={handleAddressSelected}
            highlightFirstSuggestion={!!isMapSearchTypeLocation}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    {...getInputProps({
                      placeholder: magicText.t('map.searchPlaceholder'),
                    })}
                    disableUnderline={true}
                    className={classes.filter}
                    style={searchText !== '' ? { width: '100%' } : {}}
                    startAdornment={
                      <InputAdornment position="start">
                        <Search className={classes.searchIcon} />
                      </InputAdornment>
                    }
                  />
                  {displayResultsCount}
                </div>
                {isMapSearchTypeLocation && (
                  <div
                    className="autocomplete-dropdown-container"
                    style={{
                      position: 'absolute',
                      // setting this value to 40 will eliminate the gap
                      top: '50px',
                      boxShadow: '0px 2px 5px rgba(69, 81, 87, 0.2)',
                      borderRadius: '3px',
                      overflow: 'hidden',
                      maxWidth: '90vw',
                    }}
                  >
                    {loading && <div>Loading...</div>}
                    {(suggestions.length > 0 || (!_.isEmpty(searchText) && mapSearchType === 'location')) && (
                      <div className={classes.searchResultsBox}>RESULTS</div>
                    )}
                    {!_.isEmpty(searchText) && mapSearchType === 'location' && (
                      <div className={classes.suggestionItem} onClick={() => handleLatLongPress(searchText)}>
                        <div
                          style={{
                            marginRight: '12px',
                            marginLeft: '18px',
                            display: 'inline-block',
                          }}
                        >
                          <SearchIcon />
                        </div>
                        <span>{searchText}</span>
                      </div>
                    )}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? `${classes.suggestionItem} ${classes.suggestionItemActive}`
                        : classes.suggestionItem;
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                          key={index}
                        >
                          <div
                            style={{
                              marginRight: '12px',
                              marginLeft: '18px',
                              display: 'inline-block',
                            }}
                          >
                            <SearchIcon />
                          </div>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <AttributeSearchOnMapSelect mapSearchType={mapSearchType} setMapSearchType={setMapSearchType} />
      </div>
    </Grow>
  );
};

const useStyles = makeStyles(theme => ({
  searchBarContainer: {
    display: 'flex',
    maxWidth: '90vw',
  },
  mapSearchBar: {
    backgroundColor: colors.white,
    color: theme.palette.grey[200],
    boxShadow: '0px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    width: 400,
    height: 45,
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  searchIcon: {
    height: 20,
    width: 20,
    color: theme.palette.grey[400],
    marginLeft: '15px',
  },
  filter: {
    position: 'absolute',
    top: '7px',
    left: '50%',
    fontSize: 16,
    width: '40%',
    zIndex: 999,
    transform: 'translateX(-50%)',
    transition: 'all 300ms',
    '&:focus-within': {
      left: 0,
      transform: 'translateX(0)',
      width: '100%',
    },
  },
  searchResultsBox: {
    position: 'relative',
    marginTop: '5px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#aaaaaa',
    backgroundColor: '#ffffff',
    width: 496,
    paddingTop: '7px',
    paddingBottom: '7px',
    maxWidth: '90vw',
  },
  suggestionItem: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    color: '#666666',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    borderTop: '1px solid #eeeeee',
    width: 496,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  suggestionItemActive: {
    backgroundColor: theme.palette.primary.main,
    color: colors.white,
  },
  numberOfResults: {
    position: 'absolute',
    right: '5px',
    fontSize: '16px',
    color: colors.grayDescription,
    paddingLeft: '7px',
    borderLeft: '1px solid',
    borderLeftColor: colors.grayLine,
  },
}));

export default React.memo(TopSearchBar);
