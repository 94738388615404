import { MAP_SERVICE_DEFAULT_KEYS } from '@terragotech/gen5-shared-utilities';
import { useState, useMemo, useCallback, useEffect } from 'react';
import useUserPreferences from '../../hooks/useUserPreferences';
import { MapServiceType, MapServiceLayerType } from './types';

export interface UseMapServiceStateArgs {
  mapServices: MapServiceType[];
  mapServiceLayers: MapServiceLayerType[];
}

export interface UseMapServiceStateReturnType {
  visibleMapServiceKeys: string[];
  visibleMapServiceNames: string[];
  setVisibleMapServiceKeys: (keys: string[]) => void;
}

export const useMapServiceState = ({
  mapServices,
  mapServiceLayers,
}: UseMapServiceStateArgs): UseMapServiceStateReturnType => {
  const { getPreferenceValue, setPreferenceValue } = useUserPreferences();

  const [visibleMapServiceKeys, setVisibleMapServiceKeysInternal] = useState(
    getPreferenceValue<string[]>(MAP_SERVICE_DEFAULT_KEYS.visibilityPreferenceKey) ?? []  // Default to none selected.
  );

  useEffect(() => {
    setPreferenceValue(MAP_SERVICE_DEFAULT_KEYS.visibilityPreferenceKey, visibleMapServiceKeys);
  }, [setPreferenceValue, visibleMapServiceKeys]);

  const stateKeys = useMemo(() => {
    return [...mapServices, ...mapServiceLayers].map(x => ({id: x.id, label: x.label}));
  }, [mapServices, mapServiceLayers]);

  const visibleMapServiceNames = useMemo(
    () =>
      stateKeys
        .filter((key) => visibleMapServiceKeys.includes(key.id))
        .map((key) => key.label),
    [stateKeys, visibleMapServiceKeys]
  );

  const setVisibleMapServiceKeys = useCallback(
    (keys: string[]) => {
      const newKeys = stateKeys
        .filter((key) => keys.includes(key.id))
        .map((key) => key.id);
      setVisibleMapServiceKeysInternal(newKeys);
    },
    [stateKeys, setPreferenceValue]
  );

  return {
    visibleMapServiceKeys,
    visibleMapServiceNames,
    setVisibleMapServiceKeys,
  };
};
