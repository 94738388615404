import React, { createContext, useContext, useState, FunctionComponent, useMemo, useCallback } from 'react';
import { SortDirection } from '@terragotech/react-data-grid';
import { AssetType } from './AggregatesContext/types';
import { sort } from '../hooks/useTable';

export type AssetsTableStateContextType = {
  sortColumn: string;
  sortDirection: SortDirection;
  setSortColumn: (column: string) => void;
  setSortDirection: (direction: SortDirection) => void;
  sortAssets: (assets: ReadonlyArray<AssetType>) => ReadonlyArray<AssetType>;
};

const AssetsTableStateContext = createContext<AssetsTableStateContextType>({
  sortColumn: '',
  sortDirection: 'NONE',
  setSortColumn: () => {},
  setSortDirection: () => {},
  sortAssets: () => [],
});
export const AssetsTableStateContextProvider: FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState<SortDirection>('NONE');
  const sortAssets = useCallback((assets: ReadonlyArray<AssetType>) => sort(assets, sortDirection, sortColumn), [
    sortDirection,
    sortColumn,
  ]);
  const value: AssetsTableStateContextType = useMemo(
    () => ({
      sortColumn,
      sortDirection,
      setSortColumn,
      setSortDirection,
      sortAssets,
    }),
    [sortColumn, sortDirection, sortAssets]
  );
  return <AssetsTableStateContext.Provider value={value}>{props.children}</AssetsTableStateContext.Provider>;
};
export const useAssetsTableState = () => {
  const context = useContext(AssetsTableStateContext);
  if (!context) {
    throw new Error('useAssetsTableState should be used inside AssetsTableStateContext.Provider');
  }
  return context;
};
