import { Checkbox, Zoom } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, useEffect } from 'react';
import { useMultiSelectWithProvider } from 'use-multiselect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      height: 29,
      width: 30,
      position: 'absolute',
      top: 68,
      left: 0,
      zIndex: 4,
      backgroundColor: '#f8f8f8',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkbox: {
      padding: 0,
      left: 1,
    },
    indeterminate: {
      color: theme.palette.primary.main,
    },
  })
);

export interface SelectAllCheckboxProps {
  totalCount: any;
  disabled?: boolean;
  forceSelect?: boolean;
  loading?: boolean;
}

/**
 * SelectAllCheckbox -
 * Uses the selectedCount and total count to determine the current selection state.
 * Shows as checked if all items are selected, unchecked if no items are selected and indeterminate if some are selected
 *
 * On click, either selects all or unselects all.
 *
 * @param multiSelectChecked
 * @param selectAll
 * @param selectedCount
 * @param totalCount
 */
const SelectAllCheckbox: FunctionComponent<SelectAllCheckboxProps> = (props) => {
  const { totalCount, disabled, forceSelect, loading } = props;
  const classes = useStyles();
  const { isMultiSelectActive, selectAll, deSelectAll, getSelectedCount } = useMultiSelectWithProvider();
  const selectedCount = getSelectedCount(totalCount);

  // deselect automatically whenever multiselect is turned off
  useEffect(() => {
    if (!isMultiSelectActive) {
      deSelectAll();
    }
  }, [isMultiSelectActive, deSelectAll]);

  useEffect(() => {
    if (forceSelect && !!!loading) {
      selectAll();
    }
  }, [forceSelect, loading]);

  return (
    <Zoom in={isMultiSelectActive}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          disabled={disabled}
          classes={{ root: classes.checkbox, indeterminate: classes.indeterminate }}
          indeterminate={selectedCount > 0 && selectedCount !== totalCount}
          checked={selectedCount === totalCount && selectedCount > 0}
          onClick={(e: any) => {
            if (selectedCount > 0) {
              deSelectAll();
            } else {
              selectAll();
            }
          }}
        />
      </div>
    </Zoom>
  );
};
export default SelectAllCheckbox;
