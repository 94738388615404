import React from 'react';
import { userInfoSection } from '../components/MapRightDrawer/UserInfoSection';
import { onOffSection } from '../components/MapRightDrawer/OnOffSection';
import { ClusteringIcon } from '../components/MapRightDrawer/ClusteringIcon';
import { CardIcon } from '../components/MapRightDrawer/CardIcon';
import { useAssetCards } from '../contexts/assetCardContext';
import magicText from 'i18next';
import { useSettingsSection } from './sectionsHooks/useSettingsSection';
import { DrawerItem } from '../components/TGDrawer/TGDrawer';
import { useConfig, useAuthContext } from '@terragotech/gen5-shared-components';
import { useMapTypeSection } from './sectionsHooks/useMapTypeSection';
import { useMapVisibilitySection, getMapAggregateDefinitions } from './sectionsHooks/useMapVisibilitySection';
import { useAggregates } from '../contexts/AggregatesContext';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MAP_LABEL_PREFERENCE_KEY,
  CARD_VIEW_PREFERENCE_KEY,
  PIN_CLUSTER_PREFERENCE_KEY,
  menuSelectionsState,
  forcedClusteringState,
} from '../recoil/atoms';
import { useMapServiceSection } from './sectionsHooks/useMapServiceSection';

export interface UseSections {
  items: DrawerItem[];
  isAboutOpen: boolean;
  setIsAboutOpen: (value: boolean) => void;
}

export const useSections = (): UseSections => {
  const [menuSelectionsStateValue, setMenuSelectionsStateValue] = useRecoilState(menuSelectionsState);
  const forcedClustering = useRecoilValue(forcedClusteringState);

  const { visibleAggregateTypesNames, mapServices } = useAggregates();

  const { areCardsDefined } = useAssetCards();

  const { clearToken, familyName, givenName, email } = useAuthContext();

  const { section: settingsSection, isAboutOpen, setIsAboutOpen } = useSettingsSection({
    name: givenName && familyName ? `${givenName} ${familyName}` : '',
    email: email ?? '',
  });

  const { section: mapTypeSection } = useMapTypeSection();

  const { section: mapVisibilitySection } = useMapVisibilitySection();

  const { section: mapServiceSection } = useMapServiceSection();

  const config = useConfig();
  const aggregateDefinitions = getMapAggregateDefinitions(config);

  const items = [
    userInfoSection({
      firstName: givenName ?? '',
      lastName: familyName ?? '',
      email: email ?? '',
      clearToken: clearToken ?? '',
    }),
    settingsSection,
    mapTypeSection,
    onOffSection({
      onIcon: <ClusteringIcon color="primary" />,
      offIcon: <ClusteringIcon color="disabled" />,
      title: magicText.t('map.clustering.header'),
      onToggle: (_event, checked) => {
        setMenuSelectionsStateValue({ ...menuSelectionsStateValue, ...{ [PIN_CLUSTER_PREFERENCE_KEY]: checked } });
      },
      isOn: menuSelectionsStateValue[PIN_CLUSTER_PREFERENCE_KEY] || forcedClustering,
      disable: forcedClustering || false,
      tooltip: forcedClustering || false ? 'Reduce the number of records in view to turn clustering off' : '',
    }),
  ];

  let showMapLabel = false;
  visibleAggregateTypesNames.forEach(visibleAggregateType => {
    const aggregate = aggregateDefinitions.find(val => {
      return val.name === visibleAggregateType;
    });
    if (aggregate && aggregate.mapLabelProperties && aggregate.mapLabelProperties.length > 0) {
      showMapLabel = true;
    }
  });

  if (showMapLabel) {
    items.push(
      onOffSection({
        title: magicText.t('map.mapLabels.header'),
        onToggle: (_event, checked) => {
          setMenuSelectionsStateValue({ ...menuSelectionsStateValue, ...{ [MAP_LABEL_PREFERENCE_KEY]: checked } });
        },
        isOn: menuSelectionsStateValue[MAP_LABEL_PREFERENCE_KEY],
      })
    );
  }

  if (areCardsDefined) {
    items.push(
      onOffSection({
        onIcon: <CardIcon color="primary" />,
        offIcon: <CardIcon color="disabled" />,
        title: magicText.t('map.cards.header'),
        onToggle: (_event, checked) => {
          setMenuSelectionsStateValue({ ...menuSelectionsStateValue, ...{ [CARD_VIEW_PREFERENCE_KEY]: checked } });
        },
        isOn: menuSelectionsStateValue[CARD_VIEW_PREFERENCE_KEY],
      })
    );
  }

  items.push(mapVisibilitySection);

  if (mapServices?.length) items.push(mapServiceSection);

  return { items, isAboutOpen, setIsAboutOpen };
};
