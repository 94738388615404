import { useCallback, useEffect } from 'react';
import { useState } from 'react';

/**
 * @param {timeout=} boolean - Safeguards against race conditions. Time should be the same value as your css animation.
 * @yields {animationClasses=} boolean - Add this to the component(s) you want to animate.
 * @yields {setToggle=} (arg0: boolean => void) - Add this to the function(s) you want to trigger your animation.
 */

interface StateProps {
  state: boolean;
  type: string | null;
}

const useAnimation = (timeout: number) => {
  const defaultState = { state: false, type: null };
  const [toggle, setToggle] = useState<StateProps>(defaultState);
  const [animation, setAnimation] = useState<number>(0);

  // paint animations
  const renderAnimations = useCallback(() => {
    return toggle.state ? setAnimation(1) : setAnimation(0);
  }, [toggle]);

  // cleanup animations
  const cleanupAnimations = useCallback(() => {
    setTimeout(() => {
      if (toggle.state) setToggle(defaultState);
    }, timeout);
  }, [toggle]);

  // handle paint & cleanup
  useEffect(() => {
    renderAnimations();
    return cleanupAnimations();
  }, [cleanupAnimations, renderAnimations, toggle]);

  const getClasses = () => {
    if (animation) return toggle.type === 'subfolder' ? '_animate-left' : '_animate-right';

    return '';
  };

  return {
    animationClasses: getClasses(),
    setToggle,
  };
};

export default useAnimation;
