import { useMemo, CSSProperties } from 'react';
import { Button, useTheme } from '@material-ui/core';
import csvSuccess from '../../images/csvSuccess.svg';
import csvError from '../../images/csvError.svg';
import xlsSuccess from '../../images/xlsSuccess.svg';
import xlsError from '../../images/xlsError.svg';
import { SelectedImportType } from './ImportHistory';
import { useConfig } from '@terragotech/gen5-shared-components';
import { Import } from './ImportHistory';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { downloadFile } from './FileToView';
import { DOC_API_URL } from '../../utils/docApiURL';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';

const useStyles = makeStyles(theme => ({
  cellFontStyle: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    padding: '10px',
  },
  fileCell: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    maxWidth: 300,
    padding: '10px',
  },
}));

interface DataProp {
  selectedImport?: SelectedImportType;
  setSelectedImport: React.Dispatch<React.SetStateAction<SelectedImportType | undefined>>;
  imp: Import[];
  setFileIdToView: (x: string) => void;
}
interface Props {
  data: DataProp;
  index: number;
  style: CSSProperties;
}

const CompletedJobRow = ({ data, index, style }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const specifiedImport = data?.imp[index];
  const { defaultDateTimeFormat } = useConfig();

  const fileExtension: string | undefined = specifiedImport?.filename.split('.').pop();
  const isXLS: boolean = fileExtension === 'xlsx' || fileExtension === 'xls';

  const status = useMemo(() => {
    if (specifiedImport?.commandError != null) {
      return 'Error';
    } else if (specifiedImport?.success) {
      return 'Imported';
    } else if (specifiedImport?.errorsWarnings.filter(e => e.is_error).length || specifiedImport?.committed) {
      return 'Failed';
    } else {
      return 'Cancelled';
    }
  }, [specifiedImport]);

  const showDetailsButton = useMemo(() => {
    return !specifiedImport?.commandError || specifiedImport?.commandError > 10130; // HandleImportValidate_UpdateImport (services/graphql_api/src/services/service.import.ts)
  }, [specifiedImport]);

  return (
    <TableRow style={{ backgroundColor: '#FAFAFA' }}>
      <TableCell align={'left'} className={classes.fileCell}>
        <div style={{ display: 'flex' }}>
          <div>
            {isXLS && specifiedImport?.success === true && <img height={35} src={xlsSuccess} alt="File Type Icon" />}
            {isXLS && specifiedImport?.success !== true && <img height={35} src={xlsError} alt="File Type Icon" />}
            {!isXLS && specifiedImport?.success === true && <img height={35} src={csvSuccess} alt="File Type Icon" />}
            {!isXLS && specifiedImport?.success !== true && <img height={35} src={csvError} alt="File Type Icon" />}
          </div>
          <div style={{ marginLeft: 9, display: 'flex', alignItems: 'center', maxWidth: 300, overflow: 'hidden' }}>
            {specifiedImport?.filename ? specifiedImport?.filename : 'TempFile'}
          </div>
        </div>
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {specifiedImport?.type}
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {status}
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {specifiedImport?.metadata?.userInfo?.email}
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        <span>
          {specifiedImport?.timestamp ? moment(String(specifiedImport?.timestamp)).format(getDateTimeFormat(
            defaultDateTimeFormat?.dateFormatType,
            defaultDateTimeFormat?.dateFormat,
            defaultDateTimeFormat?.dateSeperator,
            defaultDateTimeFormat?.timeFormat,
            { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS },
            defaultDateTimeFormat?.isSwitch,
          )) : ''}
        </span>
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {!specifiedImport.isintegration && showDetailsButton && (
          <Button
            style={{ color: `${theme?.palette?.primary?.main || '#56CCF2'}`, borderRadius: 7 }}
            color="primary"
            variant="outlined"
            onClick={() => {
              data.setFileIdToView(specifiedImport?.id);
              data.setSelectedImport(specifiedImport);
            }}
          >
            Records
          </Button>
        )}
        {specifiedImport.isintegration && showDetailsButton && (
          <Button
            style={{ color: `${theme?.palette?.primary?.main || '#56CCF2'}`, borderRadius: 7 }}
            color="primary"
            variant="outlined"
            onClick={() => {
              downloadFile(
                `${DOC_API_URL}${specifiedImport?.id}`,
                `${specifiedImport?.filename.substring(0, specifiedImport?.filename.lastIndexOf('.')) || specifiedImport?.filename}`,
                undefined,
                specifiedImport?.filename.substring(specifiedImport?.filename.lastIndexOf('.')+1) 
              )
            }}
          >
            Download
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CompletedJobRow;
