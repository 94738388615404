import { Fade } from '@material-ui/core';
import { createStyles, Theme, useTheme, withStyles, WithStyles } from '@material-ui/core/styles';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import distance from '@turf/distance';
import { Coord, Polygon, polygon, Properties, Units } from '@turf/helpers';
import GeoJSON, { Feature, LineString, Point } from 'geojson';
import _ from 'lodash';
import * as MapboxGl from 'mapbox-gl';
import { MapboxGeoJSONFeature, MapLayerEventType } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { getUnitAbbreviation, MapBoundsContext, round, useConfig } from '@terragotech/gen5-shared-components';
import { along, length, lineString } from '@turf/turf';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactMapboxGl, { Layer, Popup, Source } from 'react-mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Coordinates } from '../../../contexts/assetCardContext';
import { FilterContext } from '../../../contexts/FilterContext/filterContext';
import { MapBounds } from '../../../contexts/FilterContext/types';
import { MapStyleContext } from '../../../contexts/mapStyle';
import { accessToken } from '../../../hooks/MapUtils';
import { ValueType } from '../../../hooks/useTable';
import { forcedClusteringState, pinClusteringSelector } from '../../../recoil/atoms';
import { Symbol } from '../../Legend';
import MapControlsOverlay from './ControlsOverlay/MapControlsOverlay';
import MapSearchControlOverlay from './ControlsOverlay/MapSearchControlOverlay';
import RightControls from './RightControls';
import { styleProvider as defaultStyleProvider } from './SymbolCreator';
import { useSelectedLocation } from '../../../contexts/selectedLocationContext';

export const LINE_ZOOM = 12;
export const MAX_ZOOM = 20;
export const CLUSTER_DISTANCE_MARKER_ZOOM = 18;
export const MAX_ZOOM_CLUSTERING_LEVEL = 22;
export const MAX_LINE_MEASUREMENT_ZOOM = 14;
export const MIN_MAP_LABEL_ZOOM = 12;
export const MAP_LABEL_MAX_CHARS: number = 30;
export const MAP_FORCE_CLUSTERING_SIZE: number = 5000;
// This is a delay introduced to the map zoom to changes, which helps eliminate rendering hitches while ag-grid is simultaneously updating
const AG_GRID_RENDER_DELAY = 75;
const MAX_ZOOM_IN_CLUSTER = 16;
export const SYMBOL_LAYER = 'symbol';
const LINE_LAYER = 'line';
const SELECTED_LINE_LAYER = 'selectedLine';
const CLUSTER_LAYER = 'clusters';
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
MapboxGl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapBox = ReactMapboxGl({
  accessToken: accessToken,
  maxZoom: MAX_ZOOM,
});

export interface MapItem {
  styleKey: string;
  id?: string;
  selected?: boolean;
  location: GeoJSON.Point | GeoJSON.LineString;
  aggregateType: string;
  mapLabelProperties?: Array<{ label: string | undefined; value: ValueType }> | null;
  showMeasurementOnMap?: boolean;
}

export function buildSourceDataFromItems(
  items: Array<MapItem>,
  mapViewBox: any,
  currentZoomLevel: number,
  configUnits: any,
  configRoundingPrecision: any,
  unitsAbbreviation: string
): [GeoJSON.FeatureCollection, GeoJSON.FeatureCollection, GeoJSON.FeatureCollection] {
  let symbolSource: Feature<Point>[] = [];
  let lineSource: Feature<LineString>[] = [];
  let lineMeasurement: Feature<Point>[] = [];

  items.forEach(item => {
    if (item.location.type === 'Point') {
      const symbolItem = {
        geometry: item.location,
        type: 'Feature',
        properties: {
          assetId: item.id,
          selected: item.selected,
          ...item,
        },
      } as const;
      symbolSource.push(symbolItem);
    } else {
      const symbolParts = item.styleKey.split('_');
      const lineStyle = symbolParts[1];
      const lineWidth = symbolParts[2];
      const lineColor = `#${symbolParts[3]}`;
      const lineItem = {
        geometry: item.location,
        type: 'Feature',
        properties: {
          assetId: item.id,
          selected: item.selected,
          lineWidth: Number(lineWidth),
          lineColor,
          lineDashArray: lineStyle,
          location: item.location,
          showMeasurementOnMap: item.showMeasurementOnMap || false,
        },
      } as const;
      lineSource.push(lineItem);
    }
  });
  if (currentZoomLevel > MAX_LINE_MEASUREMENT_ZOOM) {
    lineSource
      .filter(line => line.properties?.showMeasurementOnMap)
      .forEach(item => {
        let previousLocation: number[] | undefined;
        item.geometry.coordinates.forEach((location: number[]) => {
          if (previousLocation) {
            let midPoint = getMidpoint([location, previousLocation]);
            if (inBounds(midPoint, mapViewBox)) {
              let distanceInUnits = distance(location, previousLocation, { units: configUnits });
              let roundedDistance = round(distanceInUnits, configRoundingPrecision);

              const measurementItem = {
                geometry: {
                  type: 'Point',
                  coordinates: midPoint,
                } as GeoJSON.Point,
                type: 'Feature',
                properties: {
                  measurement: `${roundedDistance}${unitsAbbreviation}`,
                },
              } as const;
              lineMeasurement.push(measurementItem);
            }
          }
          previousLocation = location;
        });
      });
  }
  return [
    { type: 'FeatureCollection', features: symbolSource },
    { type: 'FeatureCollection', features: lineSource },
    { type: 'FeatureCollection', features: lineMeasurement },
  ];
}

const getMidpoint = (coords: number[][]): [number, number] => {
  const xMidPoint = (coords[0][0] + coords[1][0]) / 2;
  const yMidPoint = (coords[0][1] + coords[1][1]) / 2;
  return [xMidPoint, yMidPoint];
};

const inBounds = function (location: Coord, boundsGeometry?: Feature<Polygon, Properties>) {
  if (!boundsGeometry) {
    return true;
  }
  let isContainedInViewPort = booleanPointInPolygon(location, boundsGeometry);

  return isContainedInViewPort;
};

export function buildMapLabelMarkers(items: Array<MapItem>, classes: any): any {
  let mapLabels: any = [];

  items.forEach(item => {
    if (item.location.type === 'LineString' || (item.location.type === 'Point' && item.mapLabelProperties)) {
      mapLabels.push({ coordinates: item.location.coordinates, mapLabels: item.mapLabelProperties });
    }
  });

  const truncateValue = (label: string, value: any) => {
    const totalString = (label || 0) + (value || 0);
    let newValue = value;
    if (totalString.length > MAP_LABEL_MAX_CHARS && newValue) {
      const requiredLength = MAP_LABEL_MAX_CHARS - (label?.length || 0);
      newValue = [
        value?.slice(0, Math.abs(requiredLength / 2)),
        '...',
        value?.slice(Math.abs((value?.length || 0) - requiredLength / 2)),
      ].join('');
    }
    return newValue;
  };

  const getCoords = (coords: any) => {
    if (coords.length > 1 && isNaN(coords[0])) {
      const polyline = lineString(coords);
      const polylineLength = length(polyline);
      const midpointDistance = polylineLength / 2;

      const midpoint = along(polyline, midpointDistance);
      const midpointLatitude = midpoint.geometry.coordinates[1];
      const midpointLongitude = midpoint.geometry.coordinates[0];
      return [midpointLongitude, midpointLatitude];
    }
    return coords;
  };

  let mapLabelMarker: any[] = [];
  mapLabels.map((data: any, index: any) => {
    let spans: any[] = [];
    data?.mapLabels?.map((item: any, index: number) => {
      item.value &&
        spans.push(
          <div key={index} className={classes.popupItem}>
            {item.label && (
              <span className={classes.popupItemValue}>
                <strong>{item.label + ': '}&nbsp;</strong>
              </span>
            )}
            <span className={classes.popupItemValue}>{item.value && truncateValue(item.label, item.value)}</span>
          </div>
        );
    });
    if (spans.length > 0) {
      mapLabelMarker.push(
        // @ts-ignore
        <Popup
          coordinates={getCoords(data.coordinates)}
          anchor={'bottom'}
          offset={13}
          key={index}
          className={classes.popupWrapper}
        >
          <> {spans as any}</>
        </Popup>
      );
    }
  });

  return mapLabelMarker;
}

export type EventHandler = {
  eventName: 'click' | 'mouseenter' | 'mouseleave' | 'contextmenu';
  layerName: string;
  handler: (e: MapboxGl.MapLayerMouseEvent) => void;
};

export type TouchEventHandler = {
  eventName: 'touchend' | 'touchstart' | 'contextmenu';
  layerName: string;
  handler: (e: MapboxGl.MapLayerTouchEvent) => void;
};

export type MaxZoomClickHandler = (
  map: MapboxGl.Map,
  feature: MapboxGl.MapboxGeoJSONFeature | undefined,
  location: Coordinates
) => void;
interface MapProps extends WithStyles<typeof styles> {
  desiredCenter?: [number, number];
  desiredZoom?: number;
  desiredBounds?: FitBounds;
  items?: Array<MapItem>;
  styleProvider?: (styleKey: string, size: number) => Promise<object>;
  height?: unknown;
  width?: number;
  onStyleLoad: MapEvent;
  onSymbolClick?: (e: MapLayerEventType['click' | 'touchend']) => void;
  handleLongClick?: (e: MapLayerEventType['contextmenu' | 'touchstart']) => void;
  onMaxZoomClusterClick?: MaxZoomClickHandler;
  showMapControls?: boolean;
  showRightControls?: boolean;
  limitedRightControls?: boolean;
  symbols?: Symbol[];
  isSelectedEndpoint?: boolean;
  children?: any;
  onClick?: any;
  setMapLabelItems?: (items: MapboxGeoJSONFeature[]) => void;
  selectedClusterId?: number;
  setSelectedClusterId?: React.Dispatch<React.SetStateAction<number>>;
  selectAggregateParentCluster?: (data: any) => void;
  selectedAggregateCusterId?: string;
  showMapLabels?: boolean;
  setMapViewBox: any;
  mapViewBox?: any;
  setMapCenter?: (center: [number, number]) => void;
  mapRasterSources?: Record<string, MapboxGl.RasterSource>;
  mapVisibleMapServiceKeys?: readonly string[];
  forceDisableClustering?: boolean;
  wmsControls?: boolean;
  setMapBounds?: any;
}

/**
 *  Map - is home to much of the logic controlling the Map
 *
 * Maps layers ontop of map, handles what happens when layers are clicked.
 *
 * @param height
 * @param width
 * @param desiredZoom
 * @param desiredCenter
 * @param items
 * @param clusterOn - boolean
 */
const Map: FunctionComponent<MapProps> = props => {
  const {
    classes,
    items,
    desiredCenter,
    desiredZoom,
    desiredBounds,
    height,
    width,
    onStyleLoad,
    onSymbolClick,
    showMapControls,
    showRightControls,
    limitedRightControls,
    symbols,
    onMaxZoomClusterClick,
    isSelectedEndpoint,
    onClick,
    handleLongClick,
    selectedClusterId,
    setSelectedClusterId,
    showMapLabels,
    setMapViewBox,
    mapViewBox,
    mapRasterSources,
    mapVisibleMapServiceKeys,
    forceDisableClustering,
    wmsControls,
  } = props;

  const mapRef = useRef<MapboxGl.Map | null>(null);
  const { symbolScaleFactor, geographic, integrations } = useConfig();
  const theme = useTheme();
  const { setMapBounds, filterCount, mapFilterState } = useContext(FilterContext);
  const { mapStyle, mapType } = React.useContext(MapStyleContext);
  // const { cluster, setForcedClustering } = React.useContext(MapClusterContext);
  const [forcedClustering, setForcedClustering] = useRecoilState(forcedClusteringState);
  const clusteringEnabled = useRecoilValue(pinClusteringSelector);
  const { polylineRoundingPrecision, polylineUnitOfMeasurement } = geographic || {
    polylineRoundingPrecision: undefined,
    polylineUnitOfMeasurement: undefined,
  };
  const configRoundingPrecision: 'ones' | 'tenths' | 'hundredths' | 'thousandths' = polylineRoundingPrecision || 'ones';
  const configUnits: Units = polylineUnitOfMeasurement || ('feet' as Units);
  const unitsAbbreviation = getUnitAbbreviation(configUnits);
  const { currentZoomLevel, setCurrentZoomLevel, mapCenter } = useContext(MapBoundsContext);
  const filterCounter = useRef(filterCount);
  const { selectedLocation } = useSelectedLocation();
  const longClickRef = useRef(false);

  useEffect(() => {
    if (!selectedLocation) longClickRef.current = false;
  }, [selectedLocation]);

  // const [currentZoomLevel, setCurrentZoomLevel] = useState<number>(10);
  const [symbolSourceData, lineSourceData, lineMeasurement] = useMemo(() => {
    return items
      ? buildSourceDataFromItems(
          items,
          mapViewBox,
          currentZoomLevel,
          configUnits,
          configRoundingPrecision,
          unitsAbbreviation
        )
      : [null, null, null];
  }, [items, currentZoomLevel, mapViewBox]);

  const isClustered = useMemo(() => forcedClustering || (!forceDisableClustering && clusteringEnabled), [
    forcedClustering,
    forceDisableClustering,
    clusteringEnabled,
  ]);

  const mapLabelMarkers = useCallback(
    (classes: any) => {
      if (!showMapLabels || !items || currentZoomLevel < MIN_MAP_LABEL_ZOOM || !mapRef) {
        return [];
      }
      let nonClusterDataSymbolsource: Array<any> =
        mapRef.current?.querySourceFeatures('symbolSource', { sourceLayer: SYMBOL_LAYER }) || [];
      let nonClusterDataLineSource: Array<any> =
        mapRef.current?.querySourceFeatures('lineSource', { sourceLayer: LINE_LAYER }) || [];

      var nonClusterDataSourceLayer = nonClusterDataSymbolsource.concat(nonClusterDataLineSource);
      let nonClusteredAssetIds = _.uniq(
        _.map(nonClusterDataSourceLayer, x => {
          return x.properties?.assetId;
        })
      );
      return buildMapLabelMarkers(
        items.filter(x => _.includes(nonClusteredAssetIds, x.id)),

        classes
      );
    },
    [showMapLabels, items, isClustered]
  );
  const [mapRefUpdated, setMapRefUpdated] = useState(0);
  const handleStyleLoad: MapEvent = (map, evt) => {
    if (map && mapRef.current !== map) {
      mapRef.current = map;
      setMapRefUpdated(current => current + 1);
    }
    onStyleLoad && onStyleLoad(map, evt);
  };
  const [localBounds, setLocalBounds] = useState<any>(undefined);
  const [fitOptions] = useState({ duration: 0 });
  const [localZoom] = useState<[number]>([currentZoomLevel || 10]);
  const [localCenter] = useState(mapCenter);

  const [refreshRecords, setRefreshRecords] = useState(false);

  //If the fitBounds change, update our local state
  useEffect(() => {
    desiredBounds && setTimeout(() => setLocalBounds(desiredBounds), AG_GRID_RENDER_DELAY);
  }, [desiredBounds]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [height, width]);
  // this gives mapbox time to have created the map so that it can actually receive the resize event
  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 800);
  }, []);

  useEffect(() => {
    const points = items?.filter(x => x.location?.type === 'Point')?.length || 0;
    setForcedClustering(points > MAP_FORCE_CLUSTERING_SIZE);
  }, [items, setForcedClustering]);

  useEffect(() => {
    const { mapBounds } = mapFilterState;
    const bounds = (mapRef?.current?.getBounds() as unknown) as MapBounds;
    filterCount &&
      (!mapBounds || bounds?._ne !== mapBounds?._ne || bounds?._sw !== mapBounds?._sw) &&
      setMapBounds(bounds);
    filterCounter.current = filterCount;
  }, [filterCount]);

  const onMove = useCallback(
    (map: MapboxGl.Map) => {
      setRefreshRecords(true);
      const bounds = map.getBounds();
      const center = map.getCenter();
      if (props.setMapCenter) {
        props.setMapCenter([center.lng, center.lat]);
      }
      //@ts-ignore
      filterCounter.current && setMapBounds(bounds);

      const boundsGeometry = polygon([
        [
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
          [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
          [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        ],
      ]);
      setMapViewBox(boundsGeometry);
      if (props.setMapBounds) {
        props.setMapBounds(
          (prev: any) => {
            const n = {
              minLat: bounds.getSouth(),
              minLon: bounds.getWest(),
              maxLat: bounds.getNorth(),
              maxLon: bounds.getEast(),
            };
            if (JSON.stringify(n) !== JSON.stringify(prev)) {
              return n;
            } else {
              return prev;
            }
          },
          [mapViewBox]
        );
      }
      // @ts-ignore
      const zoom = map.transform.tileZoom;
      setCurrentZoomLevel(zoom);
      setRefreshRecords(false);
    },
    [setMapBounds, filterCount, setCurrentZoomLevel]
  );
  useEffect(() => {
    if (mapRef.current && desiredCenter && desiredZoom) {
      mapRef.current.easeTo({
        center: desiredCenter,
        zoom: desiredZoom,
      });
    } else if (mapRef.current && mapCenter && currentZoomLevel) {
      mapRef.current.easeTo({
        center: mapCenter,
        zoom: currentZoomLevel,
      });
    }
  }, [mapRef.current, desiredZoom, desiredCenter]);

  // sets initial map bounds after filter setup and sets null if no filter is set
  useCallback(() => {
    const map = mapRef.current;
    if (map && filterCounter.current) {
      onMove(map);
    } else {
      setMapBounds(null);
    }
  }, [setMapBounds, filterCount, onMove, refreshRecords]);

  //Various event handlers
  const onClusterClick = useCallback(
    (ev: MapLayerEventType['click' | 'touchend']) => {
      //target seems to be the map the was clicked
      //also includes features
      const map = ev.target;
      const feature = ev.features?.[0];
      if (map && feature && feature.id && feature.geometry.type === 'Point') {
        const coordinates = feature.geometry.coordinates as [number, number]; //cast as a tuple
        const clusterId = typeof feature.id === 'string' ? parseFloat(feature.id) : feature.id;
        const source = map.getSource('symbolSource') as MapboxGl.GeoJSONSource;
        source.getClusterExpansionZoom(clusterId, (_, zoom) => {
          if (zoom > MAX_ZOOM_IN_CLUSTER) {
            onMaxZoomClusterClick && onMaxZoomClusterClick(map, feature, { lat: coordinates[1], lng: coordinates[0] });
            setSelectedClusterId && setSelectedClusterId(clusterId);
          } else {
            map.easeTo({
              center: coordinates,
              zoom: Math.min(zoom, MAX_ZOOM_IN_CLUSTER + 1),
            });
          }
        });
      }
    },
    [onMaxZoomClusterClick]
  );

  const onMouseEnter = useCallback(() => {
    const map = mapRef.current;
    if (map) {
      map.getCanvasContainer().style.cursor = 'pointer';
    }
  }, []);
  const onMouseLeave = useCallback(() => {
    const map = mapRef.current;
    if (map) {
      map.getCanvasContainer().style.cursor = '';
    }
  }, []);

  const onSymbolClickWrapper = useCallback(
    (ev: MapLayerEventType['click' | 'touchend']) => {
      // Don't process for long-clicks.
      if (longClickRef.current) return;
      // Don't process for pinch-zooming.
      if (ev.type === 'touchend' && ev.points.length >= 2) return;
      // Don't process for map movements.
      if (ev.target.isEasing() || ev.target.isMoving() || ev.target.isRotating() || ev.target.isZooming()) return;

      const feature = ev.features?.[0];
      if (
        feature &&
        feature.properties?.id &&
        feature.properties.id.match(/Cluster-/) &&
        feature.geometry.type === 'Point'
      ) {
        ev.target.easeTo({
          center: feature.geometry.coordinates as [number, number],
          zoom: currentZoomLevel + 2,
        });
      } else {
        onSymbolClick && onSymbolClick(ev);
      }
    },
    [onClusterClick, onSymbolClick, currentZoomLevel]
  );

  // useEffect(() => {
  //   window.addEventListener('touchend', this.mousewheel.bind(this), {passive:false})
  //   return () => window.removeEventListener("touchend", onSymbolClick);
  // }, []);

  useEffect(() => {
    //We have to manage the click handlers manually due to an issue with react mappbox-gl not cleaning them up properly
    const map = mapRef.current;
    const eventHandlers: Array<EventHandler | TouchEventHandler> = [];
    if (map) {
      const addHandler = (event: EventHandler | TouchEventHandler) => {
        // @ts-ignore
        map.on(event.eventName, event.layerName, event.handler);
        eventHandlers.push(event);
      };
      if (handleLongClick) {
        let mobileTimeout: any = null;
        let clearMobileTimeout = () => {
          clearTimeout(mobileTimeout);
        };

        map.on('touchstart', e => {
          console.log('start', e);
          if (e.originalEvent.touches.length > 1) {
            return;
          }
          mobileTimeout = setTimeout(() => {
            handleLongClick(e);
            longClickRef.current = true;
          }, 500);
        });
        map.on('touchend', clearMobileTimeout);
        map.on('touchcancel', clearMobileTimeout);
        map.on('touchmove', clearMobileTimeout);
        map.on('pointerdrag', clearMobileTimeout);
        map.on('pointermove', clearMobileTimeout);
        map.on('moveend', clearMobileTimeout);
        map.on('gesturestart', clearMobileTimeout);
        map.on('gesturechange', clearMobileTimeout);
        map.on('gestureend', clearMobileTimeout);

        map.on('contextmenu', handleLongClick);
      }
      //create click handlers
      onSymbolClick && addHandler({ eventName: 'click', layerName: SYMBOL_LAYER, handler: onSymbolClickWrapper });
      onSymbolClick && addHandler({ eventName: 'click', layerName: LINE_LAYER, handler: onSymbolClickWrapper });
      addHandler({ eventName: 'click', layerName: CLUSTER_LAYER, handler: onClusterClick });

      onSymbolClick && addHandler({ eventName: 'touchend', layerName: SYMBOL_LAYER, handler: onSymbolClickWrapper });
      onSymbolClick && addHandler({ eventName: 'touchend', layerName: LINE_LAYER, handler: onSymbolClickWrapper });
      addHandler({ eventName: 'touchend', layerName: CLUSTER_LAYER, handler: onClusterClick });

      // Cursor handlers
      addHandler({ eventName: 'mouseenter', layerName: SYMBOL_LAYER, handler: onMouseEnter });
      addHandler({ eventName: 'mouseenter', layerName: LINE_LAYER, handler: onMouseEnter });
      addHandler({ eventName: 'mouseenter', layerName: CLUSTER_LAYER, handler: onMouseEnter });
      addHandler({ eventName: 'mouseleave', layerName: SYMBOL_LAYER, handler: onMouseLeave });
      addHandler({ eventName: 'mouseleave', layerName: LINE_LAYER, handler: onMouseLeave });
      addHandler({ eventName: 'mouseleave', layerName: CLUSTER_LAYER, handler: onMouseLeave });
    }
    return () => {
      //when shutting down, remove any of the existing map handlers
      if (map) {
        eventHandlers.forEach(eventHandler => {
          // @ts-ignore
          map.off(eventHandler.eventName, eventHandler.layerName, eventHandler.handler);
        });
      }
    };
  }, [mapType, mapStyle, onClick, onClusterClick, onMouseEnter, onMouseLeave, onSymbolClickWrapper, mapRefUpdated]);

  //Currently using any, because external event type isn't properly defined
  const StyleLoader: MapEvent = useCallback(
    (map, e) => {
      // set an initial placeholder style
      const id = (e as React.SyntheticEvent<any, Event> & { id: string }).id;
      const [, , , , , prefSize, font] = id?.split('_');
      const newScale = prefSize ? Number(prefSize) : symbolScaleFactor;
      const scaledSize = Math.round(82 * (newScale || 1));

      // The image will be this many pixels square multiplied by the global scale factor
      const bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
      const data = new Uint8Array(scaledSize * scaledSize * bytesPerPixel).fill(100);

      //Now attempt to load the actual style and replace the placeholder
      map.addImage(id, { width: scaledSize, height: scaledSize, data: data }, { pixelRatio: 2 });
      // we had allowed for custom style providers here, but there's very little use and it would mean having to re-render a bunch
      defaultStyleProvider(id, scaledSize, font).then(img => {
        //@ts-ignore
        img && map.updateImage(id, img);
      });
    },
    [symbolScaleFactor]
  );

  const symbolGeoJsonSource = useMemo(() => {
    return {
      type: 'geojson',
      data: symbolSourceData,
      cluster: isClustered,
      clusterMaxZoom: MAX_ZOOM_CLUSTERING_LEVEL, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    };
  }, [isClustered, symbolSourceData]);

  const lineGeoJsonSource = useMemo(
    () => ({
      type: 'geojson',
      data: lineSourceData,
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    }),
    [lineSourceData]
  );

  const lineMeasurementGeoJsonSource = useMemo(
    () => ({
      type: 'geojson',
      data: lineMeasurement,
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    }),
    [lineMeasurement]
  );

  const clusterCountCircleLayerPaint = useMemo(
    () => ({
      'circle-color': theme.palette.primary.main,
      'circle-radius': 21,
      'circle-stroke-color': '#FFFFFF',
      'circle-stroke-width': 3,
    }),
    [theme.palette.primary.main]
  );

  const lineMarkerLayerPaint = useMemo(
    () => ({
      'text-color': '#FFFFFF',
      'text-halo-color': 'black',
      'text-halo-width': 2,
    }),
    []
  );

  const selectedClusterPaint = useMemo(
    () => ({
      'circle-radius': 25,
      'circle-color': '#00FFFF',
      'circle-stroke-color': '#00FFFF',
      'circle-stroke-width': 4,
    }),
    [theme.palette.primary.main, selectedClusterId]
  );

  const selectedClusterFilter = useMemo(() => ['==', 'cluster_id', selectedClusterId || ''], [selectedClusterId]);
  const selectedAggregateClusterFilter = useMemo(() => ['==', 'cluster_id', props.selectedAggregateCusterId || ''], [
    props.selectedAggregateCusterId,
  ]);

  const showNearMaps = useMemo(() => integrations?.nearMaps && mapType === 'nearmaps', [integrations, mapType]);

  const rasterLayerLayout = useMemo(() => {
    return { visibility: showNearMaps ? 'visible' : 'none' };
  }, [showNearMaps]);

  const RASTER_SOURCE_OPTIONS = useMemo(() => {
    const apiUrl = 'https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.png?apikey=' + integrations?.nearMapsKey;
    return {
      type: 'raster',
      tiles: [apiUrl],
      tileSize: 256,
    };
  }, []);

  useEffect(() => {
    const { selectAggregateParentCluster } = props;
    if (mapRef.current) {
      selectAggregateParentCluster && selectAggregateParentCluster(mapRef.current.querySourceFeatures('symbolSource'));
    }
  }, [mapRef.current]);
  return (
    <div className={classes.mapContainerWrapper} data-testid="map-wrapper">
      <Fade in={true}>
        <div style={{ height: '100%' }}>
          <MapBox
            style={mapStyle}
            containerStyle={mapboxContainerStyle}
            zoom={localZoom}
            center={localCenter}
            onStyleImageMissing={StyleLoader}
            fitBounds={localBounds}
            fitBoundsOptions={fitOptions}
            onMoveEnd={onMove}
            onZoomEnd={onMove}
            renderChildrenInPortal={true}
            onStyleLoad={handleStyleLoad}
            onClick={onClick}
          >
            <Fragment key={`${isClustered}`}>
              <Source id="symbolSource" geoJsonSource={symbolGeoJsonSource} />
              <Source id="lineSource" geoJsonSource={lineGeoJsonSource} />
              <Source id="lineMeasurementGeoJsonSource" geoJsonSource={lineMeasurementGeoJsonSource} />
              <Source id="nearmapRasterSouce" tileJsonSource={RASTER_SOURCE_OPTIONS} />
              {mapRasterSources &&
                Object.entries(mapRasterSources).map(([id, source]) => (
                  <Source key={id} id={id} tileJsonSource={source} />
                ))}
              {showMapLabels && mapLabelMarkers(classes)}
              <Layer layout={symbolLayerLayout} type="symbol" id={SYMBOL_LAYER} sourceId="symbolSource" />
              <Layer
                layout={lineLayerLayout}
                paint={lineLayerPaint}
                type="line"
                id={LINE_LAYER}
                sourceId="lineSource"
                before={SYMBOL_LAYER}
              />
              <Layer
                id="selectedSymbol"
                type="circle"
                sourceId="symbolSource"
                filter={selectedLayerFilter}
                paint={symbolLayerPaint(!!isSelectedEndpoint)}
                layerOptions={selectedLayerOptions}
                before={SYMBOL_LAYER}
              />
              <Layer
                id={SELECTED_LINE_LAYER}
                type="line"
                sourceId="lineSource"
                filter={selectedLayerFilter}
                layout={lineLayerLayout}
                paint={selectedLineLayerPaint}
                layerOptions={selectedLayerOptions}
                before={LINE_LAYER}
              />
              <Layer
                type="circle"
                paint={selectedClusterPaint}
                id={'selected-cluster'}
                sourceId="symbolSource"
                filter={selectedClusterFilter}
              />
              <Layer
                type="circle"
                paint={selectedClusterPaint}
                id={'selected-aggregate-cluster'}
                sourceId="symbolSource"
                filter={selectedAggregateClusterFilter}
              />
              <Layer
                layout={clusterCountLayerLayout}
                paint={clusterCountLayerPaint}
                id="cluster-count"
                sourceId="symbolSource"
                filter={clusterCountLayerFilter}
              />
              <Layer
                before="cluster-count"
                type="circle"
                paint={clusterCountCircleLayerPaint}
                id={CLUSTER_LAYER}
                sourceId="symbolSource"
                filter={clusterCountLayerFilter}
              />
              <Layer
                type="symbol"
                minZoom={MAX_LINE_MEASUREMENT_ZOOM}
                id={'line-measurement'}
                sourceId="lineMeasurementGeoJsonSource"
                paint={lineMarkerLayerPaint}
                layout={lineMarkerLayerLayout}
              />
              <Layer
                type="raster"
                id="nearMaps_Layer"
                sourceId={'nearmapRasterSouce'}
                layout={rasterLayerLayout}
                before={SELECTED_LINE_LAYER}
              />
              {mapRasterSources &&
                mapVisibleMapServiceKeys?.map(
                  id =>
                    mapRasterSources[id] && (
                      <Layer
                        key={`${id}-layer`}
                        type={mapRasterSources[id].type}
                        id={`${id}-layer`}
                        sourceId={id}
                        before={SELECTED_LINE_LAYER}
                      />
                    )
                )}
              {props.children}
            </Fragment>
            {showMapControls ? <MapControlsOverlay setBounds={setLocalBounds} /> : undefined}
          </MapBox>
          {showMapControls ? <MapSearchControlOverlay setBounds={setLocalBounds} /> : undefined}
          {showRightControls ? (
            <RightControls symbols={symbols} limitedRightControls={limitedRightControls} wmsControls={wmsControls} />
          ) : undefined}
        </div>
      </Fade>
    </div>
  );
};

const mapboxContainerStyle = {
  height: `100%`,
};

//MapBox Styles
const symbolLayerLayout = { 'icon-image': ['get', 'styleKey'], 'icon-allow-overlap': true };

const lineLayerLayout = {
  'line-join': 'round',
  'line-cap': 'round',
};

const lineLayerPaint = {
  'line-color': ['get', 'lineColor'],
  'line-width': ['get', 'lineWidth'],
  'line-dasharray': [
    'match',
    ['get', 'lineDashArray'],
    ['dashed'],
    ['literal', [2, 4]],
    ['dotted'],
    ['literal', [0.001, 2]],
    ['literal', [1]],
  ],
};

const symbolLayerPaint = (isSelectedEndpoint: boolean) => ({
  'circle-color': isSelectedEndpoint ? '#00FFFF' : '#FFFFFF',
  'circle-radius': 24,
  'circle-stroke-color': '#666',
  'circle-stroke-width': 0.2,
  'circle-radius-transition': { duration: 50000 },
  'circle-opacity': 0.9,
  'circle-blur': 0.15,
});

const selectedLineLayerPaint = {
  'line-color': 'white',
  'line-width': 20,
};

const selectedLayerFilter = ['==', 'selected', true];
const selectedLayerOptions = {
  pixelRatio: 2,
};

const clusterCountLayerFilter = ['has', 'point_count'];
const clusterCountLayerLayout = {
  'text-field': '{point_count_abbreviated}',
  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
  'text-size': 16,
  'text-allow-overlap': true,
};
const lineMarkerLayerLayout = {
  'text-field': ['get', 'measurement'],
  'text-letter-spacing': 0.1,
  'text-size': 14,
  'text-justify': 'auto',
  'text-font': ['Arial Unicode MS Bold'],
};

const clusterCountLayerPaint = {
  'text-color': '#FFFFFF',
};

//CSS Styles
const styles = (theme: Theme) =>
  createStyles({
    mapContainerWrapper: {
      height: '100%',
      width: '100%',
      '& .mapboxgl-ctrl-top-left': {
        [theme.breakpoints.down('xs')]: {
          left: 1,
          top: 56,
          '& .mapboxgl-ctrl-group': {
            marginLeft: '5vw',
            '& button': {
              height: 42,
              width: 42,
            },
          },
        },
      },
    },
    popupWrapper: {
      '& .mapboxgl-popup-content': {
        backgroundColor: '#333333',
        padding: '6px !important',
      },
      '& .mapboxgl-popup-tip': {
        borderTopColor: '#333333',
      },
      '&:hover': {
        zIndex: `99999 !important`,
      },
    },
    popupItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#fff',
    },
    popupItemValue: {
      fontSize: '13px',
    },
    emptyValue: {
      color: '#A8A8A8',
      fontSize: '13px',
    },
  });

export default withStyles(styles)(Map);
