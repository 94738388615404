import { useState, useCallback } from 'react';
import { SortDirection } from '@terragotech/react-data-grid';

const useSort = (defaultKey?: string) => {
  const [[sortKey, sortDirection], setSort] = useState<[string, SortDirection]>([defaultKey || '', 'ASC']);

  const handleSort = useCallback((columnKey: string, direction: SortDirection) => {
    setSort([columnKey, direction]);
  }, []);

  return { sortKey, sortDirection, handleSort };
};

export default useSort;
