import React from 'react';
import { Button, FormControl } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VideocamIcon from '@material-ui/icons/Videocam';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import MicIcon from '@material-ui/icons/Mic';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FileIcon from '@material-ui/icons/FileCopy';

export type IconType =
  | 'imageupload'
  | 'fileupload'
  | 'videoupload'
  | 'audioupload'
  | 'barcode'
  | 'signature'
  | 'location'
  | 'aggregateSelector';
export interface WorkFlowButtonFieldProps {
  mode: 'edit' | 'add';
  type: IconType;
  onClick: () => void;
  readOnly: boolean;
}

const Icons: { [index in IconType]: React.ReactElement } = {
  fileupload: <FileIcon />,
  imageupload: <AddPhotoAlternateIcon />,
  videoupload: <VideocamIcon />,
  audioupload: <MicIcon />,
  barcode: <CropFreeIcon />,
  signature: <EditIcon />,
  location: <LocationOnIcon />,
  aggregateSelector: <LocationOnIcon />,
};
const getIcon = (type: IconType): React.ReactNode | null => {
  return Icons[type] || null;
};
const createLabel: { [index in IconType]: string } = {
  fileupload: 'File',
  imageupload: 'Image',
  videoupload: 'Video',
  audioupload: 'Audio',
  barcode: 'Barcode',
  signature: 'Signature',
  location: 'Location',
  aggregateSelector: 'Item',
};

const EditComponentButton: React.FC<WorkFlowButtonFieldProps> = props => {
  const { mode, type, onClick, readOnly } = props;
  return (
    <FormControl component="fieldset" style={{ marginTop: 8, marginBottom: 5 }}>
      <Button disabled={readOnly} variant="contained" startIcon={getIcon(type)} onClick={onClick}>
        {mode} {createLabel[type]}
      </Button>
    </FormControl>
  );
};

export default EditComponentButton;
