import React, { useState } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, Input, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../styles/theme';
import magicText from 'i18next';

const HEADER_HEIGHT = 60 + 52 + 54;
const DIALOG_TOP_MARGIN = 32;

export interface DialogWrapperProps {
  title: string;
  open: boolean;
  filterPlaceHolder: string;
  onCancel: () => void;
  onDone?: () => void;
  children: React.ReactNode;
  searchText: string;
  handleSearchText: (text: string) => void;
  actions: React.ReactNode;
}
/**
  DialogWrapper - wraps lazy loaded lists and has search functionality
  Different from DialogueWrapper which is used for Media Fields and Location Modals
*/
const DialogWrapper = (props: DialogWrapperProps) => {
  const { open, onDone, onCancel, filterPlaceHolder, title, searchText, handleSearchText } = props;
  const classes = useStyles();

  const [localSearchText, setLocalSearchText] = useState(searchText);

  return (
    <Dialog open={open} className={classes.dialog} onClose={onCancel} classes={{ paperScrollPaper: classes.paperRoot }}>
      <div className={classes.dialogTitleBar}>
        <Button className={classes.button} onClick={onCancel}>
          <> {magicText.t('Common.cancelLabel')}</>
        </Button>
        <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
        {onDone && (
          <Button className={classes.button} onClick={onDone}>
            <> {magicText.t('Common.doneLabel')}</>
          </Button>
        )}
        {!onDone && <div className={classes.radioButtonSpacer}></div>}
      </div>

      <DialogActions className={classes.dialogActions}>{props.actions}</DialogActions>
      <div className={classes.searchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          placeholder={filterPlaceHolder}
          className={classes.input}
          value={localSearchText}
          onChange={(e) => {
            setLocalSearchText(e.target.value);
            handleSearchText(e.target.value);
          }}
          disableUnderline
        />
      </div>
      <div className={classes.content}>{props.children}</div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${DIALOG_TOP_MARGIN}px)`,
  },
  dialog: {
    "& [role='dialog']": {
      width: 650,
      marginBottom: 0,
      maxHeight: `calc(100vh - ${DIALOG_TOP_MARGIN}px)`,
    },
  },
  dialogTitleBar: {
    backgroundColor: theme.palette.secondary.main,
    color: colors.white,
    boxSizing: 'border-box',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    fontSize: 18,
    maxWidth: '25rem',
  },
  button: {
    color: colors.white,
  },
  radioButtonSpacer: {
    width: '68px', //width of done button
  },
  dialogActions: {
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
    height: 50,
    boxSizing: 'border-box',
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colors.white,
    color: theme.palette.grey[200],
    padding: '3px 0px',
    width: '100%',
    height: 46,
    boxSizing: 'border-box',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[200],
    fontSize: 16,
    '& :before': {
      display: 'none',
      borderBottom: 0,
    },
  },
  searchIcon: {
    height: 24,
    width: 24,
    paddingLeft: 14,
    color: theme.palette.grey[400],
  },
  input: {
    width: '100%',
  },
  paperRoot: {
    overflow: 'hidden',
  },
}));

export default DialogWrapper;
