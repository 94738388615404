import { createStyles, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import React, { useState } from 'react';
import magicText from 'i18next';
import LoginFields, { LoginField } from './LoginFields';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';
import { colors } from '../../styles/theme';
import ForgotPasswordFlow from './ForgotPassword/ForgotPasswordFlow';

interface Props {
  fields: Array<LoginField>;
  errorMessage?: string;
}

const LoginEmailView: React.FC<Props> = (props: any) => {
  const { errorMessage, fields } = props;
  const classes = useStyles();
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  return (
    <>
      <LoginFields fields={fields} errorMessage={errorMessage} />
      {errorMessage && (
        <Typography variant="h3" className={classes.errorText}>
          <>{magicText.t(errorMessage)}</>
        </Typography>
      )}
      <Link
        className={classes.forgotText}
        onClick={(ev: React.MouseEvent) => {
          ev.preventDefault();
          setShowPasswordReset(true);
        }}
        href=""
      >
        <> {magicText.t('FORGOT PASSWORD?')}</>
      </Link>
      <TGLoginButton type={TGLoginButtonType.primary}>
        <>{magicText.t('LOGIN')}</>
      </TGLoginButton>
      {showPasswordReset && (
        <ForgotPasswordFlow title={magicText.t('Reset your password')} onClose={() => setShowPasswordReset(false)} />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: colors.danger,
      marginBottom: 15,
    },
    forgotText: {
      fontSize: '13px',
      color: theme.palette.primary.main,
      marginBottom: 20,
    },
  })
);

export default LoginEmailView;
