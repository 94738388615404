import React, { FunctionComponent, useEffect } from 'react';
import { ZoomControl, RotationControl } from 'react-mapbox-gl';
import { makeStyles } from '@material-ui/core/styles';

interface MapControlsOverlayProps {
  setBounds: (bounds: any) => void;
}
const MapControlsOverlay: FunctionComponent<MapControlsOverlayProps> = props => {
  const classes = useStyles();

  useEffect(() => {
    const domElements: Array<HTMLElement> = [];
    const touchHandler = (ev: Event, preventDefault: boolean = true) => {
      // @ts-ignore
      ev?.target?.click?.();
      if (preventDefault) ev?.preventDefault();
    };
    const zpCtrl = document.getElementById('zoomIn');
    if (zpCtrl) {
      domElements.push(zpCtrl);
      zpCtrl.addEventListener('touchend', touchHandler);
    }
    const zmCtrl = document.getElementById('zoomOut');
    if (zmCtrl) {
      domElements.push(zmCtrl);
      zmCtrl.addEventListener('touchend', touchHandler);
    }
    const rtCtrl = document.getElementsByClassName(classes.rotateControls)?.[0]?.getElementsByTagName('button')?.[0];
    if (rtCtrl) {
      domElements.push(rtCtrl);
      rtCtrl.addEventListener('touchend', touchHandler);
    }
    return () => {
      domElements.forEach(domelement => {
        domelement.removeEventListener('touchend', touchHandler);
      });
    };
  }, []);

  return (
    <div className={classes.mapBody}>
      <div className={classes.mapLeftControls}>
        <ZoomControl position="top-left" className={classes.zoomRotateControls} />
        <RotationControl
          position="top-left"
          className={[classes.zoomRotateControls, classes.rotateControls].join(' ')}
        />
      </div>
      <div className={classes.mapRightControls}></div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mapBody: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  mapLeftControls: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      pointerEvents: 'none',
    },
  },
  mapRightControls: {
    height: '100%',
  },
  zoomRotateControls: {
    background: 'rgb(249, 249, 249)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginTop: 35,
    marginLeft: 0,
    pointerEvents: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: 108,
      left: '5vw !important',
      '& > button': {
        height: '42px !important',
        width: '42px !important',
        backgroundSize: '42px 420px !important',
        '&#zoomOut': {
          backgroundPositionY: '-42px !important',
        },
        '& svg': {
          pointerEvents: 'none',
        },
      },
    },
  },
  rotateControls: {
    [theme.breakpoints.down('xs')]: {
      top: '106px !important',
    },
  },
}));

export default MapControlsOverlay;
