import successCheckEmpty from '../../images/importPage/successCheckEmpty.svg';
import successCheckFilled from '../../images/importPage/successCheckFilled.svg';
import warningCheckEmpty from '../../images/importPage/warningCheckEmpty.svg';
import warningCheckFilled from '../../images/importPage/warningCheckFilled.svg';
import errorCheckEmpty from '../../images/importPage/errorCheckEmpty.svg';
import errorCheckFilled from '../../images/importPage/errorCheckFilled.svg';

interface Props {
  successFlag: boolean;
  warningFlag: boolean;
  errorFlag: boolean;
  openSuccess: () => void;
  openWarnings: () => void;
  openErrors: () => void;
}

const ImportStatusViewer = (props: Props) => {
  return (
    <span>
      <img
        src={!props.successFlag ? successCheckEmpty : successCheckFilled}
        alt="Status"
        onClick={props.successFlag ? props.openSuccess : () => {}}
        style={props.successFlag ? { cursor: 'pointer', marginLeft: 6 } : { marginLeft: 6 }}
      />
      <img
        src={!props.warningFlag ? warningCheckEmpty : warningCheckFilled}
        alt="Status"
        onClick={props.warningFlag ? props.openWarnings : () => {}}
        style={props.warningFlag ? { cursor: 'pointer', marginLeft: 6 } : { marginLeft: 6 }}
      />
      <img
        src={!props.errorFlag ? errorCheckEmpty : errorCheckFilled}
        alt="Status"
        onClick={props.errorFlag ? props.openErrors : () => {}}
        style={props.errorFlag ? { cursor: 'pointer', marginLeft: 6 } : { marginLeft: 6 }}
      />
    </span>
  );
};

export default ImportStatusViewer;
