import React, { useContext, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import magicText from 'i18next';
import LegalDisclaimerView from './LegalDisclaimerView';
import LoginCodeView from './LoginCodeView';
import LoginButtonView from './LoginButtonView';
import LoginEmailView from './LoginEmailView';
import { useConfig, ServerPublicMetaContext } from '@terragotech/gen5-shared-components';
import _ from 'lodash';

interface LoginFormProps {
  attemptLogin: () => void;
  code: string;
  codeNeeded?: boolean;
  emailNeeded?: boolean;
  errorMessage?: string;
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  secondButtonCB?: () => void;
  selectEmailLogin: () => void;
  submitAuthCode: () => void;
  email: string;
}

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  const {
    attemptLogin,
    code,
    codeNeeded,
    emailNeeded,
    errorMessage,
    onCodeChange,
    onPasswordChange,
    onEmailChange,
    password,
    secondButtonCB,
    selectEmailLogin,
    submitAuthCode,
    email,
  } = props;
  const { samlProviders, legalDisclaimer } = useContext(ServerPublicMetaContext);
  const [disclaimerNeeded, setDisclaimerNeeded] = useState(legalDisclaimer ? true : false);
  const classes = useStyles();
  const uiConfig = useConfig();
  const showLogin = uiConfig.enabledFeatures?.showLogin || 'both';

  const onDisclaimerAccepted = () => {
    setDisclaimerNeeded(false);
    _.isEmpty(samlProviders) && selectEmailLogin();
  };

  return (
    <form
      className={classes.auth}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (codeNeeded) {
          submitAuthCode();
          return;
        }
        if (emailNeeded) {
          attemptLogin();
          return;
        }
      }}
    >
      {disclaimerNeeded && (
        <LegalDisclaimerView onDisclaimerAccepted={onDisclaimerAccepted} legalDisclaimerText={legalDisclaimer} />
      )}
      {samlProviders && !disclaimerNeeded && !codeNeeded && !emailNeeded && (
        <LoginButtonView samlProviders={samlProviders} selectEmailLogin={selectEmailLogin} showLogin={showLogin} />
      )}
      {emailNeeded && !codeNeeded && !disclaimerNeeded && (
        <LoginEmailView
          errorMessage={errorMessage}
          fields={[
            { label: magicText.t('Email'), value: email, onChange: onEmailChange },
            { label: magicText.t('Password'), value: password, onChange: onPasswordChange, type: 'password' },
          ]}
        />
      )}
      {codeNeeded && (
        <LoginCodeView
          errorMessage={errorMessage}
          fields={[{ label: magicText.t('Code'), value: code, onChange: onCodeChange }]}
          handleResend={secondButtonCB}
        />
      )}
    </form>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    auth: {
      margin: '45px 43px 28px 43px',
      display: 'inline-flex',
      flexDirection: 'column',
    },
  })
);

export default LoginForm;
