import turfBbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import * as MapboxGl from 'mapbox-gl';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import { useAssetCards } from '../../../contexts/assetCardContext';
import { cardViewSelector } from '../../../recoil/atoms';
import { isLatitudeLongitudeValid } from '../../../utils/utilityHelper';
import MultipleAssetCardsContainer from './MultipleAssetCardsContainer';
import PositionedAssetCardContainer from './PositionedAssetCard';
const DEFAULT_CLICK_ZOOM = 16;

export interface AssetCardRendererProps {
  items: MapAssetType[];
  mapInstance: MapboxGl.Map | null;
  selectedAssetId: string | undefined;
  selectableAggregateTypes?: string[];
  onCardClose?: () => void;
  onCardDisplayed?: (assetId: string) => void;
  onSelect?: (tem: any) => void;
  mapEditor?: boolean;
  forceCards?: boolean;
  selectedAggregateId?: string;
  onFormSubmit?: () => void;
}
const AssetCardRenderer: React.FunctionComponent<AssetCardRendererProps> = props => {
  //This is a higher order function that adds card rendering ability to Maps using a rencer prop
  const {
    items,
    mapInstance,
    selectedAssetId,
    onCardDisplayed,
    selectableAggregateTypes,
    onSelect,
    mapEditor,
    selectedAggregateId,
    forceCards,
    onFormSubmit,
    onCardClose,
  } = props;

  const { assetIds, setAssetsIds, coordinates, areCardsDefined } = useAssetCards();

  const areCardsActive = useRecoilValue(cardViewSelector);
  const [isCardVisible, setIsCardVisible] = React.useState(false);

  let assetData = items;

  const [cardAssetId, setCardAssetId] = React.useState<string | undefined>(undefined);
  const showCard = useCallback(() => setTimeout(() => setIsCardVisible(true), 300), []);

  const setCenterToBelowCard = useCallback(
    (initialCoords: { lng: number; lat: number }) => {
      /**** Centering horizontally and 50px from bottom ****/
      if (mapInstance) {
        const maxZoom = mapInstance.getZoom() < DEFAULT_CLICK_ZOOM ? DEFAULT_CLICK_ZOOM : mapInstance.getZoom();
        if (isLatitudeLongitudeValid(initialCoords?.lat, initialCoords?.lng)) {
          const newBounds = mapInstance.cameraForBounds(
            [initialCoords.lng, initialCoords.lat, initialCoords.lng, initialCoords.lat],
            { padding: 50, maxZoom }
          );

          if (newBounds) {
            mapInstance.easeTo(newBounds);
          }
        } else {
          console.error(`Asset has invalid location ${initialCoords?.lat} ${initialCoords?.lng}`);
        }
      }
    },
    [mapInstance]
  );

  const selectedAsset = useMemo(() => {
    return assetData.find(item => item.id === selectedAssetId);
  }, [assetData, selectedAssetId]);

  //get the location of the currently selected asset, zoom is imperative, so we have state just to track the current value
  //TODO: this currently reshows the card when the assets change, we need to change this to only show the card when the selectedAssetId changes.
  useEffect(() => {
    switch (selectedAsset?.primaryLocation.type) {
      case 'Point':
        const pointCoords = {
          lng: selectedAsset.lon,
          lat: selectedAsset.lat,
        };
        setCenterToBelowCard(pointCoords);
        setCardAssetId(selectedAsset.id);
        showCard();
        break;
      case 'LineString':
        let coords = selectedAsset.primaryLocation.coordinates;
        if (coords && coords.length >= 2) {
          let line = lineString(coords);
          let box = turfBbox(line);
          if (mapInstance) {
            mapInstance.fitBounds([
              [box[0], box[1]],
              [box[2], box[3]],
            ]);
            // data structure format
            // [
            //   [lowerLon, lowerLat],
            //   [upperLon, upperLat],
            // ]
          }
        }
        // TODO: need to implement this with bounding box, maybe use part of current function to just find y offset
        // setCenterToBelowCard();
        setCardAssetId(selectedAsset.id);
        showCard();
        break;
      // if we cannot determine the type, try lat/lon
      default:
        if (selectedAsset && selectedAsset.lat && (selectedAsset.lon || selectedAsset.lon)) {
          const initialCoords = {
            lng: selectedAsset.lon,
            lat: selectedAsset.lat,
          };
          setCenterToBelowCard(initialCoords);
          setCardAssetId(selectedAsset.id);
          showCard();
        }
        break;
    }
  }, [mapInstance, selectedAsset, setCenterToBelowCard, showCard]);

  React.useEffect(() => {
    if (assetIds.length > 0 && coordinates) {
      setCenterToBelowCard(coordinates);
      showCard();
    }
  }, [assetIds, showCard, coordinates, setCenterToBelowCard]);

  const handleClose = useCallback(() => {
    setIsCardVisible(false);
    setAssetsIds([]);
    setCardAssetId(undefined);
    onCardClose && onCardClose();
  }, [setAssetsIds]);
  //TODO: add a reference to the cards in order to return the size of the displayed cards for offset purposes
  if (areCardsDefined && (areCardsActive || forceCards) && isCardVisible) {
    if (assetIds.length > 1) {
      return (
        <MultipleAssetCardsContainer
          assets={assetData.filter(a => assetIds.includes(a.id))}
          onCloseClick={handleClose}
          onCardChanged={onCardDisplayed}
          selectableAggregateTypes={selectableAggregateTypes}
          onSelect={onSelect}
          mapEditor={mapEditor}
          selectedAggregateId={selectedAggregateId}
          relative={forceCards}
          onFormSubmit={onFormSubmit}
        />
      );
    } else if (assetIds.length === 1) {
      return (
        <PositionedAssetCardContainer
          onFormSubmit={onFormSubmit}
          asset={selectedAsset}
          assetId={assetIds[0]}
          onCloseClick={handleClose}
          relative={forceCards}
        />
      );
    }
    return (
      <PositionedAssetCardContainer
        onFormSubmit={onFormSubmit}
        asset={selectedAsset}
        assetId={cardAssetId ?? ''}
        onCloseClick={handleClose}
        relative={forceCards}
      />
    );
  }
  return null;
};
export default AssetCardRenderer;
