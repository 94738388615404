import { MapAssetType, MapServiceType, MapServiceLayerType, WithAggregateName } from './types';

import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { ProjectType } from '../projectsContext';
import _ from 'lodash';
import { MAP_SERVICE_DEFAULT_KEYS } from '@terragotech/gen5-shared-utilities';

export const getAssetCountOfRecordTypes = (projects: ProjectType[], aggregateDefinitions: AggregateDefinition[]) =>
  projects.reduce((acc, project) => {
    const sumForAllAggregates = aggregateDefinitions.reduce<number>((acc, definition) => {
      return acc + Number.parseInt(project[`${definition.queryKey}_count`] as string);
    }, 0);
    return acc + sumForAllAggregates;
  }, 0);

export const filterNonMapAggregates = (aggregateDefinitions: AggregateDefinition[]) =>
  aggregateDefinitions.filter((definition) => !['Folder', 'Project'].includes(definition.name));

/**
 * Creates object that has an index structure with data generated by generator
 *
 * @param aggregateDefinitions
 * @param generator
 *
 * The resulted object has following structure
 * {
 *  [aggregateKey]: dataGeneratedByGenerator
 * }
 */
export const createWithAggregateNameObject = <T extends unknown>(
  aggregateDefinitions: AggregateDefinition[],
  generator: (definition: AggregateDefinition) => T
): WithAggregateName<T> =>
  aggregateDefinitions.reduce<WithAggregateName<T>>((result, definition) => {
    const key = definition.queryKey;
    result[key] = generator(definition);
    return result;
  }, {});

export const filterMapServiceAssets = (assets: MapAssetType[]) => 
  assets.filter(x => x.recordTypeKey === MAP_SERVICE_DEFAULT_KEYS.recordTypeKey) as MapServiceType[];

export const reduceMapServicesToLayers = (services: MapServiceType[]) =>
  services.reduce((acc, cur, i, arr) => {
    cur.layers.forEach(l => acc.push(_.omit({ ...cur, id: `${cur.id}-${l.layerName}`, label: l.layerTitle || l.layerName, layer: l }, 'layers')));
    return acc;
  }, [] as MapServiceLayerType[]);
