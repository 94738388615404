import * as React from 'react';
import { formatDistanceStrict } from 'date-fns';
import { withStyles, WithStyles, createStyles, CircularProgress, Tooltip } from '@material-ui/core';
import { colors } from '../styles/theme';
import { ReactComponent as RefreshIcon } from '../images/refresh.svg';
import { useRef } from 'react';
import { useAggregates } from '../contexts/AggregatesContext';

const styles = createStyles({
  root: {
    display: 'flex',
  },
  refreshButton: {
    width: 20,
    height: 20,
    cursor: 'pointer',
  },
  refreshSpinner: {
    cursor: 'pointer',
    marginLeft: 3,
    marginRight: 3,
  },
  popper: {
    top: '15px !important',
  },
  buttonDiv: {
    borderLeft: '1px solid #EEEEEE',
    borderTop: '1px solid #EEEEEE',
    padding: '0px 14px',
    display: 'flex',
    alignItems: 'center',
  },
});
export interface RefreshDataButtonProps extends WithStyles<typeof styles> {}

const RefreshDataButton: React.FunctionComponent<RefreshDataButtonProps> = (props) => {
  const { classes } = props;
  const assetInfo = useAggregates();
  const [refresh, setRefresh] = React.useState(false);
  const [refreshTime, setRefreshTime] = React.useState('Refresh Assets');
  const lastRefreshed = useRef(new Date());

  const handleRefreshTable = async () => {
    setRefresh(true);
    await assetInfo.refetchAll();
    setRefreshTime('0 secs ago');
    setTimeout(() => {
      setRefresh(false);
    }, 500);
    lastRefreshed.current = new Date();
  };
  return (
    <Tooltip
      title={refreshTime}
      placement="top"
      classes={{ popper: classes.popper }}
      onOpen={() => {
        if (refreshTime !== 'Refresh Assets') setRefreshTime(getRefreshTimeText(lastRefreshed.current));
      }}
    >
      <div
        className={classes.buttonDiv}
        onClick={() => {
          handleRefreshTable();
        }}
      >
        {refresh && <CircularProgress style={{ color: colors.success }} size={16} className={classes.refreshSpinner} />}
        {refresh || <RefreshIcon className={classes.refreshButton} />}
      </div>
    </Tooltip>
  );
};
export default withStyles(styles)(RefreshDataButton);

export function getRefreshTimeText(lastRefreshed?: Date): string {
  let time: string = '';
  let current: Date = new Date();
  let lastRefresh: Date = (lastRefreshed as unknown) as Date;

  if (lastRefresh) {
    time = formatDistanceStrict(lastRefresh, current, { roundingMethod: 'floor' });
    //check plural first otherwise the 's' will not be replaced.
    if (time.includes('hour'))
      time.includes('hours') ? (time = time.replace('hours', ' hrs ago')) : (time = time.replace(' hour', ' hr ago'));
    else if (time.includes('minute'))
      time.includes('minutes')
        ? (time = time.replace('minutes', ' mins ago'))
        : (time = time.replace(' minute', ' min ago'));
    else
      time.includes('seconds')
        ? (time = time.replace('seconds', ' secs ago'))
        : (time = time.replace('second', ' sec ago'));
  }
  return time;
}
