// Adapted from https://v4.mui.com/components/tabs/#full-width

import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs, { TabsTypeMap } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:not($selected)': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
})((props: StyledTabsProps) => <Tabs {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  children: React.ReactElement<SwipeableTabPanelProps, typeof SwipeableTabPanel>[];
}

interface SwipeableTabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index?: any;
  value?: any;
  label: string;
  hideTab?: boolean;
}

export function SwipeableTabPanel(props: SwipeableTabPanelProps) {
  const { children, value, index, hideTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`swipeable-tabpanel-${index}`}
      aria-labelledby={`swipeable-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `swipeable-tab-${index}`,
    'aria-controls': `swipeable-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));

export interface SwipeableTabsProps {
  children: React.ReactElement<SwipeableTabPanelProps, typeof SwipeableTabPanel>[];
  selectedTabIndex: number;
  setSelectedTabIndex: (index: number) => void;
  hideTabs?: boolean;
}

export default function SwipeableTabs(props: TabsTypeMap<SwipeableTabsProps>['props']) {
  const { selectedTabIndex, setSelectedTabIndex, hideTabs, children, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      {!hideTabs && <AppBar position="static" color="default">
        <StyledTabs
          value={selectedTabIndex}
          onChange={(_:any, newValue:any) => setSelectedTabIndex(newValue)}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          aria-label="swipeable tabs"
          {...other}
        >
          {children.filter(x => !x.props.hideTab).map((t, i) => <StyledTab key={i} label={t.props.label} {...a11yProps(i)} />)}
        </StyledTabs>
      </AppBar>}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={selectedTabIndex}
        onChangeIndex={i => setSelectedTabIndex(i)}
      >
        {children
          .filter(x => !x.props.hideTab)
          .map((t, i) => (
            <t.type key={i} value={selectedTabIndex} index={i} dir={theme.direction} {...t.props} />
          ))}
      </SwipeableViews>
    </div>
  );
}
