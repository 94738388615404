import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import AssetCardContainer from '../AssetCardContainer';
import { MapAssetType } from '../../../../contexts/AggregatesContext/types';

type CardContainerProps = {
  setSize: (width: number, height: number) => void;
  style?: React.CSSProperties;
  classes?: {
    container?: string;
  };
  assetId: string;
  onCloseClick?: () => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  asset?: MapAssetType;
  onFormSubmit?: ()=>void;
};

const CardHolder = (props: CardContainerProps) => {
  const { setSize, style, classes: customClasses } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [currentCardVersion, setCurrentCardVersion] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    const current = ref.current;
    if (current) {
      const resize = () => {
        setSize(current.offsetWidth, current.offsetHeight);
      };
      resize();
      current.addEventListener('resize', resize);
      return current.removeEventListener('resize', resize);
    }
  }, [ref, setSize, currentCardVersion]);

  return (
    <div className={clsx(classes.container, customClasses?.container || '')} style={style} ref={ref}>
      <AssetCardContainer
        {...props}
        refreshCard={() => {
          setCurrentCardVersion((prevState) => prevState + 1);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
  },
}));

export default CardHolder;
