import { Button, Modal, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React, { FunctionComponent, useState } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';
import logo from '../images/aboutLogo.svg';
import magicText from 'i18next';
import { colors } from '../styles/theme';
import { useMultiClick } from '../hooks/useMultiClick';

const styles = (theme: Theme) =>
  createStyles({
    menuItem: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $secondary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    primary: {},
    secondary: {},
    icon: {
      margin: 0,
    },
    modalContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    aboutModalContainer: {
      minHeight: 225,
      minWidth: 380,
      background: colors.white,
      boxShadow: '0px 2px 1px 1px rgba(0, 0, 0, 0.1)',
      borderRadius: 3,
      display: 'flex',
      alignSelf: 'center',
      flexDirection: 'column',
      position: 'absolute',
    },
    logoSpan: {
      position: 'absolute',
      alignSelf: 'center',
      top: -26,
    },
    logo: {
      borderRadius: '50%',
      height: 82,
      width: 82,
      backgroundColor: 'white',
      boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
      padding: 5,
    },
    headers: {
      display: 'inline-flex',
      margin: 20,
      userSelect: 'none',
    },
    aboutText: {
      margin: 20,
      padding: 0,
      alignSelf: 'center',
      minHeight: 145,
      width: 340,
      textAlign: 'center',
    },
    aboutHeader: {
      fontWeight: 500,
    },
    aboutSubHeaderText: {
      color: theme.palette.grey[500],
      fontWeight: 400,
      paddingLeft: 7,
    },
    aboutButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 30,
    },
    close: {
      alignSelf: 'flex-end',
    },
    copyRight: {
      margin: 0,
      color: theme.palette.grey[400],
    },
    closeButton: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
  });

interface AboutMenuItemModalProps extends WithStyles<typeof styles> {
  openAbout: boolean;
  closeAboutModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AboutMenuItemModal: FunctionComponent<AboutMenuItemModalProps> = (props) => {
  let uiConfig = useConfig();

  const [displayAppInfo, setDisplayAppInfo] = useState(false);
  const [onClick] = useMultiClick(() => setDisplayAppInfo((curVal) => !curVal), 4);
  return (
    <Modal
      disableAutoFocus
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.openAbout}
      onClose={props.closeAboutModal}
      className={props.classes.modalContainer}
    >
      <div className={props.classes.aboutModalContainer}>
        <span className={props.classes.logoSpan}>
          <img
            src={(uiConfig.theme.logoUrl.length > 0 && uiConfig.theme.logoUrl) || logo}
            alt="logo"
            className={props.classes.logo}
          />
        </span>
        <Button onClick={props.closeAboutModal} className={props.classes.close}>
          <Close />
        </Button>
        <div className={props.classes.aboutText}>
          <div className={props.classes.headers} onClick={onClick}>
            <Typography variant="h2" className={props.classes.aboutHeader}>
              {uiConfig && uiConfig.applicationName}
            </Typography>
            <Typography variant="h2" className={props.classes.aboutSubHeaderText}>
              {uiConfig && `v${uiConfig.appInfo?.productVersion}`}
            </Typography>
          </div>
          {displayAppInfo && (
            <Typography variant="h3" className={props.classes.copyRight}>
              Version: v{uiConfig.appInfo?.version};<br />
              CI build time: {uiConfig.appInfo?.ciBuildtimestamp};<br />
              Commit: {uiConfig.appInfo?.commit};<br />
              CI build number: {uiConfig.appInfo?.ciBuildNumber};<br />
              <br />
            </Typography>
          )}
          <Typography variant="h3" className={props.classes.copyRight}>
            <>{magicText.t('Settings.about.copyRight', { year: new Date().getFullYear() })}</>
          </Typography>
          <div className={props.classes.aboutButtonContainer}>
            <Button
              className={props.classes.closeButton}
              href={magicText.t('Settings.about.endUserAgreementLink')}
              target="_blank"
            >
              Eula
            </Button>
            <Button
              className={props.classes.closeButton}
              href={magicText.t('Settings.about.supportLink')}
              target="_blank"
            >
              <> {magicText.t('Settings.about.support')}</>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(AboutMenuItemModal);
