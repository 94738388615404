import { useState, useMemo, CSSProperties } from 'react';
import magicText from 'i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { gql, useMutation } from '@apollo/client';
import { SelectedImportType } from './ImportHistory';
import TGAlertDialogWithIcon from '../TGAlertDialogWithIcon';
import { useConfig } from '@terragotech/gen5-shared-components';
import { Import } from './ImportHistory';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PendingJobsIcon from './PendingJobsIcon';
import PendingJobsWarningIcon from './PendingJobsWarningIcon';
import { downloadFile } from './FileToView';
import { DOC_API_URL } from '../../utils/docApiURL';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';

const CANCEL_IMPORT = gql`
  mutation cancelImport($importId: String!) {
    cancelImport(importId: $importId) {
      message
    }
  }
`;

export interface CancelImportResponse {
  response: any;
}

export interface CancelImportVariables {
  importId: string;
}

interface DataProp {
  selectedImport?: SelectedImportType;
  setSelectedImport: React.Dispatch<React.SetStateAction<SelectedImportType | undefined>>;
  imp: Import[];
  setFileIdToView: (x: string) => void;
}
interface Props {
  data: DataProp;
  index: number;
  style: CSSProperties;
}
const useStyles = makeStyles(theme => ({
  themeBackground: {
    backgroundColor: `${theme?.palette?.primary?.main || '#56CCF2'}`,
  },
  cellFontStyle: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    padding: '10px',
  },
  fileCell: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    maxWidth: 300,
    padding: '10px',
  },
}));

const PendingJobRow = (props: Props) => {
  const { data, index } = props;
  const classes = useStyles();
  const theme = useTheme();
  const specifiedImport = data?.imp[index];
  const { defaultDateTimeFormat } = useConfig();

  const status = useMemo(() => {
    if (specifiedImport?.commandError != null) {
      return 'Error';
    } else if (specifiedImport?.committed === null) {
      return 'Uploading';
    } else if (specifiedImport?.committed === true) {
      return 'Importing';
    } else if (specifiedImport?.errorsWarnings.length) {
      return 'Needs Action';
    } else {
      return 'Pending';
    }
  }, [specifiedImport]);

  const reviewButtonDisabled = useMemo(() => {
    return status === 'Uploading' || status === 'Importing' || status === 'Error';
  }, [status]);

  const downloadButtonDisabled = useMemo(() => {
    return status === 'Uploading' || status === 'Error';
  }, [status]);

  const [cancelImport] = useMutation<CancelImportResponse, CancelImportVariables>(CANCEL_IMPORT);
  const importIdToCancel = specifiedImport?.id;

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <TableRow style={{ backgroundColor: '#FAFAFA' }}>
      <TableCell align={'left'} className={classes.fileCell}>
        <div style={{ display: 'flex', marginLeft: 5 }}>
          {status === 'Needs Action' ? (
            <PendingJobsWarningIcon fill={theme.palette.primary.main || '#56CCF2'} size={22} />
          ) : (
            <PendingJobsIcon fill={theme.palette.primary.main || '#56CCF2'} size={22} />
          )}
          <div
            style={{
              marginLeft: 19,
              display: 'flex',
              alignItems: 'center',
              maxWidth: 300,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {specifiedImport?.filename}
          </div>
        </div>
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {specifiedImport?.type}
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {status}
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {specifiedImport?.metadata?.userInfo?.email}
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        <span>
          {specifiedImport?.timestamp ? moment(String(specifiedImport?.timestamp)).format(getDateTimeFormat(
            defaultDateTimeFormat?.dateFormatType,
            defaultDateTimeFormat?.dateFormat,
            defaultDateTimeFormat?.dateSeperator,
            defaultDateTimeFormat?.timeFormat,
            { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS },
            defaultDateTimeFormat?.isSwitch,
          )) : ''}
        </span>
      </TableCell>
      <TableCell align={'center'} className={classes.cellFontStyle}>
        {!specifiedImport.isintegration && <Button
          disabled={reviewButtonDisabled}
          disableElevation
          style={{ color: '#FFFFFF', marginRight: 10 }}
          color="primary"
          variant="contained"
          className={classes.themeBackground}
          onClick={() => {
            data.setFileIdToView(specifiedImport?.id);
            data.setSelectedImport(specifiedImport);
          }}
        >
          Review
        </Button>}
        {specifiedImport.isintegration && <Button
          disabled={downloadButtonDisabled}
          disableElevation
          style={{ color: '#FFFFFF', marginRight: 10 }}
          color="primary"
          variant="contained"
          className={classes.themeBackground}
          onClick={() => {
            downloadFile(
              `${DOC_API_URL}${specifiedImport?.id}`,
              `${specifiedImport?.filename.substring(0, specifiedImport?.filename.lastIndexOf('.')) || specifiedImport?.filename}`,
              undefined,
              specifiedImport?.filename.substring(specifiedImport?.filename.lastIndexOf('.')+1) 
            )
          }}
        >
          Download
        </Button>}
        <IconButton
          style={{ backgroundColor: '#F3F3F3', borderRadius: 4, height: 40 }}
          onClick={() => setDialogOpen(true)}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <TGAlertDialogWithIcon
        open={dialogOpen}
        title={'Confirm'}
        confirmButtonTitle={'Discard'}
        warningIcon={true}
        onCancel={() => setDialogOpen(false)}
        onConfirm={async () =>
          await cancelImport({ variables: { importId: importIdToCancel } }).then(() => setDialogOpen(false))
        }
        bodyText={magicText.t('import.cancelConfirm')}
        theme={theme}
      />
    </TableRow>
  );
};

export default PendingJobRow;
