import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  FormControlProps,
  FileFieldProps,
  FieldProps,
  LocationFieldProps,
  PolylineFieldProps,
  useFormDataMappingSources,
  MapAggregateSelectorFieldProps,
  V2MapAggregateSelectorComponent,
  FileFieldPropsWithName,
  V2MediaFileComponent,
  V2UserLoader,
  DynamicLoaderType,
  SimpleOptionType,
  AggregateLoaderType,
  UserLoaderType,
} from '@terragotech/form-renderer';
import { TGLocationFieldProps } from '../FormFields/TGLocationField/TGLocationField';
import { TGPolylineFieldProps } from '../FormFields/TGLocationField/TGPolylineField';

import {
  V2NumberTemplateComponent,
  V2TextHeaderComponent,
  V2CurrencyTemplateComponent,
  V2TextTemplateComponent,
  V2SingleSelectComponent,
  V2MultiSelectComponent,
  V2BarcodeComponent,
  V2MediaComponent,
  V2LocationComponent,
  V2PolylineComponent,
  V2OptionsType,
  V2AggregateLoader,
} from '@terragotech/form-renderer';
import {
  useConfig,
  useAuthContext,
  useUserInfo,
  computeGraphQlQueryFromQueryDefinitionWithPagination,
  computeGraphQlQueryFetchAggregateTypeByIds,
  dateValueToDatePicker,
  datePickerToDateValue,
  timePickerToTimeValue,
  timeValueToTimePicker,
  TGBarcodeInputProps,
  TGDateFieldProps,
  FileType,
  MediaFieldProps,
  MediaFileFieldProps,
  TGRadioFieldProps,
  TGSelectFieldProps,
  TGTextFieldProps,
  TGTextHeaderProps,
  TGTimeFieldProps, PageInfoType,
} from '@terragotech/gen5-shared-components';
import { TGMapAggregateSelectorFieldProps } from '../FormFields/TGMapAggregateSelectorField';
import { CircularProgress } from '@material-ui/core';

const DATA_COUNT_PER_PAGE = 100;
export type ValidationFunction<T = string> = (
  value: T,
  allValues: Record<string, unknown>
) =>
  | {
      warnings: string[];
      errors: string[]; //TODO: add the ability to pass a required field error here
    }
  | Promise<{
      warnings: string[];
      errors: string[]; //TODO: add the ability to pass a required field error here
    }>;

interface ErrorAndWarningProps {
  error: boolean;
  warning: boolean | undefined;
  helperText: string;
}

const getErrorAndWarningProps = ({
  errors,
  warnings,
}: {
  errors?: string[];
  warnings?: string[];
}): ErrorAndWarningProps => {
  let helperText = '';
  if (errors && errors[0]) {
    helperText = errors[0];
  } else if (warnings && warnings[0]) {
    helperText = warnings[0];
  }

  return {
    error: !!(errors && errors[0]),
    warning: warnings && warnings[0] ? true : undefined,
    helperText,
  };
};
const useFilterTextFieldProps = (
  props: FieldProps<V2TextTemplateComponent | V2CurrencyTemplateComponent | V2NumberTemplateComponent>,
  type?: string
) => {
  const { value, onChange, controlDefinition, warnings, onFocus, onBlur, readOnly } = props;
  const { required, label, placeholder, info } = controlDefinition;
  const filteredProps = {
    type: type || 'text',
    ...getErrorAndWarningProps(props),
    value,
    onFocus,
    onBlur,
    onChange,
    warnings,
    label,
    required,
    info,
    placeholder: placeholder || 'No Placeholder',
    readOnly,
  };
  return filteredProps;
};
const useOptions = (optionDef: V2OptionsType) => {
  const client = useApolloClient();
  // TODO: I don't like this. Options within form dropdowns, probably shouldn't be reading the accessors.
  const { accessors } = useFormDataMappingSources();
  const { aggregateDefinitions } = useConfig();
  const { username, roles } = useUserInfo();
  const optionsProvider = useCallback(
    async (params?: { filter?: string }, endCursor?: string, searchText?: string): Promise<{items: Array<{ label: string; value: unknown }>, pageInfo: PageInfoType} | {}> => {
      if (optionDef.type === SimpleOptionType) {
        return {};
      }
      // TODO use default filtering and mandatory filtering to generate the query not here though
      else if (optionDef.type === AggregateLoaderType) {
        try {
          const dataLoader = optionDef as V2AggregateLoader;
          const aggregateSource = (accessors.STATE && (accessors.STATE() as Record<string, unknown>)) || {};
          const queryKey = aggregateDefinitions.find(d => d.name === dataLoader.aggregateType)?.queryKey;
          if (!queryKey) {
            console.error('No query key found for ', dataLoader.aggregateType);
            return {};
          }
          const graphQlQuery = computeGraphQlQueryFromQueryDefinitionWithPagination({
            queryKey: queryKey,
            queryDefinition: dataLoader.mandatoryFilter,
            objectToMatchKeyWord: aggregateSource,
            username,
            userRoles: roles.map(role => role.name),
            limit: DATA_COUNT_PER_PAGE,
            after: endCursor,
            searchText: searchText,
          });
          const items = await client
            .query({
              query: gql`
                ${graphQlQuery.query}
              `,
            })
            .then(response => {
              const nodes = response.data[`${queryKey}Connection`]['nodes'];
              const pageInfo = response.data[`${queryKey}Connection`]['pageInfo'];
              const totalCount = response.data[`${queryKey}Connection`]['totalCount'];
              const metadata = (accessors.METADATA && accessors.METADATA()) || {};
              if (
                metadata.hasOwnProperty('aggregateType') &&
                (metadata as { aggregateType?: string }).aggregateType === dataLoader.aggregateType
              ) {
                return {items: nodes.filter(({ id }: { id: string }) => id !== aggregateSource.id), pageInfo: {...pageInfo, totalCount: totalCount} };
              }
              return {items: nodes, pageInfo: {...pageInfo, totalCount: totalCount}};
            })
              .then(graphQlData => {
                const data = (graphQlData?.items || []).map(({label, id}: { label: string; id: string }) => ({
                  label,
                  value: id,
                }));
                return {...graphQlData, items: data};
              });
          return items;
        } catch (e) {
          console.error(e);
          return {};
        }
      }
      else if (optionDef.type === UserLoaderType) {
        try {
          const dataLoader = optionDef as V2UserLoader;
          const userSource = (accessors.STATE && (accessors.STATE() as Record<string, unknown>)) || {};
          const queryKey = 'usersSelect';
          const graphQlQuery = computeGraphQlQueryFromQueryDefinitionWithPagination({
            queryKey,
            queryDefinition: dataLoader.mandatoryFilter,
            objectToMatchKeyWord: userSource,
            username,
            userRoles: roles.map(role => role.name),
            limit: DATA_COUNT_PER_PAGE,
            after: endCursor,
            searchText: searchText,
          });
          const items = await client
            .query({
              query: gql`
                ${graphQlQuery.query}
              `,
            })
            .then(response => {
              const nodes = response.data[`${queryKey}Connection`]['nodes'];
              const pageInfo = response.data[`${queryKey}Connection`]['pageInfo'];
              const totalCount = response.data[`${queryKey}Connection`]['totalCount'];
              return {items: nodes, pageInfo: {...pageInfo, totalCount: totalCount}};
            })
            .then(graphQlData => {
              const data = (graphQlData?.items || []).map(({label, id}: { label: string; id: string }) => ({
                label,
                value: id,
              }));
              return {...graphQlData, items: data};
            });

          return items;
        } catch (e) {
          console.error(e);
          return {};
        }
      }
      else {
        return {};
      }
    },
    [optionDef, client, aggregateDefinitions, accessors]
  );
  const valueOptionsProvider = useCallback(
    async (value: unknown): Promise<{ value: unknown; label: string }[]> => {
      if (optionDef.type !== AggregateLoaderType) return [];
      const dataLoader = optionDef as V2AggregateLoader;
      const queryKey = aggregateDefinitions.find(d => d.name === dataLoader.aggregateType)?.queryKey;
      if (!queryKey) {
        console.error('No query key found for ', dataLoader.aggregateType);
        return [];
      }
      const graphQlQuery = computeGraphQlQueryFetchAggregateTypeByIds({
        queryKey,
        ids: Array.isArray(value) ? value : [value],
      });
      try {
        const items = await client
          .query({
            query: gql`
              ${graphQlQuery.query}
            `,
            variables: graphQlQuery.variables,
          })
          .then(response => {
            return response.data[queryKey].map(({ label, id }: { label: string; id: string }) => ({
              label,
              value: id,
            }));
          });
        return items;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [optionDef, client, aggregateDefinitions]
  );
  const options = optionDef.type === SimpleOptionType ? optionDef.items : undefined;
  const dataProvider: TGSelectFieldProps['dataProvider'] =
    optionDef.type !== SimpleOptionType && optionDef.type !== DynamicLoaderType
      ? {
          optionsProvider,
          valueOptionsProvider,
        }
      : undefined;
  return {
    options,
    dataProvider,
  };
};
const useSelectFieldProps = (props: FieldProps<V2SingleSelectComponent | V2MultiSelectComponent>) => {
  const { controlDefinition, errors, warnings, ...moreProps } = props;
  const { options, label, placeholder, required, info } = controlDefinition;
  const optionsList = useOptions(options);

  const filteredProps = {
    ...optionsList,
    label,
    placeholder,
    required,
    info,
    ...getErrorAndWarningProps(props),
    ...(moreProps || {}),
  };
  return filteredProps;
};

export const withTextFieldProps = (Component: React.FC<TGTextFieldProps>) => {
  return (props: FieldProps<V2TextTemplateComponent>) => {
    const filteredProps = useFilterTextFieldProps(props);
    return <Component {...filteredProps} />;
  };
};

export const withTextFieldPropsComputed = (Component: React.FC<TGTextFieldProps>) => {
  return (props: FieldProps<V2TextTemplateComponent>) => {
    const filteredProps = useFilterTextFieldProps(props);
    return <Component computed {...filteredProps} />;
  };
};

export const withNumberFieldProps = (Component: React.FC<TGTextFieldProps>) => {
  return (props: FieldProps<V2NumberTemplateComponent>) => {
    const filteredProps = useFilterTextFieldProps(props, 'number');
    return <Component {...filteredProps} />;
  };
};

export const withDateFieldProps = (Component: React.FC<TGDateFieldProps>) => {
  return (props: FieldProps<V2TextTemplateComponent>) => {
    const { value, onChange, controlDefinition, warnings, readOnly } = props;
    const valueToUse = value && typeof value === 'number' ? dateValueToDatePicker(value) : null;
    const { label, placeholder, required, info } = controlDefinition;
    const filteredProps = {
      label,
      placeholder,
      required,
      info,
      value: valueToUse,
      ...getErrorAndWarningProps(props),
      warnings,
      onChange: (date: Date | null) => {
        onChange(date ? datePickerToDateValue(date) : null);
      },
      readOnly,
    };
    return <Component {...filteredProps} />;
  };
};

export const withSelectFieldProps = (Component: React.FC<TGSelectFieldProps>) => {
  return (props: FieldProps<V2SingleSelectComponent | V2MultiSelectComponent> & {pending?: boolean}) => {
    const filteredProps = useSelectFieldProps(props);
    return props.pending ? <CircularProgress color="primary" /> : <Component {...filteredProps} />;
  };
};

export const withAggregateSelectorFieldProps = (Component: React.FC<TGMapAggregateSelectorFieldProps>) => {
  return (props: MapAggregateSelectorFieldProps<V2MapAggregateSelectorComponent>) => {
    //const filteredProps = useSelectFieldProps(props);
    //return <Component {...filteredProps} />;
    const { controlDefinition, errors, warnings, value, onChange, ...moreProps } = props;
    const { label, placeholder, required, info, selectableAggregateTypes } = controlDefinition;

    const filteredProps = {
      value,
      onChange,
      label,
      placeholder,
      required,
      info,
      selectableAggregateTypes,
      ...getErrorAndWarningProps(props),
      ...(moreProps || {}),
    };
    return <Component {...filteredProps} />;
  };
};

export const withRadioFieldProps = (Component: React.FC<TGRadioFieldProps>) => {
  return (props: FieldProps<V2SingleSelectComponent> & {pending?: boolean}) => {
    const { controlDefinition, readOnly, value, onChange, ...moreProps } = props;
    const { options, required, label, placeholder, info } = controlDefinition;
    const optionsList = useOptions(options);
    const filteredProps = {
      ...getErrorAndWarningProps(props),
      ...optionsList,
      value,
      onChange,
      readOnly,
      required,
      info,
      label,
      placeholder,
      ...(moreProps || {}),
    };
    return props.pending ? <CircularProgress color="primary" /> : <Component {...filteredProps} />;
  };
};

export const withCheckboxProps = (Component: React.FC<TGSelectFieldProps>) => {
  return (props: FieldProps<V2MultiSelectComponent> & {pending?: boolean}) => {
    const selectFieldProps = useSelectFieldProps(props);
    return props.pending ? <CircularProgress color="primary" /> : <Component {...selectFieldProps} multiSelect />;
  };
};

export const withTimeFieldProps = (Component: React.FC<TGTimeFieldProps>) => {
  return (props: FieldProps<V2TextTemplateComponent>) => {
    const { value, onChange, controlDefinition, readOnly } = props;
    const { label, placeholder, required, info } = controlDefinition;
    const valueToUse = value && typeof value === 'number' ? timeValueToTimePicker(value) : null;
    const filteredProps = {
      value: valueToUse,
      ...getErrorAndWarningProps(props),
      onChange: (time: Date | null) => {
          onChange(time ? timePickerToTimeValue(time) : null);
      },
      label,
      placeholder,
      required,
      info,
      readOnly,
    };
    return <Component {...filteredProps} />;
  };
};

export const withCurrencyFieldProps = (Component: React.FC<TGTextFieldProps>) => {
  return (props: FieldProps<V2CurrencyTemplateComponent>) => {
    const textFieldProps = useFilterTextFieldProps(props, 'number');
    const filteredProps = {
      ...textFieldProps,
      InputProps: {
        startAdornment: <InputAdornment position="start">{props.controlDefinition.currency}</InputAdornment>,
      },
    };
    return <Component {...filteredProps} />;
  };
};

export const withTextHeaderProps = (Component: React.FC<TGTextHeaderProps>) => {
  return (props: FormControlProps<V2TextHeaderComponent>) => {
    const { label } = props.controlDefinition;
    const filteredProps = {
      label,
    };
    return <Component {...filteredProps} />;
  };
};

export const withTextareaFieldProps = (Component: React.FC<TGTextFieldProps>) => {
  return (props: FieldProps<V2TextTemplateComponent>) => {
    const textFieldProps = useFilterTextFieldProps(props);
    const filteredProps = {
      ...textFieldProps,
      multiline: true,
    };
    return <Component {...filteredProps} />;
  };
};
const getTypeFromFile = (file: string) => {
  const parts = file?.split('.');
  if(parts && parts.length > 0){
    switch (parts[parts.length - 1].toLowerCase()) {
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
    }
  }
  return 'image/jpeg'; // reasonable? Prolly not
};
// type to map file id to local url blob
interface FileUrlType {
  id: string;
  url: string | null;
}
export const withMediaFieldProps = (Component: React.FC<MediaFieldProps>) => {
  return (props: FileFieldProps<V2MediaComponent>) => {
    const { value, controlDefinition, getFile, removeFile, addFile, readOnly } = props;
    const [fileUrls, setFileUrls] = useState<Array<FileUrlType>>([]);
    const authState = useAuthContext();

    useEffect(() => {
      //any time value changes, check to see if we need a url based on id.
      //TODO: This is a mess. File handling is pretty bad right now. Most of this is to work around the lack of mime types
      if (value) {
        let localVal: Array<string> = Array.isArray(value) ? value : [value];
        localVal &&
          localVal.forEach(val => {
            if (!fileUrls.map(fileUrl => fileUrl.id).includes(val)) {
              //then attempt to load the file into the url cache
              //first try the local cache?
              let possibleFile = getFile(val);
              if (possibleFile) {
                setFileUrls(current => [...current, { id: val, url: getUrl(possibleFile) }]);
              } else {
                // then try the doc_api
                authState.token &&
                  authState.token().then(token => {
                    const options = {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    };
                    fetch(`/media/v1/${val}`, options)
                      .then(res => res.blob())
                      .then(blob => {
                        const file = new File([blob], val, { type: getTypeFromFile(val) });
                        setFileUrls(current => [...current, { id: val, url: URL.createObjectURL(file) }]);
                      });
                  });
              }
            }
          });
        // having this set of dependencies could cause this to be called more often than needed
      }
    }, [value, fileUrls, setFileUrls, authState, getFile]);
    const getUrl = (file: any) => {
      if (file && file.name) {
        return URL.createObjectURL(file);
      }
      if (_.isString(file)) {
        return file;
      }
      return null;
    };
    const files = Array.isArray(value)
      ? value?.map(val => ({
          id: val,
          file: getFile(val),
          url: fileUrls.find(entry => entry.id === val)?.url || '',
        }))
      : //@ts-ignore
      value
      ? [{ id: value as string, file: getFile(value), url: fileUrls.find(entry => entry.id === value)?.url || '' }]
      : [];
    const filteredProps = {
      type: controlDefinition.type,
      required: !!controlDefinition.required,
      info: controlDefinition.info,
      addFiles: (files: Array<FileType>) => {
        files.forEach((fileOb: FileType) => {
          addFile(fileOb.file);
          //also add the url to the local cache
          setFileUrls(current => [...current, { id: fileOb.id, url: getUrl(fileOb.file) }]);
        });
      },
      removeFiles: (ids: Array<string>) => {
        ids.forEach(id => removeFile(id));
      },
      files,
      value,
      min: controlDefinition.min || 0,
      max: controlDefinition.max || -1,
      label: controlDefinition.label,
      // errors, TODO - not supported
      readOnly,
      ...getErrorAndWarningProps(props),
    };
    return <Component {...filteredProps} />;
  };
};

export const withFileFieldProps = (Component: React.FC<MediaFileFieldProps>) => {
  return (props: FileFieldPropsWithName<V2MediaFileComponent>) => {
    const { value, controlDefinition, getFile, removeFile, addFile, readOnly } = props;
    const [fileUrls, setFileUrls] = useState<Array<FileUrlType>>([]);
    const authState = useAuthContext();

    useEffect(() => {
      //any time value changes, check to see if we need a url based on id.
      //TODO: This is a mess. File handling is pretty bad right now. Most of this is to work around the lack of mime types
      if (value) {
        let localVal = Array.isArray(value) ? value : [value];
        let newFileUrls = _.cloneDeep(fileUrls);
        localVal &&
          localVal.forEach(val => {
            if (!fileUrls.map(fileUrl => fileUrl.id).includes(val.id)) {
              //then attempt to load the file into the url cache
              //first try the local cache?
              let possibleFile = getFile(val.id);
              if (possibleFile) {
                newFileUrls = [...newFileUrls, { id: val.id, url: getUrl(possibleFile) }];
              } else {
                newFileUrls = [...newFileUrls, { id: val.id, url: null }];
              }
            }
          });
        if (!_.isEqual(fileUrls, newFileUrls)) {
          setFileUrls(newFileUrls);
        }
        // having this set of dependencies could cause this to be called more often than needed
      }
    }, [value, fileUrls, setFileUrls, authState, getFile]);
    const getUrl = (file: any) => {
      if (file && file.name) {
        return URL.createObjectURL(file);
      }
      if (_.isString(file)) {
        return file;
      }
      return null;
    };
    const files = Array.isArray(value)
      ? value?.map(val => ({
          id: val.id,
          file: getFile(val.id) || { name: val.name },
          url: fileUrls.find(entry => entry.id === val.id)?.url || '',
        }))
      : //@ts-ignore
      value
      ? [
          {
            id: (value as any).id as string,
            file: getFile((value as any).id),
            url: fileUrls.find(entry => entry.id === (value as any).id)?.url || '',
          },
        ]
      : [];
    const filteredProps = {
      type: controlDefinition.type,
      required: !!controlDefinition.required,
      info: controlDefinition.info,
      addFiles: (files: Array<FileType>) => {
        files.forEach((fileOb: FileType) => {
          addFile(fileOb.file);
          //also add the url to the local cache
          setFileUrls(current => [...current, { id: fileOb.id, url: getUrl(fileOb.file) }]);
        });
      },
      removeFiles: (ids: Array<string>) => {
        ids.forEach(id => removeFile(id));
      },
      files,
      value,
      min: controlDefinition.min || 0,
      max: controlDefinition.max || -1,
      label: controlDefinition.label,
      // errors, TODO - not supported
      readOnly,
      ...getErrorAndWarningProps(props),
    };
    return <Component {...filteredProps} />;
  };
};

export const withBarcodeFieldProps = (Component: React.FC<TGBarcodeInputProps>) => {
  return (props: FieldProps<V2BarcodeComponent>) => {
    const { controlDefinition, readOnly, onChange, value, validationFn } = props;
    const { label, placeholder, required, type, info } = controlDefinition;
    const validator: ValidationFunction | undefined = validationFn
      ? (val, allValues) => {
          return validationFn(val, allValues, true); // adds true to the function to ignore whether or not the input has been touched when validating
        }
      : undefined;
    const filteredProps = {
      type,
      label,
      readOnly,
      required,
      info,
      onChange,
      value: value as string,
      placeholder: placeholder || '',
      validator: validator,
      ...getErrorAndWarningProps(props),
    };
    return <Component {...filteredProps} />;
  };
};

export const withLocationFieldProps = (Component: React.FC<TGLocationFieldProps>) => {
  return (props: LocationFieldProps<V2LocationComponent>) => {
    const { controlDefinition, readOnly, onChange, value, validationFn } = props;
    const { label, placeholder, type, required, info, multiplePoints } = controlDefinition;
    const validator: ValidationFunction | undefined = validationFn
      ? (val: string, allValues: Record<string, unknown>) => {
          return validationFn(val, allValues, true);
        }
      : undefined;
    const locationProps = {
      type,
      label,
      readOnly,
      required,
      info,
      multiplePoints,
      onChange: (val: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null | undefined) => {
        if (val) {
          if (Array.isArray(val)) {
            let restructuredJson = val.map((point: GeoJSON.Point) => {
              let arrayEntry = { location: {} };
              arrayEntry.location = point;
              return arrayEntry;
            });
            onChange(restructuredJson);
          } else {
            onChange({ location: val });
          }
        } else {
          onChange(undefined);
        }
      },
      ...getErrorAndWarningProps(props),
      value: Array.isArray(value) ? value.filter(x => !!x?.location).map(x => x?.location) : value?.location || null,
      placeholder: placeholder || '',
      validator: validator,
    };
    return <Component {...locationProps} />;
  };
};

export const withPolylineFieldProps = (Component: React.FC<TGPolylineFieldProps>) => {
  return (props: PolylineFieldProps<V2PolylineComponent>) => {
    const { controlDefinition, readOnly, onChange, value, validationFn } = props;
    const { label, placeholder, type, required, info } = controlDefinition;
    const validator: ValidationFunction | undefined = validationFn
      ? (val: string, allValues: Record<string, unknown>) => {
          return validationFn(val, allValues, true);
        }
      : undefined;
    const polylineProps = {
      type,
      label,
      readOnly,
      required,
      info,
      onChange: (val: GeoJSON.LineString | null | undefined) => {
        if (val) {
          onChange({ polyline: val });
        } else {
          onChange(undefined);
        }
      },
      ...getErrorAndWarningProps(props),
      value: value?.polyline || null,
      placeholder: placeholder || '',
      validator: validator,
    };
    return <Component {...polylineProps} />;
  };
};
