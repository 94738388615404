import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMultiSelectWithProvider } from 'use-multiselect';
import ActionsButton from '../components/ActionsButton';
import AssetTable, { RecordsChanged } from '../components/AssetTable/AssetTable';
import SearchBar from '../components/SearchBar';
import SelectAllCheckbox from '../components/SelectAllCheckbox';
import {
  useConfig,
  isOneToOneRelationshipField,
  isRelationshipField,
  isOneToManyRelationshipField,
} from '@terragotech/gen5-shared-components';
import { useFilter } from '../contexts/FilterContext/filterContext';
import { useRecordType } from '../contexts/recordTypeContext';
import { TableColumnContextProvider } from '../contexts/TableColumnContext';
import useSubmitCommands, { CommandType } from '../hooks/useSubmitCommand';
import { ActionsExecutorProvider } from '../hooks/useActionsExecutor';
import { useAggregates } from '../contexts/AggregatesContext';
export interface AssetTableContainerProps {
  height: number;
  minimizeAndExpandTable?: (value: number) => void;
}
interface CurrentChangedRecords {
  [recordId: string]: {
    [fieldName: string]: unknown;
  };
}
const SEARCH_BAR_HEIGHT = 35;

/**
 * AssetsTableContainer - Handles the data needs and logic for the Assets Table UI component.
 *
 * Re-renders the list of assets displayed when filters change
 *
 * 4 components,
 *    searchBar
 *    actionsButton
 *    selectAllCheckBox
 *    ActionsMenu
 *
 * And of course,
 *    AssetsTable
 *
 * @param heights
 * @param searchText
 * @param handleSearchText
 */

// Stores all changes to the table. Updated on change or on every keystroke in the case of text inputs.
// React-data-grid persists changes so in order to avoid unnecessary rerender triggering temporary changes are saved as a variable instead of state
let currentChangesVariable: CurrentChangedRecords = {};

const AssetsTableContainer: React.FunctionComponent<AssetTableContainerProps> = props => {
  const { height, minimizeAndExpandTable } = props;
  const { selectedRecordType } = useRecordType();
  const { aggregateDefinitions } = useConfig();
  const assetInfo = useAggregates();
  const [changedRecords, setChangedRecords] = useState<Array<string>>([]);
  const { filterCount, resetAllFilters, drawtoolFilter } = useFilter();
  const match = useRouteMatch<{ assetId: string }>(`/${selectedRecordType}/:assetId`);
  const assetId = match?.params?.assetId;
  const [organizeTableOpen, setOrganizeTableOpen] = useState(false);
  const handleOrganizeTableClick = useCallback(() => {
    setOrganizeTableOpen(true);
  }, [setOrganizeTableOpen]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { isMultiSelectActive, setMultiSelectActive } = useMultiSelectWithProvider();
  const handleOnChange = useCallback((recordId: any, field: any, value: any) => {
    const changes: RecordsChanged = {
      [recordId]: {
        [field]: value,
      },
    };
    // each key returned is a recordId
    Object.keys(changes).forEach(recordId => {
      const change = changes[recordId];
      //each key here is a field name
      const fieldNames = Object.keys(change);
      // cycle through all of the fields and compare the new value to the old.
      fieldNames.forEach(fieldName => {
        currentChangesVariable = {
          ...currentChangesVariable,
          [recordId]: { ...currentChangesVariable[recordId], [fieldName]: change[fieldName] },
        };
      });
    });
  Object.keys(currentChangesVariable).forEach((id: any) => {
    setChangedRecords(Object.keys(currentChangesVariable[id]));
});
  }, []);

  useEffect(() => {
    setMultiSelectActive(drawtoolFilter);
  }, [drawtoolFilter]);
  const assetsWithAppliedChanges = useMemo(() => {
    // If there are no changes, then skip checking anything and just return the assets as-is
    if (Object.keys(currentChangesVariable).length === 0) {
      return assetInfo.filteredCurrentTypeAssets;
    }
    return assetInfo.filteredCurrentTypeAssets.map(asset => {
      if (currentChangesVariable[asset.id]) {
        return { ...asset, ...currentChangesVariable[asset.id] };
      } else {
        return asset;
      }
    });
    // Dependency array must contain currentChangesVariable even, because it's a workaround to avoid unnecesary rerenders
    // eslint-disable-next-line
  }, [assetInfo.filteredCurrentTypeAssets, currentChangesVariable]);

  const { submitCommands } = useSubmitCommands();

  const handleSave = useCallback(() => {
    const changeCommands = Object.keys(currentChangesVariable).map<CommandType>(aggregateId => {
      const computedCommandData: Record<string, unknown> = {};
      Object.keys(currentChangesVariable[aggregateId]).forEach(fieldName => {
        let fieldData: any = currentChangesVariable[aggregateId][fieldName];
        if (isRelationshipField(aggregateDefinitions, selectedRecordType, fieldName)) {
          if (isOneToOneRelationshipField(aggregateDefinitions, selectedRecordType, fieldName)) {
            // One to One
            fieldData = (fieldData && fieldData.length > 0) ? fieldData[0].id || null : null;
          } else if (isOneToManyRelationshipField(aggregateDefinitions, selectedRecordType, fieldName)) {
            // One to Many
            const previousRecordIds: string[] = (assetInfo.filteredCurrentTypeAssets.find(
              record => record?.id === aggregateId
            )?.[fieldName] as Array<{ id: string }>).map(record => record.id);
            const newRecordIds: string[] = fieldData.map((item: { id: string }) => item.id);
            fieldData = {
              // Loop through new id's, return if record didn't exist before
              added: newRecordIds.filter((record: string) => !previousRecordIds.includes(record)),
              // Loop through old id's, return if they no longer exist in new
              removed: previousRecordIds.filter((record: string) => !newRecordIds.includes(record)),
            };
          } else {
            fieldData = (fieldData as Array<{ id: string }>).map(x => {
              return x.id;
            });
          }
        }
        computedCommandData[fieldName] = fieldData;
      });
      return {
        target: aggregateId,
        version: -1,
        aggregateType: selectedRecordType,
        commandName: 'AdminUpdate',
        commandData: computedCommandData,
        isSynchronous: false, // Don't need high polling rate for this
        hasNoAggregateIdCommand: false,
      };
    });
    setIsLoading(true);
    submitCommands(changeCommands)
      .then(() => {
        setIsLoading(false);
        //TODO: Update adminUpdate command to use graphQL response to update the data set rather than requery.
        // setTimeout(() => {
        //   assetInfo.refetchAll().then(() => {
        //     setIsLoading(false);
        //TODO: This timeout is badd, it probably doens't work well for most things
        //    But often times it should allow us to continute displaying what the user changed until the data is actually refreshed.
        setTimeout(() => {
          currentChangesVariable = {};
          setChangedRecords([]);
        }, 1000);
        //   });
        // }, 500);
      })
      .catch(() => {
        // If errors with the update query, revert the cell value
        currentChangesVariable = {};
        setChangedRecords([]);
        setIsLoading(false);
      });
    //TODO: Update this to make sure the new data is reflected in the UI, rather than relying on keeping the changes in place.
  }, [submitCommands, selectedRecordType, aggregateDefinitions, assetInfo]);

  const cancelChanges = useCallback(() => {
    currentChangesVariable = {};
    setChangedRecords([]);
  }, []);

  return (
    <div style={{ width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <TableColumnContextProvider recordType={selectedRecordType}>
        <ActionsExecutorProvider>
          {isLoading && (
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <SearchBar
            height={SEARCH_BAR_HEIGHT}
            showing={assetInfo.filteredCurrentTypeAssets.length}
            totalAssets={assetInfo.currentTypeAssetsCount}
            numberOfFilters={filterCount}
            clearAllFilters={resetAllFilters}
            handleColumnFilterOpen={handleOrganizeTableClick}
            checked={isMultiSelectActive}
            setChecked={setMultiSelectActive}
            isLoadingData={assetInfo.loading}
            minimizeAndExpandTable={minimizeAndExpandTable}
          />
          <ActionsButton />
          <SelectAllCheckbox
            totalCount={assetInfo.filteredCurrentTypeAssets.length}
            disabled={assetInfo.loading}
            forceSelect={drawtoolFilter}
            loading={isLoading}
          />
          <AssetTable
            rowData={assetsWithAppliedChanges}
            height={height - SEARCH_BAR_HEIGHT}
            id={assetId}
            columnFilterOpen={organizeTableOpen}
            setColumnFilterOpen={setOrganizeTableOpen}
            changedRecords={changedRecords}
            onEditCanceled={cancelChanges}
            onEditSaved={handleSave}
            onChange={handleOnChange}
          />
        </ActionsExecutorProvider>
      </TableColumnContextProvider>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);
export default AssetsTableContainer;
