import React from 'react';

import { ListItem, IconButton, Divider, Typography, makeStyles, Fade, Menu } from '@material-ui/core';
import TGTooltip from '../TGTooltip';

export interface TGDrawerItemProps {
  open: boolean;
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  iconMenu?: React.ReactNode;
  onIconClick?: () => void;
  tooltip?: string;
  closeMenuOnClick?: boolean;
}

const TGDrawerItem = (props: TGDrawerItemProps) => {
  const { open, icon, title, iconMenu, tooltip, closeMenuOnClick } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    props.onIconClick && props.onIconClick();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = () => {
    if (closeMenuOnClick !== false) {
      handleMenuClose();
    }
  };
  return (
    <>
      <ListItem disableGutters={!open}>
        {!open && icon && (
          <TGTooltip placement="left" title={tooltip && title ? title : ''}>
            <IconButton onClick={handleIconClick} color="primary">
              {icon}
            </IconButton>
          </TGTooltip>
        )}
        {open && (
          <Fade in={open} timeout={1000}>
            <div className={classes.content}>
              {title && (
                <Typography className={classes.title} variant="h3">
                  {title}
                </Typography>
              )}
              <div className={classes.nowrap}>
                {props.children}
              </div>
            </div>
          </Fade>
        )}
      </ListItem>
      {open && <Divider />}
      {iconMenu && (
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.menuPaper,
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={handleMenuClick}
        >
          {iconMenu}
        </Menu>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  menuPaper: {
    padding: 0,
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  nowrap:{
    whiteSpace:'nowrap',
  }
}));

export default TGDrawerItem;
