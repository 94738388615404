import * as React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles({
  dialogPaper: {
    top: '2vh',
    height: '96vh',
    maxHeight: '100%',
    position: 'inherit',
  },
});
export interface SlideDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  children?: React.ReactNode;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SlideDialog: React.FunctionComponent<SlideDialogProps> = (props) => {
  const { open, onClose, maxWidth } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{ paperScrollPaper: classes.dialogPaper }}
      TransitionComponent={Transition}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth || 'sm'}
    >
      {props.children}
    </Dialog>
  );
};
export default SlideDialog;
