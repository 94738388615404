import React, { useContext, useRef, useEffect } from 'react';
import { Checkbox, useTheme } from '@material-ui/core';
import magicText from 'i18next';
import { SizeMapContext } from './SetFilterDialog';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  filterItemOption: {
    display: 'flex',
  },
  lowerPadding: {
    padding: 6,
    alignSelf: 'start',
  },
  textField: {
    display: 'flex',
    marginTop: '6px',
  },
  blankTextField: {
    display: 'flex',
    marginTop: '8px',
  },
}));

export const Row = ({ index, value, isSelected, setSelected, field }: any) => {
  const classes = useStyles();
  const { setSize } = useContext(SizeMapContext);
  const theme: { palette: { primary: { main: string } } } = useTheme();
  const BLANK_FIELD = '(Blanks)';
  const root = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setSize(index, root.current.getBoundingClientRect().height);
  }, []);

  return (
    <div ref={root} style={{ minHeight: 35 }}>
      {!value || value === '' || value === BLANK_FIELD ? (
        <div className={classes.filterItemOption}>
          <Checkbox
            value={BLANK_FIELD}
            checked={isSelected(BLANK_FIELD)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelected(BLANK_FIELD, e.target.checked);
            }}
            style={{ color: theme.palette.primary.main }}
            classes={{ root: classes.lowerPadding }}
          />
          <div className={classes.blankTextField}>
            <> {magicText.t('Simple.Blanks')}</>
          </div>
        </div>
      ) : (
        <div className={classes.filterItemOption}>
          <Checkbox
            value={value}
            checked={isSelected(value as string)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelected(value as string, e.target.checked);
            }}
            style={{ color: theme.palette.primary.main }}
            classes={{ root: classes.lowerPadding }}
          />
          <div className={classes.textField}>{value}</div>
        </div>
      )}
    </div>
  );
};

export default Row;
