import React, { FunctionComponent } from 'react';
import { Button, createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import magicText from 'i18next';

interface ColumnFilterInstructionBoxProps extends WithStyles<typeof styles> {
  areColumnsChanged: boolean;
  handleReset: () => void;
}

const ColumnFilterInstructionBox: FunctionComponent<ColumnFilterInstructionBoxProps> = (props) => {
  const { classes, areColumnsChanged, handleReset } = props;

  return (
    <div className={classes.instructionContainer}>
      <Typography variant="body1" className={classes.instructionText}>
        <> {magicText.t('ColumnFilter.Instructions')}</>
      </Typography>
      {areColumnsChanged ? (
        <Button classes={{ root: classes.resetButton }} onClick={handleReset}>
          <> {magicText.t('ColumnFilter.Reset')}</>
        </Button>
      ) : (
        <div className={classes.resetButton}></div>
      )}
    </div>
  );
};

const styles = () =>
  createStyles({
    instructionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    instructionText: {
      fontSize: 14,
      color: colors.grayDescription,
      margin: '10px 20px',
      width: '100%',
      marginRight: '0',
    },
    resetButton: {
      width: '210px',
      marginRight: 25,
      fontSize: 14,
      textTransform: 'uppercase',
      color: colors.danger,
    },
  });

export default withStyles(styles)(ColumnFilterInstructionBox);
