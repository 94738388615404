import { createStyles, Link, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import magicText from 'i18next';
import { ServerPublicMetaContext, colors } from '@terragotech/gen5-shared-components';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';

interface SamlProvidersType {
  label: string;
  providerName: string;
}

interface LoginButtonViewProps {
  samlProviders: SamlProvidersType[];
  selectEmailLogin: () => void;
  showLogin: 'native' | 'saml' | 'both';
}

const LoginButtonView: React.FC<LoginButtonViewProps> = (props: LoginButtonViewProps) => {
  const classes = useStyles();
  const { samlProviders, selectEmailLogin, showLogin } = props;
  const { clientId, redirectUrl, authServerDomain } = useContext(ServerPublicMetaContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleAnchorOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.buttonContainer}>
      {showLogin === 'both' && (
        <TGLoginButton type={TGLoginButtonType.primary} onClick={selectEmailLogin}>
          <> {magicText.t('Login with email')}</>
        </TGLoginButton>
      )}
      {!!samlProviders && samlProviders.length >= 1 && showLogin === 'both' && (
        <Typography variant="h3" className={classes.dividerText}>
          <> {magicText.t('OR')}</>
        </Typography>
      )}
      {!!samlProviders && samlProviders.length === 1 && (
        <TGLoginButton type={TGLoginButtonType.secondary} onClick={handleAnchorOpen}>
          <Link
            href={`https://${authServerDomain}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=email+openid&redirect_uri=${redirectUrl}&identity_provider=${samlProviders[0].providerName}`}
          >
            <> {magicText.t(`Login with ${samlProviders[0].providerName}`)}</>
          </Link>
        </TGLoginButton>
      )}
      {!!samlProviders && samlProviders.length > 1 && (
        <TGLoginButton
          type={TGLoginButtonType.secondary}
          onClick={handleAnchorOpen}
          aria-controls="saml-menu"
          aria-haspopup="true"
        >
          <>
            {magicText.t('Login with organization')}
            <Menu id="saml-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleAnchorClose}>
              {samlProviders &&
                samlProviders.map(({ label, providerName }: { label: string; providerName: string }) => (
                  <MenuItem onClick={handleAnchorClose} key={label}>
                    <Link
                      href={`https://${authServerDomain}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=email+openid&redirect_uri=${redirectUrl}&identity_provider=${providerName}`}
                      className={classes.menuText}
                    >
                      {label}
                    </Link>
                  </MenuItem>
                ))}
            </Menu>
          </>
        </TGLoginButton>
      )}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
      marginTop: 28,
    },
    dividerText: {
      color: colors.black,
      marginTop: 10,
      marginBottom: 7,
      alignSelf: 'center',
    },
    menuText: {
      color: colors.black,
    },
  })
);

export default LoginButtonView;
