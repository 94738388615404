import { useCallback } from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import csvFileIcon from '../../images/csvFileIcon.svg';
import xlsFileIcon from '../../images/xlsFileIcon.svg';
import clearImportButton from '../../images/clearImportButton.svg';
import { UseConditionalImport } from '../../hooks/useConditionalImports';
import { useInfoContext } from '../../contexts/InfoContext/infoContext';

const useDropzoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileuploader: {
      position: 'relative',
      height: '537px',
      width: '100%',
      border: `4px dashed ${theme.palette.secondary.main}`,
      background: '#f6f6f6',
      margin: '10px 8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '&.disabled': {
        opacity: 0.33,
      }
    },
    dragActive: {
      border: `4px dashed ${theme.palette.primary.main}`,
    },
    title: {
      position: 'relative',
      color: '#656565',
      textAlign: 'center',

      '&.disabled': {
        cursor: 'inherit !important',
        color: 'inherit !important',
        borderBottom: 'inherit !important'
      }
    },
    uploadbtn: {
      margin: '20px',
    },
    formats: {
      fontSize: '13px',
    },
    errorText: {
      color: '#eb5757',
      position: 'relative',
      textAlign: 'center',
      marginTop: '30px',
    },
  })
);

interface Props {
  onBack?: () => void;
  changeHandler: (e: FileWithPath[]) => void;
  isSelected: boolean;
  selectedFile: FileWithPath | undefined;
  clearSelection: () => void;
  disabled: boolean;
  tooltip: JSX.Element | string;
  selected?: UseConditionalImport;
}

const ImportDropZone = (props: Props) => {
  const { changeHandler, isSelected, selectedFile, clearSelection, disabled, tooltip, selected } = props;
  const dropZoneStyles = useDropzoneStyles();
  const theme = useTheme();
  const {openSnackbar} = useInfoContext();
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], rejectedFiles:FileRejection[]) => {
      if (acceptedFiles && acceptedFiles.length === 1) {
        changeHandler(acceptedFiles);
      }
      if (acceptedFiles && acceptedFiles.length > 1) {
        openSnackbar({
          type: 'ERROR',
          title: 'You can only select one file'
        })
      }
      if(rejectedFiles && rejectedFiles.length){
        openSnackbar({
          type: 'ERROR',
          title: 'Invalid file selected: ' + rejectedFiles.map((f)=>(f.file.name + ' - ' + f.errors.map((e)=>(e.message))))
        })
      }
    },
    [changeHandler]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: selected?.fileTypes?.split(',').map((ft)=>('.'+ft)) || ['.csv', '.xlsx'],
    onDrop: onDrop,
    noClick: true,
    disabled: disabled,
  });

  const fileExtension: string | undefined = selectedFile?.name.split('.').pop();
  const isXLS: boolean = fileExtension === 'xlsx' || fileExtension === 'xls';

  return (
    <Tooltip title={tooltip}>
      <div className={clsx(dropZoneStyles.fileuploader, isDragActive && dropZoneStyles.dragActive, (disabled ? 'disabled' : ''))} {...getRootProps()}>
        <input {...getInputProps()} />
        {isSelected && selectedFile ? (
          <div
            style={{
              height: '80%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: '#F1F1F1',
                display: 'flex',
                justifyContent: 'center',
                height: 83,
                width: 137,
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
              }}
            >
              <div style={{ flex: 1 }}></div>
              <div style={{ flex: 2 }}>
                <img
                  src={isXLS ? xlsFileIcon : csvFileIcon}
                  style={{ marginTop: '3px' }}
                  height={80}
                  width={80}
                  alt="File Icon"
                />
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={clearImportButton}
                  alt="Clear Selection Button"
                  height={26}
                  width={26}
                  style={{ marginTop: '2px', cursor: 'pointer' }}
                  onClick={clearSelection}
                />
              </div>
            </div>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: 300,
                lineHeight: '28px',
                marginTop: '10px',
                letterSpacing: '0px',
                textAlign: 'center',
              }}
            >
              {selectedFile?.path}
            </Typography>
          </div>
        ) : (
          <div>
            <div id="upload-label" style={{ width: '200px', margin: 'auto', textAlign: 'center' }}>
              <CloudUploadIcon color="disabled" style={{ fontSize: 120 }} />
              <br />
            </div>
            <div
              style={{
                width: '100%',
                margin: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography style={{ fontFamily: 'Roboto', fontWeight: 300, fontSize: '28px', lineHeight: '32px' }}>
                {'Drag & Drop file here'}
              </Typography>
              <br />
              <Typography style={{ fontFamily: 'Roboto', fontWeight: 300, fontSize: '20px', lineHeight: '23px' }}>
                or
              </Typography>
              <br />
              <Typography
                onClick={open}
                className={clsx(dropZoneStyles.title, (disabled ? 'disabled' : ''))}
                style={{
                  width: '220px',
                  cursor: 'pointer',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  fontSize: 20,
                  lineHeight: '23px',
                  color: `${theme.palette.primary.main || "#56CCF2"}`,
                  borderBottom: `1px solid ${theme.palette.primary.main || "#56CCF2"}`
                }}
              >
                Select a file from device
              </Typography>
              {false && <Typography className={dropZoneStyles.errorText}>{'error'}</Typography>}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ImportDropZone;
