import { FilterState } from '../../FilterContext/types';
import { MapAssetType } from '../types';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';

const doesFilterAllow = (
  asset: MapAssetType,
  filterState: FilterState,
  field: string,
  aggregateDefinition: AggregateDefinition | undefined
): boolean => {
  const actualField = field.split('-')[0];
  const currType: string | undefined = aggregateDefinition?.propertyDefinitions.find(obj => obj.field === actualField)
    ?.type;

  // Date only filtering
  if (currType && currType === 'Date') {
    const startDate = new Date(filterState[field][0]).getTime();
    const endDate = new Date(filterState[field][1]).getTime();
    const comparisonDate = new Date(asset[field] as string);
    const offset = comparisonDate.getTimezoneOffset() * 60000;
    const comparisonDateWithOffset = comparisonDate.getTime() + offset;

    return startDate <= comparisonDateWithOffset && comparisonDateWithOffset <= endDate;
    // added DateTime filtering
  } else if (currType && currType === 'DateTime') {
    const startDate = new Date(filterState[field][0]).getTime();
    const endDate = new Date(filterState[field][1]).getTime();
    const comparisonDate = new Date(asset[field] as string).getTime();

    return startDate <= comparisonDate && comparisonDate <= endDate;
    // Date fields, previous date filtering, check this
  }
  if (field.toLowerCase().includes('date') && !field.endsWith('-QuickFilter')) {
    const startDate = new Date(filterState[field][0]).getTime();
    const endDate = new Date(filterState[field][1]).getTime();
    const comparisonDate = new Date(asset[field] as string).getTime();
    return startDate <= comparisonDate && comparisonDate <= endDate;
    // Searches in table column search box
  } else if (field.endsWith('-QuickFilter') && asset[actualField]) {
    // Columns with relationship values are objects, or null, but not strings, so we need to handle accordingly
    if (!!asset[actualField] && typeof asset[actualField] === 'object') {
      //TODO: technically this could be other values
      const assetActualField = asset[actualField] as { id: string; label: string };
      return assetActualField.label.toLowerCase().includes(filterState[field].toLowerCase());
    }
    return asset[actualField].toString().toLowerCase().includes(filterState[field].toLowerCase());
  } else {
    const assetFieldValue: any = asset[field];
    // Columns with relationship values are objects, or null, but not strings, so we need to handle accordingly
    if (!!assetFieldValue && typeof assetFieldValue === 'object') {
      return filterState[field].map((value: any) => value?.label).includes(assetFieldValue?.label);
    }
    return filterState[field].includes(assetFieldValue);
  }
};

export const filterByColumns = (
  assets: MapAssetType[],
  getFilterStateFromKey: (key: string) => FilterState,
  aggregateDefinitions: AggregateDefinition[]
) => {
  return assets.filter(asset => {
    const filterState = getFilterStateFromKey(asset.recordTypeKey);
    const filterKeys = Object.keys(filterState);
    const aggregateDefinition: AggregateDefinition | undefined = aggregateDefinitions.find(
      obj => obj.queryKey === asset.recordTypeKey
    );
    if (filterKeys.length > 0) {
      return filterKeys.reduce<boolean>(
        (result, field) => result && doesFilterAllow(asset, filterState, field, aggregateDefinition),
        true
      );
    }
    return true;
  });
};
