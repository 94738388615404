import React from 'react';
import TGDrawer from '../components/TGDrawer/TGDrawer';
import { useSections } from '../hooks/useSections';
import AboutMenuItemModalContainer from './AboutMenuItemModalContainer';

export const OPENED_DRAWER_WIDTH = 165;

export interface MapRightDrawerContainerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}
const MapRightDrawerContainer = (props: MapRightDrawerContainerProps) => {
  const { open, setOpen } = props;
  const { items, isAboutOpen, setIsAboutOpen } = useSections();

  const closeAboutModal = () => {
    setIsAboutOpen(false);
  };

  return (
    <div>
      <TGDrawer open={open} setOpen={setOpen} items={items} width={OPENED_DRAWER_WIDTH} />
      <AboutMenuItemModalContainer openAbout={isAboutOpen} closeAboutModal={closeAboutModal} />
    </div>
  );
};

export default React.memo(MapRightDrawerContainer);
