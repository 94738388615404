import { useContext } from 'react';
import { mapTypeSection } from '../../components/MapRightDrawer/MapTypeSection';
import { MapStyleContext, MapTypes } from '../../contexts/mapStyle';
import magicText from 'i18next';
import { useConfig } from '@terragotech/gen5-shared-components';

export const useMapTypeSection = () => {
  const { setMapType: setMapStyle, mapType } = useContext(MapStyleContext);
  const { integrations } = useConfig();
  const defaultMapStyles: ReadonlyArray<{ label: string; value: MapTypes }> = [
    { label: magicText.t('map.styles.light'), value: 'light' },
    { label: magicText.t('map.styles.dark'), value: 'dark' },
    { label: magicText.t('map.styles.satellite'), value: 'satellite' },
    { label: magicText.t('map.styles.satellite-hybrid'), value: 'satellite-hybrid' },
    { label: magicText.t('map.styles.streets'), value: 'streets' },
  ];
  const mapStylesPairs: ReadonlyArray<{ label: string; value: MapTypes }> = integrations?.nearMaps
    ? [...defaultMapStyles, { label: magicText.t('map.styles.nearMaps'), value: 'nearmaps' }]
    : defaultMapStyles;
  const setMapType = (label: string) => {
    const value = mapStylesPairs.find((x) => x.label === label)?.value;
    if (value) {
      setMapStyle(value);
    }
  };
  const currentMapTypeLabel = mapStylesPairs.find((x) => x.value === mapType)?.label;

  const section = mapTypeSection({
    allowedTypes: mapStylesPairs.map((x) => x.label),
    currentType: currentMapTypeLabel ?? '',
    onChange: setMapType,
  });
  return { section };
};
