import React, { useMemo, useState } from 'react';
import { Checkbox, Button, InputAdornment, Modal, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import magicText from 'i18next';
import { TableData } from '../../hooks/useTable';
import { Editor2Props } from '@terragotech/react-data-grid';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { User, UserWithoutId } from './types';
import useStyles, { getClasses } from '../../components/StyledTable/Editors/Common';
import { permissionLabel } from '@terragotech/gen5-shared-utilities';
import { useConfig, useUserInfo } from '@terragotech/gen5-shared-components';

export interface AdminPermissionEditorModalProp extends Editor2Props<TableData> {
  numberOfUsersWithAnalyticsPermissions: number;
}

const AdminPermissionsEditorModal = (props: AdminPermissionEditorModalProp) => {
  const { row, onRowChange, onClose, numberOfUsersWithAnalyticsPermissions } = props;
  const classes = useLocalStyles();
  const tableClasses = useStyles();
  const { isTgoAdmin } = useUserInfo();
  const uiConfig = useConfig();

  const analyticsEnabled = useMemo(() => uiConfig.enabledFeatures?.analyticsModule, [uiConfig]);
  const analyticsMaxSeatCount = useMemo(() => uiConfig.analyticsMaxSeatCount, [uiConfig]);
  const mapServicesEnabled = useMemo(() => uiConfig.integrations?.mapServices, [uiConfig]);

  const canModifyAnalyticsStatus = isTgoAdmin;
  const canModifyMapServicesPermissions = isTgoAdmin;

  const [isModalOpen, setIsModalOpen] = useState(false);
  //I don't like these casts, but nested objects don't work well with ValueType
  const [isUserAdmin, setIsUserAdmin] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isUserAdmin
  );
  const [isRoleAndDataAdmin, setIsRoleAndDataAdmin] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isRoleAndDataAdmin
  );
  const [isAnalyticsUser, setIsAnalyticsUser] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isAnalyticsUser
  );
  const [isMapServicesUser, setIsMapServicesUser] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isMapServicesUser
  );

  const [currentAnalyticsSeatCount, setCurrentAnalyticsSeatCount] = useState(numberOfUsersWithAnalyticsPermissions);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOnClick = () => {
    setIsModalOpen(true);
  };
  const handleOkClicked = () => {
    onRowChange(
      {
        ...row,
        adminPermissions: {
          isUserAdmin,
          isRoleAndDataAdmin,
          isAnalyticsUser,
          isMapServicesUser,
        },
        __changes: {
          adminPermissions:
            row.isUserAdmin !== isUserAdmin ||
            row.isRoleAndDataAdmin !== isRoleAndDataAdmin ||
            row.isAnalyticsUser !== isAnalyticsUser ||
            row.isMapServicesUser !== isMapServicesUser
              ? {
                  isUserAdmin: isUserAdmin,
                  isRoleAndDataAdmin: isRoleAndDataAdmin,
                  isAnalyticsUser: isAnalyticsUser,
                  isMapServicesUser: isMapServicesUser,
                }
              : undefined,
        },
      },
      true // Commit change immediately to the rowData
    );
    handleClose();
  };
  const handleCancelClicked = () => {
    handleClose();
    onClose(false);
  };
  return (
    <div className={getClasses(props, tableClasses).join(' ')}>
      <TextField
        value={permissionLabel((row as User).adminPermissions).join(', ')}
        /*onClick={handleOnClick}*/
        inputProps={{ 'aria-label': `change data`, className: classes.input }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown color="primary" className={classes.icon} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        onClick={handleOnClick}
      />
      <Modal open={isModalOpen} onClose={handleClose}>
        <div className={classes.modal}>
          <div>
            <div className={classes.headerTitle}>
              <>{magicText.t('UsersAdministration.adminPermissionModalTitle')}</>
            </div>
            <div className={classes.headerDescription}>
              <> {magicText.t('UsersAdministration.adminPermissionModalDescription')}</>
            </div>
          </div>
          <div className={classes.permissionContainer}>
            <div>
              <Checkbox
                className={classes.checkbox}
                checked={Boolean(isUserAdmin)}
                onChange={() => setIsUserAdmin(!isUserAdmin)}
                color={'primary'}
                disableRipple={true}
              />
            </div>
            <div className={classes.descriptionContainer}>
              <div className={classes.titleText}>
                <>{magicText.t('UsersAdministration.manage_users')}</>
              </div>
              <div className={classes.descriptionText}>
                <> {magicText.t('UsersAdministration.manage_users_description')}</>
              </div>
            </div>
          </div>
          <div className={classes.permissionContainer}>
            <div>
              <Checkbox
                className={classes.checkbox}
                checked={Boolean(isRoleAndDataAdmin)}
                onChange={() => setIsRoleAndDataAdmin(!isRoleAndDataAdmin)}
                color={'primary'}
                disableRipple={true}
              />
            </div>
            <div className={classes.descriptionContainer}>
              <div className={classes.titleText}>
                <>{magicText.t('UsersAdministration.manage_roles')}</>
              </div>
              <div className={classes.descriptionText}>
                <> {magicText.t('UsersAdministration.manage_roles_description')}</>
              </div>
            </div>
          </div>
          {canModifyMapServicesPermissions && mapServicesEnabled && (
            <div className={classes.permissionContainer}>
              <div>
                <Checkbox
                  className={classes.checkbox}
                  checked={Boolean(isMapServicesUser)}
                  onChange={() => setIsMapServicesUser(!isMapServicesUser)}
                  color={'primary'}
                  disableRipple={true}
                />
              </div>
              <div className={classes.descriptionContainer}>
                <div className={classes.titleText}>
                  <>{magicText.t('UsersAdministration.map_services_users')}</>
                </div>
                <div className={classes.descriptionText}>
                  <> {magicText.t('UsersAdministration.map_services_users_description')}</>
                </div>
              </div>
            </div>
          )}
          {
            //only TGO Admin have the ability to set a user analytics enabled.
            canModifyAnalyticsStatus && analyticsEnabled && (
              <div className={classes.permissionContainer}>
                <div>
                  <Checkbox
                    className={classes.checkbox}
                    checked={Boolean(isAnalyticsUser)}
                    onChange={e => {
                      setCurrentAnalyticsSeatCount(
                        isAnalyticsUser ? currentAnalyticsSeatCount - 1 : currentAnalyticsSeatCount + 1
                      );
                      setIsAnalyticsUser(!isAnalyticsUser);
                    }}
                    color={'primary'}
                    disableRipple={true}
                    disabled={currentAnalyticsSeatCount >= analyticsMaxSeatCount && !isAnalyticsUser}
                  />
                </div>
                <div className={classes.descriptionContainer}>
                  <div className={classes.titleText}>
                    <>{magicText.t('UsersAdministration.view_analytics_module')}</>
                  </div>
                  <div className={classes.descriptionText}>
                    <> {magicText.t('UsersAdministration.view_analytics_module_description')}</>
                  </div>
                  <div
                    className={
                      currentAnalyticsSeatCount < analyticsMaxSeatCount
                        ? classes.availableLicense
                        : classes.unavailableLicense
                    }
                  >
                    <>
                      {' '}
                      {magicText.t('UsersAdministration.licenses_available')}:{' '}
                      {`${analyticsMaxSeatCount - currentAnalyticsSeatCount} of ${analyticsMaxSeatCount}.`}{' '}
                      {currentAnalyticsSeatCount >= analyticsMaxSeatCount && (
                        <span style={{ fontWeight: 'normal' }}>
                          <>{magicText.t('UsersAdministration.licenses_unavailable_description')}</>
                        </span>
                      )}
                    </>
                  </div>
                </div>
              </div>
            )
          }
          <div className={classes.buttonContainer}>
            <Button onClick={handleCancelClicked} className={classes.button}>
              <> {magicText.t('cancel')}</>
            </Button>
            <Button onClick={handleOkClicked} className={classes.button}>
              <> {magicText.t('ok')}</>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const useLocalStyles = makeStyles((theme: Theme) => ({
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    width: 578,
    padding: '20px 4px 10px 20px',
    backgroundColor: 'white',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    padding: 0,
    paddingRight: '4px',
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    paddingBottom: '26px',
  },
  headerDescription: {
    color: `${theme.palette.grey[500]}`,
    fontSize: '16px',
    lineHeight: '19px',
  },
  permissionContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: '10px',
  },
  descriptionContainer: {
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flex: '0 1',
    justifyContent: 'flex-end',
  },
  titleText: {
    color: '#1D1D1F',
    fontSize: '14px',
    lineHeight: '16px',
    paddingBottom: '4px',
  },
  descriptionText: {
    color: `${theme.palette.grey[500]}`,
    fontSize: '13px',
    lineHeight: '128.9%',
  },
  button: {
    color: `${theme.palette.primary.main}`,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    margin: '0px 10px',
    textTransform: 'uppercase',
  },
  input: {
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 0,
    verticalAlign: 'middle',
  },
  icon: {
    width: '15px',
    height: '15px',
  },
  availableLicense: {
    color: `${theme.palette.success.main}`,
    fontSize: '13px',
    lineHeight: '128.9%',
    fontWeight: 500,
  },
  unavailableLicense: {
    color: `${theme.palette.error.main}`,
    fontSize: '13px',
    lineHeight: '128.9%',
    fontWeight: 500,
  },
}));

export default AdminPermissionsEditorModal;
