import _ from 'lodash';

export const getBoundsArrayFromResult = (result: google.maps.GeocoderResult) => {
  const viewport = result.geometry.viewport;
  const viewportKeys = Object.keys(viewport) as (keyof typeof viewport)[];

  const lngObject = viewport[viewportKeys[1]];

  const lngKeys = Object.keys(lngObject) as (keyof typeof lngObject)[];
  const lngLeft = lngObject[lngKeys[0]];
  const lngRight = lngObject[lngKeys[1]];

  const latObject = viewport[viewportKeys[0]];

  const latKeys = Object.keys(latObject) as (keyof typeof latObject)[];
  const latBottom = latObject[latKeys[1]];
  const latTop = latObject[latKeys[1]];

  return [
    [lngLeft, latBottom],
    [lngRight, latTop],
  ];
};

// const longitudeLeftDelta = 0.00714544333 / 2;
// const latitudeLeftDelta = 0.00421659333 / 2;
// const longitudeRightDelta = 0.00484386333 / 2;
// const latitudeRightDelta = 0.00860203333 / 2;


export const getBoundsFromLatLng = (locationString: string) => {
  if (!_.isEmpty(locationString)) {
    const coords = _.split(_.trim(locationString), ',');
    if (coords.length === 2) {
      const latitude = _.toNumber(coords[0]);
      const longitude = _.toNumber(coords[1]);
      const isLatitude = isFinite(latitude) && Math.abs(latitude) <= 90;
      const isLongitude = isFinite(longitude) && Math.abs(longitude) <= 180;
      if (isLatitude && isLongitude) {
        return [
          [longitude, latitude],
          [longitude, latitude ]
        ];
      }
    }
  }
  return null;
};
