import { Button, makeStyles, Input, InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useState } from 'react';
import { Search } from '@material-ui/icons';
import { colors } from '../../styles/theme';
import { sortBy } from 'lodash';
import magicText from 'i18next';
import { ColumnFilterInputOptions } from '../../hooks/tableHooks/useColumns';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    height: 46,
    minHeight: 46,
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'space-between',
    paddingLeft: 12,
  },
  headerText: {
    color: '#fff',
    textTransform: 'none',
    alignSelf: 'center',
    fontSize: 18,
  },
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    height: 46,
    minHeight: 46,
    backgroundColor: '#f8f8f8',
    paddingLeft: 4,
  },
  searchBar: {
    backgroundColor: '#fff',
    color: theme.palette.grey[200],
    padding: '3px 0px',
    display: 'inline-flex',
    width: '100%',
    height: 46,
    minHeight: 46,
    borderTop: '1px solid #eee',
    borderBottom: '1px solid #eee',
  },
  searchIcon: {
    height: 24,
    width: 24,
    paddingLeft: '12px',
    color: theme.palette.grey[400],
  },
  input: {
    width: '100%',
    fontSize: 16,
    fontWeight: 'normal',
  },
  button: {
    color: colors.white,
    '& input': {
      color: theme.palette.primary.main,
    },
  },
  filterItems: {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
  },
  text: {
    color: '#3a3a3a',
  },
  checkbox: {
    paddingLeft: '16px',
  },
}));

interface FilterDialogWrapperProps {
  onClose: (selectedElements: ReadonlyArray<string>) => void;
  title: string;
  elements: ReadonlyArray<string>;
  selectedElements: ReadonlyArray<string>;
  inputOptions?: ColumnFilterInputOptions;
}

const FilterDialogWrapper = (props: FilterDialogWrapperProps) => {
  const classes = useStyles();

  const [selectedElements, setSelectedElements] = useState(props.selectedElements || []);

  const [searchValue, setSearchValue] = useState('');

  const toggleCheckbox = (element: string) => {
    if (selectedElements.includes(element)) {
      setSelectedElements(selectedElements.filter((x) => x !== element));
    } else {
      setSelectedElements([...selectedElements, element]);
    }
  };

  const checkbox = (element: string) => (
    <Checkbox checked={selectedElements.includes(element)} onChange={() => toggleCheckbox(element)} />
  );

  const regexp = (() => {
    try {
      return new RegExp(searchValue, 'gi');
    } catch (e) {
      console.error(e);
      return '';
    }
  })();

  const getElementLabel = (element: string) =>
    props.inputOptions?.labelConverter?.(element) || element || magicText.t<string>('Simple.Blanks');

  const filteredElements = sortBy(
    props.elements.filter((x) => selectedElements.includes(x) || x.match(regexp)),
    (v) => getElementLabel(v)
  );
  const allSelected = selectedElements.length === props.elements.length;

  const selectAll = () => {
    if (allSelected) setSelectedElements([]);
    else setSelectedElements([...props.elements]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerText}>{props.title}</div>
        <Button className={classes.button} onClick={() => props.onClose(selectedElements)}>
          Done
        </Button>
      </div>
      <div className={classes.selectAll}>
        <Button className={classes.text} onClick={selectAll}>
          {allSelected ? 'Deselect All' : 'Select All'}
        </Button>
      </div>
      <div className={classes.searchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          disableUnderline
          placeholder={`Search Attributes`}
          className={classes.input}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className={classes.filterItems}>
        {filteredElements.map((element) => (
          <FormGroup key={`filter-dialog-${element}`} row className={classes.checkbox}>
            <FormControlLabel control={checkbox(element)} label={getElementLabel(element)} />
          </FormGroup>
        ))}
      </div>
    </div>
  );
};

export default FilterDialogWrapper;
