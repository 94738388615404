import { createStyles, Theme, makeStyles, Slide } from '@material-ui/core';
import React, { FunctionComponent, useContext, useState, useEffect, useRef } from 'react';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { MultiSelectContextProvider } from 'use-multiselect';
import BottomRecordTypeSelectorContainer from '../../containers/BottomRecordTypeSelectorContainer';
import AssetsTableContainer from '../../containers/AssetsTableContainer';
import TopSplitter from '../../components/TopSplitter';
import { EditModeContext } from '../../contexts/editModeContext';
import { SelectedAssetProvider } from '../../contexts/selectedAssetContext';
import './AssetsDashboard.css';
import AssetDashboardFABContainer from '../../containers/AssetDashboardFABContainer';
import UsersAndRoles from '../../containers/UsersAndRolesContainer';
import { useAccountModal } from '../../contexts/accountModalContext';
import { useAnalyticsModal } from '../../contexts/analyticsModalContext';
import { ProjectsProvider } from '../../contexts/projectsContext';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { AggregatesContextProvider } from '../../contexts/AggregatesContext';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import { ActionsExecutorProvider } from '../../hooks/useActionsExecutor';
import { AssetsTableStateContextProvider } from '../../contexts/assetsTableStateContext';
import { PhotoViewerProvider } from '../../hooks/usePhotoViewer';
import Analytics from '../../containers/AnalyticsContainer';
import { useImportModal } from '../../contexts/importModalContext';
import Import from '../../containers/ImportContainer';
import { useWatchAuthState } from '../../hooks/useAuth';
import { FileViewerProvider } from '../../hooks/useFileViewer';
import { useAuthContext, AlertDialog } from '@terragotech/gen5-shared-components';

const ASSET_TYPE_BAR_SIZE = 55;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100%',
    },
    topSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
    leftSection: {
      display: 'flex',
      flex: 1,
    },
    topRightSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
  })
);

export type AssetsDashboardProps = {};
export const switchPointPercentage = 90;

function checkLocalStorage() {
  try {
    return parseInt(localStorage.getItem('width') || '200');
  } catch (e) {}
  return 200;
}

const pixelsToVerticalPercentage = (px: number) => (px / window.innerHeight) * 100;

const initialSideBarWidth = checkLocalStorage;

/**
 * AssetsDashboard - first component processed when a user successfully logs in
 *
 * Divides up the Dashboard.
 *
 *             top section
 *        ----------------------- (Can adjust the height by dragging the divide (SplitterLayout))
 *            bottom section
 *
 * Top section : top left (<Sidebar />) and top right (<AssetDetailSection />)
 *
 * Bottom: <AssetDetailContainer />
 *
 */

const AssetsDashboard: FunctionComponent<AssetsDashboardProps> = props => {
  const classes = useStyles();
  const [width, setWidth] = useState(initialSideBarWidth);
  const [height, setHeight] = useState((43 / 100) * window.innerHeight);
  const { isUserAndRolesOpen, closeUserAndRoles } = useAccountModal();
  const { editModeActive } = useContext(EditModeContext);
  const { isAnalyticsOpen, closeAnalytics } = useAnalyticsModal();
  const { isImportOpen, closeImport } = useImportModal();
  const { verticalPercentage, setVerticalPercentage, fullscreen, setFullscreen } = useContext(AssetsDashboardContext);
  const { clearToken } = useAuthContext();
  const splitterLayoutRef = useRef<SplitterLayout | null>(null);
  const { dialogMessage, setDialogMessage } = useWatchAuthState();

  useEffect(() => {
    function handleResize() {
      setHeight((verticalPercentage / 100) * window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [verticalPercentage]);

  const onDragEnd = (verticalPercentage: number) => {
    if (verticalPercentage >= switchPointPercentage) {
      setVerticalPercentage(100);
    }
    setHeight((verticalPercentage / 100) * window.innerHeight);
    setFullscreen(verticalPercentage >= switchPointPercentage);
  };

  const minimizeAndExpandTable = (verticalPercentage: number) => {
    setVerticalPercentage(verticalPercentage);
    setHeight((verticalPercentage / 100) * window.innerHeight);
    setFullscreen(verticalPercentage >= switchPointPercentage);
    splitterLayoutRef?.current && splitterLayoutRef.current.setState({ secondaryPaneSize: verticalPercentage });
  };

  return (
    <ProjectsProvider>
      <AggregatesContextProvider>
        <AssetsTableStateContextProvider>
          <SelectedAssetProvider>
            <ActionsExecutorProvider>
              <PhotoViewerProvider>
                <FileViewerProvider>
                  {!fullscreen ? (
                    <SplitterLayout
                      ref={splitterLayoutRef}
                      percentage={true}
                      secondaryInitialSize={verticalPercentage}
                      vertical={true}
                      customClassName="custom-splitter"
                      onSecondaryPaneSizeChange={setVerticalPercentage}
                      onDragEnd={() => onDragEnd(verticalPercentage)}
                      secondaryMinSize={pixelsToVerticalPercentage(ASSET_TYPE_BAR_SIZE)}
                    >
                      <TopSplitter height={height} inProp={!editModeActive} width={width} setWidth={setWidth} />
                      <div className={classes.bottomSection}>
                        {
                          // @ts-ignore
                          <MultiSelectContextProvider>
                            <AssetsTableContainer height={height} minimizeAndExpandTable={minimizeAndExpandTable} />
                          </MultiSelectContextProvider>
                        }
                        <BottomRecordTypeSelectorContainer />
                      </div>
                    </SplitterLayout>
                  ) : (
                    <TopSplitter
                      height={height}
                      inProp={!editModeActive}
                      width={width}
                      setWidth={setWidth}
                      onDragEnd={onDragEnd}
                      minimizeAndExpandTable={minimizeAndExpandTable}
                    />
                  )}
                  <AssetDashboardFABContainer isHidden={isUserAndRolesOpen || isAnalyticsOpen || isImportOpen} />
                  <Slide
                    direction="up"
                    in={isUserAndRolesOpen || isAnalyticsOpen || isImportOpen}
                    mountOnEnter
                    unmountOnExit
                    timeout={600}
                    style={{ zIndex: USERS_AND_ROLES_Z_INDEX, position: 'relative' }}
                  >
                    <div>
                      {isUserAndRolesOpen && <UsersAndRoles onBack={closeUserAndRoles} />}
                      {isAnalyticsOpen && <Analytics onBack={closeAnalytics} />}
                      {isImportOpen && <Import onBack={closeImport} />}
                    </div>
                  </Slide>
                </FileViewerProvider>
              </PhotoViewerProvider>
            </ActionsExecutorProvider>
          </SelectedAssetProvider>
        </AssetsTableStateContextProvider>
      </AggregatesContextProvider>
      {dialogMessage && (
        <AlertDialog
          title={'Your session is about to expire and you must log in again.'}
          okText={'LOGOUT'}
          cancelText={'CONTINUE'}
          onOkPress={() => {
            clearToken();
            setDialogMessage('');
          }}
          onCancelPress={() => {
            setDialogMessage('');
            setTimeout(() => {
              clearToken();
            }, 1000 * 60);
          }}
        >
          {dialogMessage}
        </AlertDialog>
      )}
    </ProjectsProvider>
  );
};
export default AssetsDashboard;
