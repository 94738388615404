import React from 'react';
import { ListItemText, Typography, makeStyles, List, ListItemIcon, ListItem } from '@material-ui/core';
import magicText from 'i18next';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAuthContext, colors } from '@terragotech/gen5-shared-components';

export interface UserInfoSectionProps {
  firstName: string;
  lastName: string;
  email: string;
  clearToken?: () => void;
}

const NUMBER_OF_VISIBLE_CHARS_FROM_END = 8;

const Content = (props: UserInfoSectionProps) => {
  const classes = useStyles();
  const { clearToken } = useAuthContext();
  return (
    <List className={classes.container}>
      <ListItem disableGutters className={classes.item}>
        <ListItemText>
          <Typography component="span" className={classes.name}>
            <div className={classes.firstName}>{props.firstName}</div>
            <div className={classes.firstPart}>{props.lastName.slice(0, -1)}</div>
            <div>{props.lastName.slice(-1)}</div>
          </Typography>
          <Typography component="span" className={classes.email}>
            <div className={classes.firstPart}>{props.email.slice(0, -NUMBER_OF_VISIBLE_CHARS_FROM_END)}</div>
            <div>{props.email.slice(-NUMBER_OF_VISIBLE_CHARS_FROM_END)}</div>
          </Typography>
        </ListItemText>
      </ListItem>
      <ListItem disableGutters className={classes.item} onClick={clearToken}>
        <ListItemIcon className={classes.itemIcon}>
          <ExitToAppIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.itemText }} primary={<>{magicText.t('map.settings.logout')}</>} />
      </ListItem>
    </List>
  );
};

export const userInfoSection = (props: UserInfoSectionProps) => ({
  content: <Content {...props} />,
  icon: <ExitToAppIcon />,
  iconClickHandler: props.clearToken,
});

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 0,
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: 14,
  },
  firstName: {
    paddingRight: 7,
  },
  email: {
    fontSize: 12,
    color: colors.grayDescription,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
  },
  firstPart: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  item: {
    padding: 0,
    cursor: 'pointer',
  },
  itemText: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: '13px',
  },
  itemIcon: { minWidth: 0, paddingRight: 5, marginBottom: 0.5 },
}));
