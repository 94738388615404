import { Fade } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useContext, useCallback, useMemo, useEffect } from 'react';
import { EditModeContext } from '../../../contexts/editModeContext';
import { TableDataContext } from '../../../hooks/useTableData';
import SearchBarSelect from './SearchBarSelect';
import SearchInput from './SearchInput';
import InfoSection from './InfoSection';

const styles = () =>
  createStyles({
    mainDiv: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
    },
    searchSection: {
      flex: 1,
      display: 'flex',
      borderTop: '1px solid #eee',
      borderBottom: '1px solid #eee',
    },
    spacer: {
      flex: 1,
      display: 'flex',
    },
  });

interface SearchBarProps extends WithStyles<typeof styles> {
  height: number;
  checked: boolean;
  setChecked: (value: boolean) => void;
  isLoadingData: boolean;
  setSelectedRows: (value: []) => void;
  setSelected: (value: Record<number, boolean | undefined>) => void;
}

const isFilterActive = (filter: string | string[]) => filter && (Array.isArray(filter) ? filter.length > 0 : true);

const SearchBar: FunctionComponent<SearchBarProps> = props => {
  const { classes, height, checked, setChecked, isLoadingData, setSelectedRows, setSelected } = props;

  const { data, filteredData, setFilter, filters, globalFilter } = useContext(TableDataContext);

  const { editModeActive } = useContext(EditModeContext);

  const setSearchText = useCallback((value: string) => setFilter(null, value), [setFilter]);

  const numberOfFilters = useMemo(
    () =>
      Object.keys(filters).reduce((result, key) => result + (isFilterActive(filters[key]) ? 1 : 0), 0) +
      (globalFilter === '' ? 0 : 1),
    [filters, globalFilter]
  );

  const handleFilterReset = () => {
    Object.keys(filters).forEach(key => setFilter(key, ''));
    setSearchText('');
  };

  useEffect(() => {
    // reset all our selections if the user toggles the multiselect button state to off
    if (!checked) {
      setSelectedRows([]);
      setSelected({});
    }
  }, [checked]);

  return (
    <div className={classes.mainDiv} style={{ height: height }}>
      <div className={classes.searchSection}>
        <Fade in={!editModeActive} unmountOnExit>
          <SearchBarSelect checked={checked} setChecked={setChecked} isLoading={isLoadingData} />
        </Fade>
        <SearchInput value={String(globalFilter)} setValue={setSearchText} />
      </div>
      <InfoSection
        isLoadingData={isLoadingData}
        numberOfElements={data.length}
        numberOfVisible={filteredData.length}
        handleFilterReset={handleFilterReset}
        numberOfFilters={numberOfFilters}
      />
      <div className={classes.spacer}></div>
    </div>
  );
};

export default withStyles(styles)(SearchBar);
